import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

function AIQuestionGenerator({
  onCancel,
  onSurveySaved,
  onQuestionsGenerated,
}) {
  const [aiPrompt, setAiPrompt] = useState("");
  const [aiQuestions, setAiQuestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleGenerateAIQuestions = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const url = `https://zola.technology/api/company/surveys/ai/generate-questions/?industry=${encodeURIComponent(
        aiPrompt
      )}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch AI-generated questions");
      }

      const data = await response.json();
      setAiQuestions(data.questions);
      toast.success("AI questions generated successfully!");
    } catch (error) {
      console.error("Failed to generate questions:", error);
      toast.error("Failed to generate questions. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitAIQuestions = async () => {
    if (aiQuestions.length === 0) {
      toast.error("No AI questions to submit. Generate questions first.");
      return;
    }

    const token = localStorage.getItem("token");
    const userData = JSON.parse(localStorage.getItem("userData"));
    const preferredBusinessName = userData?.preferred_business_name || "Survey";

    // Prepare the payload for submission
    const surveyData = {
      title: `${preferredBusinessName} survey`,
      questions: aiQuestions.map((q) => ({
        question_text: q.question,
        question_type: q.type === "multiple_choice" ? "multi_choice" : q.type,
        choices: q.choices || [],
      })),
    };

    try {
      const response = await fetch(
        "https://zola.technology/api/company/surveys/add-survey/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(surveyData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        toast.success("Survey submitted successfully!");
        console.log("Submitted Survey:", data);

        // Clear state after successful submission
        setAiPrompt("");
        setAiQuestions([]);
        onSurveySaved();
        onQuestionsGenerated();
      } else {
        const errorData = await response.json();
        console.error("Submission failed:", errorData);
        toast.error("Failed to submit survey. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting survey:", error);
      toast.error("An error occurred during submission. Please try again.");
    }
  };

  const handleRegenerateQuestions = () => {
    setAiPrompt("");
    setAiQuestions([]);
    toast.info("AI questions cleared. Please generate again.");
  };

  return (
    <div className="h-[75vh] flex flex-col bg-white dark:bg-gray-900 rounded-lg shadow-lg p-4 md:p-6 space-y-4">
      <ToastContainer position="top-right" autoClose={3000} />

      <div className="flex-grow overflow-y-scroll space-y-4 p-2">
        {loading ? (
          <div className="flex flex-col items-start justify-start h-full">
            <h2 className="text-xl md:text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">
              Generating Questions...
            </h2>
            <p className="text-gray-600 dark:text-gray-300 text-lg md:text-xl animate-pulse">
              <span>Generating</span>
              <span className="animate-typing ml-1"></span>
            </p>
          </div>
        ) : aiQuestions.length > 0 ? (
          <>
            <h2 className="text-xl md:text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">
              AI-Generated Survey Questions
            </h2>
            {aiQuestions.map((question, index) => (
              <div
                key={`ai-question-${index}`}
                className="p-3 md:p-4 bg-gray-100 dark:bg-gray-800 rounded-lg border border-gray-300 dark:border-gray-700 shadow"
              >
                <h4 className="font-semibold text-md md:text-lg text-gray-900 dark:text-gray-200">
                  {index + 1}. {question.question}
                </h4>

                {/* Multiple Choice */}
                {question.type === "multiple_choice" && (
                  <ul className="list-disc pl-6 mt-2 md:mt-3 text-gray-700 dark:text-gray-300">
                    {question.choices.map((choice, idx) => (
                      <li
                        key={`option-${index}-${idx}`}
                        className="mb-1 md:mb-2"
                      >
                        {choice}
                      </li>
                    ))}
                  </ul>
                )}

                {/* Numeric Choice */}
                {question.type === "numeric_choice" && (
                  <div className="mt-2 md:mt-3">
                    <h6 className="text-sm font-semibold text-gray-700 dark:text-gray-300">
                      Range:
                    </h6>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                      {question.range}
                    </p>

                    {question.choices.length > 0 && (
                      <div className="flex justify-start space-x-4 mt-2 md:mt-3">
                        <ul className="list-none flex space-x-2">
                          {question.choices.map((option, idx) => (
                            <li
                              key={`option-${index}-${idx}`}
                              className="flex items-center justify-center w-8 h-8 text-sm font-semibold text-white bg-blue-500 dark:bg-blue-600 rounded-full shadow"
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}

                {/* Paragraph */}
                {question.type === "paragraph" && (
                  <textarea
                    className="w-full mt-2 md:mt-3 p-2 md:p-3 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                    placeholder="Write your answer here..."
                    rows={3}
                  ></textarea>
                )}
              </div>
            ))}
          </>
        ) : (
          <p className="text-gray-600 dark:text-gray-300">
            No AI-generated questions yet. Enter your industry and click
            "Generate".
          </p>
        )}
      </div>

      {/* Footer Section with Form Input & Buttons */}
      <div className="pt-4 border-t border-gray-300 dark:border-gray-700 flex flex-col space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Specify your business industry to generate AI-driven survey
            questions:
          </label>
          <input
            type="text"
            value={aiPrompt}
            onChange={(e) => setAiPrompt(e.target.value)}
            className="mt-2 block w-full p-2 md:p-3 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-gray-200 sm:text-sm"
            placeholder="e.g., Healthcare, Education, Retail..."
          />
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
          <button
            onClick={handleGenerateAIQuestions}
            className={`w-full sm:w-auto px-6 py-3 font-semibold rounded-lg shadow-md transition ${
              loading
                ? "bg-gray-400 dark:bg-gray-600 cursor-not-allowed text-white"
                : "bg-blue-600 dark:bg-blue-500 hover:bg-blue-700 dark:hover:bg-blue-400 text-white"
            }`}
            disabled={loading}
          >
            {loading ? "Generating..." : "Generate"}
          </button>
          <button
            onClick={onCancel}
            className="w-full sm:w-auto px-6 py-3 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-200 font-semibold rounded-lg shadow-md hover:bg-gray-400 dark:hover:bg-gray-600 transition"
          >
            Go Back
          </button>
          {aiQuestions.length > 0 && (
            <>
              <button
                onClick={handleSubmitAIQuestions}
                className="w-full sm:w-auto px-6 py-3 bg-green-500 dark:bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 dark:hover:bg-green-500 transition"
              >
                Submit
              </button>
              <button
                onClick={handleRegenerateQuestions}
                className="w-full sm:w-auto px-6 py-3 bg-red-600 dark:bg-red-700 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 dark:hover:bg-red-600 transition"
              >
                Clear
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AIQuestionGenerator;
