import { useState, useEffect } from "react";
import { Clock } from "lucide-react";
import { format, addDays, startOfWeek, parseISO } from "date-fns";
import { useCampaigns } from "../../../context/CampaignContext";

const ScheduleCampaign = ({ className }) => {
  const today = new Date();
  const startOfWeekDate = startOfWeek(today, { weekStartsOn: 1 });

  // Generate week dates dynamically
  const weekDates = Array.from({ length: 7 }, (_, i) => {
    const date = addDays(startOfWeekDate, i);
    return {
      day: format(date, "EEE"),
      date: format(date, "dd"),
      fullDate: format(date, "yyyy-MM-dd"),
    };
  });

  // Set initial selected date dynamically to today's date
  const [selectedDate, setSelectedDate] = useState(format(today, "yyyy-MM-dd"));
  const { campaigns, loading } = useCampaigns();

  useEffect(() => {
    console.log("ScheduleCampaign - Received campaigns:", campaigns);
  }, [campaigns]);

  // Filter campaigns for the selected date
  const filteredCampaigns = (campaigns || []).filter(({ scheduled_at }) =>
    scheduled_at ? format(parseISO(scheduled_at), "yyyy-MM-dd") === selectedDate : false
  );

  // Function to assign alternating colors
  const getBorderColor = (index) => {
    const colors = ["border-l-blue-500", "border-l-yellow-400", "border-l-black"];
    return colors[index % colors.length];
  };

  return (
    <div className={`p-6 bg-white rounded-xl shadow-lg ${className}`}>
      {/* Header */}
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900">Schedule Campaign</h2>
      </div>

      {/* Date Selector */}
      <p className="text-gray-500 text-sm">{format(new Date(selectedDate), "MMMM dd, yyyy")}</p>

      <div className="flex items-center gap-4 mt-3 overflow-x-auto no-scrollbar">
        {weekDates.map(({ day, date, fullDate }) => (
          <button
            key={fullDate}
            onClick={() => setSelectedDate(fullDate)}
            className={`flex flex-col items-center justify-center px-2 py-4 transition relative rounded-full
            ${
              selectedDate === fullDate
                ? "bg-yellow-400 text-gray-900 shadow-md"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            <span className="text-sm">{date}</span>
            <span className="text-xs">{day}</span>

            {/* Black dot indicator for the selected date */}
            {selectedDate === fullDate && (
              <div className="absolute bottom-1 w-2 h-2 bg-black rounded-full"></div>
            )}
          </button>
        ))}
      </div>

      <div className="mt-6 max-h-[300px] overflow-y-auto no-scrollbar">
        {loading ? (
          <p className="text-gray-500">Loading campaigns...</p>
        ) : filteredCampaigns.length > 0 ? (
          filteredCampaigns.map(({ id, name, scheduled_at }, index) => (
            <div key={id} className="relative flex items-center gap-4 mb-4">
              <div className="flex flex-col items-center h-auto font-light text-md justify-center">
                <span>{format(parseISO(scheduled_at), "hh:mm a")}</span>
              </div>
              <div className={`relative p-6 bg-white rounded-xl flex flex-col shadow-md border-l-8 ${getBorderColor(index)} w-full`}>
                <h3 className="text-md font-semibold truncate max-w-[250px]">{name}</h3>
                <p className="text-gray-500 text-sm flex items-center mt-1">
                  <Clock className="w-4 h-4 mr-1" />
                  {format(parseISO(scheduled_at), "hh:mm a")}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No campaign scheduled for this day</p>
        )}
      </div>
    </div>
  );
};

export default ScheduleCampaign;
