import { useEffect, useState } from "react";
import Overview from "./Overview";
import AreaChart from "./Surveyanalytics/SurveyChart";
import { useTimeRange } from "../../context/TimeRangeContext";
import CsatChart from "./Surveyanalytics/CsatChart";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler, // ✅ Import Filler for area chart effect
} from "chart.js";
import { useOutletContext } from "react-router-dom";
import Subscription from "./Subscription";

// ✅ Register all required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler // ✅ Required for area fill effect
);

function SurveyAnalysis() {
  const [npsData, setNpsData] = useState([]);
  const [npsChartData, setNpsChartData] = useState(null);
  const [csatData, setCsatData] = useState([]);
  const [error, setError] = useState(null);
  const { timeRange } = useTimeRange();
  const { isExpired } = useOutletContext();
  const [npsScore, setNpsScore] = useState(0);

  const fetchNPSData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/surveys/get-data/nps/?filter=${timeRange}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const spreadResponse = await fetch(
        `https://zola.technology/api/company/surveys/get-data/nps/spread-data/?filter=${timeRange}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok || !spreadResponse.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      const spreadData = await spreadResponse.json();
      setNpsScore(spreadData);
      console.log("API Response:", data); // Log full API response

      // Transform the data into cardData format
      const transformedData = [
        {
          label: "Total Responses",
          value: (
            data.promoters.count +
            data.passives.count +
            data.detractors.count
          ).toLocaleString(),
          change: "",
          // comparison: `vs ${
          //   timeRange === "day" ? "yesterday" : `last ${timeRange}`
          // }`,
          changeColor:
            data.nps_change.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
        {
          label: "Promoters",
          value: data.promoters.count.toLocaleString(),
          change: `${
            data.promoters.change.type === "Increase" ? "+" : ""
          }${data.promoters.change.percentage_change.toFixed(1)}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data.promoters.change.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
        {
          label: "Detractors",
          value: data.detractors.count.toLocaleString(),
          change: `${
            data.detractors.change.type === "Increase" ? "+" : ""
          }${data.detractors.change.percentage_change.toFixed(1)}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor: "text-red-500",
        },
        {
          label: "Passives",
          value: data.passives.count.toLocaleString(),
          change: `${data.passives.change.percentage_change.toFixed(1)}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor: "text-gray-500",
        },
        {
          label: "NPS Score",
          value: `${data.nps_score.toFixed(1)}%`,
          change: ` ${data.nps_change.percentage_change.toFixed(1)}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data.nps_change.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
      ];

      setNpsData(transformedData);

      // Process spread data for trend chart
      const trendData = Array.isArray(spreadData.periods)
        ? spreadData.periods
        : [];
      const chartLabels = trendData.map((item) =>
        timeRange === "year" ? item.period.split(" ")[0] : item.period
      );
      const chartValues = trendData.map((item) => item.nps_score);

      // ✅ Handle missing `nps_trend` by using fallback dummy data

      const defaultTrend = [
        { month: "Jan", nps_score: data.nps_score || 0 },
        { month: "Feb", nps_score: data.nps_score || 0 },
        { month: "Mar", nps_score: data.nps_score || 0 },
        { month: "Apr", nps_score: data.nps_score || 0 },
        { month: "May", nps_score: data.nps_score || 0 },
        { month: "Jun", nps_score: data.nps_score || 0 },
      ];

      // const npsTrendData = Array.isArray(data.nps_trend) ? data.nps_trend : defaultTrend;

      // const chartLabels = npsTrendData.map((item) => item.month);
      // const chartValues = npsTrendData.map((item) => item.nps_score);

      setNpsChartData({
        labels: chartLabels,
        datasets: [
          {
            label: "Net Promoter Score",
            data: chartValues,
            borderColor: "rgba(33, 150, 243, 1)", // Blue border
            backgroundColor: "rgba(33, 150, 243, 0.3)", // ✅ Semi-transparent fill for area effect
            pointBackgroundColor: "#ffffff",
            pointBorderColor: "rgba(33, 150, 243, 1)",
            pointRadius: 4,
            tension: 0.4, // Smooth curve
            fill: "start", // ✅ Enables area fill
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching NPS data:", error);
      setError("Failed to fetch NPS data");
    }
  };

  const fetchCSATData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/surveys/get-data/csat/?filter=${timeRange}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();

      console.log(data);
      const transformedCSATData = [
        {
          label: "Total Responses",
          value: data?.total_responses.count.toLocaleString(),
          change: `${
            data?.total_responses?.change?.total_change > 0 ? "+" : ""
          }${data?.total_responses?.change?.total_change}`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data?.total_responses?.change?.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
        {
          label: "Satisfied Responses",
          value: data?.satisfied_responses?.count.toLocaleString(),
          change: `${data.satisfied_responses?.change?.percentage_change.toFixed(
            1
          )}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data?.satisfied_responses?.change?.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
        {
          label: "Neutral Responses",
          value: data?.neutral_responses.count.toLocaleString(),
          change: `${data?.neutral_responses?.change?.percentage_change?.toFixed(
            1
          )}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data?.neutral_responses?.change?.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
        {
          label: "Dissatisfied Responses",
          value: data?.dissatisfied_responses?.count?.toLocaleString(),
          change: `${data?.dissatisfied_responses?.change?.percentage_change?.toFixed(
            1
          )}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data?.dissatisfied_responses?.change?.type === "Increase"
              ? "text-red-500"
              : "text-green-500",
        },
        {
          label: "CSAT Score",
          value: `${data?.csat_score?.toLocaleString()}%`,
          change: `${data?.csat_change?.percentage_change?.toFixed(1)}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data?.csat_change?.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
      ];

      setCsatData(transformedCSATData);
    } catch (error) {
      console.error("Error fetching CSAT data:", error);
      setError("Failed to fetch CSAT data");
    }
  };

  useEffect(() => {
    fetchNPSData();
    fetchCSATData();
  }, [timeRange]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="">
      {/* Overview at the Top */}
      <Overview title="Survey Analysis" showExportButton={false} />

      {/* <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mb-8">
        <NpsGaugeChart colSpan="lg:col-span-4" />
        <NpsSummaryCard colSpan="lg:col-span-4" />
        <DeliveryStats colSpan="lg:col-span-4" />
      </div> */}

      {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-8">
        <ResponseNonResponseChart />
        <ResponsesTrendChart />
        <ResponseRateChart />
      </div> */}

      {/* Main Content Layout */}
      <div className="flex gap-6">
        <div className="flex-grow">
          <div className="mb-8">
            <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">
              Net Promoter Score (NPS)
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
              {npsData.map((item, index) => (
                <div
                  key={index}
                  className="p-4 space-y-2 bg-white dark:bg-gray-800 rounded-lg text-center flex flex-col justify-between"
                >
                  <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">
                    {item.label}
                  </h3>
                  <p className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                    {item.value}
                  </p>
                  <div className="text-sm flex items-center justify-center space-x-2">
                    <span className={`font-medium ${item.changeColor}`}>
                      {item.change}
                    </span>
                    <span className="text-gray-500 dark:text-gray-400">
                      {item.comparison}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
  
          {npsChartData && (
            <div className="bg-white dark:bg-gray-800 p-6 mt-8 rounded-lg">
              <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">
                Net Promoter Score:{" "}
                <span className="text-blue-700">{npsData[4].value}</span>
              </h2>
              <div className="relative h-96">
                <AreaChart />
              </div>
            </div>
          )}
  
          <div className="mt-8">
            <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">
              Customer Satisfaction Score (CSAT)
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
              {csatData.map((item, index) => (
                <div
                  key={index}
                  className="p-4 space-y-2 bg-white dark:bg-gray-800 rounded-lg text-center flex flex-col justify-between"
                >
                  <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">
                    {item.label}
                  </h3>
                  <p className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                    {item.value}
                  </p>
                  <div className="text-sm flex items-center justify-center space-x-2">
                    <span className={`font-medium ${item.changeColor}`}>
                      {item.change}
                    </span>
                    <span className="text-gray-500 dark:text-gray-400">
                      {item.comparison}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
  
          {npsChartData && (
            <div className="bg-white dark:bg-gray-800 p-6 mt-8 rounded-lg">
              <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">
                Customer Satisfaction:{" "}
                <span className="text-blue-700">{csatData[4].value}</span>
              </h2>
              <div className="relative h-96">
                <CsatChart />
              </div>
            </div>
          )}
  
        </div>

        {/* <div className="w-1/4 bg-white h-screen dark:bg-gray-800 ">
          <ResponsesList />
        </div> */}
      </div>
      {isExpired && <Subscription />}
    </div>
  );
}

export default SurveyAnalysis;




// import React, { useEffect, useState } from "react";
// import SemiCircleChart from "./SpeedometerChart";
// import Chart from "react-apexcharts";
// import Overview from "./Overview";
// import { useSurvey } from "../../context/SurveyContext";

// const SurveyDashboard = () => {
//   const [npsData, setNpsData] = useState(null);
//   const [csatData, setCsatData] = useState(null);
//   const [surveyData, setSurveyData] = useState(null);
//   // const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const { activeSurvey, loading, fetchSurveys } = useSurvey();
//   const token = localStorage.getItem("token");

//   useEffect(() => {
//     if (!token) {
//       setNpsData(null);
//       setCsatData(null);
//       setSurveyData(null);
//       setError("No active survey found.");
//       return;
//     }

//     fetchSurveys(); // Ensure fresh surveys are fetched on token change
//   }, [token]);

//   useEffect(() => {
//     if (loading) return;
//     if (!activeSurvey || !activeSurvey.id) {
//       setError("No active survey found.");
//       return;
//     }

//     const fetchSurveyData = async () => {
//       try {
//         const [npsResponse, csatResponse, surveyResponse] = await Promise.all([
//           fetch(
//             `https://zola.technology/api/company/surveys/get-data/nps/?filter=week`,
//             {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//                 "Content-Type": "application/json",
//               },
//             }
//           ),
//           fetch(
//             `https://zola.technology/api/company/surveys/get-data/csat/spread-data/?filter=week`,
//             {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//                 "Content-Type": "application/json",
//               },
//             }
//           ),
//           fetch(
//             `https://zola.technology/api/company/surveys/survey/${activeSurvey.id}/`,
//             {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//                 "Content-Type": "application/json",
//               },
//             }
//           ),
//         ]);

//         if (!npsResponse.ok) throw new Error("Failed to fetch NPS data");
//         if (!csatResponse.ok) throw new Error("Failed to fetch CSAT data");
//         if (!surveyResponse.ok) throw new Error("Failed to fetch survey data");

//         setNpsData(await npsResponse.json());
//         setCsatData(await csatResponse.json());
//         setSurveyData(await surveyResponse.json());
//       } catch (error) {
//         console.error("Error fetching survey data:", error);
//         setError(error.message);
//       }
//     };

//     fetchSurveyData();
//   }, [activeSurvey]);
//   if (loading) {
//     return <p className="text-center">Loading...</p>;
//   }

//   if (error) {
//     return <p className="text-center text-red-600">{error}</p>;
//   }

//   const { nps_score, promoters, passives, detractors } = npsData || {};
//   const totalResponses = csatData?.total_responses || 0;

//   // Extract recommend question data
//   const recommendQuestion = surveyData?.analytics.find((q) =>
//     q.question_text.toLowerCase().includes("recommend")
//   );

//   const recommendChoices = recommendQuestion?.analytics.choice_counts || {};
//   const choiceLabels = Object.keys(recommendChoices);
//   const choiceValues = Object.values(recommendChoices);

//   // Compute total responses for this question
//   const totalRecommendResponses = Object.values(recommendChoices).reduce(
//     (acc, count) => acc + count,
//     0
//   );

//   // Get? counts for 10 and (9+10)
//   const count10 = recommendChoices["10"] || 0;
//   const count9and10 = (recommendChoices["9"] || 0) + count10;

//   // Compute? percentages
//   const percent10 = totalRecommendResponses
//     ? ((count10 / totalRecommendResponses) * 100).toFixed(2)
//     : 0;
//   const percent9and10 = totalRecommendResponses
//     ? ((count9and10 / totalRecommendResponses) * 100).toFixed(2)
//     : 0;

//   const getUserData = () => {
//     const userData = localStorage.getItem("userData");
//     return userData ? JSON.parse(userData) : null;
//   };

//   const user = getUserData();

//   return (
//     <div className="">
//       <Overview />
//       <div className="mx-auto bg-white p-6 rounded-lg shadow-lg">
//         {/* Grid Layout */}
//         <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
//           {/* NPS Gauge Chart */}
//           <div className="p-4 bg-white rounded-lg shadow-md">
//             <div className="border-b-4">
//               <div>
//                 {" "}
//                 <h2 className="text-md font-semibold mb-2">
//                   How likely are you to recommend
//                 </h2>
//                 <p className="text-gray-600">Survey ID: {activeSurvey?.id}</p>
//               </div>
//               <div className="flex justify-between items-center mb-2">
//                 <p className="text-xs text-gray-500">
//                   {user.company_name} Survey (All Questions)
//                 </p>
//                 <p className="text-xs text-black bg-slate-200 p-1 rounded">
//                   Responses: {totalResponses}
//                 </p>
//               </div>
//             </div>

//             <SemiCircleChart value={nps_score} />
//           </div>

//           {/* Promoters, Passives, and Detractors Bars */}
//           <div className="p-4 bg-white rounded-lg shadow-md">
//             <div className="border-b-4">
//               <div>
//                 {" "}
//                 <h2 className="text-md font-semibold mb-2">
//                   How likely are you to recommend
//                 </h2>
//               </div>
//               <div className="flex justify-between items-center mb-2">
//                 <p className="text-xs text-gray-500">
//                   {user.company_name} Survey (All Questions)
//                 </p>
//                 <p className="text-xs text-black bg-slate-200 p-1 rounded">
//                   Responses: {totalResponses}
//                 </p>
//               </div>
//             </div>
//             <div className="space-y-5 mt-[20%]">
//               {/* Promoters Bar */}
//               <div className="flex items-center">
//                 <span className="w-24 text-green-700 font-semibold">
//                   Promoters
//                 </span>
//                 <div className="w-full bg-gray-200 rounded-lg h-4 ml-2">
//                   <div
//                     className="h-4 bg-green-500 rounded-lg"
//                     style={{ width: `${promoters?.percentage}%` }}
//                   ></div>
//                 </div>
//                 <span className="ml-2 font-semibold">
//                   {promoters?.percentage}%
//                 </span>
//               </div>

//               {/* Passives Bar */}
//               <div className="flex items-center">
//                 <span className="w-24 text-yellow-600 font-semibold">
//                   Passives
//                 </span>
//                 <div className="w-full bg-gray-200 rounded-lg h-4 ml-2">
//                   <div
//                     className="h-4 bg-yellow-400 rounded-lg"
//                     style={{ width: `${passives?.percentage}%` }}
//                   ></div>
//                 </div>
//                 <span className="ml-2 font-semibold">
//                   {passives?.percentage}%
//                 </span>
//               </div>

//               {/* Detractors Bar */}
//               <div className="flex items-center">
//                 <span className="w-24 text-red-700 font-semibold">
//                   Detractors
//                 </span>
//                 <div className="w-full bg-gray-200 rounded-lg h-4 ml-2">
//                   <div
//                     className="h-4 bg-red-500 rounded-lg"
//                     style={{ width: `${detractors?.percentage}%` }}
//                   ></div>
//                 </div>
//                 <span className="ml-2 font-semibold">
//                   {detractors?.percentage}%
//                 </span>
//               </div>
//             </div>

//             <ul className="text-sm mt-7">
//               <li className="text-green-600">
//                 ✔ {promoters?.percentage}% - {promoters?.count} Promoters
//               </li>
//               <li className="text-yellow-600 mt-4">
//                 ⚠ {passives?.percentage}% - {passives?.count} Passives
//               </li>
//               <li className="text-red-600 mt-4">
//                 ❌ {detractors?.percentage}% - {detractors?.count} Detractors
//               </li>
//             </ul>
//           </div>

//           {/* Survey Responses */}
//           <div className="p-4 bg-white rounded-lg border-2">
//             <div className="border-b-4">
//               <div>
//                 {" "}
//                 <h2 className="text-md font-semibold mb-2">Survey Responses</h2>
//               </div>
//               <div className="flex justify-between items-center mb-2">
//                 <p className="text-xs text-gray-500">
//                   {user.company_name} Survey (All Questions)
//                 </p>
//                 <p className="text-xs text-black bg-slate-200 p-1 rounded">
//                   Responses: {totalResponses}
//                 </p>
//               </div>
//             </div>

//             <div className="flex flex-col items-center mt-[25%]">
//               <p className="text-5xl font-bold">{totalResponses}</p>
//               <p className="text-2xl text-gray-500">
//                 Total Responses Collected
//               </p>
//               {/* <p className="text-xl font-semibold mt-2">103.5 Sec</p>
//               <p className="text-sm text-gray-500">Average Completion Time</p> */}
//             </div>
//           </div>

//           {/* Top Box Score */}
//           <div className="p-2 bg-white rounded-lg border-2 ">
//             <div className="border-b-4 flex flex-col ">
//               <div>
//                 {" "}
//                 <h2 className="text-md font-semibold mb-2">
//                   How likely are you to recommend
//                 </h2>
//               </div>
//               <div className="flex justify-between items-center mb-2">
//                 <p className="text-xs text-gray-500">
//                   {user.company_name} Survey (All Questions)
//                 </p>
//                 <p className="text-xs text-black bg-slate-200 p-1 rounded">
//                   Responses: {totalResponses}
//                 </p>
//               </div>
//             </div>
//             <div className="flex flex-col justify-center items-center mt-[10%]">
//               <p className="text-5xl font-bold mt-4">{percent10}%</p>
//               <p className="text-2xl text-gray-500">Top Box</p>
//             </div>
//           </div>

//           {/* Top 2 Box Score */}
//           <div className="p-2 bg-white rounded-lg border-2 ">
//             <div className="border-b-4 flex flex-col ">
//               <div>
//                 {" "}
//                 <h2 className="text-md font-semibold mb-2">
//                   How likely are you to recommend
//                 </h2>
//               </div>
//               <div className="flex justify-between items-center mb-2">
//                 <p className="text-xs text-gray-500">
//                   {user.company_name} Survey (All Questions)
//                 </p>
//                 <p className="text-xs text-black bg-slate-200 p-1 rounded">
//                   Responses: {totalResponses}
//                 </p>
//               </div>
//             </div>
//             <div className="flex flex-col justify-center items-center mt-[10%]">
//               <p className="text-5xl font-bold mt-4">{percent9and10}%</p>
//               <p className="text-2xl text-gray-500">Top 2 Box</p>
//             </div>
//           </div>

//           {/* Column Chart Placeholder */}
//           <div className="p-4 bg-white rounded-lg border-2">
//             <div className="border-b-4">
//               <div>
//                 {" "}
//                 <h2 className="text-md font-semibold mb-2">
//                   How likely are you to recommend
//                 </h2>
//               </div>
//               <div className="flex justify-between items-center mb-2">
//                 <p className="text-xs text-gray-500">
//                   {user.company_name} Survey (All Questions)
//                 </p>
//                 <p className="text-xs text-black bg-slate-200 p-1 rounded">
//                   Responses: {totalResponses}
//                 </p>
//               </div>
//             </div>
//             <Chart
//               options={{
//                 chart: { type: "bar" },
//                 colors: ["#5a32d8"],
//                 xaxis: { categories: choiceLabels },
//                 dataLabels: { enabled: false },
//               }}
//               series={[
//                 {
//                   name: "Responses",
//                   data: choiceValues,
//                 },
//               ]}
//               type="bar"
//               height={180}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SurveyDashboard;


