import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditCouponModal = ({ coupon, onClose, onUpdate }) => {
  const [name, setName] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [percentageDiscount, setPercentageDiscount] = useState("");
  const [cashDiscount, setCashDiscount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setDuration] = useState("");
  const [loading, setLoading] = useState(false);
  const [redemptionArea, setRedemptionArea] = useState("");
  const [usageType, setUsageType] = useState("");
  const [usageLimit, setUsageLimit] = useState("");

  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (coupon) {
      setName(coupon.name);
      setDiscountType(coupon.discountType || "");
      setPercentageDiscount(coupon.percentageDiscount || "");
      setCashDiscount(coupon.cashDiscount || "");
      setStartDate(coupon.startDate || "");
      setEndDate(coupon.endDate || "");
      setRedemptionArea(coupon.applicableArea || "");
      setUsageType(coupon.usageType || "general");
      setUsageLimit(coupon.usageLimit || "");
    }
  }, [coupon]);

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const difference = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
      setDuration(
        difference >= 0
          ? `${difference} day${difference !== 1 ? "s" : ""}`
          : "Invalid range"
      );
    } else {
      setDuration("");
    }
  }, [startDate, endDate]);

  const handleSubmit = async () => {
    if (
      !name ||
      !discountType ||
      !startDate ||
      !endDate ||
      !duration ||
      !redemptionArea ||
      (discountType === "Percentage" && !percentageDiscount) ||
      (discountType === "Cash" && !cashDiscount)
    ) {
      toast.error("Please fill in all fields correctly.");
      return;
    }
    if (duration === "Invalid range") {
      toast.error("End date cannot be before start date.");
      return;
    }

    const formattedData = {
      name,
      usage_type: usageType,
      // coupon_code_prefix: discountType === "Percentage" ? "PER" : "CASH",
      // code: `${discountType === "Percentage" ? "PER" : "CASH"}91E7-${percentageDiscount || cashDiscount}`,
      discount_type: discountType === "Cash" ? "fixed" : "percentage",
      discount_value: discountType === "Percentage" ? percentageDiscount : cashDiscount,
      currency: "USD",
      valid_from: new Date(startDate).toISOString(),
      valid_until: new Date(endDate).toISOString(),
      is_active: true,
      used_count: coupon?.used_count || 0,
      applicable_area: redemptionArea,
      ...(usageType === "general" && { usage_limit: Number(usageLimit) }),
    };
    
    

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://zola.technology/api/company/coupons/${coupon.id}/update/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with actual token if needed
          },
          body: JSON.stringify(formattedData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        toast.success("Coupon updated successfully!");
        onUpdate(data);
        onClose();
      } else {
        // Handle structured errors
        if (data && typeof data === "object") {
          Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((msg) => toast.error(`${msg}`));
            } else {
              toast.error(`${value}`);
            }
          });
        } else {
          toast.error("Failed to update coupon.");
        }
      }
    } catch (error) {
      toast.error("An error occurred while updating the coupon.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white dark:bg-gray-900 p-6 rounded-2xl shadow-xl w-full max-w-lg space-y-4"
      >
        <h3 className="text-2xl font-bold text-gray-800 dark:text-white">
          Edit Coupon
        </h3>

        <div className="space-y-4">
          <div>
            <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
              Coupon Name
            </label>
            <input
              type="text"
              placeholder="Enter coupon name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
              Usage Type
            </label>
            <select
              value={usageType}
              onChange={(e) => setUsageType(e.target.value)}
              className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
            >
              <option value="" disabled>
                Select Usage Type
              </option>
              <option value="general">General</option>
              <option value="unique">Unique</option>
            </select>
          </div>

          {usageType === "general" && (
  <div>
    <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
      Usage Limit
    </label>
    <input
      type="number"
      min="1"
      placeholder="Enter usage limit"
      value={usageLimit}
      onChange={(e) => setUsageLimit(e.target.value)}
      className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
    />
  </div>
)}


          <div>
            <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
              Discount Type
            </label>
            <select
              value={discountType}
              onChange={(e) => setDiscountType(e.target.value)}
              className="w-full p-3 border rounded-md bg-gray-100 dark:bg-gray-800 dark:border-gray-700"
            >
              <option value="">Select Type</option>
              <option value="Percentage">Percentage</option>
              <option value="Cash">Cash</option>
            </select>
          </div>

          {discountType === "Percentage" && (
            <div>
              <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
                Discount Percentage
              </label>
              <input
                type="number"
                placeholder="Enter percentage (e.g., 20)"
                value={percentageDiscount}
                onChange={(e) => setPercentageDiscount(e.target.value)}
                className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
              />
            </div>
          )}

          {discountType === "Cash" && (
            <div>
              <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
                Cash Discount Value
              </label>
              <input
                type="number"
                placeholder="Enter cash amount (e.g., 50)"
                value={cashDiscount}
                onChange={(e) => setCashDiscount(e.target.value)}
                className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
              />
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div>
              <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
                Start Date
              </label>
              <input
                type="date"
                value={startDate}
                min={today}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
                End Date
              </label>
              <input
                type="date"
                value={endDate}
                min={startDate || today}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
              />
            </div>
          </div>
        </div>
        <div>
          <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
            Coupon Redemption Area
          </label>
          <select
            value={redemptionArea}
            onChange={(e) => setRedemptionArea(e.target.value)}
            className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
          >
            <option value="" disabled>
              Select Redemption Area
            </option>
            <option value="feedback">Feedback</option>
            <option value="survey">Survey</option>
          </select>
        </div>

        <div className="flex justify-end space-x-2 pt-4">
          <button
            onClick={onClose}
            className="px-5 py-2.5 bg-gray-200 text-gray-800 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-5 py-2.5 bg-blue-600 text-white rounded-md"
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCouponModal;
