import { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
  const [activeSurvey, setActiveSurvey] = useState(null);
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      // Reset when user logs out or switches accounts
      setActiveSurvey(null);
      setSurveys([]);
      localStorage.removeItem("activeSurvey");
      setLoading(false);
      return;
    }

    fetchSurveys();
  }, [token]); // Trigger refetch on token change

  const fetchSurveys = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://zola.technology/api/company/surveys/get-all/",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.data) throw new Error("No survey data found.");

      setSurveys(response.data);
      const active = response.data.find((survey) => survey.active === true);

      if (active) {
        setActiveSurvey(active);
      } else {
        setActiveSurvey(null);
      }
    } catch (error) {
      console.error("Error fetching surveys:", error);
      setActiveSurvey(null);
      setSurveys([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SurveyContext.Provider value={{ activeSurvey, surveys, fetchSurveys, loading }}>
      {children}
    </SurveyContext.Provider>
  );
};

export const useSurvey = () => useContext(SurveyContext);
