import { useEffect, useState } from "react";
import {
  FaBuilding,
  FaUsers,
  FaExclamationTriangle,
  FaStar,
  FaSmile,
  FaStarHalfAlt,
} from "react-icons/fa";
import Branches from "./Branches";

import {
  MdEmail,
} from "react-icons/md";
import {
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa6";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BiQr } from "react-icons/bi";
import { SiNfc } from "react-icons/si";
// import { FaStar, FaBuilding, FaUsers, FaExclamationTriangle } from 'react-icons/fa';
import { FaLink, FaSms } from "react-icons/fa";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import OverviewSection from "../components/overview/OverviewSection";
import BranchPerformanceOverview from "./Analytics/BranchPerformanceOverview ";
import { useTimeRange } from "../../context/TimeRangeContext";
import BranchList from "./BranchList";

// Register required elements
ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
  const { timeRange } = useTimeRange();

  // const { branches, employeeTaps } = useContext(BranchContext);
  const [totalAndAverageRating, setTotalAndAverageRating] = useState(null);
  const [branchData, setBranchData] = useState(null);
  const [googleReviews, setGoogleReviews] = useState(null);
  const [totalFilteredTaps, setTotalFilteredTaps] = useState(0);
  const [feedbackData, setFeedbackData] = useState(0);
  const [issuesData, setIssuesData] = useState(0);
  const [channelsData, setChannelsData] = useState(0);

  const fetchWithToken = async (url) => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      return null;
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const ratingData = await fetchWithToken(
        "https://zola.technology/api/hq-company/branches/feedback/total-and-average-rating/"
      );
      setTotalAndAverageRating(ratingData);

      const branchDataResponse = await fetchWithToken(
        "https://zola.technology/api/hq-company/branches/get-data/"
      );
      setBranchData(branchDataResponse);

      const reviewsData = await fetchWithToken(
        "https://zola.technology/api/hq-company/branches/google-reviews/"
      );
      setGoogleReviews(reviewsData);

      const fetchFilteredTaps = await fetchWithToken(
        "https://zola.technology/api/hq-company/analytics/get-branch-employee-taps/?filter=week&page=1&page_size=1"
      );
      setTotalFilteredTaps(fetchFilteredTaps?.total_filtered_taps || 0);

      const feedbackResp = await fetchWithToken(
        `https://zola.technology/api/hq-company/dashboard/feedback-data/get/?filter=${timeRange}`
      );
      setFeedbackData(feedbackResp);
      const issuesres = await fetchWithToken(
        `https://zola.technology/api/hq-company/dashboard/data/employees-taps-issues-performance/?filter=${timeRange}`
      );
      setIssuesData(issuesres);
      const channelsres = await fetchWithToken(
        `https://zola.technology/api/hq-company/dashboard/data/feedback-channels/?filter=${timeRange}`
      );
      setChannelsData(channelsres);
    };
    fetchData();
  }, [timeRange]);

  console.log(`channels data ${channelsData.qr_code?.count}`);
  console.log(
    `branchData positive count ${branchData?.total_positive_feedback_count}`
  );
  console.log(`feedback data ${feedbackData.total_feedback}`);

  const isZero = totalFilteredTaps === 0;
  const percentageChange = isZero ? "100%" : "+25%";
  function formatNumber(value) {
    if (value >= 1000) {
      return (value / 1000).toFixed(1).replace(/\.0$/, "") + "k";
    }
    return value?.toString() || 0;
  }

  const formattedReviews = formatNumber(googleReviews?.total_reviews);

  const totalNeutralData = feedbackData?.neutral;
  const totalPositiveData = feedbackData?.positive;
  const totalNegativeData = feedbackData?.negative;

  //performance employees andissues cards
  // const combinedPerformance = issuesData;
  const employees = issuesData.employees;
  const issues = issuesData.issues;
  // const performance = issuesData.performance;
  const taps = issuesData.taps;

  const cards = [
    {
      title: "Reviews",
      value: `${formattedReviews}`,
      percentage: "",
      change: "All time reviews",
      icon: <FaStar className="text-yellow-500" />,
      iconBg: "#fef8e9",
      color: "bg-blue-500",
      positive: true,
    },
    {
      title: "Total branches",
      value: `${branchData?.total_branches || 0}`,
      percentage: "",
      change: "Total branch count",
      icon: <FaBuilding className="text-blue-500" />,
      iconBg: "#ecf1fe",
      color: "bg-blue-500",
      positive: false,
    },
    {
      title: "Total Employees",
      value: `${branchData?.total_employees || 0}`,
      percentage: `${employees?.change?.percentage_change || 0}%`,
      change: "Employees",
      icon: <FaUsers className="text-red-300" />,
      iconBg: "#feebeb",
      color: "bg-red-500",
      positive: employees?.change?.type,
    },
    {
      title: "Total issues",
      value: `${issues?.count || 0}`,
      extraInfo: `Resolved: ${branchData?.total_positive_feedback_count} Pending: ${branchData?.total_negative_feedback_count}`,
      icon: <FaExclamationTriangle className="text-red-400" />,
      iconBg: "#fce9eb",
      color: "bg-red-500",
    },
  ];

  const sms = channelsData?.sms;
  const whatsapp = channelsData?.whatsapp;
  const nfc = channelsData?.nfc_card;
  const qr = channelsData?.qr_code;
  const email = channelsData?.email;
  const conversions = [
    {
      icon: <BiQr size={24} className="text-white" />,
      iconBg: "#191f2f",
      title: "QR Code",
      description: "Feedbacks Through QR Code",
      value: qr?.count ?? 0, // Use QR count
      change: `${qr?.change?.percentage_change ?? 0}%`, // Use QR percentage change
      positive: qr?.change?.type === "Increase",
    },
    {
      icon: <SiNfc size={24} className="text-white" />,
      iconBg: "#0c4ef8",
      title: "NFC Cards",
      description: "NFC Scans",
      value: nfc?.count ?? 0, // Use NFC count
      change: `${nfc?.change?.percentage_change ?? 0} %`, // Use NFC percentage change
      positive: nfc?.change?.type === "Increase",
    },
    {
      icon: <FaSms size={24} className="text-white" />,
      iconBg: "#eab846",
      title: "SMS",
      description: "SMS Received",
      value: sms?.count ?? 0, // Use SMS count
      change: `${sms?.change?.percentage_change ?? 0}%`, // Use SMS percentage change
      positive: sms?.change?.type === "Increase",
    },
    {
      icon: <MdEmail size={24} className="text-white" />, // Email icon
      iconBg: "#D44638", // Gmail-like red color
      title: "Email",
      description: "Feedbacks Through Email",
      value: `${email?.count || 0}`,
      change: `${email?.change?.percentage_change || 0}%`,
      percentage: `${email?.percentage || 0}%`,
      positive:
        email?.change?.type === "Increase" ||
        email?.change?.percentage_change === 0,
    },
  ];

  const data = {
    labels: ["QR Code", "NFC Cards", "SMS", "Emails"],
    datasets: [
      {
        label: "Conversions",
        data: [
          qr?.percentage ?? 0, // QR Code percentage
          nfc?.percentage ?? 0, // NFC percentage
          sms?.percentage ?? 0, // SMS percentage
          email?.percentage ?? 0, //email percentage
        ],
        backgroundColor: ["#111827", "#3B82F6", "#FACC15", "#D44638"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "75%",
  };

  return (
    <div>
      <OverviewSection title="Overview" showExportButton={true} />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8 bg-gray-50">
        {/* Feedback Section */}
        <div className="bg-white rounded-xl p-6 shadow">
          <div className="w-full">
            {/* Header Section */}
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center gap-3">
                <div className="bg-green-100 p-3 rounded-lg">
                  <FaThumbsUp className="text-green-500" />
                </div>
                <div>
                  <h2 className="text-md font-medium text-gray-500">
                    Feedback
                  </h2>
                  <h1 className="text-4xl font-bold">
                    {feedbackData?.total_feedback}
                  </h1>
                  <p
                    className={`text-sm mt-1 ${
                      feedbackData?.change?.type === "Increase"
                        ? "text-green-500"
                        : feedbackData?.change?.type === "Decrease"
                        ? "text-red-500"
                        : "text-gray-500"
                    }`}
                  >
                    {feedbackData?.change?.type === "Increase"
                      ? "↑"
                      : feedbackData?.change?.type === "Decrease"
                      ? "↓"
                      : ""}{" "}
                    {feedbackData?.change?.percentage_change || 0}%{" "}
                    <span className="text-gray-400">{`Since last ${
                      timeRange?.toLowerCase() || ""
                    }`}</span>
                  </p>
                </div>
              </div>
            </div>

            {/* Feedback Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-[#f9fafb] rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <FaStar className="text-yellow-500 text-xl" />
                    <h4 className="text-md font-medium">Average Rating</h4>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <h1 className="text-4xl font-bold">
                    {feedbackData?.average_rating?.toFixed(1)}
                  </h1>
                  <div className="flex justify-center space-x-1">
                    {[...Array(5)].map((_, index) => (
                      <FaStar
                        key={index}
                        size={24}
                        className={
                          index < Math.floor(feedbackData?.average_rating)
                            ? "text-[#F3BB21]" // Full stars
                            : index ===
                                Math.floor(feedbackData?.average_rating) &&
                              feedbackData?.average_rating % 1 >= 0.5
                            ? "text-[#F3BB21] opacity-50" // Half star
                            : "text-[#EAEAEC]" // Empty stars
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>

              {/* Positive Reviews */}
              <div className="bg-[#f9fafb] rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <FaThumbsUp className="text-green-500 text-xl" />
                    <h4 className="text-md font-medium">Positive Reviews</h4>
                  </div>
                  {/* <IoIosMore className="text-gray-400 cursor-pointer" /> */}
                </div>
                <div className="flex items-center gap-2">
                  <h1 className="text-4xl font-bold">
                    {totalPositiveData?.count}
                  </h1>
                  <span className="text-green-500 bg-green-100 px-2 py-1 rounded-md text-xs">
                    {totalPositiveData?.change.total_change}
                  </span>
                </div>
                <div className="flex w-full gap-2 items-center mt-3">
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-green-500 h-2 rounded-full"
                      style={{
                        width: `${totalPositiveData?.percentage}%`,
                      }}
                    ></div>
                  </div>
                  <p className="text-xs text-gray-500">
                    {totalPositiveData?.percentage}%
                  </p>
                </div>
              </div>

              {/* Neutral Reviews */}
              <div className="bg-[#f9fafb] rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <FaStar className="text-yellow-500 text-xl" />
                    <h4 className="text-md font-medium">Neutral Reviews</h4>
                  </div>
                  {/* <IoIosMore className="text-gray-400 cursor-pointer" /> */}
                </div>
                <div className="flex items-center gap-2">
                  <h1 className="text-4xl font-bold">
                    {totalNeutralData?.count}
                  </h1>
                  <span className="text-yellow-500 bg-yellow-100 px-2 py-1 rounded-md text-xs">
                    {totalNeutralData?.change.total_change}
                  </span>
                </div>
                <div className="flex w-full gap-2 items-center mt-3">
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-yellow-500 h-2 rounded-full"
                      style={{
                        width: `${totalNeutralData?.percentage}%`,
                      }}
                    ></div>
                  </div>
                  <p className="text-xs text-gray-500">
                    {totalNeutralData?.percentage}%
                  </p>
                </div>
              </div>

              {/* Negative Reviews */}
              <div className="bg-[#f9fafb] rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <FaThumbsDown className="text-red-500 text-xl" />
                    <h4 className="text-md font-medium">Negative Reviews</h4>
                  </div>
                  {/* <IoIosMore className="text-gray-400 cursor-pointer" /> */}
                </div>
                <div className="flex items-center gap-2">
                  <h1 className="text-4xl font-bold">
                    {totalNegativeData?.count}
                  </h1>
                  <span className="text-red-500 bg-red-100 px-2 py-1 rounded-md text-xs">
                    {totalNegativeData?.change.total_change}
                  </span>
                </div>
                <div className="flex w-full gap-2 items-center mt-3">
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-red-500 h-2 rounded-full"
                      style={{
                        width: `${totalNegativeData?.percentage}%`,
                      }}
                    ></div>
                  </div>
                  <p className="text-xs text-gray-500">
                    {totalNegativeData?.percentage}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Reviews Section */}
        <div className="bg-white rounded-xl p-6 shadow-md">
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center gap-2">
              <div className="bg-yellow-100 p-2 rounded-lg">
                <FaStar className="text-yellow-500" />
              </div>
              <div>
                <h2 className="text-md font-medium text-gray-500">Reviews</h2>
                <h1 className="text-4xl font-bold">{formattedReviews}</h1>
                <p className="text-green-500 text-sm mt-1">
                  <span className="text-gray-400">All time reviews</span>
                </p>
              </div>
            </div>
          </div>

          {/* Average Rating */}
          <div className="bg-[#f9fafb] rounded-lg p-6">
            <h4 className="text-md font-medium mb-3">Average Rating</h4>
            <div className="flex items-center gap-4">
              <h1 className="text-5xl font-bold">
                {googleReviews?.average_rating}
              </h1>
              <div className="flex ml-3">
                {Array.from({ length: 5 }).map((_, index) => {
                  const rating = googleReviews?.average_rating || 0; // Fallback to 0
                  if (rating >= index + 1) {
                    // Full star
                    return (
                      <FaStar
                        key={index}
                        className="text-yellow-500 text-xl"
                        style={24}
                      />
                    );
                  } else if (rating >= index + 0.5) {
                    // Half star
                    return (
                      <FaStarHalfAlt
                        key={index}
                        className="text-yellow-500 text-xl"
                        style={24}
                      />
                    );
                  } else {
                    // Empty star
                    return (
                      <FaStar
                        key={index}
                        className="text-gray-300 text-xl"
                        style={24}
                      />
                    );
                  }
                })}
              </div>
            </div>

            {/* Rating Breakdown */}
            {/* <div className="mt-6 space-y-4">
              {[
                { value: 5, count: 3500, color: "bg-green-500" },
                { value: 4, count: 2500, color: "bg-blue-500" },
                { value: 3, count: 800, color: "bg-indigo-700" },
                { value: 2, count: 500, color: "bg-yellow-500" },
                { value: 1, count: 0, color: "bg-red-500" },
              ].map((rating) => (
                <div key={rating.value} className="flex items-center gap-4">
                  <div className="w-5 text-sm font-medium">{rating.value}</div>
                  <div className="w-full bg-gray-200 rounded-full h-3">
                    <div
                      className={`${rating.color} h-3 rounded-full transition-all`}
                      style={{ width: `${(rating.count / 5000) * 100}%` }}
                    ></div>
                  </div>
                  <span className="w-12 text-right text-sm">
                    {rating.count.toLocaleString()}
                  </span>
                </div>
              ))}
            </div> */}
          </div>
          <div className="rounded-lg p-6 bg-[#f9fafb] mt-4">
            <BranchList />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        {cards.map((card, index) => (
          <div key={index} className="bg-white py-2 px-4 relative">
            <div className="flex justify-between items-start">
              <div className="grid gap-4">
                <p className="text-gray-500">{card.title}</p>
                <h2 className="text-3xl font-medium">{card.value}</h2>
                <div className="flex items-center mb-2">
                  {card.percentage && (
                    <span
                      className={`text-sm ${
                        card.positive === "Increase"
                          ? "text-green-500"
                          : "text-red-500"
                      } font-medium`}
                    >
                      {card.positive === "Increase" ? "↑" : "↓"}{" "}
                      {card.percentage}
                    </span>
                  )}
                  {card.extraInfo && (
                    <p className="text-sm">
                      <span className="text-green-500 font-medium">
                        Resloved:{issues?.resolved?.count}
                      </span>
                      <span className="text-red-500 ml-2">
                        Non resolved:{issues?.non_resolved?.count}
                      </span>
                    </p>
                  )}
                  <p className="text-sm text-gray-500 ml-2">{card.change}</p>
                </div>
              </div>
              <div
                style={{ backgroundColor: card.iconBg }}
                className="p-3 rounded-lg"
              >
                {card.icon}
              </div>
            </div>
            <div
              className={`absolute bottom-0 left-0 w-full h-[4px] ${card.color}`}
            ></div>
          </div>
        ))}
      </div>
      {/* <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4 gap-3 mb-4">
        <div className="group bg-white md:h-40 rounded-xl xs:h-[150px] lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex  group-hover:bg-white items-center gap-3 border p-4 rounded-xl w-full border-[#EAEAEC]">
            <div className=" border border-[#0C4EF8]  text-white group-hover:text-blue-600 p-2 rounded-lg">
              <PiCityFill size={24} className="text-[#0C4EF8]" />
            </div>
            <p className="text-gray-700 font-medium text-sm ">
              Total <br />
              Locations
            </p>
          </div>
          <p className="text-3xl font-medium text-[#454861] group-hover:text-white">
            {branchData?.total_branches}
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>

        <div className="group md:h-40 bg-white rounded-xl xs:h-[150px] lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex border border-[#EAEAEC] group-hover:bg-white rounded-xl p-4 w-full items-center gap-3">
            <div className="border border-[#0C4EF8]  text-white group-hover:text-blue-600 p-2 rounded-lg">
              <PiUsersFill size={24} className="text-[#0C4EF8]" />
            </div>
            <p className="text-gray-700 font-medium text-sm ">
              Total <br />
              Employees
            </p>
          </div>
          <p className="text-3xl font-medium text-[#454861] group-hover:text-white ">
            {branchData?.total_employees}
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>

        <div className="group bg-white md:h-40 rounded-xl p-4 flex flex-col justify-between items-start space-y-2 transition transform hover:scale-105 hover:bg-[#0c4ef8] hover:shadow-lg">
          <div className="flex border border-[#EAEAEC] p-4 w-full group-hover:bg-white rounded-xl items-center gap-3">
            <div className="border border-red-600 group-hover:bg-white  text-white group-hover:text-red-600  p-2 rounded-lg">
              <FaExclamationTriangle size={24} className="text-red-600" />
            </div>
            <p className="text-gray-700 font-medium text-sm ">
              Total <br />
              Feedbacks
            </p>
          </div>
          <div className="flex w-full items-end justify-between ">
            <p className="text-3xl font-medium text-[#454861] group-hover:text-white">
              {branchData?.total_feedback_count}
            </p>

            <div className="space-y-2">
              <p className="px-2 rounded-xl  text-green-400 group-hover:bg-green-200 group-hover:text-green-600">
                Positive: {branchData?.total_positive_feedback_count}
              </p>
              <p className="px-2 rounded-xl  text-red-400 group-hover:bg-red-200 group-hover:text-red-600">
                Negative: {branchData?.total_negative_feedback_count}
              </p>
            </div>
          </div>
        </div>

        <div className="group bg-white rounded-xl md:h-40 p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:bg-[#0c4ef8] hover:shadow-lg">
          <div className="flex border p-4 group-hover:bg-white border-[#EAEAEC] rounded-xl w-full items-center gap-3">
            <div className="border border-[#00C159]  text-white group-hover:text-[#00C159] p-2 rounded-lg">
              <HiChartBar size={24} className="text-[#00C159]" />
            </div>
            <p className="text-gray-700 font-medium text-sm ">
              Average <br />
              Performance
            </p>
          </div>
          <p className="text-3xl font-medium text-[#454861] group-hover:text-white">
            {branchData?.average_performance_score}%
          </p>
        </div>
      </div> */}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        {/* Overall Sentiments */}
        <div className="bg-white rounded-xl p-6 shadow-md">
          <div className="flex items-center gap-3 mb-4">
            <div className="bg-green-100 p-2 rounded-lg">
              <FaSmile className="text-green-500" />
            </div>
            <h2 className="text-lg font-medium">Overall Sentiments</h2>
          </div>

          {/* Sentiment Breakdown */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {/* Average Sentiment */}
            <div className="bg-gray-50 p-5 rounded-lg grid gap-4">
              <div>
                <h4 className="text-md font-medium text-gray-500 mb-1">
                  Average Sentiment
                </h4>
              </div>
              <div className="flex items-center gap-2">
                {(() => {
                  const totalFeedback = branchData?.total_feedback_count || 1; // Avoid division by 0
                  const negativePercentage = (
                    (branchData?.total_negative_feedback_count /
                      totalFeedback) *
                    100
                  ).toFixed(2);
                  const positivePercentage = (
                    (branchData?.total_positive_feedback_count /
                      totalFeedback) *
                    100
                  ).toFixed(2);
                  const sentiment =
                    positivePercentage > negativePercentage ? "Good" : "Bad";
                  const sentimentColor = sentiment === "Good" ? "green" : "red";
                  const sentimentPercentage =
                    sentiment === "Good"
                      ? `+${positivePercentage}%`
                      : `+${negativePercentage}%`;

                  return (
                    <>
                      <h1
                        className={`text-4xl font-bold ${
                          sentiment === "Good"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {sentiment}
                      </h1>
                      <span
                        className={`bg-${sentimentColor}-100 text-${sentimentColor}-500 px-1 py-1 rounded-md text-sm`}
                      >
                        {sentimentPercentage}
                      </span>
                    </>
                  );
                })()}
              </div>
            </div>
            <div className="grid gap-4">
              <div className="bg-white rounded-lg p-4 flex justify-between items-center shadow-sm">
                <div className="flex items-center gap-2">
                  <FaThumbsUp className="text-green-500" />
                  <p className="text-sm text-gray-600">Positive Sentiment</p>
                </div>
                <span className="text-green-500 font-medium text-sm bg-green-100 px-2 py-1 rounded-lg">
                  {(
                    (branchData?.total_positive_feedback_count /
                      (branchData?.total_feedback_count || 1)) *
                    100
                  ).toFixed(2)}
                  %
                </span>
              </div>
              <div className="bg-white rounded-lg p-4 flex justify-between items-center shadow-sm">
                <div className="flex items-center gap-2">
                  <FaThumbsDown className="text-red-500" />
                  <p className="text-sm text-gray-600">Negative Sentiment</p>
                </div>
                <span className="text-red-500 font-medium text-sm bg-red-100 px-2 py-1 rounded-lg">
                  {(
                    (branchData?.total_negative_feedback_count /
                      (branchData?.total_feedback_count || 1)) *
                    100
                  ).toFixed(2)}
                  %
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Link Interaction */}
        <div className="bg-white rounded-xl p-6 shadow-md">
          <div className="flex items-center gap-3 mb-4">
            <div className="bg-blue-100 p-2 rounded-lg">
              <FaLink className="text-blue-500" />
            </div>
            <h2 className="text-lg font-medium">Link Interaction</h2>
          </div>

          <div className="bg-gray-50 py-8 px-4 rounded-lg flex flex-row items-center justify-between shadow-sm">
            <h1 className="text-5xl font-bold">{taps?.count ?? 0}</h1>
            <div className="flex flex-col items-center gap-2 mt-4">
              <span
                className={`flex items-center px-3 py-1 rounded-full text-sm ${
                  taps?.change.type === "Increase"
                    ? "bg-green-100 text-green-500"
                    : taps?.change.type === "Decrease"
                    ? "bg-red-100 text-red-500"
                    : "bg-gray-100 text-gray-500"
                }`}
              >
                {taps?.change.type === "Increase" ? (
                  <IoIosArrowUp className="text-green-500" />
                ) : taps?.change.type === "Decrease" ? (
                  <IoIosArrowDown className="text-red-500" />
                ) : null}
                {taps?.change?.percentage_change ?? 0}%
              </span>
              <p className="text-gray-400 text-sm">
                {taps?.change?.total_change} change
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
        <div
          className="hidden md:block bg-black p-4 rounded-xl  flex flex-col justify-between relative overflow-hidden"
          style={{ boxShadow: " 0px 24px 48px -12px #1018282E" }}
        >
          <div className="flex items-center space-x-1">
            <FaWhatsapp size={20} className="text-green-500" />
            <h3 className="text-white text-sm font-medium">WhatsApp</h3>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-32">
            <h1 className="text-white text-3xl font-medium mt-2">35%</h1>

            <div className="flex flex-col items-start md:flex-row gap-8 md:items-end w-full">
              <div>
                <h4 className="text-white font-medium text-2xl">20k</h4>
                <p className="text-white text-xs">Messages</p>
              </div>

              <div className="flex items-center space-x-2">
                <div className="px-2 rounded-full flex items-center border border-green-400">
                  <span className="text-green-400 text-sm p-1 font-medium flex items-center gap-1">
                    <MdOutlineArrowCircleUp size={20} />
                    75%
                  </span>
                </div>
              </div>
            </div>
          </div>

          <svg
            className="absolute bottom-0 -right-32 w-full h-auto opacity-50 md:opacity-100"
            width="236"
            height="94"
            viewBox="0 0 236 94"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.1"
              d="M1.78718 95.6311C16.8369 102.433 32.9032 110.81 50.2852 112.391C71.7329 114.342 85.4871 100.673 97.0459 88.6012C120.309 64.3057 127.737 31.7611 156.137 10.3617C172.769 -2.17024 200.258 -1.03494 221.561 3.27687C232.12 5.4139 248.357 18.1304 252.283 25.7953"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              opacity="0.4"
              d="M15.1134 116.354C30.163 123.156 46.2294 131.533 63.6114 133.114C85.0591 135.064 98.8133 121.396 110.372 109.324C133.635 85.0283 141.063 52.4838 169.464 31.0844C186.096 18.5524 213.584 19.6877 234.887 23.9995C245.446 26.1366 261.683 38.8531 265.609 46.518"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              opacity="0.8"
              d="M26.1134 138.354C41.163 145.156 57.2294 153.533 74.6114 155.114C96.0591 157.064 109.813 143.396 121.372 131.324C144.635 107.028 152.063 74.4838 180.464 53.0844C197.096 40.5524 224.584 41.6877 245.887 45.9995C256.446 48.1366 272.683 60.8531 276.609 68.518"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        </div>

        <div className="flex flex-col gap-6 lg:flex-row items-start lg:items-center justify-between border border-gray-200 bg-white p-6 rounded-lg shadow-sm">
          
          <div className="flex items-center gap-4">
            <div className="bg-yellow-100 p-3 rounded-lg flex items-center justify-center">
              <FaStar size={20} className="text-yellow-500" />
            </div>
            <h2 className="text-sm font-medium text-gray-600">
              Review Collection
            </h2>
          </div>

          
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between w-full lg:w-auto gap-6 sm:gap-12">
            <div className="flex flex-col items-start gap-1">
              <span className="text-green-500 text-md flex items-center gap-1 leading-tight">
                <MdOutlineArrowCircleUp size={18} />
                50%
              </span>
              <p className="text-sm text-gray-500 -mt-1">-18 Employees</p>
            </div>
            <h1 className="text-3xl sm:text-4xl font-bold text-gray-800">
              175k
            </h1>
          </div>
        </div>
      </div> */}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center gap-2 mb-4">
            <div className="bg-white p-2 rounded-lg shadow w-fit">
              <FaLink className="text-blue-500" size={22} />
            </div>
            <h2 className="text-lg font-light">Conversions</h2>
          </div>
          <div className="space-y-6">
            {conversions.map((conversion, index) => (
              <div
                key={index}
                className="flex justify-between items-center border-b pb-4 last:border-b-0"
              >
                <div className="flex items-center gap-4">
                  <div
                    style={{ backgroundColor: conversion.iconBg }}
                    className="p-3 rounded-full"
                  >
                    {conversion.icon}
                  </div>
                  <div>
                    <h3 className="text-md font-medium">{conversion.title}</h3>
                    <p className="text-sm text-gray-500">
                      {conversion.description}
                    </p>
                  </div>
                </div>
                <div className="text-right">
                  <h4 className="text-xl font-medium">{conversion.value}</h4>
                  <p
                    className={`text-sm ${
                      conversion.positive ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {conversion.change}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Donut Chart */}
        <div className="bg-white rounded-lg shadow p-6 flex flex-col">
          <h2 className="text-lg font-light mb-6 self-start">
            Conversion Statistics
          </h2>
          <div className="flex flex-col lg:flex-row w-full justify-between items-center gap-4">
            <div className="relative w-72 h-72 mb-6">
              <Doughnut data={data} options={options} />
              <div className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full shadow-lg flex items-center justify-center">
                <div className="text-center">
                  <h3 className="text-4xl font-bold">
                    {channelsData?.total_feedback_from_channels ?? 0}
                  </h3>
                  <p className="text-sm text-gray-500">Reviews</p>
                </div>
              </div>
            </div>

            {/* Chart Legend */}
            <div className="w-full flex flex-col gap-4">
              {data.labels.map((label, index) => (
                <div key={index} className="flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    <div
                      className="w-3 h-3 rounded-full"
                      style={{
                        backgroundColor:
                          data.datasets[0].backgroundColor[index],
                      }}
                    ></div>
                    <p className="text-sm">{label}</p>
                  </div>
                  <h4 className="text-md font-medium">
                    {conversions[index].value}
                  </h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-md mb-8">
        <h2 className="text-lg font-medium mb-4">
          Branch performance overview
        </h2>
        <BranchPerformanceOverview />
      </div>

      <div className="grid grid-cols-1 gap-6 mb-8">
        {/* <PerformanceChart branches={branches} /> */}
        <Branches showSearchAndCreate={false} showOverview={false} />
      </div>
    </div>
  );
};

export default Dashboard;
