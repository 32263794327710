import { createContext, useState, useEffect, useContext, useCallback } from "react";
import { useAuth } from "./AuthContext"; // Import AuthContext

const CompanyProfileContext = createContext();

export const useCompanyProfile = () => {
  return useContext(CompanyProfileContext);
};

export const CompanyProfileProvider = ({ children }) => {
  const { isAuthenticated } = useAuth(); // Track authentication state
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProfile = useCallback(async () => {
    const token = localStorage.getItem("token"); // Always get the latest token
    if (!token) {
      setError("No token found");
      setProfile(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch("https://zola.technology/api/company/profile/", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      setProfile(data);
      setError(null); // Reset error on success
    } catch (err) {
      setError(err.message);
      setProfile(null);
    } finally {
      setLoading(false);
    }
  }, []);

  // Refetch profile when the user logs in or switches accounts
  useEffect(() => {
    if (isAuthenticated) {
      fetchProfile();
    } else {
      setProfile(null);
    }
  }, [isAuthenticated, fetchProfile]);

  return (
    <CompanyProfileContext.Provider value={{ profile, loading, error, fetchProfile }}>
      {children}
    </CompanyProfileContext.Provider>
  );
};
