import Home from "./Home/Home";
import CustomerResults from "./CustomerResults/CustomerResult";
import Performance from "./Perfomance/Performance";
import HowItWorks from "./HowItWorks/HowItWorks";
import GetReviews from "./GetReviews/GetReviews";
import ClearlyTrack from "./ClearlyTrack/ClearlyTrack";
import Testimonial from "./Testimonial/Testimonial";
import Ready from "./Ready/Ready";
import KeyBenefits from "./KeyBenefits/KeyBenefits";
import LogoCarousel from "./LogoCarousel/LogoCarousel";
import Chatbot from "../components/Chatbot/Chatbot";

function Homepage() {
  return (
    <div className="lg:px-8">
      <Home />
      <LogoCarousel />
      <HowItWorks />
      <CustomerResults />

      <KeyBenefits />
      <Performance />
      <GetReviews />
      <ClearlyTrack />

      <Testimonial />
      <Ready />
      <Chatbot />
    </div>
  );
}

export default Homepage;
