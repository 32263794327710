import { useState, useContext } from "react";
import { X } from "lucide-react";
import { CustomerContactsContext } from "../../../context/CustomerContactsContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CreateCampaignModal({
  onClose,
  campaignType,
  refetchCampaigns,
}) {
  const { emailContacts, phoneContacts } = useContext(CustomerContactsContext);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    message: "",
    schedule: "",
    campaign_type: "feedback", // ✅ Ensure it's included
  });
  const [loading, setLoading] = useState(false);
  const [showAllContacts, setShowAllContacts] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(""); // Track dropdown value
  // Determine contacts based on campaign type
  const contacts = campaignType === "email" ? emailContacts : phoneContacts;

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // ✅ Handle individual selection
  const handleContactSelect = (contact) => {
    setSelectedContacts((prev) =>
      prev.includes(contact)
        ? prev.filter((c) => c !== contact)
        : [...prev, contact]
    );
  };

  // ✅ Select All Contacts
  const handleSelectAll = () => {
    if (selectedContacts.length === contacts.length) {
      setSelectedContacts([]); // Deselect all
      setDropdownValue(""); // Reset dropdown
    } else {
      setSelectedContacts(contacts); // Select all
    }
  };

  // ✅ Deselect All Contacts
  const handleDeselectAll = () => {
    setSelectedContacts([]);
    setDropdownValue(""); // Reset dropdown
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedContacts.length === 0) {
      toast.error("Please select at least one contact.");
      return;
    }

    setLoading(true);

    const campaignData = {
      name: formData.name,
      message_type: campaignType, // "email" or "sms"
      campaign_type: formData.campaign_type,
      subject: formData.subject,
      message: formData.message,
      scheduled_at: new Date(formData.schedule).toISOString(),
      contact_ids: selectedContacts.map((contact) => contact.id),
    };
console.log(campaignData)
    try {
      const token = localStorage.getItem("token"); // Use token from AuthContext

      const response = await fetch(
        "https://zola.technology/api/company/campaigns/add/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(campaignData),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        const errorMessage =
          result.non_field_errors?.[0] ||
          result.message ||
          "Failed to create campaign";
        toast.error(errorMessage); // ✅ Show toast with error
        throw new Error(errorMessage);
      }

      toast.success("Campaign created successfully!"); // ✅ Success message
      refetchCampaigns();
      onClose();
    } catch (error) {
      console.error("Error creating campaign:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 p-4">
      <ToastContainer />
      <div className="bg-white dark:bg-gray-900 p-6 rounded-xl shadow-lg relative w-[750px]">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white"
        >
          <X className="w-6 h-6" />
        </button>

        <h2 className="text-2xl font-semibold mb-6">Create Campaign</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Campaign Type Selector */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Message Type
            </label>
            <p className="mt-1 w-full p-2 border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white">
              {campaignType === "sms" ? "SMS" : "Email"}
            </p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Campaign Type
            </label>
            <select
  name="campaign_type"
  value={formData.campaign_type} // ✅ Ensure it's bound to state
  onChange={handleChange} // ✅ Updates state when changed
  className="mt-1 w-full p-2 border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white"
>
  <option value="feedback">Feedback</option>
  <option value="survey">Survey</option>
</select>

          </div>

          {/* Contacts Dropdown */}
          <div className="mt-1 w-full p-2 border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white min-h-[40px]">
      {contacts.length === 0 ? (
        <p className="text-gray-500 text-sm">
          No contacts available for this campaign type. Add contacts in the contacts section.
        </p>
      ) : (
        <>
          {/* ✅ Dropdown for Selecting Individual Contacts */}
          <select
            value={dropdownValue}
            onChange={(e) => {
              const selected = contacts.find(
                (c) =>
                  (campaignType === "sms" ? c.phone_number : c.email) === e.target.value
              );
              if (selected) {
                handleContactSelect(selected);
                setDropdownValue(""); // Reset dropdown after selection
              }
            }}
            className="w-full bg-transparent border-none outline-none mb-2"
          >
            <option value="">Select Contact</option>
            {contacts
              .filter((contact) =>
                campaignType === "sms" ? contact.phone_number : contact.email
              )
              .map((contact) => (
                <option
                  key={contact.id}
                  value={campaignType === "sms" ? contact.phone_number : contact.email}
                >
                  {contact.name.split(" ")[0]} -{" "}
                  {campaignType === "sms" ? contact.phone_number : contact.email}
                </option>
              ))}
          </select>

          {/* ✅ Selected Contacts Display */}
          {selectedContacts.length > 0 && (
            <div className="mt-2 h-20 overflow-y-auto">
              {/* Show first 5 contacts */}
              <div className="flex flex-wrap gap-2">
                {selectedContacts.slice(0, 5).map((contact) => (
                  <span
                    key={contact.id}
                    className="bg-blue-100 text-blue-700 px-2 py-1 rounded-md inline-flex items-center"
                  >
                    {campaignType === "sms" ? contact.phone_number : contact.email}
                    <button
                      type="button"
                      className="ml-1 text-red-500"
                      onClick={() => handleContactSelect(contact)}
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>

              {/* ✅ Select All & Deselect All Buttons */}
              <div className="mt-2 flex justify-between items-center">
                <button
                  type="button"
                  onClick={handleSelectAll}
                  className="text-sm text-green-600 hover:underline"
                >
                  {selectedContacts.length === contacts.length ? "" : "Select All"}
                </button>

                <button
                  type="button"
                  onClick={handleDeselectAll}
                  className="text-sm text-red-600 hover:underline"
                >
                  Deselect All
                </button>
              </div>

              {/* ✅ Toggle View All Button */}
              {selectedContacts.length > 5 && (
                <div className="">
                  <button
                    type="button"
                    onClick={() => setShowAllContacts(!showAllContacts)}
                    className="text-sm text-blue-600 hover:underline"
                  >
                    {showAllContacts ? "Hide Contacts" : `View All (${selectedContacts.length})`}
                  </button>
                </div>
              )}

              {/* ✅ Scrollable Two-Column Dropdown for All Contacts */}
              {showAllContacts && (
                <div className="mt-2 max-h-40 overflow-y-auto border p-2 rounded-md bg-gray-100 dark:bg-gray-800">
                  <div className="grid grid-cols-2 gap-2">
                    {selectedContacts.map((contact) => (
                      <span
                        key={contact.id}
                        className="bg-blue-100 text-blue-700 px-2 py-1 rounded-md inline-flex items-center"
                      >
                        {campaignType === "sms" ? contact.phone_number : contact.email}
                        <button
                          type="button"
                          className="ml-1 text-red-500"
                          onClick={() => handleContactSelect(contact)}
                        >
                          ×
                        </button>
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>

          {/* Name and Subject */}
          <div className="flex gap-4">
            <div className={campaignType === "sms" ? "w-full" : "w-1/2"}>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Name
              </label>
              <input
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter campaign name"
                required
                className="mt-1 w-full p-2 border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white"
              />
            </div>

            {campaignType !== "sms" && (
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Subject
                </label>
                <input
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Enter subject"
                  className="mt-1 w-full p-2 border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                />
              </div>
            )}
          </div>

          {/* Message */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Message
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Enter your message"
              className="mt-1 w-full p-2 border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white"
            />
          </div>

          {/* Schedule */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Schedule
            </label>
            <input
              type="datetime-local"
              name="schedule"
              value={formData.schedule}
              onChange={handleChange}
              required
              min={new Date().toISOString().slice(0, 16)} // Prevents selecting past dates
              className="mt-1 w-full p-2 border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white"
            />
          </div>

          {/* Buttons */}
          <div className="flex justify-end gap-2 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700"
            >
              {loading ? "Creating..." : "Create Campaign"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
