import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { PiPencilSimpleLineFill } from "react-icons/pi";
import axios from "axios";

const MobileSurvey = () => {
  const { company_username } = useParams();
  const [companyData, setCompanyData] = useState(null);
  const [surveyId, setSurveyId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const hasUpdatedCount = useRef(false); // Prevent multiple API calls

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await fetch(
          `https://zola.technology/api/company/get/${company_username}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch company data");
        }
        const data = await response.json();
        setCompanyData(data);
      } catch (error) {
        setError(error.message);
        console.error("Error fetching company data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchActiveSurveyId = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/${company_username}/surveys/survey/get-active/`
        );
        if (response.data && response.data.id) {
          setSurveyId(response.data.id);

          // Only call updateViewCount if it hasn't been triggered yet
          if (!hasUpdatedCount.current) {
            hasUpdatedCount.current = true; // Mark as updated before calling API
            updateViewCount(response.data.id);
          }
        }
      } catch (error) {
        console.error("Error fetching active survey:", error);
      }
    };

    fetchCompanyData();
    fetchActiveSurveyId();
  }, [company_username]);

  // Update view count on page visit
  const updateViewCount = async (surveyId) => {
    try {
      await axios.patch(
        `https://zola.technology/api/company/${company_username}/surveys/survey/update-clicked-count/?survey_id=${surveyId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error updating survey view count:", error);
    }
  };

  if (error) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <p className="text-red-500">Error: {error}</p>
      </div>
    );
  }

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
    const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };

  const initials = getInitials(companyData?.first_name, companyData?.last_name);

  return (
    <div className="w-full relative h-[100dvh] flex flex-col items-center justify-center bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      {companyData?.profile_image ? (
        <img
          src={companyData.profile_image}
          alt={`${companyData.company_name} Profile`}
          className="object-contain bg-white w-36 h-36 flex items-center justify-center rounded-full border-4 border-blue-200 shadow-lg"
        />
      ) : (
        <div className="w-36 h-36 flex items-center justify-center rounded-full border-4 border-blue-200 shadow-lg bg-blue-600">
          <span className="text-white text-3xl font-bold">{initials}</span>
        </div>
      )}

      <div className="mt-6 text-center px-6">
        <p className="text-2xl font-semibold">
          {companyData?.preferred_business_name || "Hello Customer"}
        </p>
        <p className="text-lg mt-2 text-gray-600 dark:text-gray-300">
          We're glad to have you with us! Your feedback helps us grow.
        </p>
      </div>

      <div className="mt-8 px-4 w-full flex justify-center">
        <button
          aria-label="Take a survey"
          onClick={() => navigate(`/survey/${company_username}/`)}
          className="bg-blue-600 hover:bg-blue-700 transition-colors rounded-xl h-14 flex items-center space-x-3 px-6 w-full max-w-md justify-center shadow-lg"
        >
          <span className="text-white text-lg font-medium">Take a Survey</span>
          <PiPencilSimpleLineFill className="w-6 h-6 text-white" />
        </button>
      </div>

      {/* Footer */}
      <div className="mt-10 absolute bottom-4 flex gap-1 items-center">
        <p className="text-gray-600 dark:text-gray-300 text-lg">Powered by</p>
        <img src={logo} alt="Company Logo" className="mt-2 w-24 h-auto" />
      </div>
    </div>
  );
};

export default MobileSurvey;
