export const getStoredTokens = () => ({
  token: localStorage.getItem("token"),
  refreshToken: localStorage.getItem("refreshToken"),
  expiryTime: localStorage.getItem("expiryTime"),
});

export const storeTokens = (accessToken, refreshToken) => {
  const now = new Date().getTime();
  const expiryTime = now + 24 * 60 * 60 * 1000; // 24 hrs
  localStorage.setItem("token", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("expiryTime", expiryTime);
};

export const clearTokens = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("expiryTime");
};

export const refreshTokenRequest = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  try {
    const response = await fetch("https://zola.technology/api/token/refresh/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ refresh: refreshToken }),
    });
    const data = await response.json();

    if (response.ok) {
      storeTokens(data.access, refreshToken);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Failed to refresh token:", error);
    return false;
  }
};
