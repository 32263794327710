import GaugeChart from "react-gauge-chart";

const SemiCircleChart = ({ value = 0 }) => {
  // Generate labels and colors dynamically
  const sectionLabels = Array.from({ length: 21 }, (_, i) => (-100 + i * 10).toString());
  const sectionColors = [
    "#FF0000", "#FF1900", "#FF3300", "#FF4C00", "#FF6600", "#FF7F00", "#FF9900", "#FFB200", "#FFCC00", "#FFE500",
    "#FFFF00", "#E5FF00", "#CCFF00", "#B2FF00", "#99FF00", "#7FFF00", "#66FF00", "#4CFF00", "#33FF00", "#19FF00", "#00FF00"
  ];

  // Normalize value from -100 to 100 → to range 0-1 for GaugeChart
  const normalizedValue = (value + 100) / 200;

  return (
    <div className="w-full flex flex-col items-center relative">
      {/* Gauge Chart */}
      <GaugeChart
        id="semi-circle-gauge"
        nrOfLevels={sectionColors.length} // Match number of segments to labels
        colors={sectionColors}
        arcWidth={0.3}
        percent={normalizedValue}
        animate={false}
        textColor="transparent"
        needleColor="#333"
        needleBaseColor="#333"
        formatTextValue={() => ""}
      />
      {/* Value Display */}
      <div className="text-center font-bold text-lg mt-4">
        {value.toFixed(2)}
      </div>
      <div className="text-center text-gray-500">Overall NPS</div>
      {/* Legend */}
      {/* <div className="hidden md:flex justify-between w-full mt-2 overflow-x-auto">
        {sectionLabels.map((label, index) => (
          <div key={index} className="flex flex-col items-center">
            <div
              className="w-3 h-3 rounded-full"
              style={{ backgroundColor: sectionColors[index] }}
            ></div>
            <span className="text-xs font-bold text-gray-800 mt-1">
              {label}
            </span>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default SemiCircleChart;
