
const Settings = () => {
  return (
    <div className="ml-4 lg:ml-[30px]">
      <h1>Settings</h1>
      <p>Adjust your application settings and preferences here.</p>
    </div>
  );
};

export default Settings;
