import { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { fetchPlacePredictions } from "../../../pages/SignUp/googlePlaces";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Eye, EyeOff } from "lucide-react";

const CompanyForm = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    username: "",
    email: "",
    phone_number: "",
    industry_type: "",
    company_type: "",
    preferred_business_name: "",
    is_hq_company: false,
    number_of_locations: "",
    password: "",
    confirm_password: "",
    number_of_cards: 0,
    number_of_stands: 0,
    card_amount: 0,
    stand_amount: 0,
    subscription_amount: 0,
    reward_amount_per_score: 0,
    subscription_interval: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errors, setErrors] = useState({}); // State to store validation errors

  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const suggestionsRef = useRef();

  const [passwordMatch, setPasswordMatch] = useState(true);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const [loading, setLoading] = useState(false);

  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phone_number: phone });
  };

  useEffect(() => {
    if (formData.company_name.length > 2) {
      const fetchSuggestions = async () => {
        const suggestions = await fetchPlacePredictions(formData.company_name);
        setPlaceSuggestions(suggestions);
      };
      fetchSuggestions();
    } else {
      setPlaceSuggestions([]);
    }
  }, [formData.company_name]);

  const handlePlaceSelect = (description) => {
    setFormData({ ...formData, company_name: description });
    setPlaceSuggestions([]);
  };

  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setPlaceSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Password validation
    if (formData.password !== formData.confirm_password) {
      setPasswordMatch(false);
      setErrors({ confirm_password: "Passwords do not match!" });
      toast.error("Passwords do not match!");
      setLoading(false);
      return;
    }
    setPasswordMatch(true);

    try {
      // Step 1: Sign up the company
      const signupResponse = await fetch(
        "https://zola.technology/api/company/signup/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const signupData = await signupResponse.json();

      if (!signupResponse.ok) {
        setErrors(signupData);
        toast.error(signupData.detail || "Signup failed! Please try again.");
        setLoading(false);
        return;
      }

      // Success message for signup
      toast.success("Company signup successful!");

      // Store username on successful signup
      localStorage.setItem("username", formData.username);
      setErrors({});

      // Step 2: Calculate payment data
      const number_of_cards = parseFloat(formData.number_of_cards) || 0;
      const card_amount = parseFloat(formData.card_amount) || 0;
      const number_of_stands = parseFloat(formData.number_of_stands) || 0;
      const stand_amount = parseFloat(formData.stand_amount) || 0;
      const subscription_amount = parseFloat(formData.subscription_amount) || 0;
      const number_of_locations = parseFloat(formData.number_of_locations) || 1;

      const totalCardAmount = number_of_cards * card_amount;
      const totalStandAmount = number_of_stands * stand_amount;
      const totalSubscriptionAmount = subscription_amount * number_of_locations;
      const grandTotal =
        totalCardAmount + totalStandAmount + totalSubscriptionAmount;

      const transactionType =
        totalCardAmount > 0 && totalSubscriptionAmount > 0
          ? "both"
          : totalSubscriptionAmount > 0
          ? "subscription"
          : "payment";

      const paymentData = {
        username: formData.username,
        unit_amount: grandTotal - totalSubscriptionAmount,
        phone_number: formData.phone_number,
        subscription_interval: formData.subscription_interval,
        subscription_amount: totalSubscriptionAmount,
        transaction_type: transactionType,
      };

      // Step 3: Make payment
      const paymentResponse = await fetch(
        "https://zola.technology/api/company/make-payment/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      const paymentDataResponse = await paymentResponse.json();

      if (!paymentResponse.ok) {
        setErrors(paymentDataResponse);
        toast.error(
          paymentDataResponse.detail || "Payment failed! Please try again."
        );
        setLoading(false);
        return;
      }

      // Success message for payment
      toast.success("Payment email sent successfully!");

      const receivedUrl = paymentDataResponse.url;

      // Step 4: Send email with registration completion link
      const emailData = {
        subject: "Complete registration",
        message: `Please complete your registration using the following link: ${receivedUrl}`,
        recipients: [formData.email],
      };

      const emailResponse = await fetch(
        "https://zola.technology/api/send-bulk-emails/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        }
      );

      if (!emailResponse.ok) {
        const emailErrorData = await emailResponse.json();
        console.error("Error sending email:", emailErrorData);
        toast.error("Error sending email. Please check your email address.");
      } else {
        // Success message for email
        toast.success("Email sent successfully!");
      }

      // Show modal on successful submission
      setFormData({
        ...formData,
        totalCardAmount,
        totalStandAmount,
        totalSubscriptionAmount,
        grandTotal,
      });
      // setModalOpen(true);
      setErrors({}); // Clear any previous errors
    } catch (error) {
      console.error("Error in submission process:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-8 max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md"
    >
      <ToastContainer />
      <style>
        {`
          .input {
            width: 100%;
            padding: 0.5rem 1rem;
            border: 1px solid #D1D5DB;
            border-radius: 0.375rem;
            transition: border-color 0.3s;
          }
          .input:focus {
            outline: none;
            border-color: #3B82F6;
          }
          .suggestions-list {
            position: absolute;
            background-color: white;
            border: 1px solid #D1D5DB;
            border-radius: 0.375rem;
            width: 100%;
            z-index: 10;
          }
          .suggestion-item {
            padding: 0.5rem;
            cursor: pointer;
          }
          .suggestion-item:hover {
            background-color: #F3F4F6;
          }
        `}
      </style>

      {/* Company Details Section */}
      <div>
        <h2 className="text-xl font-semibold text-gray-700">Company Details</h2>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 mt-4">
          <input
            type="text"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="First Name"
            className="input"
            required
          />
          <input
            type="text"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            placeholder="Last Name"
            className="input"
            required
          />

          <div className="relative">
            <input
              type="text"
              name="company_name"
              value={formData.company_name}
              onChange={handleChange}
              placeholder="Company Name"
              className="input"
              required
            />
            {placeSuggestions.length > 0 && (
              <div ref={suggestionsRef} className="suggestions-list">
                {placeSuggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    onClick={() => handlePlaceSelect(suggestion.description)}
                    className="suggestion-item"
                  >
                    {suggestion.description}
                  </div>
                ))}
              </div>
            )}
          </div>

          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Username"
            className="input"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="input"
            required
          />

          <div className="w-full">
            <PhoneInput
              country={"us"}
              value={formData.phone_number}
              onChange={handlePhoneChange}
              inputClass="input"
              containerClass="w-full"
              placeholder="Phone Number"
            />
          </div>

          <select
            id="company_type"
            name="company_type"
            value={formData.company_type}
            onChange={handleChange}
            className="input"
          >
            <option value="">Select Company Type</option>
            <option value="team">Team</option>
            <option value="one_person">One Person</option>
          </select>
          <input
            type="text"
            name="preferred_business_name"
            value={formData.preferred_business_name}
            onChange={handleChange}
            placeholder="Preferred Business Name"
            className="input"
          />
          <input
            type="text"
            name="number_of_locations"
            value={formData.number_of_locations}
            onChange={handleChange}
            placeholder="Number of Locations"
            className="input"
          />

          {/* Password Field */}
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              className="input pr-10"
              required
            />
            <span
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
            </span>
          </div>

          {/* Confirm Password Field */}
          <div className="relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              placeholder="Confirm Password"
              className="input pr-10"
              required
            />
            <span
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
              onClick={() => setShowConfirmPassword((prev) => !prev)}
            >
              {showConfirmPassword ? <EyeOff size={18} /> : <Eye size={18} />}
            </span>
          </div>

          {!passwordMatch && (
            <p className="text-red-500">Passwords do not match.</p>
          )}

          <label className="flex items-center space-x-3 text-gray-600">
            <input
              type="checkbox"
              name="is_hq_company"
              checked={formData.is_hq_company}
              onChange={handleChange}
              className="rounded border-gray-300 text-blue-500 focus:ring-blue-400"
            />
            <span>Is HQ Company</span>
          </label>
        </div>
      </div>
      {/* Payment Details Section */}
      <div>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          Payment Details
        </h2>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 mt-4">
          {/* Number of Cards */}
          <div>
            <label
              htmlFor="number_of_cards"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Number of Cards
            </label>
            <input
              type="number"
              id="number_of_cards"
              name="number_of_cards"
              value={formData.number_of_cards}
              onChange={handleChange}
              placeholder="Enter the number of cards"
              className="input"
            />
          </div>

          {/* Number of Stands */}
          <div>
            <label
              htmlFor="number_of_stands"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Number of Stands
            </label>
            <input
              type="number"
              id="number_of_stands"
              name="number_of_stands"
              value={formData.number_of_stands}
              onChange={handleChange}
              placeholder="Enter the number of stands"
              className="input"
            />
          </div>

          {/* Card Amount */}
          <div>
            <label
              htmlFor="card_amount"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Card Price
            </label>
            <input
              type="number"
              id="card_amount"
              name="card_amount"
              value={formData.card_amount}
              onChange={handleChange}
              placeholder="Enter the card amount"
              className="input"
            />
          </div>

          {/* Stand Amount */}
          <div>
            <label
              htmlFor="stand_amount"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Stand Price
            </label>
            <input
              type="number"
              id="stand_amount"
              name="stand_amount"
              value={formData.stand_amount}
              onChange={handleChange}
              placeholder="Enter the stand amount"
              className="input"
            />
          </div>

          {/* Subscription Amount */}
          <div>
            <label
              htmlFor="subscription_amount"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Subscription Amount
            </label>
            <input
              type="number"
              id="subscription_amount"
              name="subscription_amount"
              value={formData.subscription_amount}
              onChange={handleChange}
              placeholder="Enter the subscription amount"
              className="input"
            />
          </div>

          {/* Reward Amount per Score
          <div>
            <label
              htmlFor="reward_amount_per_score"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Reward Amount per Score
            </label>
            <input
              type="number"
              id="reward_amount_per_score"
              name="reward_amount_per_score"
              value={formData.reward_amount_per_score}
              onChange={handleChange}
              placeholder="Enter reward amount per score"
              className="input"
            />
          </div> */}

          {/* Subscription Interval */}
          <div>
            <label
              htmlFor="subscription_interval"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Subscription Interval
            </label>
            <select
              id="subscription_interval"
              name="subscription_interval"
              value={formData.subscription_interval}
              onChange={handleChange}
              required
              className="input"
            >
              <option value="">Select Subscription Interval</option>
              <option value="month">Monthly</option>
              <option value="year">Yearly</option>
            </select>
          </div>

          {/* Total Amount Section */}
          <div className="col-span-2 p-4 bg-gray-50 rounded-lg border mt-4">
            <h3 className="text-lg font-semibold mb-2">Total Amount Summary</h3>

            {(() => {
              const formatCurrency = (value) =>
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(value);

              const cardTotal =
                formData.number_of_cards * formData.card_amount || 0;
              const standTotal =
                formData.number_of_stands * formData.stand_amount || 0;
              const subscriptionTotal =
                formData.subscription_amount * formData.number_of_locations ||
                0;
              const grandTotal = cardTotal + standTotal + subscriptionTotal;

              return (
                <ul className="text-gray-700 space-y-1">
                  <li>Card Total: {formatCurrency(cardTotal)}</li>
                  <li>Stand Total: {formatCurrency(standTotal)}</li>
                  <li>
                    Subscription Total: {formatCurrency(subscriptionTotal)}
                  </li>
                  <li className="font-bold mt-2">
                    Grand Total: {formatCurrency(grandTotal)}
                  </li>
                </ul>
              );
            })()}
          </div>
        </div>
      </div>
      <button
        disabled={loading}
        aria-label="Submit"
        aria-disabled={loading}
        type="submit"
        className="w-full py-3 px-6 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        {loading ? "Submitting..." : "Submit"}
      </button>
    </form>
  );
};

export default CompanyForm;
