import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

const PasswordSettings = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePasswordUpdate = async () => {
    // Check if new passwords match
    if (newPassword !== confirmPassword) {
      toast.error("New passwords do not match");
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("token");

      // Make sure token is available
      if (!token) {
        toast.error("You must be logged in to update the password.");
        setLoading(false);
        return;
      }

      // Make the API request to update the password
      const response = await fetch(
        `https://zola.technology/api/company/password/update/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            current_password: currentPassword,
            new_password: newPassword,
          }),
        }
      );

      // Handle the response from the server
      if (response.ok) {
        const data = await response.json();
        toast.success(data.message || "Password updated successfully");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || "Failed to update password");
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="md:p-6">
      <ToastContainer />
      <h3 className="text-2xl font-semibold mb-6 dark:text-gray-100">Change Password</h3>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200 mb-1">Current Password</label>
        <input
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200 mb-1">New Password</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 dark:text-gray-200 mb-1">Confirm New Password</label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <button
        aria-label="Update Password"
        onClick={handlePasswordUpdate}
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
        disabled={loading}
      >
        {loading ? "Updating..." : "Update Password"}
      </button>
    </div>
  );
};

export default PasswordSettings;
