import { useState } from "react";
import { IoClose } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_ENDPOINTS = {
  "Google Business Profile":
    "https://zola.technology/api/company/google-reviews/add-google-profile-url/",
  Yelp: "https://zola.technology/api/company/yelp/add-review-url/",
  Tripadvisor:
    "https://zola.technology/api/company/trip-advisor/add-location-id/",
};

export default function UpdateModal({ account, onClose, onUpdate }) {
  const [formData, setFormData] = useState({
    name: account.name,
    type: account.type,
    review_url: account.review_url || "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, review_url: e.target.value });
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("User is not authenticated.");
      return;
    }
  
    const endpoint = API_ENDPOINTS[account.name];
    if (!endpoint) {
      toast.error("Invalid account type.");
      return;
    }
  
    const requestBody = {};
    let urlKey = ""; // Store the specific URL key based on the platform
  
    if (account.name === "Google Business Profile") {
      requestBody.google_profile_url = formData.review_url;
      urlKey = "google_profile_url";
    } else if (account.name === "Yelp") {
      requestBody.yelp_url = formData.review_url;
      urlKey = "yelp_url";
    } else if (account.name === "Tripadvisor") {
      requestBody.tripadvisor_url = formData.review_url;
      urlKey = "tripadvisor_url";
    }
  
    try {
      setLoading(true);
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
  
      const responseData = await response.json(); // Parse JSON response
  
      if (!response.ok) {
        console.log("Response Status:", response.status);
        console.log("Response Body:", responseData);
  
        // Check for platform-specific URL errors
        let errorMessage = responseData[urlKey]?.[0] || responseData.detail?.split('.')[0] || "Failed to update review URL.";
        throw new Error(errorMessage);
      }
  
      // Ensure UI reflects the updated URL
      onUpdate({ ...account, review_url: formData.review_url });
  
      toast.success("Review URL updated successfully!");
  
      // Close the modal after a short delay for better UX
      setTimeout(() => {
        onClose();
      }, 500);
  
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-semibold">Update Account</h3>
          <IoClose className="text-2xl cursor-pointer" onClick={onClose} />
        </div>

        {/* Account Name (Read-Only) */}
        <div className="mt-4">
          <label className="block text-sm font-medium">Account Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            readOnly
            className="w-full px-3 py-2 border rounded-md bg-gray-100 cursor-not-allowed"
          />
        </div>

        {/* Review URL Input (Editable) */}
        <div className="mt-4">
          <label className="block text-sm font-medium">Review URL</label>
          <input
            type="text"
            name="review_url"
            value={formData.review_url}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        {/* Action Buttons */}
        <div className="mt-6 flex justify-end space-x-2">
          <button className="px-4 py-2 bg-gray-300 rounded-md" onClick={onClose} disabled={loading}>
            Cancel
          </button>
          <button className="px-4 py-2 bg-blue-500 text-white rounded-md" onClick={handleUpdate} disabled={loading}>
            {loading ? "Updating..." : "Save Changes"}
          </button>
        </div>
      </div>

      {/* Toast Notifications */}
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}
