
const EditBranchPopup = ({
  selectedBranch,
  editData,
  setEditData,
  handleEditSubmit,
  handleClosePopup,
  errors, // Receive errors as a prop
}) => {
  if (!selectedBranch) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[600px] max-h-[80vh] overflow-y-auto">
        <h2 className="text-2xl font-semibold mb-6 text-center border-b pb-3">
          Edit Branch: {selectedBranch.name}
        </h2>
        <form onSubmit={handleEditSubmit} className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">First Name</label>
            <input
              type="text"
              className="border p-2 rounded-lg w-full"
              value={editData.first_name ?? selectedBranch.first_name ?? ""}
              onChange={(e) =>
                setEditData({ ...editData, first_name: e.target.value })
              }
            />
            {errors.first_name && (
              <p className="text-red-500 text-sm mt-1">{errors.first_name[0]}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Last Name</label>
            <input
              type="text"
              className="border p-2 rounded-lg w-full"
              value={editData.last_name ?? selectedBranch.last_name ?? ""}
              onChange={(e) =>
                setEditData({ ...editData, last_name: e.target.value })
              }
            />
            {errors.last_name && (
              <p className="text-red-500 text-sm mt-1">{errors.last_name[0]}</p>
            )}
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-medium mb-1">Company Name</label>
            <input
              type="text"
              className="border p-2 rounded-lg w-full"
              value={editData.company_name ?? selectedBranch.company_name ?? ""}
              onChange={(e) =>
                setEditData({ ...editData, company_name: e.target.value })
              }
            />
            {errors.company_name && (
              <p className="text-red-500 text-sm mt-1">{errors.company_name[0]}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="email"
              className="border p-2 rounded-lg w-full"
              value={editData.email ?? selectedBranch.email ?? ""}
              onChange={(e) =>
                setEditData({ ...editData, email: e.target.value })
              }
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email[0]}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Phone Number</label>
            <input
              type="text"
              className="border p-2 rounded-lg w-full"
              value={editData.phone_number ?? selectedBranch.phone_number ?? ""}
              onChange={(e) =>
                setEditData({ ...editData, phone_number: e.target.value })
              }
            />
            {errors.phone_number && (
              <p className="text-red-500 text-sm mt-1">{errors.phone_number[0]}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Industry Type</label>
            <input
              type="text"
              className="border p-2 rounded-lg w-full"
              value={editData.industry_type ?? selectedBranch.industry_type ?? ""}
              onChange={(e) =>
                setEditData({ ...editData, industry_type: e.target.value })
              }
            />
            {errors.industry_type && (
              <p className="text-red-500 text-sm mt-1">{errors.industry_type[0]}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Company Type</label>
            <input
              type="text"
              className="border p-2 rounded-lg w-full"
              value={editData.company_type ?? selectedBranch.company_type ?? ""}
              onChange={(e) =>
                setEditData({ ...editData, company_type: e.target.value })
              }
            />
            {errors.company_type && (
              <p className="text-red-500 text-sm mt-1">{errors.company_type[0]}</p>
            )}
          </div>
          <div className="col-span-2 flex justify-end gap-4 mt-4">
            <button
              type="button"
              onClick={handleClosePopup}
              className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-200"
            >
              Close
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditBranchPopup;
