import { Gift, RefreshCw, TicketPercent } from "lucide-react";

function CouponsStats({ coupons }) {
  const totalRedemptions = coupons.length
    ? coupons.reduce((sum, c) => sum + c.redemptions, 0)
    : 0;

  const totalCoupons = coupons.length;

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div className="p-6 bg-gradient-to-r from-purple-600 to-purple-800 text-white rounded-2xl shadow-lg flex items-center space-x-4 hover:scale-[1.02] transition">
        <Gift className="w-10 h-10 opacity-80" />
        <div>
          <p className="text-sm opacity-75">Total Redemptions</p>
          <p className="text-3xl font-bold">{totalRedemptions}</p>
        </div>
      </div>

      <div className="p-6 bg-gradient-to-r from-yellow-500 to-yellow-600 text-white rounded-2xl shadow-lg flex items-center space-x-4 hover:scale-[1.02] transition">
        <RefreshCw className="w-10 h-10 opacity-80" />
        <div>
          <p className="text-sm opacity-75">New Redemptions</p>
          <p className="text-3xl font-bold">{totalRedemptions}</p>
        </div>
      </div>

      <div className="p-6 bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-2xl shadow-lg flex items-center space-x-4 hover:scale-[1.02] transition">
        <TicketPercent className="w-10 h-10 opacity-80" />
        <div>
          <p className="text-sm opacity-75">Total Coupons</p>
          <p className="text-3xl font-bold">{totalCoupons}</p>
        </div>
      </div>
    </div>
  );
}

export default CouponsStats;

// import React from "react";
// import { Gift, RefreshCw, TicketPercent, Clock, CheckCircle, XCircle, DollarSign, BarChart2 } from "lucide-react";

// function CouponsStats({ coupons }) {
//   const totalCoupons = coupons.length;

//   const totalRedemptions = coupons.reduce((sum, c) => sum + c.redemptions, 0);

//   const totalActiveCoupons = coupons.filter((c) =>
//     c.codes.some((code) => code.status === "Active")
//   ).length;

//   const totalExpiredCoupons = coupons.filter((c) =>
//     c.codes.some((code) => code.status === "Expired")
//   ).length;

//   const totalDiscounts = coupons.reduce((sum, c) => {
//     if (c.discountType === "Cash") return sum + parseFloat(c.cashDiscount || 0);
//     if (c.discountType === "Percentage") return sum + parseFloat(c.percentageDiscount || 0);
//     return sum;
//   }, 0);

//   const averageDiscount = totalCoupons ? (totalDiscounts / totalCoupons).toFixed(2) : 0;

//   const percentageCoupons = coupons.filter((c) => c.discountType === "Percentage").length;
//   const cashCoupons = coupons.filter((c) => c.discountType === "Cash").length;

//   const redemptionRate = totalCoupons ? ((totalRedemptions / totalCoupons) * 100).toFixed(2) : 0;

//   return (
//     <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
//       <StatCard icon={<Gift />} title="Total Redemptions" value={totalRedemptions} />
//       <StatCard icon={<RefreshCw />} title="Redemption Rate (%)" value={`${redemptionRate}%`} />
//       <StatCard icon={<TicketPercent />} title="Total Coupons" value={totalCoupons} />
//       <StatCard icon={<CheckCircle />} title="Active Coupons" value={totalActiveCoupons} />
//       <StatCard icon={<XCircle />} title="Expired Coupons" value={totalExpiredCoupons} />
//       <StatCard icon={<DollarSign />} title="Total Discount Value" value={totalDiscounts} />
//       <StatCard icon={<BarChart2 />} title="Avg. Discount/Coupon" value={averageDiscount} />
//       <StatCard icon={<Clock />} title="Coupon Types" value={`${percentageCoupons} % | ${cashCoupons} $`} />
//     </div>
//   );
// }

// const StatCard = ({ icon, title, value }) => (
//   <div className="p-6 bg-gradient-to-r from-indigo-500 to-indigo-700 text-white rounded-2xl shadow-lg flex items-center space-x-4 hover:scale-[1.02] transition">
//     {React.cloneElement(icon, { className: "w-10 h-10 opacity-80" })}
//     <div>
//       <p className="text-sm opacity-75">{title}</p>
//       <p className="text-3xl font-bold">{value}</p>
//     </div>
//   </div>
// );

// export default CouponsStats;
