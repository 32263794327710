import { useEffect, useState } from "react";
import Overview from "./Overview";
import { useTimeRange } from "../../context/TimeRangeContext";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler, // ✅ Import Filler for area chart effect
} from "chart.js";
import { useOutletContext } from "react-router-dom";
import Subscription from "./Subscription";
import NpsSummaryCard from "./Nps/NpsSummaryCard";
import DeliveryStats from "./Nps/DeliveryStats";
import NpsGaugeChart from "./Nps/NpsGaugeChart";
import CsatGaugeChart from "./Nps/CsatGaugeChart";
import CsatSummaryCard from "./Nps/CsatSummaryCard";
import WordChart from "./WordChart.jsx"

// ✅ Register all required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler // ✅ Required for area fill effect
);

function SurveyAnalysis() {
  const [npsData, setNpsData] = useState([]);
  const [npsChartData, setNpsChartData] = useState(null);
  const [csatData, setCsatData] = useState([]);
  const [error, setError] = useState(null);
  const { timeRange } = useTimeRange();
  const { isExpired } = useOutletContext();
  const [npsScore, setNpsScore] = useState(0);

  const fetchNPSData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/surveys/get-data/nps/?filter=${timeRange}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const spreadResponse = await fetch(
        `https://zola.technology/api/company/surveys/get-data/nps/spread-data/?filter=${timeRange}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok || !spreadResponse.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      const spreadData = await spreadResponse.json();
      setNpsScore(spreadData);
      console.log("API Response:", data); // Log full API response

      // Transform the data into cardData format
      const transformedData = [
        {
          label: "Total Responses",
          value: (
            data.promoters.count +
            data.passives.count +
            data.detractors.count
          ).toLocaleString(),
          change: "",
          // comparison: `vs ${
          //   timeRange === "day" ? "yesterday" : `last ${timeRange}`
          // }`,
          changeColor:
            data.nps_change.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
        {
          label: "Promoters",
          value: data.promoters.count.toLocaleString(),
          change: `${
            data.promoters.change.type === "Increase" ? "+" : ""
          }${data.promoters.change.percentage_change.toFixed(1)}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data.promoters.change.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
        {
          label: "Detractors",
          value: data.detractors.count.toLocaleString(),
          change: `${
            data.detractors.change.type === "Increase" ? "+" : ""
          }${data.detractors.change.percentage_change.toFixed(1)}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor: "text-red-500",
        },
        {
          label: "Passives",
          value: data.passives.count.toLocaleString(),
          change: `${data.passives.change.percentage_change.toFixed(1)}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor: "text-gray-500",
        },
        {
          label: "NPS Score",
          value: `${data.nps_score.toFixed(1)}%`,
          change: ` ${data.nps_change.percentage_change.toFixed(1)}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data.nps_change.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
      ];

      setNpsData(transformedData);

      // Process spread data for trend chart
      const trendData = Array.isArray(spreadData.periods)
        ? spreadData.periods
        : [];
      const chartLabels = trendData.map((item) =>
        timeRange === "year" ? item.period.split(" ")[0] : item.period
      );
      const chartValues = trendData.map((item) => item.nps_score);

      // ✅ Handle missing `nps_trend` by using fallback dummy data

      const defaultTrend = [
        { month: "Jan", nps_score: data.nps_score || 0 },
        { month: "Feb", nps_score: data.nps_score || 0 },
        { month: "Mar", nps_score: data.nps_score || 0 },
        { month: "Apr", nps_score: data.nps_score || 0 },
        { month: "May", nps_score: data.nps_score || 0 },
        { month: "Jun", nps_score: data.nps_score || 0 },
      ];

      // const npsTrendData = Array.isArray(data.nps_trend) ? data.nps_trend : defaultTrend;

      // const chartLabels = npsTrendData.map((item) => item.month);
      // const chartValues = npsTrendData.map((item) => item.nps_score);

      setNpsChartData({
        labels: chartLabels,
        datasets: [
          {
            label: "Net Promoter Score",
            data: chartValues,
            borderColor: "rgba(33, 150, 243, 1)", // Blue border
            backgroundColor: "rgba(33, 150, 243, 0.3)", // ✅ Semi-transparent fill for area effect
            pointBackgroundColor: "#ffffff",
            pointBorderColor: "rgba(33, 150, 243, 1)",
            pointRadius: 4,
            tension: 0.4, // Smooth curve
            fill: "start", // ✅ Enables area fill
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching NPS data:", error);
      setError("Failed to fetch NPS data");
    }
  };

  const fetchCSATData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/surveys/get-data/csat/?filter=${timeRange}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();

      console.log(data);
      const transformedCSATData = [
        {
          label: "Total Responses",
          value: data?.total_responses.count.toLocaleString(),
          change: `${
            data?.total_responses?.change?.total_change > 0 ? "+" : ""
          }${data?.total_responses?.change?.total_change}`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data?.total_responses?.change?.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
        {
          label: "Satisfied Responses",
          value: data?.satisfied_responses?.count.toLocaleString(),
          change: `${data.satisfied_responses?.change?.percentage_change.toFixed(
            1
          )}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data?.satisfied_responses?.change?.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
        {
          label: "Neutral Responses",
          value: data?.neutral_responses.count.toLocaleString(),
          change: `${data?.neutral_responses?.change?.percentage_change?.toFixed(
            1
          )}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data?.neutral_responses?.change?.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
        {
          label: "Dissatisfied Responses",
          value: data?.dissatisfied_responses?.count?.toLocaleString(),
          change: `${data?.dissatisfied_responses?.change?.percentage_change?.toFixed(
            1
          )}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data?.dissatisfied_responses?.change?.type === "Increase"
              ? "text-red-500"
              : "text-green-500",
        },
        {
          label: "CSAT Score",
          value: `${data?.csat_score?.toLocaleString()}%`,
          change: `${data?.csat_change?.percentage_change?.toFixed(1)}%`,
          comparison: `vs ${
            timeRange === "day" ? "yesterday" : `last ${timeRange}`
          }`,
          changeColor:
            data?.csat_change?.type === "Increase"
              ? "text-green-500"
              : "text-red-500",
        },
      ];

      setCsatData(transformedCSATData);
    } catch (error) {
      console.error("Error fetching CSAT data:", error);
      setError("Failed to fetch CSAT data");
    }
  };

  useEffect(() => {
    fetchNPSData();
    fetchCSATData();
  }, [timeRange]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="">
      {/* Overview at the Top */}
      <Overview title="Survey Analysis" showExportButton={false} />

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mb-8">
        <NpsGaugeChart colSpan="lg:col-span-4" />
        <NpsSummaryCard colSpan="lg:col-span-4" />
        <DeliveryStats colSpan="lg:col-span-4" />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 mb-8">
        <CsatGaugeChart colSpan="lg:col-span-6" />
        <CsatSummaryCard colSpan="lg:col-span-6" />
        {/* <DeliveryStats colSpan="lg:col-span-4" /> */}
      </div>
      <WordChart />

      {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-8">
        <ResponseNonResponseChart />
        <ResponsesTrendChart />
        <ResponseRateChart />
      </div> */}

      {/* Main Content Layout */}
      {/* <div className="flex gap-6">
        <div className="flex-grow">
          <div className="mb-8">
            <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">
              Net Promoter Score (NPS)
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
              {npsData.map((item, index) => (
                <div
                  key={index}
                  className="p-4 space-y-2 bg-white dark:bg-gray-800 rounded-lg text-center flex flex-col justify-between"
                >
                  <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">
                    {item.label}
                  </h3>
                  <p className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                    {item.value}
                  </p>
                  <div className="text-sm flex items-center justify-center space-x-2">
                    <span className={`font-medium ${item.changeColor}`}>
                      {item.change}
                    </span>
                    <span className="text-gray-500 dark:text-gray-400">
                      {item.comparison}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
  
          {npsChartData && (
            <div className="bg-white dark:bg-gray-800 p-6 mt-8 rounded-lg">
              <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">
                Net Promoter Score:{" "}
                <span className="text-blue-700">{npsData[4].value}</span>
              </h2>
              <div className="relative h-96">
                <AreaChart />
              </div>
            </div>
          )}
  
          <div className="mt-8">
            <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">
              Customer Satisfaction Score (CSAT)
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
              {csatData.map((item, index) => (
                <div
                  key={index}
                  className="p-4 space-y-2 bg-white dark:bg-gray-800 rounded-lg text-center flex flex-col justify-between"
                >
                  <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">
                    {item.label}
                  </h3>
                  <p className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                    {item.value}
                  </p>
                  <div className="text-sm flex items-center justify-center space-x-2">
                    <span className={`font-medium ${item.changeColor}`}>
                      {item.change}
                    </span>
                    <span className="text-gray-500 dark:text-gray-400">
                      {item.comparison}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
  
          {npsChartData && (
            <div className="bg-white dark:bg-gray-800 p-6 mt-8 rounded-lg">
              <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">
                Customer Satisfaction:{" "}
                <span className="text-blue-700">{csatData[4].value}</span>
              </h2>
              <div className="relative h-96">
                <CsatChart />
              </div>
            </div>
          )}
  
        </div>

        <div className="w-1/4 bg-white h-screen dark:bg-gray-800 ">
          <ResponsesList />
        </div>
      </div> */}
      {isExpired && <Subscription />}
    </div>
  );
}

export default SurveyAnalysis;
