import React from "react";

const EmployeeFeedbackSkeleton = () => {
  return (
    <div className="w-full max-w-md mx-auto px-4 py-6 animate-pulse">
      {/* Cover Image Skeleton */}
      <div className="relative">
        <div className="w-full h-48 bg-gray-300 rounded-md" />

        {/* Profile Image Skeleton */}
        <div className="absolute -bottom-16 left-4 w-36 h-36 bg-gray-200 rounded-full border-4 border-white shadow-lg" />
      </div>

      {/* Name + Company Skeleton */}
      <div className="mt-20 text-center px-6 text-black">
        <div className="h-6 bg-gray-300 w-2/3 mx-auto mb-2 rounded" />
        <div className="h-4 bg-gray-300 w-1/2 mx-auto rounded" />
      </div>

      {/* Question 1 */}
      <div className="mt-8 text-center">
        <div className="h-5 w-3/4 bg-gray-300 mx-auto mb-4 rounded" />
        <div className="flex justify-center gap-3 px-6">
          {[...Array(5)].map((_, idx) => (
            <div
              key={idx}
              className="w-10 h-10 bg-gray-200 rounded-full"
            />
          ))}
        </div>
      </div>

      {/* Question 2 */}
      <div className="mt-6 text-center">
        <div className="h-5 w-3/4 bg-gray-300 mx-auto mb-4 rounded" />
        <div className="flex justify-center gap-3 px-6">
          {[...Array(5)].map((_, idx) => (
            <div
              key={idx}
              className="w-10 h-10 bg-gray-200 rounded-full"
            />
          ))}
        </div>
      </div>

      {/* CTA Button Skeleton */}
      <div className="mt-8 px-6">
        <div className="h-12 w-full bg-gray-300 rounded-full" />
      </div>
    </div>
  );
};

export default EmployeeFeedbackSkeleton;
