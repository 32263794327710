import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaCog, FaTicketAlt, FaBullhorn } from "react-icons/fa";
import Logo from "../../HQDashboard/components/sidebar/zolaDark.png";
import ZolaLogo from "../../HQDashboard/components/sidebar/Layer_1.png";
import { PiChatCircleDotsFill } from "react-icons/pi";
import { FaCircleCheck } from "react-icons/fa6";
import { PiDeviceMobileCameraFill } from "react-icons/pi";
import { AuthContext } from "../../context/AuthContext";
import { PiGitBranchBold } from "react-icons/pi";
import {
  MdAddToPhotos,
  MdBusiness,
} from "react-icons/md";
import { PiMoneyWavyFill } from "react-icons/pi";
import { HiViewGridAdd } from "react-icons/hi";
import { FiBarChart2, FiChevronDown, FiGrid, FiMessageSquare } from "react-icons/fi";
import { RiSurveyFill } from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import { RiContactsBook2Fill } from "react-icons/ri";

const Sidebar = ({
  theme,
  toggleTheme,
  toggleSidebar,
  selectedFeedbackTab,
  setSelectedFeedbackTab,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [surveyOpen, setSurveyOpen] = useState(false);

  const toggleFeedback = () => setFeedbackOpen(!feedbackOpen);
  const toggleSurvey = () => setSurveyOpen(!surveyOpen);

  if (!currentUser) {
    return null;
  }

  const userData = JSON.parse(localStorage.getItem("userData"));
  const companyType = userData.company_type;
  const superUser = userData.is_superuser;
  console.log(superUser);

  useEffect(() => {
    // Reset selectedFeedbackTab when navigating away from feedback section
    if (!location.pathname.includes("/dashboard/feedback")) {
      setSelectedFeedbackTab(null);
    }
  }, [location.pathname, setSelectedFeedbackTab]);

  const isActive = (path) =>
    location.pathname === path
      ? "bg-blue-100 text-blue-600 border-r-4 border-blue-600 font-semibold dark:bg-gray-800 dark:text-blue-400 dark:border-blue-400"
      : "text-gray-900 hover:bg-blue-50 dark:text-gray-300 dark:hover:bg-gray-700";

  // Close sidebar when a link is clicked on smaller screens
  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      toggleSidebar();
    }
  };

  const handleTabClick = (tab) => {
    setSelectedFeedbackTab(tab);
    navigate("/dashboard/feedback");
  };

  return (
    <>
      {/* Button to toggle sidebar */}
      {/* {!sidebarVisible && (
        <button
          className="absolute w-[40px] h-[40px] top-5 left-2 z-100 text-gray-700 dark:text-white mr-5 bg-[#FFFFFF] rounded-[4px]"
          onClick={() => setSidebarVisible(true)}
          aria-label="Open sidebar"
        >
          <FaBars size={24} className="m-2" />
        </button>
      )} */}

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 transition-all duration-300   ${
          theme === "light"
            ? "bg-white text-gray-900"
            : "bg-gray-900 text-white"
        } w-64 transform
        } md:translate-x-0 overflow-y-auto custom-scrollbar border-r-[1px] border-[#EAEAEC] dark:border-gray-800`} // Added responsive toggle for sidebar visibility
      >
        {/* Top Section: Logo */}
        <div className="p-8 flex-shrink-0 sticky top-0 bg-inherit z-10">
          <img
            src={theme === "light" ? ZolaLogo : Logo}
            alt="Zola Logo"
            className="w-[150px] h-[30px] object-contain"
          />
        </div>

        {/* Navigation Links */}
        <div className="py-2 h-[80vh] overflow-y-auto no-scrollbar">
          <h2 className="text-sm font-semibold text-gray-400 mb-2 mx-4">
            Main Menu
          </h2>
          <ul>
            <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/dashboard"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/dashboard"
                className="flex items-center gap-4 w-full"
              >
                <FiGrid className="w-5 h-5" />
                Dashboard
              </Link>
            </li>
            {!superUser && (
              <>
                {/* Feedback Dropdown */}
                <li
                  className="px-4 py-3 cursor-pointer"
                  onClick={toggleFeedback}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-4">
                      <PiChatCircleDotsFill className="w-5 h-5" />
                      Feedback
                    </div>
                    <FiChevronDown
                      className={`transition-transform ${
                        feedbackOpen ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                </li>

                <AnimatePresence>
                  {feedbackOpen && (
                    <motion.ul
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      className="pl-12"
                    >
                      <li
                        className={`py-2 hover:text-blue-500 ${
                          selectedFeedbackTab === "all"
                            ? "bg-blue-100 text-blue-600 border-r-4 border-blue-600 font-semibold dark:bg-gray-800 dark:text-blue-400 dark:border-blue-400"
                            : ""
                        }`}
                      >
                        <button onClick={() => handleTabClick("all")}>
                          Overview
                        </button>
                      </li>
                      <li
                        className={`py-2 hover:text-blue-500 ${
                          selectedFeedbackTab === "issues"
                            ? "bg-blue-100 text-blue-600 border-r-4 border-blue-600 font-semibold dark:bg-gray-800 dark:text-blue-400 dark:border-blue-400"
                            : ""
                        }`}
                      >
                        <button onClick={() => handleTabClick("issues")}>
                          Issues
                        </button>
                      </li>

                      <Link
                        onClick={handleLinkClick}
                        to="/dashboard/request-feedback"
                        className={`flex py-2 items-center gap-4 w-full hover:text-blue-500 ${isActive(
                          "/dashboard/request-feedback"
                        )}`}
                      >
                        Request Feedback
                      </Link>

                      <Link
                        onClick={handleLinkClick}
                        to="/dashboard/ai-insights"
                        className={`flex items-center gap-4 w-full py-2 hover:text-blue-500 ${isActive(
                          "/dashboard/ai-insights"
                        )}`}
                      >
                        AI Insights
                      </Link>
                    </motion.ul>
                  )}
                </AnimatePresence>

                {/* <li
                  className={`flex items-center px-4 py-3 ${isActive(
                    "/dashboard/review-management"
                  )}`}
                >
                  <Link
                    onClick={handleLinkClick}
                    to="/dashboard/review-management"
                    className="flex items-center gap-4 w-full"
                  >
                    <FiMessageSquare className="w-5 h-5" />
                    Review Management
                  </Link>
                </li> */}

                <li
                  className={`flex items-center px-4 py-3 ${isActive(
                    "/dashboard/analytics"
                  )}`}
                >
                  <Link
                    onClick={handleLinkClick}
                    to="/dashboard/analytics"
                    className="flex items-center gap-4 w-full"
                  >
                    <FiBarChart2 className="w-5 h-5" />
                    Analytics
                  </Link>
                </li>
                {companyType === "team" && (
                  <li
                    className={`flex items-center px-4 py-3 ${isActive(
                      "/dashboard/team-performance"
                    )}`}
                  >
                    <Link
                      onClick={handleLinkClick}
                      to="/dashboard/team-performance"
                      className="flex items-center gap-4 w-full"
                    >
                      <FaCircleCheck className="w-5 h-5" />
                      Leaderboard
                    </Link>
                  </li>
                )}
                {!superUser && (
                  <>
                    <li
                      className={`flex items-center px-4 py-3 ${isActive(
                        "/dashboard/coupons"
                      )}`}
                    >
                      <Link
                        onClick={handleLinkClick}
                        to="/dashboard/coupons"
                        className="flex items-center gap-4 w-full"
                      >
                        <FaTicketAlt className="w-5 h-5" />
                        Coupons
                      </Link>
                    </li>
                    <li
                      className={`flex items-center px-4 py-3 ${isActive(
                        "/dashboard/campaigns"
                      )}`}
                    >
                      <Link
                        onClick={handleLinkClick}
                        to="/dashboard/campaigns"
                        className="flex items-center gap-4 w-full"
                      >
                        <FaBullhorn className="w-5 h-5" />
                        Campaigns
                      </Link>
                    </li>
                  </>
                )}

                {/* <li
                  className={`flex items-center px-4 py-3 ${isActive(
                    "/dashboard/request-feedback"
                  )}`}
                >
                  <Link
                    onClick={handleLinkClick}
                    to="/dashboard/request-feedback"
                    className="flex items-center gap-4 w-full"
                  >
                    <PiChatCircleDotsFill className="w-5 h-5" />
                    Request Feedback
                  </Link>
                </li>
                <li
                  className={`flex items-center px-4 py-3 ${isActive(
                    "/dashboard/manage-feedback"
                  )}`}
                >
                  <Link
                    onClick={handleLinkClick}
                    to="/dashboard/manage-feedback"
                    className="flex items-center gap-4 w-full"
                  >
                    <PiChatCircleDotsFill className="w-5 h-5" />
                    Manage Feedback
                  </Link>
                </li> */}
                {/* <li
                  className={`flex items-center px-4 py-3 ${isActive(
                    "/dashboard/ai-chat"
                  )}`}
                >
                  <Link
                    onClick={handleLinkClick}
                    to="/dashboard/ai-chat"
                    className="flex items-center gap-4 w-full"
                  >
                    <MdOutlineAutoAwesome className="w-5 h-5" />
                    AI Chat
                  </Link>
                </li> */}
              </>
            )}
            {superUser && (
              <>
                <li
                  className={`flex items-center px-4 py-3 ${isActive(
                    "/dashboard/companies"
                  )}`}
                >
                  <Link
                    onClick={handleLinkClick}
                    to="/dashboard/companies"
                    className="flex items-center gap-4 w-full"
                  >
                    <MdBusiness className="w-5 h-5" />
                    Companies
                  </Link>
                </li>
                <li
                  className={`flex items-center px-4 py-3 ${isActive(
                    "/dashboard/create-account"
                  )}`}
                >
                  <Link
                    onClick={handleLinkClick}
                    to="/dashboard/create-account"
                    className="flex items-center gap-4 w-full"
                  >
                    <MdBusiness className="w-5 h-5" />
                    New Company
                  </Link>
                </li>
                <li
                  className={`flex items-center px-4 py-3 ${isActive(
                    "/dashboard/branch"
                  )}`}
                >
                  <Link
                    onClick={handleLinkClick}
                    to="/dashboard/branch"
                    className="flex items-center gap-4 w-full"
                  >
                    <MdAddToPhotos className="w-5 h-5" />
                    Locations
                  </Link>
                </li>
              </>
            )}
          </ul>

          <h2 className="text-sm font-semibold text-gray-400 my-2 mx-4">
            Other menu
          </h2>
          <ul>
            <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/dashboard/mobile-preview"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/dashboard/mobile-preview"
                className="flex items-center gap-4 w-full"
              >
                <PiDeviceMobileCameraFill className="w-5 h-5" />
                Mobile Preview
              </Link>
            </li>
            {/* <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/dashboard/survey"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/dashboard/survey"
                className="flex items-center gap-4 w-full"
              >
                <RiSurveyFill className="w-5 h-5" />
                Survey
              </Link>
            </li> */}
            <li className="px-4 py-3 cursor-pointer" onClick={toggleSurvey}>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-4">
                  <RiSurveyFill className="w-5 h-5" />
                  Survey
                </div>
                <FiChevronDown
                  className={`transition-transform ${
                    surveyOpen ? "rotate-180" : ""
                  }`}
                />
              </div>
            </li>
            <AnimatePresence>
              {surveyOpen && (
                <motion.ul
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="pl-8"
                >
                  <li
                    className={`py-2 hover:text-blue-500 ${isActive(
                      "/dashboard/surveydashboard"
                    )}`}
                  >
                    <Link to="/dashboard/surveydashboard">Overview</Link>
                  </li>
                  <li
                    className={`py-2 hover:text-blue-500 ${isActive(
                      "/dashboard/survey"
                    )}`}
                  >
                    <Link to="/dashboard/survey">My Surveys</Link>
                  </li>
                  <li
                    className={`py-2 hover:text-blue-500 ${isActive(
                      "/dashboard/survey-analysis"
                    )}`}
                  >
                    <Link to="/dashboard/survey-analysis">Analysis</Link>
                  </li>
                  <li
                    className={`py-2 hover:text-blue-500 ${isActive(
                      "/dashboard/survey-request"
                    )}`}
                  >
                    <Link to="/dashboard/survey-request">Request Survey</Link>
                  </li>
                  <li
                    className={`py-2 hover:text-blue-500 ${isActive(
                      "/dashboard/survey-ai-insights"
                    )}`}
                  >
                    <Link to="/dashboard/survey-ai-insights">AI Insights</Link>
                  </li>
                </motion.ul>
              )}
            </AnimatePresence>
            {/* <li
                  className={`flex items-center px-4 py-3 ${isActive(
                    "/dashboard/surveyanalytics"
                  )}`}
                >
                  <Link
                    onClick={handleLinkClick}
                    to="/dashboard/surveyanalytics"
                    className="flex items-center gap-4 w-full"
                  >
                    <FiBarChart2 className="w-5 h-5" />
                    Survey Analytics
                  </Link>
                </li> */}
            {/* <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/dashboard/survey-analysis"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/dashboard/survey-analysis"
                className="flex items-center gap-4 w-full"
              >
                <RiSurveyFill className="w-5 h-5" />
                Survey Analysis
              </Link>
            </li> */}
            {/* </li> */}

            <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/dashboard/contacts"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/dashboard/contacts"
                className="flex items-center gap-4 w-full"
              >
                <RiContactsBook2Fill className="w-5 h-5" />
                Contacts
              </Link>
            </li>
            {/* <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/dashboard/survey-responses"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/dashboard/survey-responses"
                className="flex items-center gap-4 w-full"
              >
                <RiSurveyFill className="w-5 h-5" />
                Survey Responses
              </Link>
            </li> */}
            {superUser && (
              <>
                <li
                  className={`flex items-center px-4 py-3 ${isActive(
                    "/dashboard/payment"
                  )}`}
                >
                  <Link
                    onClick={handleLinkClick}
                    to={{
                      pathname: "/dashboard/payment",
                      state: { transactionType: "payment" },
                    }}
                    className="flex items-center gap-4 w-full"
                  >
                    <PiMoneyWavyFill className="w-5 h-5" />
                    Payment
                  </Link>
                </li>
                {/* <li
                  className={`flex items-center px-4 py-3 ${isActive(
                    "/dashboard/subscriptions"
                  )}`}
                >
                  <Link
                    onClick={handleLinkClick}
                    to={{
                      pathname: "/dashboard/subscriptions",
                      state: { transactionType: "subscription" },
                    }}
                    className="flex items-center gap-4 w-full"
                  >
                    <HiViewGridAdd className="w-5 h-5" />
                    Subscriptions
                  </Link>
                </li> */}
              </>
            )}
            {companyType === "team" && (
              <li
                className={`flex items-center px-4 py-3 ${isActive(
                  "/dashboard/companyemployees"
                )}`}
              >
                <Link
                  onClick={handleLinkClick}
                  to="/dashboard/companyemployees"
                  className="flex items-center gap-4 w-full"
                >
                  <PiGitBranchBold className="w-5 h-5" />
                  Employees
                </Link>
              </li>
            )}
          </ul>

          <h2 className="text-sm font-semibold text-gray-400 my-2 mx-4">
            Help & Settings
          </h2>
          <ul>
            <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/dashboard/setting"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/dashboard/setting"
                className="flex items-center gap-4 w-full"
              >
                <FaCog className="w-5 h-5" />
                Settings
              </Link>
            </li>
          </ul>
        </div>
        {/* Theme Toggle */}
        <div className="flex-shrink-0 absolute bottom-0 w-full p-4  border-gray-300 dark:border-gray-700 flex items-center justify-between">
          <span className="text-sm font-medium">Dark mode</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only"
              checked={theme === "dark"}
              onChange={toggleTheme}
            />
            <div className="w-11 h-6 bg-gray-200 flex items-center rounded-full dark:bg-gray-600">
              <div
                className={`dot absolute w-4 h-4 bg-white rounded-full shadow-md transition ${
                  theme === "dark" ? "transform translate-x-6" : "ml-1"
                }`}
              ></div>
            </div>
          </label>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
