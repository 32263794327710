import { useEffect } from "react";
import { FaDownload } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { useTimeRange } from "../../../context/TimeRangeContext";

function Overview({ title = "Overview", showExportButton = true }) {
  const { timeRange, setTimeRange } = useTimeRange();

  useEffect(() => {
    // Retrieve the saved filter from localStorage on mount
    const savedFilter = localStorage.getItem("filter") || "week"; // Default to "week" if not set
    setTimeRange(savedFilter);
  }, [setTimeRange]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setTimeRange(selectedValue);

    // Save the selected filter to localStorage
    localStorage.setItem("filter", selectedValue);

    console.log(`Time Range changed to: ${selectedValue}`);
  };

  return (
    <div className="flex flex-col lg:flex-row gap-4 items-start lg:items-center justify-between py-6">
      {/* Left Section */}
      <div className="text-start">
        <h1 className="text-3xl font-normal text-gray-900">{title}</h1>
        {/* <p className="text-gray-500">
          There is the latest update for this {timeRange.toLowerCase()}. Check now.
        </p> */}
      </div>

      {/* Right Section */}
      <div className="flex items-center gap-4">
        {/* Dropdown for Time Range */}
        <div className="relative inline-block w-32">
          <select
            value={timeRange}
            onChange={handleSelectChange}
            className="appearance-none bg-white border border-gray-300 rounded-lg px-4 py-2 pr-10 text-gray-700 font-medium focus:outline-none focus:ring-2 focus:ring-blue-400 w-full"
          >
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select>
          <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
            <IoIosArrowDown className="text-gray-500 text-sm" />
          </div>
        </div>

        {/* Export Button */}
        {showExportButton && (
          <button
            className="bg-blue-600 text-white px-6 py-2 w-32 rounded-md flex items-center hover:bg-blue-700 transition"
          >
            <FaDownload className="mr-2" /> Export
          </button>
        )}
      </div>
    </div>
  );
}

export default Overview;
