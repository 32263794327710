import { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const incrementTapCount = async (company_username, employee_username) => {
  try {
    const response = await fetch(
      `https://zola.technology/api/company/employees/update/${company_username}/${employee_username}/increment-taps/`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      console.log("Tap count incremented successfully");
    } else {
      console.error("Failed to increment tap count");
    }
  } catch (error) {
    console.error("Error while sending tap data to backend", error);
  }
};

function Emoji() {
  const { company_username, employee_username } = useParams();
  const [placeId, setPlaceId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchPlaceId = async () => {
    try {
      const response = await axios.get(
        `https://zola.technology/api/company/reviews/${company_username}`
      );

      const placeId = response.data;
      setPlaceId(placeId);
      return placeId;
    } catch (error) {
      console.error("Error fetching place ID:", error);
      setPlaceId(null);
      return null;
    }
  };

  const generateGoogleReviewURL = (placeId) => {
    return `https://search.google.com/local/writereview?placeid=${placeId}`;
  };

  const handleEmojiClick = async (emoji) => {
    if (loading) return;
    setLoading(true);

    await incrementTapCount(company_username, employee_username);

    if (emoji === "angry" || emoji === "sad") {
      navigate(
        `/feedback/${encodeURIComponent(company_username)}/${encodeURIComponent(employee_username)}/${emoji}/make-a-zola-review`
      );
    } else if (emoji === "neutral" || emoji === "happy" || emoji === "love") {
      const placeId = await fetchPlaceId();
      if (placeId) {
        window.open(generateGoogleReviewURL(placeId), "_blank");
      } else {
        alert(
          "Failed to fetch place ID. Please check the customer username and try again."
        );
      }
    }

    setLoading(false);
  };

  return (
    <section className="flex flex-col items-center text-center gap-4 justify-center h-[90vh] bg-black">
      <h2 className="text-white text-2xl md:text-4xl lg:text-5xl">How was your experience today?</h2>
      <ul className="list-none flex gap-4">
        <li className="text-white text-5xl" onClick={() => handleEmojiClick("angry")}>&#128545;</li>
        <li className="text-white text-5xl" onClick={() => handleEmojiClick("sad")}>&#128533;</li>
        <li className="text-white text-5xl" onClick={() => handleEmojiClick("neutral")}>&#128528;</li>
        <li className="text-white text-5xl" onClick={() => handleEmojiClick("happy")}>&#128515;</li>
        <li className="text-white text-5xl" onClick={() => handleEmojiClick("love")}>&#128525;</li>
      </ul>
    </section>
  );
}

export default Emoji;
