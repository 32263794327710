import { useState, useEffect } from "react";
import GaugeChart from "react-gauge-chart";
import { useTimeRange } from "../../../context/TimeRangeContext";

const CsatGaugeChart = ({ colSpan }) => {
  const { timeRange } = useTimeRange(); // Get selected time range
  const [csatScore, setCsatScore] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCsatData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Authentication token not found.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://zola.technology/api/company/surveys/get-data/csat/spread-data/?filter=${timeRange}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setCsatScore(data.csat_score);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCsatData();
  }, [timeRange]); // Fetch data when time range changes

  return (
    <div
      className={`bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 w-full relative ${colSpan}`}
    >
      {/* Header */}
      <div className="flex justify-between items-center text-gray-700 dark:text-gray-200 mb-3">
        <h3 className="text-lg font-semibold">Customer Satisfaction (CSAT)</h3>
      </div>

      {loading ? (
        <p className="text-gray-500 dark:text-gray-400 text-center">
          Loading...
        </p>
      ) : error ? (
        <p className="text-red-500 dark:text-red-400 text-center">{error}</p>
      ) : (
        <div className="flex flex-col items-center">
          {/* Gauge Chart */}
          <GaugeChart
            id="csat-gauge"
            nrOfLevels={20} // Smoother color transitions
            colors={["#EA4228", "#F5C623", "#5BE12C"]} // Red, Yellow, Green
            arcWidth={0.3} // Thickness of the gauge
            percent={csatScore / 100} // Normalize CSAT score (0-100) to percentage (0-1)
            textColor="#333"
            animate={false}
            needleColor="#4B5563"
          />

          {/* CSAT Value Display */}
          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              CSAT Score
            </p>
            <p className="text-xl font-bold text-gray-800 dark:text-gray-200">
              {csatScore}%
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CsatGaugeChart;
