import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function MobileSurvey() {
  const [survey, setSurvey] = useState(null);
  const [error, setError] = useState(null);
  const [responses, setResponses] = useState({});
  const { company_username } = useParams();
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState(null); // State to store company data
  const [loading, setLoading] = useState(true);
  const [surveyStarted, setSurveyStarted] = useState(false);
  useEffect(() => {
    if (Object.keys(responses).length > 0) {
      setSurveyStarted(true);
    }
  }, [responses]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await fetch(
          `https://zola.technology/api/company/get/${company_username}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch company data");
        }
        const data = await response.json();
        console.log(data);
        setCompanyData(data);
      } catch (error) {
        setError(error.message);
        console.error("Error fetching company data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, [company_username]);

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        // const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://zola.technology/api/company/${company_username}/surveys/survey/get-active/`,
          {
            headers: {
              // Authorization: `Bearer ${token}`,
            },
          }
        );
        setSurvey(response.data);
      } catch (error) {
        console.error("Error fetching survey:", error);
        setError("Failed to load survey. Please try again.");
      }
    };

    fetchSurvey();
  }, [company_username]);

  const handleResponseChange = (questionId, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: value,
    }));
  };

  const handleCheckboxChange = (questionId, choice) => {
    setResponses((prevResponses) => {
      const currentSelections = prevResponses[questionId] || [];
      const updatedSelections = currentSelections.includes(choice)
        ? currentSelections.filter((item) => item !== choice)
        : [...currentSelections, choice];
      return {
        ...prevResponses,
        [questionId]: updatedSelections,
      };
    });
  };

  const updateSurveyAbandonedCount = async (company_username, survey_id) => {
    if (!company_username || !survey_id) return;

    try {
      await fetch(
        `https://zola.technology/api/company/${company_username}/surveys/survey/update-abandoned-count/?survey_id=${survey_id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
          keepalive: true, // Ensures the request is sent before unload
        }
      );
    } catch (error) {
      console.error("Failed to update abandoned survey count:", error);
    }
  };

  // Function to mark survey as abandoned
  const markSurveyAsAbandoned = (company_username, survey_id) => {
    updateSurveyAbandonedCount(company_username, survey_id);
    localStorage.setItem("survey_abandoned", "true");
  };

  // Function to update last interaction timestamp
  const updateLastInteraction = (survey_id) => {
    if (!survey_id) return;
    localStorage.setItem(
      `survey_last_interaction_${survey_id}`,
      Date.now().toString()
    );
  };

  // Function to check inactivity and mark as abandoned if 12 hours pass
  const checkSurveyInactivity = (company_username, survey_id) => {
    const TWELVE_HOURS = 12 * 60 * 60 * 1000;
    const lastInteraction = parseInt(
      localStorage.getItem(`survey_last_interaction_${survey_id}`),
      10
    );

    if (Date.now() - lastInteraction > TWELVE_HOURS) {
      markSurveyAsAbandoned(company_username, survey_id);
    }
  };

  // Function to start inactivity tracking
  const startSurveyInactivityTracking = (company_username, survey_id) => {
    if (!company_username || !survey_id) return;

    updateLastInteraction(survey_id);

    // Check inactivity every 5 minutes
    const interval = setInterval(
      () => checkSurveyInactivity(company_username, survey_id),
      5 * 60 * 1000
    );

    // Handle page unload
    const handleBeforeUnload = () =>
      markSurveyAsAbandoned(company_username, survey_id);

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("click", () => updateLastInteraction(survey_id));
    window.addEventListener("keydown", () => updateLastInteraction(survey_id));

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("click", () =>
        updateLastInteraction(survey_id)
      );
      window.removeEventListener("keydown", () =>
        updateLastInteraction(survey_id)
      );
      clearInterval(interval);
    };
  };

  const handleSubmit = async () => {
    const formattedAnswers = survey.questions.map((question) => {
      const response = responses[question.id];
      let formattedResponse;
  
      if (question.question_type === "multi_choice") {
        formattedResponse = response || [];
      } else if (question.question_type === "single_choice") {
        formattedResponse = response || "";
      } else if (question.question_type === "numeric_choice") {
        formattedResponse = response || 0;
      } else {
        formattedResponse = response || "";
      }
  
      return {
        question_id: question.id,
        response: formattedResponse,
      };
    });
  
    const payload = {
      survey_id: survey.id,
      answers: formattedAnswers,
    };
  
    try {
      // Submit survey responses
      await axios.post(
        `https://zola.technology/api/company/${company_username}/surveys/survey/get-responses/`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      // Update completed count
      await axios.patch(
        `https://zola.technology/api/company/${company_username}/surveys/survey/update-completed-count/?survey_id=${survey.id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      try {
        // Try generating coupon
        const couponResponse = await axios.post(
          `https://zola.technology/api/company/${company_username}/coupon/codes/generate/?applicable_area=survey`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (couponResponse.data) {
          localStorage.setItem("coupon_data", JSON.stringify(couponResponse.data));
          console.log("Coupon data saved:", couponResponse.data);
          navigate("/redeem-coupon");
        } else {
          // Redirect to feedback page if coupon data is missing
          navigate(`/feedback/${company_username}/${company_username}`);
        }
      } catch (couponError) {
        console.error("Coupon generation error:", couponError);
        // Redirect to feedback page if coupon generation fails
        navigate(`/feedback/${company_username}/${company_username}`);
      }
    } catch (error) {
      console.error("Error submitting survey:", error);
  
      if (error.response?.data?.non_field_errors) {
        toast.error(error.response.data.non_field_errors[0]);
      } else {
        toast.error("Failed to submit the survey. Please try again.");
      }
    }
  };
  
  
  

  if (error)
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-50 dark:bg-gray-900 space-y-6">
        <p className="text-xl font-semibold text-gray-700 dark:text-gray-200">
          {error === "Failed to load survey. Please try again."
            ? "Oops! There are no active surveys available at the moment."
            : error}
        </p>
        <button
          onClick={() => window.history.back()} // Go back to the previous page
          className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition"
        >
          Go Back
        </button>
      </div>
    );

  if (!survey)
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="animate-pulse text-gray-500 dark:text-gray-400">
          Loading survey...
        </div>
      </div>
    );

  return (
    <div className="py-8 bg-white dark:bg-gray-900 min-h-screen font-outfit">
      <ToastContainer />
      {/* Survey Header */}
      <div className="mx-auto flex items-center gap-4 dark:bg-gray-800 justify-center">
        <img
          src={companyData?.profile_image}
          className="w-20 object-contain bg-gray-50 h-20 rounded-full"
          alt=""
        />
        <h1 className="text-xl md:text-4xl font-semibold text-gray-900 dark:text-gray-100">
          {companyData?.preferred_business_name}
        </h1>
      </div>

      {/* Survey Questions */}
      <div className="space-y-10 mt-6 px-4 md:px-10 lg:px-20">
        {survey.questions.map((question, index) => (
          <div key={question.id} className="w-full transition">
            <h2 className="text-lg md:text-2xl font-medium text-gray-900 dark:text-gray-100 text-center">
              {question.question_text}
            </h2>

            <div className="mt-6 space-y-4">
              {/* Paragraph Question */}
              {question.question_type === "paragraph" && (
                <textarea
                  className="w-full py-4 px-4 border-2 border-transparent rounded-lg bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white outline-none transition-all duration-300 focus:border-blue-600 focus:bg-white dark:focus:bg-gray-700 text-lg"
                  rows="5"
                  placeholder="Type your answer..."
                  value={responses[question.id] || ""}
                  onChange={(e) =>
                    handleResponseChange(question.id, e.target.value)
                  }
                />
              )}

              {/* Single Choice Question */}
              {question.question_type === "single_choice" && (
                <div className="flex flex-col gap-4">
                  {Object.entries(question.choices).map(([key, value]) => (
                    <label
                      key={key}
                      className={`flex items-center bg-gray-100 dark:bg-gray-700 rounded-lg p-4 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 transition-all
                        ${
                          responses[question.id] === value
                            ? "ring-2 ring-blue-500 dark:ring-blue-400"
                            : ""
                        }`}
                    >
                      <input
                        type="radio"
                        name={`question-${question.id}`}
                        value={value}
                        checked={responses[question.id] === value}
                        onChange={() =>
                          handleResponseChange(question.id, value)
                        }
                        className="hidden"
                      />
                      <span className="text-lg text-gray-900 dark:text-white">
                        {value}
                      </span>
                    </label>
                  ))}
                </div>
              )}

              {/* Numeric Choice Question */}
              {question.question_type === "numeric_choice" && (
                <div className="flex flex-col items-center gap-5">
                  <div className="flex flex-wrap justify-center gap-4">
                    {Object.entries(question.choices).map(([key, value]) => (
                      <label
                        key={key}
                        className={`w-12 h-12 flex items-center justify-center rounded-full text-white cursor-pointer transition-all
                          ${
                            responses[question.id] === value
                              ? "bg-green-500"
                              : "bg-blue-600 hover:bg-blue-400"
                          }`}
                      >
                        <input
                          type="radio"
                          name={`question-${question.id}`}
                          value={value}
                          checked={responses[question.id] === value}
                          onChange={() =>
                            handleResponseChange(question.id, value)
                          }
                          className="hidden"
                        />
                        <span className="text-lg font-semibold">
                          {responses[question.id] === value ? "✔" : value}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              )}

              {/* Multi Choice Question */}
              {question.question_type === "multi_choice" && (
                <div className="flex flex-col gap-4">
                  {Object.entries(question.choices).map(([key, value]) => (
                    <label
                      key={key}
                      className={`flex items-center justify-between rounded-lg p-4 cursor-pointer transition duration-300
                        ${
                          responses[question.id]?.includes(value)
                            ? "bg-green-600 text-white"
                            : "bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700"
                        }`}
                    >
                      <input
                        type="checkbox"
                        value={value}
                        checked={
                          responses[question.id]?.includes(value) || false
                        }
                        onChange={() =>
                          handleCheckboxChange(question.id, value)
                        }
                        className="hidden"
                      />
                      <span className="text-lg font-medium">{value}</span>
                      <div
                        className={`w-6 h-6 flex items-center justify-center rounded-full border-2 transition-all duration-300
                        ${
                          responses[question.id]?.includes(value)
                            ? "bg-white text-blue-600 border-white"
                            : "border-gray-500 dark:border-gray-400"
                        }`}
                      >
                        {responses[question.id]?.includes(value) && "✔"}
                      </div>
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Submit Button */}
      <div className="mt-12 flex justify-center md:px-10 lg:px-20">
        <button
          onClick={handleSubmit}
          className="px-6 py-4 w-full bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white text-lg font-semibold rounded-lg shadow-lg transform transition-transform"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default MobileSurvey;
