import { useState, useEffect } from "react";
import { useTimeRange } from "../../../context/TimeRangeContext";

const CsatSummaryCard = ({ colSpan }) => {
  const { timeRange } = useTimeRange(); // Get selected time range
  const [csatData, setCsatData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCsatData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Authentication token not found.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://zola.technology/api/company/surveys/get-data/csat/spread-data/?filter=${timeRange}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setCsatData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCsatData();
  }, [timeRange]); // Fetch data when time range changes

  if (loading) return <p className="text-gray-500 text-center">Loading...</p>;
  if (error) return <p className="text-red-500 text-center">{error}</p>;

  const {
    csat_score,
    total_responses,
    satisfied_responses,
    neutral_responses,
    dissatisfied_responses,
  } = csatData || {};

  const satisfiedPercentage = total_responses
    ? ((satisfied_responses / total_responses) * 100).toFixed(2)
    : 0;
  const neutralPercentage = total_responses
    ? ((neutral_responses / total_responses) * 100).toFixed(2)
    : 0;
  const dissatisfiedPercentage = total_responses
    ? ((dissatisfied_responses / total_responses) * 100).toFixed(2)
    : 0;

  return (
    <div
      className={`bg-white dark:bg-gray-900 shadow-md rounded-lg p-4 ${colSpan}`}
    >
      {/* Header */}
      <div className="flex justify-between items-center text-gray-700 dark:text-gray-200 mb-3">
        <h3 className="text-lg font-semibold">CSAT Summary</h3>
        <span className="text-xl cursor-pointer text-gray-500 dark:text-gray-400">
          ⋮
        </span>
      </div>

      {/* Segmented Progress Bar */}
      <div className="flex w-full h-6 rounded-full overflow-hidden border border-gray-300 dark:border-gray-700">
        <div
          className="bg-red-500 text-white text-xs flex items-center justify-center font-medium"
          style={{ width: `${dissatisfiedPercentage}%` }}
        >
          {dissatisfiedPercentage}%
        </div>
        <div
          className="bg-gray-500 text-white text-xs flex items-center justify-center font-medium"
          style={{ width: `${neutralPercentage}%` }}
        >
          {neutralPercentage}%
        </div>
        <div
          className="bg-green-500 text-white text-xs flex items-center justify-center font-medium"
          style={{ width: `${satisfiedPercentage}%` }}
        >
          {satisfiedPercentage}%
        </div>
      </div>

      {/* CSAT Score */}
      <div className="flex justify-between items-center text-gray-700 dark:text-gray-200 mt-4">
        <p className="text-sm">CSAT Score</p>
        <h2 className="text-xl font-bold">{csat_score}%</h2>
      </div>

      {/* CSAT Breakdown */}
      <div className="mt-3 text-gray-700 dark:text-gray-200">
        <div className="flex justify-between py-1 border-b border-gray-300 dark:border-gray-700">
          <p>Total Responses</p>
          <p className="font-bold">{total_responses}</p>
        </div>
        <div className="flex justify-between py-1 border-b border-gray-300 dark:border-gray-700">
          <p>Dissatisfied</p>
          <p className="font-bold text-red-500">
            {dissatisfied_responses}{" "}
            <span className="text-gray-500 dark:text-gray-400">
              ({dissatisfiedPercentage}%)
            </span>
          </p>
        </div>
        <div className="flex justify-between py-1 border-b border-gray-300 dark:border-gray-700">
          <p>Neutral</p>
          <p className="font-bold text-gray-500">
            {neutral_responses}{" "}
            <span className="text-gray-500 dark:text-gray-400">
              ({neutralPercentage}%)
            </span>
          </p>
        </div>
        <div className="flex justify-between py-1 border-b border-gray-300 dark:border-gray-700">
          <p>Satisfied</p>
          <p className="font-bold text-green-500">
            {satisfied_responses}{" "}
            <span className="text-gray-500 dark:text-gray-400">
              ({satisfiedPercentage}%)
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CsatSummaryCard;
