import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend);

const SurveysChart = ({ events }) => {
  const replied = events?.completed?.count || 0;
  const unreplied = events?.abandoned?.count || 0;
  const total = replied + unreplied;

  // Chart Data
  const data = {
    labels: ["Replied", "Unreplied"],
    datasets: [
      {
        data: [replied, unreplied],
        backgroundColor: ["#121826", "#E8AC2C"],
        borderWidth: 0,
      },
    ],
  };

  // Chart Options
  const options = {
    cutout: "70%",
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
      datalabels: {
        color: ["white", "black"],
        font: { weight: "bold", size: 12 },
        formatter: (value) => {
          const percentage = total === 0 ? 0 : ((value / total) * 100).toFixed(1);
          return `${percentage}%`;
        },
        anchor: "center",
        align: "center",
      },
    },
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-5 w-full">
      <div className="flex justify-between items-center mb-3">
        <h3 className="text-lg font-semibold">Surveys</h3>
      </div>

      <div className="flex flex-col items-center gap-4">
        <div className="relative flex justify-center">
          <Doughnut data={data} options={options} plugins={[ChartDataLabels]} />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            <p className="text-lg font-semibold">Surveys</p>
          </div>
        </div>

        <div className="flex gap-4 text-sm">
          <div className="flex flex-col items-center gap-2 mb-3">
            <span className="text-gray-600">Replied</span>
            <span className="text-lg font-semibold text-black flex items-center gap-1">
              <span className="w-3 h-3 bg-black rounded-full"></span>
              {replied}
            </span>
          </div>
          <div className="flex flex-col items-center gap-2">
            <span className="text-gray-600">Unreplied</span>
            <span className="text-lg font-semibold text-black flex items-center gap-1">
              <span className="w-3 h-3 bg-[#E8AC2C] rounded-full"></span>
              {unreplied}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveysChart;
