import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
const EditForm = ({
  selectedItem = {
    id: "",
    name: "",
    username: "",
    reward: "",
    profile_image: "",
  },
  onClose,
  onUpdate,
  showRewardField = false,
}) => {
  const [formValues, setFormValues] = useState({
    name: selectedItem.name,
    username: selectedItem.username,
    reward: selectedItem.reward || "not_rewarded",
    profile_image: selectedItem.profile_image || "",
  });

  const [errors, setErrors] = useState({});
  const [previewImage, setPreviewImage] = useState(selectedItem.profile_image);
  const [imageFile, setImageFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      setFormValues({
        name: selectedItem.name || "",
        username: selectedItem.username || "",
        reward: selectedItem.reward || "not_rewarded",
        profile_image: selectedItem.profile_image || "",
      });
      setPreviewImage(selectedItem.profile_image);
    }
  }, [selectedItem]);

  const handleFormChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedFormats = [
      "image/png",
      "image/jpeg",
      "image/gif",
      "image/svg+xml",
      "image/webp",
    ];

    if (!allowedFormats.includes(file.type)) {
      toast.error(
        "Invalid file format. Allowed formats: PNG, JPEG, GIF, SVG, WEBP"
      );
      setImageFile(null);
      setPreviewImage(null);
      return;
    }

    try {
      const options = {
        maxSizeMB: 0.5, // max file size in MB
        maxWidthOrHeight: 800, // max width or height
        useWebWorker: true,
        fileType: "image/jpeg",
      };

      const compressedFile = await imageCompression(file, options);

      // Generate a unique name using timestamp + random value
      const uniqueName = `compressed_${Date.now()}_${Math.floor(
        Math.random() * 10000
      )}.jpg`;
      const compressedWithNewName = new File([compressedFile], uniqueName, {
        type: compressedFile.type,
      });

      setImageFile(compressedWithNewName);

      // Show preview
      const previewUrl = URL.createObjectURL(compressedWithNewName);
      setPreviewImage(previewUrl);

      toast.success("Image compressed successfully");
    } catch (error) {
      toast.error("Failed to compress image");
      console.error("Compression error:", error);
    }
  };

  const uploadFile = async (file) => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);

    try {
      setIsUploading(true);
      const response = await fetch("https://zola.technology/api/upload-file/", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "File upload failed");
      }

      const result = await response.json();
      toast.success("Image uploaded successfully");
      setIsUploading(false);
      return result.file_url;
    } catch (error) {
      toast.error(error.message || "Error uploading image");
      setIsUploading(false);
      return null;
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.name.trim()) newErrors.name = "Full name is required.";
    if (formValues.username.length < 6)
      newErrors.username = "Username must be at least 6 characters long.";
    return newErrors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    let profileImageUrl = formValues.profile_image;

    if (imageFile) {
      const uploadedImageUrl = await uploadFile(imageFile);
      if (uploadedImageUrl) {
        profileImageUrl = uploadedImageUrl;
      }
    }
    console.log({
      id: selectedItem.id,
      name: formValues.name,
      username: formValues.username,
      reward: formValues.reward,
      profile_image: profileImageUrl,
    });

    try {
      await onUpdate({
        id: selectedItem.id, // Ensure correct employee is updated
        name: formValues.name,
        username: formValues.username,
        reward: formValues.reward,
        profile_image: profileImageUrl, // Use new or existing image
      });

      toast.success("Profile updated successfully!");
      setFormValues({
        name: "",
        username: "",
        reward: "not_rewarded",
        profile_image: "",
      });
      setImageFile(null);
      setPreviewImage(null);
      onClose();
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile");
      setImageFile(null);
      setPreviewImage(null);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity"
      onClick={onClose}
    >
      <div
        className="bg-white dark:bg-gray-800 rounded-xl w-[90%] max-w-sm space-y-4 transition-colors duration-300"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <h2 className="text-lg rounded-t-xl p-4 font-semibold text-white bg-[#0C4EF8] dark:bg-blue-600">
          Edit Employee
        </h2>

        {/* Profile Image */}
        <div className="flex justify-center mt-4">
          {previewImage ? (
            <img
              src={previewImage}
              alt="Profile"
              className="w-24 h-24 rounded-full object-cover border border-gray-300"
            />
          ) : (
            <div className="w-24 h-24 flex items-center justify-center rounded-full bg-blue-600 text-white text-2xl font-bold">
              {(formValues.name || "")
                .split(" ")
                .slice(0, 2)
                .map((word) => word?.[0]?.toUpperCase() || "")
                .join("")}
            </div>
          )}
        </div>

        {/* Image Upload */}
        <div className="flex justify-center">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="text-sm text-gray-500 dark:text-gray-300"
          />
        </div>

        <form onSubmit={handleFormSubmit}>
          {/* Full Name */}
          <div className="mb-4 px-6">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-[#282A3E] dark:text-gray-300"
            >
              Full Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleFormChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-black dark:text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
              required
            />
          </div>

          {/* Username */}
          {/* <div className="mb-4 px-6">
            <label htmlFor="username" className="block text-sm font-medium text-[#282A3E] dark:text-gray-300">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formValues.username}
              onChange={handleFormChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-black dark:text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 cursor-not-allowed"
              readOnly
            />
          </div> */}

          {/* Submit Button */}
          <div className="flex p-6">
            <button
              type="submit"
              className="px-4 py-2 w-full bg-[#0C4EF8] dark:bg-blue-500 text-white font-bold rounded-lg"
            >
              {isUploading ? "Uploading..." : "Update"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditForm;
