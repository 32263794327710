import { useEffect } from "react";

function ExternalRedirectTracker({ onReturn }) {
  useEffect(() => {
    const timerKey = "zola_redirect_timer_start";
    const couponKey = "zola_coupon_granted";

    const checkOnReturn = () => {
      const granted = localStorage.getItem(couponKey);
      const start = localStorage.getItem(timerKey);
      const elapsed = start ? (Date.now() - parseInt(start, 10)) / 1000 : 0;

      if (granted === "true" && elapsed > 8) {
        if (typeof onReturn === "function") {
          onReturn();
        }
      }
    };

    // Check on mount (when user returns)
    checkOnReturn();

    // Optionally: you can also listen for focus event
    window.addEventListener("focus", checkOnReturn);

    return () => {
      window.removeEventListener("focus", checkOnReturn);
    };
  }, [onReturn]);

  return null;
}

export default ExternalRedirectTracker;
