import CustomersHome from './components/CustomersHome'
import OurCustomers from './components/OurCustomers'

function Customers() {
  return (
    <div>
      <CustomersHome />
      <OurCustomers />
    </div>
  )
}

export default Customers