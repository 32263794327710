import { lazy } from "react";

// const Dashboard = lazy(() => import("./HQDashboard/pages/Dashboard"));
// const BranchDashboard = lazy(() => import("./HQDashboard/pages/BranchDashboard"));
// const Messages = lazy(() => import("./HQDashboard/pages/Messages"));
// const HQAnalytics = lazy(() => import("./HQDashboard/pages/Analytics"));
// const Profile = lazy(() => import("./HQDashboard/pages/Profile"));
// const Settings = lazy(() => import("./HQDashboard/pages/Settings"));
// const Branches = lazy(() => import("./HQDashboard/pages/Branches"));

import Dashboard from "./HQDashboard/pages/Dashboard";
import BranchDashboard from "./HQDashboard/pages/BranchDashboard";
import Messages from "./HQDashboard/pages/Messages";
import HQAnalytics from "./HQDashboard/pages/Analytics";
import Profile from "./HQDashboard/pages/Profile";
import Settings from "./HQDashboard/pages/Settings";
import Branches from "./HQDashboard/pages/Branches";

// const Dashboard = lazy(() => import ("./HQDashboard/pages/Dashboard"));
// const BranchDashboard = lazy(() => import("./HQDashboard/pages/BranchDashboard"));
// const Messages = lazy(() => import("./HQDashboard/pages/Messages"));
// const HQAnalytics = lazy(() => import("./HQDashboard/pages/Analytics"));
// const Profile = lazy(() => import("./HQDashboard/pages/Profile"));
// const Settings = lazy(() => import("./HQDashboard/pages/Settings"));
// const Branches = lazy(() => import("./HQDashboard/pages/Branches"));
// import Survey from "./HQDashboard/pages/Survey";

export const HQDashboardRoutes = [
  { path: "", element: <Dashboard /> }, // index
  { path: "analytics", element: <HQAnalytics /> },
  { path: "profile", element: <Profile /> },
  { path: "settings", element: <Settings /> },
  { path: "branches", element: <Branches /> },
  { path: "messages", element: <Messages /> },
  // { path: "survey", element: <Survey /> }, // optional
  { path: "branchdashboard/:branchId", element: <BranchDashboard /> },
];
