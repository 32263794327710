import { useEffect, useState } from "react";
import {
  FaThumbsUp,
  FaThumbsDown,
  FaStar,
} from "react-icons/fa";
import { useTimeRange } from "../../../context/TimeRangeContext";

const FeedbackAndReview = ({ branchId, data }) => {
  const [feedbackData, setFeedbackData] = useState(null);
  const [googleData, setGoogleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { timeRange } = useTimeRange();

  useEffect(() => {
    const fetchFeedbackData = async () => {
      try {
        setError(null);

        const response = await fetch(
          `https://zola.technology/api/hq-company/dashboard/branches/branch/data/${branchId}/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch feedback data");
        }

        const data = await response.json();
        setFeedbackData(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchFeedbackData();
  }, [branchId, timeRange]);

  if (error) return <p className="text-red-500">{error}</p>;

  console.log(feedbackData, "this is data");

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8 bg-gray-50 mt-7">
      {/* Feedback Section */}
      <div className="bg-white rounded-xl p-6 shadow">
        <div className="w-full">
          {/* Filter Selection */}
          {/* <div className="flex gap-3 mb-4">
            {['day', 'week', 'month', 'year'].map((option) => (
              <button
                key={option}
                className={`px-4 py-2 rounded-md ${filter === option ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setFilter(option)}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
          </div> */}

          {/* Header Section */}
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center gap-3">
              <div className="bg-green-100 p-3 rounded-lg">
                <FaThumbsUp className="text-green-500" />
              </div>
              <div>
                <h2 className="text-md font-medium text-gray-500">
                  Zola Feedback
                </h2>
                <h1 className="text-4xl font-bold">
                  {feedbackData?.total_feedback}
                </h1>
                <p
                  className={`text-sm mt-1 ${
                    feedbackData?.change?.type === "Increase"
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {feedbackData?.change?.type === "Increase" ? "↑" : "↓"}{" "}
                  {feedbackData?.change?.percentage_change || 0}%{" "}
                  <span className="text-gray-400">Since last {timeRange}</span>
                </p>
              </div>
            </div>
          </div>

          {/* Feedback Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Average Rating */}
            <div className="bg-[#f9fafb] rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center gap-2">
                  <FaStar className="text-yellow-500 text-xl" />
                  <h4 className="text-md font-medium">Average Rating</h4>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <h1 className="text-4xl font-bold">
                  {feedbackData?.average_rating?.toFixed(1)}
                </h1>
                <div className="flex justify-center space-x-1">
                  {[...Array(5)].map((_, index) => (
                    <FaStar
                      key={index}
                      size={24}
                      className={
                        index < Math.floor(feedbackData?.average_rating)
                          ? "text-[#F3BB21]"
                          : "text-[#EAEAEC]"
                      }
                    />
                  ))}
                </div>
              </div>
            </div>

            {!feedbackData ? (
              <p>No data</p>
            ) : (
              ["positive", "neutral", "negative"].map((type, index) => {
                const icons = {
                  positive: <FaThumbsUp className="text-green-500 text-xl" />,
                  neutral: <FaStar className="text-yellow-500 text-xl" />,
                  negative: <FaThumbsDown className="text-red-500 text-xl" />,
                };

                const colors = {
                  positive: "bg-green-500",
                  neutral: "bg-yellow-500",
                  negative: "bg-red-500",
                };

                const feedback = feedbackData[type] || {}; // Default to an empty object if feedbackData[type] is null or undefined.

                return (
                  <div key={index} className="bg-[#f9fafb] rounded-lg p-6">
                    <div className="flex justify-between items-center mb-4">
                      <div className="flex items-center gap-2">
                        {icons[type]}
                        <h4 className="text-md font-medium">
                          {type.charAt(0).toUpperCase() + type.slice(1)} Reviews
                        </h4>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <h1 className="text-4xl font-bold">
                        {feedback.count || 0}
                      </h1>
                      <span
                        className={`px-2 py-1 rounded-md text-xs ${colors[type]} text-white`}
                      >
                        {feedback.change?.total_change || 0}
                      </span>
                    </div>
                    <div className="flex w-full gap-2 items-center mt-3">
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div
                          className={`${colors[type]} h-2 rounded-full`}
                          style={{ width: `${feedback.percentage || 0}%` }}
                        ></div>
                      </div>
                      <p className="text-xs text-gray-500">
                        {feedback.percentage || 0}
                      </p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      {/* Reviews Section */}
      <div className="bg-white rounded-xl p-6 shadow-md">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-2">
            <div className="bg-yellow-100 p-2 rounded-lg">
              <FaStar className="text-yellow-500" />
            </div>
            <div>
              <h2 className="text-md font-medium text-gray-500">
                Google Reviews
              </h2>
              <h1 className="text-4xl font-bold">{data?.reviews || 0}</h1>
              <p className="text-gray-400 text-sm">All-time reviews</p>
            </div>
          </div>
        </div>

        {/* Average Rating Section */}
        <div className="bg-[#f9fafb] rounded-lg p-6">
          <h4 className="text-md font-medium mb-3">Average Rating</h4>
          <div className="flex items-center gap-4">
            <h1 className="text-5xl font-bold">{data?.rating || 0}</h1>
            <div className="flex ml-3">
              {[...Array(5)].map((_, index) => (
                <FaStar
                  key={index}
                  className={
                    index < Math.floor(data?.rating || 0)
                      ? "text-yellow-500 text-xl"
                      : "text-gray-300 text-xl"
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackAndReview;
