import { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditContactModal = ({ isOpen, onClose, contact, onSave }) => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    internalNote: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (contact) {
      setFormData({
        name: contact.name,
        phoneNumber: contact.phone_number,
        email: contact.email,
        internalNote: contact.internalNote || "",
      });
    }
  }, [contact]);

  if (!isOpen) return null;

  const handleSave = async () => {
    // if (!formData.name || !formData.phoneNumber || !formData.email) {
    //   toast.error("All fields are required!");
    //   return;
    // }

    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Authentication token not found.");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `https://zola.technology/api/company/customers/contact/${contact.id}/update/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: formData.name,
            phone_number: formData.phoneNumber,
            email: formData.email,
            internal_note: formData.internalNote,
          }),
        }
      );

      if (response.ok) {
        toast.success("Contact updated successfully!");
        onSave(); // Trigger refresh in ContactsPage
        onClose(); // Close modal after successful submission
      } else {
        const data = await response.json();

        // Check if the error message contains the unique constraint violation
        if (
          data?.detail?.includes(
            "Unexpected error: duplicate key value violates unique constraint"
          )
        ) {
          // Extract the part after "(company_id, email)=" and format it
          const match = data.detail.match(
            /email\)=\(\d+, (.+?)\) already exists\./
          );
          if (match && match[1]) {
            toast.error(`${match[1]} already exists.`);
          } else {
            toast.error("Phone number can not be blank.");
          }
        } else {
          toast.error(
            data?.detail ||
              data?.phone_number?.[0] ||
              "Failed to update contact."
          );
        }
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
          <button
            className="absolute top-3 right-3 text-gray-600"
            onClick={onClose}
          >
            <MdClose size={20} />
          </button>
          <h2 className="text-purple-600 text-xl font-semibold text-center">
            Edit Contact
          </h2>

          <div className="mt-6">
            <label className="text-sm font-medium">Name</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              className="w-full border-b-2 border-purple-600 outline-none p-1"
            />
          </div>

          <div className="mt-4">
            <label className="text-sm font-medium text-gray-500">
              Phone Number
            </label>
            <input
              type="text"
              value={formData.phoneNumber}
              onChange={(e) =>
                setFormData({ ...formData, phoneNumber: e.target.value })
              }
              className="w-full border-b border-gray-300 outline-none p-1"
            />
          </div>

          <div className="mt-4">
            <label className="text-sm font-medium text-gray-500">Email</label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              className="w-full border-b border-gray-300 outline-none p-1"
            />
          </div>

          <button
            className="w-full bg-blue-500 text-white py-2 rounded-lg mt-6 text-lg font-semibold"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditContactModal;
