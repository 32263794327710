import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CampaignTable from "./CampaignDetailsTable";
import CampaignModal from "./CampaignMessage";
import EmailCard from "./Emailcards";
import CampaignStats from "./CampaignStats";

const CampaignDashboard = () => {
  const { id } = useParams();
  const [campaignData, setCampaignData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://zola.technology/api/company/campaigns/${id}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setCampaignData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div>
      {/* Header Section */}
      <div className="flex justify-between items-center mb-4">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">
            {campaignData?.name}
          </h2>
          <p className="text-gray-500 text-sm">
            {new Date(campaignData?.scheduled_at)
              .toLocaleString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
                timeZone: "UTC",
              })
              .replace(",", " -")}
          </p>
        </div>
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm hover:bg-blue-700"
          onClick={() => setIsOpen(true)}
        >
          View {campaignData?.message_type === "sms" ? "SMS" : "Email"}
        </button>
      </div>

      {/* Tabs Section */}
      <div className="flex mt-4 bg-white w-60 rounded-md overflow-hidden">
        <button
          className={`px-4 py-2 text-sm ${
            activeTab === "overview"
              ? "text-gray-900 bg-gray-200"
              : "text-gray-600"
          }`}
          onClick={() => setActiveTab("overview")}
        >
          Overview
        </button>
        <button
          className={`px-4 py-2 text-sm ${
            activeTab === "activity"
              ? "text-gray-900 bg-gray-200"
              : "text-gray-600"
          }`}
          onClick={() => setActiveTab("activity")}
        >
          Activity
        </button>
        <button className="px-4 py-2 text-sm text-gray-600">Links</button>
      </div>

      {/* Conditional Rendering Based on Active Tab */}
      {activeTab === "overview" && (
        <>
          <EmailCard
            data={campaignData}
            onViewContacts={() => setActiveTab("activity")}
          />
          {/* <OpenedCard campaignData={campaignData}/>
           */}

           <div className="mt-4">
           <CampaignStats
                   statsData={{
                     delivered: campaignData?.total_delivered,
                     opened: campaignData?.total_bounced,
                     clicked: campaignData?.total_opened,
                     bounced: campaignData?.total_clicked,
                   }}
                 />
           </div>
          {/* Statistics Section */}
          {/* <div className="grid grid-cols-4 gap-6 mt-6">
            {[
              {
                title: "Delivered",
                value: campaignData?.total_delivered,
                change: "0%",
                trend: "no-change",
              },
              {
                title: "Opened",
                value: campaignData?.total_bounced,
                change: "0%",
                trend: "no-change",
              },
              {
                title: "Clicked",
                value: campaignData?.total_opened,
                change: "0%",
                trend: "no-change",
              },
              {
                title: "Bounced",
                value: campaignData?.total_clicked,
                change: "0%",
                trend: "no-change",
              },
            ].map((stat, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-900 font-semibold">{stat.title}</p>
                <p className="text-3xl font-bold mt-2">{stat.value}</p>
                <p
                  className={`text-sm flex items-center mt-1 ${
                    stat.trend === "up"
                      ? "text-green-600"
                      : stat.trend === "down"
                      ? "text-red-600"
                      : "text-gray-500"
                  }`}
                >
                  {stat.trend === "up" ? "⬆" : stat.trend === "down" ? "⬇" : ""}{" "}
                  {stat.change}
                  {stat.trend !== "no-change" && " vs last month"}
                </p>
              </div>
            ))}
          </div> */}
        </>
      )}

      {activeTab === "activity" && (
        <CampaignTable contacts={campaignData?.contacts} />
      )}

      {/* email/message component pop up */}
      {isOpen && (
        <CampaignModal
          campaignData={campaignData}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default CampaignDashboard;
