import { createContext, useState, useEffect, useContext } from "react";
import { useAuth } from "./AuthContext"; // Import the AuthContext

export const CouponContext = createContext();

export const useCoupons = () => {
  return useContext(CouponContext);
};

export const CouponProvider = ({ children }) => {
  const { isAuthenticated } = useAuth(); // Get auth status
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCoupons = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem("token");

    if (!token) {
      setError("Authentication token is missing.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch("https://zola.technology/api/company/coupons/list/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setCoupons(data);
      } else {
        setError(data.message || "Failed to fetch coupons.");
      }
    } catch (error) {
      setError("Network error: Unable to connect to the server.");
      console.error("Error fetching coupons:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchCoupons();
    }
  }, [isAuthenticated]); // Fetch coupons when authentication status changes

  return (
    <CouponContext.Provider value={{ coupons, loading, error, fetchCoupons }}>
      {children}
    </CouponContext.Provider>
  );
};
