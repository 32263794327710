import { useEffect, useState } from "react";
import axios from "axios";
import { FaStar, FaPhoneAlt, FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { ToastContainer } from "react-toastify";

function FeedbacksAll() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [showModal, setShowModal] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);

  useEffect(() => {
    fetchFeedbacks(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  const fetchFeedbacks = async (page, pageSize) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `https://zola.technology/api/company/feedback/list/`,
        {
          params: { page, page_size: pageSize },
          headers: {
            "accept": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );
      console.log("API Response:", response.data);

      setFeedbacks(response.data.data || []);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error("Error fetching feedbacks:", error);
    }
  };

  // Function to open modal with feedback details
  const openModal = (feedback) => {
    setSelectedFeedback(feedback);
    setShowModal(true);
  };

  // Function to close modal
  const closeModal = () => {
    setSelectedFeedback(null);
    setShowModal(false);
  };

  return (
    <div className="my-6">
      <ToastContainer />
      <h2 className="text-2xl mb-4">Feedback</h2>

      {/* Feedback Cards */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {feedbacks.length > 0 ? (
          feedbacks.map((feedback, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow hover:scale-105 transition cursor-pointer"
              onClick={() => openModal(feedback)}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="font-medium text-lg text-gray-900 dark:text-gray-100">
                    {feedback.author_name || "Anonymous"}
                  </h3>
                  <span className="text-gray-600 dark:text-gray-400 text-sm">
                    {new Date(feedback.created_at).toLocaleDateString()}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-gray-700 dark:text-gray-300 font-medium">
                    {feedback.rating}
                  </span>
                  <FaStar className="text-yellow-500" />
                </div>
              </div>
              <p className="my-2 text-gray-600 dark:text-gray-400 font-normal text-md">
                {feedback.text_info || "No additional feedback provided."}
              </p>
              <div className="mt-3 flex justify-between items-center">
                <span
                  className={`px-3 py-1 rounded-md ${
                    feedback.resolved
                      ? "bg-green-100 text-green-700"
                      : "bg-red-100 text-red-700"
                  }`}
                >
                  {feedback.resolved ? "Resolved" : "Pending"}
                </span>
                <button className="text-blue-600 hover:underline">
                  View Details
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-8">
            <p className="text-gray-500 dark:text-gray-400">No feedback available.</p>
          </div>
        )}
      </div>

      {/* Pagination */}
      <div className="mt-6 flex justify-between items-center">
        <button
          className={`px-4 py-2 rounded-md transition ${
            currentPage === 1
              ? "bg-gray-200 text-gray-500 cursor-not-allowed"
              : "bg-gray-300 hover:bg-gray-400"
          }`}
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <span className="text-gray-700 dark:text-gray-300 font-medium">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className={`px-4 py-2 rounded-md transition ${
            currentPage === totalPages
              ? "bg-gray-200 text-gray-500 cursor-not-allowed"
              : "bg-gray-300 hover:bg-gray-400"
          }`}
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div>

      {/* Modal for Feedback Details */}
      {showModal && selectedFeedback && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-2xl p-6 shadow-2xl relative">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                Feedback Details
              </h2>
              <button
                aria-label="Close Modal"
                onClick={closeModal}
                className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 transition duration-200"
              >
                <MdClose size={24} />
              </button>
            </div>

            <div className="space-y-4">
              <p className="text-gray-700 dark:text-gray-300 text-sm">
                {selectedFeedback.text_info || "No additional feedback provided."}
              </p>

              <div className="border border-gray-300 dark:border-gray-600 p-4 rounded-lg shadow-sm">
                <h3 className="text-md font-semibold text-gray-500 dark:text-gray-400">
                  Employee
                </h3>
                <p className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                  {selectedFeedback?.employee?.name || "N/A"}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {selectedFeedback?.employee?.company?.company_name || "No Company"}
                </p>
                {selectedFeedback?.phone_number && (
                  <p className="text-gray-500 dark:text-gray-400 flex items-center gap-2">
                    <FaPhoneAlt className="text-gray-400 dark:text-gray-500" />
                    {selectedFeedback.phone_number}
                  </p>
                )}
              </div>

              <div className="flex items-center space-x-2">
                <span className="text-gray-700 dark:text-gray-300 font-medium">
                  {selectedFeedback.rating}
                </span>
                <FaStar className="text-yellow-500" />
              </div>

              <div className="flex items-center gap-3 bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                {selectedFeedback?.resolved ? (
                  <FaCheckCircle className="text-green-500 text-lg" />
                ) : (
                  <FaTimesCircle className="text-red-500 text-lg" />
                )}
                <p className="text-gray-700 dark:text-gray-300">
                  <span className="font-semibold">Resolved:</span>{" "}
                  {selectedFeedback?.resolved ? "Yes" : "No"}
                </p>
              </div>
            </div>

            <div className="mt-4 text-right">
              <button
                onClick={closeModal}
                className="bg-gray-500 text-white font-medium px-4 py-2 rounded-lg hover:bg-gray-600 transition-all duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FeedbacksAll;
