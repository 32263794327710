import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  IoIosArrowBack,
} from "react-icons/io";
import { FaExclamationTriangle } from "react-icons/fa";
import { MdVerifiedUser } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";

function SurveyDetail() {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [showPopUp, setShowPopUp] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editedQuestionText, setEditedQuestionText] = useState("");

  const pieColors = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#d88484",
    "#ff7300",
    "#36A2EB",
    "#FF6384",
    "#A569BD",
  ];

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/surveys/survey/${surveyId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSurvey(response.data);
        setIsActive(response.data.active); // Set initial activation status
      } catch (error) {
        console.error("Error fetching survey:", error);
        toast.error("Failed to fetch survey details.");
      }
    };

    fetchSurvey();
  }, [surveyId, token]);

  const updateSurveyStatus = async (active) => {
    const endpoint = active
      ? `https://zola.technology/api/company/surveys/survey/activate/?survey_id=${surveyId}`
      : `https://zola.technology/api/company/surveys/survey/deactivate/?survey_id=${surveyId}`;

    try {
      await axios.patch(
        endpoint,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsActive(active);
      setShowPopUp(false);
      toast.success(
        `Survey successfully ${active ? "activated" : "deactivated"}.`
      );
    } catch (error) {
      console.error("Error updating survey status:", error);
      toast.error("Failed to update survey status.");
    }
  };

  const handleUpdateQuestion = async (questionId) => {
    try {
      await axios.patch(
        `https://zola.technology/api/company/surveys/survey/question/${questionId}/update/`,
        { question_text: editedQuestionText },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update survey data in state after editing
      setSurvey((prevSurvey) => ({
        ...prevSurvey,
        questions: prevSurvey.questions.map((q) =>
          q.id === questionId ? { ...q, question_text: editedQuestionText } : q
        ),
      }));

      setEditingQuestionId(null);
      toast.success("Question updated successfully!");
    } catch (error) {
      console.error("Error updating question:", error);
      toast.error("Failed to update question.");
    }
  };

  if (!survey) return null;

  return (
    <div className="p-6 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex items-center justify-between">
        <button
          onClick={() => navigate(-1)}
          className="mb-4 flex items-center text-blue-600 dark:text-blue-400 hover:underline focus:outline-none"
        >
          <IoIosArrowBack className="mr-1" /> Back
        </button>

        {isActive ? (
          <button
            onClick={() => setShowPopUp(true)}
            className="mb-4 flex items-center text-red-600 dark:text-red-400 px-2 rounded-lg hover:text-white dark:hover:text-gray-900 hover:bg-red-400 dark:hover:bg-red-500"
          >
            Deactivate Survey
          </button>
        ) : (
          <button
            onClick={() => setShowPopUp(true)}
            className="mb-4 flex items-center text-green-600 dark:text-green-400 px-2 rounded-lg hover:text-white dark:hover:text-gray-900 hover:bg-green-400 dark:hover:bg-green-500"
          >
            Activate Survey
          </button>
        )}
      </div>

      {/* Survey Title and Info */}
      <h2 className="text-3xl font-semibold text-gray-800 dark:text-white mb-2">
        {survey.title}
      </h2>
      <p className="text-gray-500 dark:text-gray-400 mb-6">
        Created at: {new Date(survey.created_at).toLocaleString()}
      </p>

      {/* Questions List */}
      <div className="space-y-6">
        {survey.questions.map((question, index) => {
          const analyticsData = survey.analytics.find(
            (item) => item.id === question.id
          );
          const choiceCounts = analyticsData?.analytics.choice_counts || {};
          return (
            <div
              key={question.id}
              className="p-4 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-md"
            >
              {/* Question Title */}
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                  Question {index + 1}: {question.question_text}
                </h3>

                <div className="flex items-center space-x-3">
                  {/* Badge */}
                  <span className="px-2 py-1 rounded-lg text-sm font-semibold text-white bg-blue-600 dark:bg-blue-500 shadow-md">
                    {question.question_type.replace("_", " ").toUpperCase()}
                  </span>

                  {/* Edit Button */}
                  <button
                    className="px-4 py-1 text-sm font-medium text-blue-600 dark:text-blue-400 border border-blue-600 dark:border-blue-400 rounded-lg transition-all duration-300 hover:bg-blue-600 dark:hover:bg-blue-500 hover:text-white dark:hover:text-gray-900 focus:ring-2 focus:ring-blue-300"
                    onClick={() => {
                      setEditingQuestionId(question.id);
                      setEditedQuestionText(question.question_text);
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>

              {/* Question Type Indicator and Choices */}
              <div className="mt-2">
                {editingQuestionId === question.id ? (
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                    value={editedQuestionText}
                    onChange={(e) => setEditedQuestionText(e.target.value)}
                  />
                ) : (
                  <p className="text-gray-600 dark:text-gray-300">
                    {question.question_text}
                  </p>
                )}

                {/* Save & Cancel Buttons */}
                {editingQuestionId === question.id && (
                  <div className="flex space-x-4 mt-2">
                    <button
                      className="px-4 py-2 bg-green-500 dark:bg-green-600 text-white rounded-lg hover:bg-green-600 dark:hover:bg-green-700 transition"
                      onClick={() => handleUpdateQuestion(question.id)}
                    >
                      Save
                    </button>
                    <button
                      className="px-4 py-2 bg-gray-400 dark:bg-gray-500 text-white rounded-lg hover:bg-gray-500 dark:hover:bg-gray-600 transition"
                      onClick={() => setEditingQuestionId(null)}
                    >
                      Cancel
                    </button>
                  </div>
                )}

                {/* 🟢 Bar Chart for Numeric Choice */}
                {question.question_type === "numeric_choice" && (
                  <div className="bg-white dark:bg-gray-800 mt-10 w-full md:w-1/2 p-4 rounded-lg shadow">
                    <ResponsiveContainer width="100%" height={250}>
                      <BarChart
                        data={Object.entries(choiceCounts).map(
                          ([key, value]) => ({
                            choice: key,
                            count: value,
                          })
                        )}
                      >
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <YAxis />
                        <XAxis dataKey="choice" />
                        <Tooltip />
                        <Bar dataKey="count" fill="#2196F3" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                )}

                {/* 🟠 Pie Chart for Multi or Single Choice */}
                {(question.question_type === "multi_choice" ||
                  question.question_type === "single_choice") && (
                  <div className="bg-white dark:bg-gray-800 w-full md:w-1/2 p-4 rounded-lg shadow">
                    <ResponsiveContainer width="100%" height={250}>
                      <PieChart>
                        <Pie
                          data={Object.entries(choiceCounts).map(
                            ([key, value]) => ({
                              name: key,
                              value,
                            })
                          )}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {Object.entries(choiceCounts).map(([key], index) => (
                            <Cell
                              key={key}
                              fill={pieColors[index % pieColors.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          align="right"
                          verticalAlign="middle"
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {showPopUp && (
        <div className="fixed inset-0 bg-black bg-opacity-30 dark:bg-opacity-50 flex items-center justify-center z-50">
          <div
            className="bg-white dark:bg-gray-800 rounded-xl p-6 w-[90%] max-w-sm text-center space-y-4"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            {isActive ? (
              <>
                <FaExclamationTriangle className="text-[#EE6F7C] dark:text-[#FF878D] text-5xl mx-auto" />
                <h2 className="text-2xl text-[#454861] dark:text-white font-semibold">
                  Deactivate Survey?
                </h2>
                <p className="text-gray-600 dark:text-gray-400">
                  This action will deactivate the selected survey.
                </p>
              </>
            ) : (
              <>
                <MdVerifiedUser className="text-[#6fee84] dark:text-[#86FFA4] text-5xl mx-auto" />
                <h2 className="text-2xl text-[#454861] dark:text-white font-semibold">
                  Activate Survey?
                </h2>
                <p className="text-gray-600 dark:text-gray-400">
                  This action will activate the selected survey and deactivate
                  any active survey.
                </p>
              </>
            )}
            <div className="flex justify-center space-x-4 mt-4">
              <button
                onClick={() => updateSurveyStatus(!isActive)}
                className="bg-[#0C4EF8] dark:bg-blue-500 text-[#FEFEFF] font-semibold px-8 py-1 rounded-[4px] hover:bg-blue-700 dark:hover:bg-blue-600 transition"
              >
                Yes
              </button>
              <button
                className="border border-[#F7BCC2] dark:border-red-500 text-[#E6283B] dark:text-red-400 font-semibold px-8 py-1 rounded-[4px] hover:bg-red-600 dark:hover:bg-red-500 hover:text-white transition"
                onClick={() => setShowPopUp(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SurveyDetail;
