import { Outlet } from 'react-router-dom';
import Dashboard from './New-Dashboard/Dashboard'
import DashboardProviders from './DashboardProviders';

const DashboardLayout = () => {
  return (
    <DashboardProviders>
      <Dashboard>
        <Outlet />
      </Dashboard>
    </DashboardProviders>
  );
};

export default DashboardLayout;
