import { lazy, Suspense, useEffect, useState, useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import PublicLayout from "./PublicLayout";
import DashboardLayout from "./DashboardLayout";
import HQDashboardLayout from "./HQDashboardLayout";

// const PublicLayout = lazy(() => import("./PublicLayout"));
// const DashboardLayout = lazy(() => import("./DashboardLayout"));
// const HQDashboardLayout = lazy(() => import("./HQDashboardLayout"));

import { AuthContext } from "./context/AuthContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ScrollToTop from "./ScrollToTop";

import { PublicRoutes } from "./PublicRoutes";
import { DashboardRoutes } from "./DashboardRoutes";
import { HQDashboardRoutes } from "./HQDashboardRoutes";

function App() {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !currentUser?.token) {
      // Only set if currentUser is not already set
      setCurrentUser({ token }); // Replace this with actual user data
    }
    setIsCheckingAuth(false); // Adding a delay for the loader
  }, [currentUser, setCurrentUser]); // Include currentUser to check if it's already set

  if (isCheckingAuth) {
    return;
  }

  return (
    <div className="App">
      <ScrollToTop />
      {/* <Suspense fallback={<></>}> */}
        <Routes>
          <Route
            path="/"
            element={
              currentUser ? <Navigate to="/dashboard" /> : <PublicLayout />
            }
          >
            {PublicRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Route>

          <Route
            path="/dashboard/*"
            element={currentUser ? <DashboardLayout /> : <Navigate to="/" />}
          >
            {DashboardRoutes.map(({ path, element, children }, index) =>
              children ? (
                <Route key={index} path={path} element={element}>
                  {children.map(
                    (
                      { path: childPath, element: childElement },
                      childIndex
                    ) => (
                      <Route
                        key={childIndex}
                        path={childPath}
                        element={childElement}
                      />
                    )
                  )}
                </Route>
              ) : (
                <Route key={index} path={path} element={element} />
              )
            )}
          </Route>

          {/* Route for the HQ Dashboard as per now */}
          <Route
            path="/hqdashboard/*"
            element={
              currentUser ? <HQDashboardLayout /> : <Navigate to="/login" />
            }
          >
            {HQDashboardRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Route>

          {/* Catch-all route to redirect unknown paths to home */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      {/* </Suspense> */}
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
    </div>
  );
}

export default App;
