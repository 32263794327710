import { useState } from "react";
import { toast } from "react-toastify";

export default function UpdateCampaignForm({
  campaign,
  onClose,
  refetchCampaigns,
}) {
  const [formData, setFormData] = useState({
    name: campaign?.name || "",
    message_type: "email",
    campaign_type: "feedback",
    subject: campaign?.subject || "",
    message: campaign?.message || "",
    scheduled_at: campaign?.scheduled_at || new Date().toISOString(),
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://zola.technology/api/company/campaigns/${campaign.id}/update/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update campaign");
      }
      toast.success("Campaign updated successfully");
      refetchCampaigns(); // Refresh the campaign list
      onClose();
    } catch (error) {
      toast.error("Error updating campaign");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white dark:bg-gray-900 p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-lg font-semibold mb-4">Update Campaign</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full border px-3 py-2 rounded-md"
              required
            />
          </div>
          {/* <div className="mb-4">
            <label className="block text-sm font-medium">Subject</label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="w-full border px-3 py-2 rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium">Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full border px-3 py-2 rounded-md"
              required
            ></textarea>
          </div> */}

          <div className="mb-4">
            <label className="block text-sm font-medium">Scheduled At</label>
            <p className="text-gray-500 text-sm mb-1">
              Current: {new Date(formData.scheduled_at).toLocaleString()}
            </p>
            <input
              type="datetime-local"
              name="scheduled_at"
              value={new Date(formData.scheduled_at).toISOString().slice(0, 16)}
              onChange={handleChange}
              className="w-full border px-3 py-2 rounded-md"
            />
          </div>
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 rounded-md"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
