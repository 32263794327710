import { FaExclamationTriangle } from "react-icons/fa";

export default function DisconnectionModal({
  disconnectAccount,
  onClose,
  onConfirm,
}) {
  const handleDisconnect = () => {
    console.log(`Disconnecting ${disconnectAccount.name}`);
    // Add API call logic here to handle disconnection
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-96 shadow-lg">
        <h2 className="text-xl font-semibold text-center text-red-600">
          Disconnect Account
        </h2>
        <FaExclamationTriangle className="text-red-500 dark:text-red-400 text-5xl mx-auto" />

        <p className="mt-4 text-gray-600 text-center">
          Are you sure you want to disconnect{" "}
          <strong>{disconnectAccount.name}</strong>? 
        </p>
        <div className="flex justify-between mt-6">
          <button
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={onConfirm}
          >
            Disconnect
          </button>
        </div>
      </div>
    </div>
  );
}
