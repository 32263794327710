
const Profile = () => {
  return (
    <div className="ml-4 lg:ml-[30px]">
      <h1>Profile</h1>
      <p>Manage your profile settings and personal information here.</p>
    </div>
  );
};

export default Profile;
