import { useState, useRef } from "react";
import { FiSearch } from "react-icons/fi";
import { IoCloseCircleOutline, IoFilterOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { BiImport } from "react-icons/bi";
import Papa from "papaparse"; // ✅ Import PapaParse
import Filters from "./Filters"; // ✅ Import Filters component
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const SearchActions = ({
  setShowAddContact,
  setSearchQuery,
  setAppliedFilters,
  appliedFilters,
  onContactAdded,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false); // ✅ Track upload progress
  const fileInputRef = useRef(null);

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    setSearchQuery(value.toLowerCase());
  };

  // Handle filter application (add to existing filters instead of replacing)
  const handleApplyFilters = (newFilters) => {
    setAppliedFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    setShowFilters(false);
  };

  // Remove a single filter
  const removeFilter = (filterKey) => {
    setAppliedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[filterKey];
      return updatedFilters;
    });
  };

  // Handle file selection and parsing
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      Papa.parse(file, {
        complete: async (result) => {
          for (const row of result.data) {
            const nameField = row.Name || row["Full Name"];
            const phoneField = row.Phone || row["Phone Number"];
            const emailField = row.Email || row["Email Address"];
  
            if (nameField) {
              const requestBody = { name: nameField.trim() };
  
              if (phoneField) {
                requestBody.phone_number = phoneField.trim();
              }
  
              if (emailField) {
                requestBody.email = emailField.trim();
              }
  
              await uploadData(requestBody);
            }
          }
          setLoading(false); // ✅ Reset loading after processing
          event.target.value = ""; // ✅ Reset file input after processing
        },
        error: () => {
          setLoading(false); // ✅ Ensure loading resets on error
          event.target.value = ""; // ✅ Reset file input after processing
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  };
  

  // Upload data one row at a time
  const uploadData = async (contact) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        "https://zola.technology/api/company/customers/contact/create/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(contact),
        }
      );

      const data = await response.json(); // Ensure JSON parsing

      if (response.ok) {
        console.log(`✅ Uploaded: ${contact.name}`);
        onContactAdded();
      } else {
        // Extract error message safely
        const errorMessage =
          data.phone_number?.[0] ||
          data.error ||
          data.detail ||
          "An unknown error occurred";

          toast.error(`Error: ${contact.name} - ${errorMessage}`);

      }
    } catch (error) {
      console.error("Error uploading:", error);
      toast.error("An error occurred while uploading. Please try again.");
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-[2fr_auto_auto] items-center gap-4 w-full mt-6 relative">
      {/* <ToastContainer /> */}

      {/* Search Input */}
      <div className="flex items-center bg-gray-100 px-4 py-2 rounded-full w-full">
        <FiSearch className="text-gray-500" />
        <input
          type="text"
          placeholder="Search for contacts"
          className="ml-2 bg-transparent outline-none w-full text-gray-500"
          value={searchText}
          onChange={handleSearchChange}
        />
      </div>

      {/* Filters Button */}
      <button
        className="flex items-center bg-gray-100 px-4 py-2 rounded-full text-gray-700 space-x-2 relative"
        onClick={() => setShowFilters(!showFilters)}
      >
        <IoFilterOutline className="text-gray-600 text-lg" />
        <span>Filters</span>
        <span className={`bg-gray-300 text-xs px-2 py-1 rounded-full`}>
          {Object.values(appliedFilters).some(Boolean) ? "Active" : "None"}
        </span>
      </button>

      {/* Display Selected Filters */}
      {Object.keys(appliedFilters).length > 0 && (
        <div className="flex flex-wrap space-x-2 mt-2">
          {Object.entries(appliedFilters)
            .filter(([key, value]) => value)
            .map(([key]) => (
              <div
                key={key}
                className="bg-blue-100 text-blue-600 px-2 py-1 text-xs rounded-full flex items-center"
              >
                <span>
                  {key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase())}
                </span>
                <IoCloseCircleOutline
                  className="ml-2 cursor-pointer text-blue-600"
                  onClick={() => removeFilter(key)}
                />
              </div>
            ))}
        </div>
      )}

      {/* Action Buttons */}
      <div className="flex items-center space-x-3">
        {/* Add Contact Button */}
        <button
          className="bg-black text-white p-3 rounded-full flex justify-center items-center"
          onClick={() => setShowAddContact(true)}
        >
          <AiOutlinePlus className="text-lg" />
        </button>

        {/* Import CSV Button */}
        <button
          className="flex items-center border px-4 py-2 rounded-full justify-center"
          onClick={() => fileInputRef.current.click()}
          disabled={loading}
        >
          <BiImport
            className={`text-green-600 text-lg ${loading ? "opacity-50" : ""}`}
          />
          <span className="ml-2">
            {loading ? "Uploading..." : "Upload CSV"}
          </span>
        </button>
        <input
          type="file"
          accept=".csv"
          ref={fileInputRef}
          onChange={handleFileUpload}
          className="hidden"
        />
      </div>

      {/* Filters Component */}
      {showFilters && (
        <div className="absolute top-14 right-0 z-10">
          <Filters
            isOpen={showFilters}
            onClose={() => setShowFilters(false)}
            applyFilters={handleApplyFilters}
          />
        </div>
      )}
    </div>
  );
};

export default SearchActions;
