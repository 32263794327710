import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { FaArrowUp } from "react-icons/fa";
import { useTimeRange } from "../../../context/TimeRangeContext";

const IncentiveSection = () => {
  const [totalRewards, setTotalRewards] = useState(0);
  const [rewardCurrency, setRewardCurrency] = useState("");
  const [averageRating, setAverageRating] = useState(0);
  const [averageRewardScore, setAverageRewardScore] = useState(0);
  const { timeRange } = useTimeRange();

  const fetchRewards = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/performance/rewards/?filter=${timeRange}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch rewards");
      }
      const data = await response.json();
      console.log(data);
      const total = data.data.reduce(
        (sum, item) => sum + (item.reward_total || 0),
        0
      );
      setTotalRewards(total);
      setRewardCurrency(data.data[0]?.reward_currency || "");

      // Calculate the average rating
      const totalRating = data.data.reduce(
        (sum, item) => sum + (item.average_rating || 0),
        0
      );
      const avgRating = totalRating;
      setAverageRating(avgRating);

      // Calculate the average reward score
      const totalRewardScore = data.data.reduce(
        (sum, item) => sum + (item.reward_score || 0),
        0
      );
      const avgRewardScore = totalRewardScore / data.data.length;
      setAverageRewardScore(avgRewardScore);
    } catch (error) {
      console.error("Error fetching rewards:", error);
    }
  };

  useEffect(() => {
    fetchRewards();
  }, [timeRange]);

  console.log(totalRewards);
  console.log(averageRewardScore);
  console.log(averageRating, "tjis is vgrating");

  const incentivesData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "Incentives",
        data: [50, 70, 80, 90, 100, 120, 150],
        backgroundColor: [
          "#e4e8f3",
          "#e4e8f3",
          "#e4e8f3",
          "#e4e8f3",
          "#e4e8f3",
          "#e4e8f3",
          "#007bff",
        ],
        borderRadius: 10,
        barThickness: 16,
      },
    ],
  };

  const incentivesOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        display: false,
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        display: false,
      },
    },
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow-md rounded-xl p-6 flex flex-col justify-between">
      <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">
        Total Incentive
      </h4>
      <div className="flex justify-between items-center">
        {/* Left Side Content */}
        <div className="flex flex-col gap-2">
          <h2 className="text-4xl font-bold flex items-center gap-1 text-gray-900 dark:text-gray-100">
            {new Intl.NumberFormat().format(totalRewards)}{" "}
            <span className="text-green-500 dark:text-green-400 text-sm font-medium">
              {rewardCurrency}
            </span>
          </h2>

          <p className="text-base font-light flex items-center gap-1 text-gray-900 dark:text-gray-200">
            Total rewards this {timeRange}
          </p>
        </div>

        {/* Right Side Chart with Arrow */}
        <div className="relative flex items-center h-20 w-32">
          {/* Chart */}
          <Bar data={incentivesData} options={incentivesOptions} />

          {/* Up Arrow */}
          <div className="absolute top-0 right-0 bg-green-100 dark:bg-green-900 p-1 rounded-full flex items-center justify-center">
            <FaArrowUp className="text-green-500 dark:text-green-400 text-sm" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncentiveSection;
