import { useEffect, useState } from "react";
import {
  FaStar,
} from "react-icons/fa";
import { PiThumbsUpFill } from "react-icons/pi";
import { PiThumbsDownFill } from "react-icons/pi";
import Overview from "./Overview";
import { useTimeRange } from "../../context/TimeRangeContext";
import { ToastContainer, toast } from "react-toastify";
import Subscription from "./Subscription";
import { useOutletContext } from "react-router-dom";
import FeedbacksAll from "./FeedbacksAll";
import FeedbackIssues from "./FeedbackIssues";
import { TbMoodNeutralFilled } from "react-icons/tb";
function Feedback() {
  const [activeTab, setActiveTab] = useState("responded");
  const { timeRange } = useTimeRange();

  const [filteredFeedback1, setFilteredFeedback1] = useState([]);
  const [categorizedFeedback, setCategorizedFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [nonRespondedTotal, setNonRespondedTotal] = useState(0); // Total non-responded feedback
  const [respondedTotal, setRespondedTotal] = useState(0); // Total responded feedback

  const [ratingData, setRatingData] = useState({
    rating_of_1_count: 0,
    rating_of_2_count: 0,
    rating_of_3_count: 0,
    rating_of_4_count: 0,
    rating_of_5_count: 0,
  });
  const [resolvedFeedbackData, setResolvedFeedbackData] = useState([]);
  const [nonResolvedFeedback, setNonResolvedFeedback] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const { isExpired, selectedFeedbackTab } = useOutletContext();
  // const [filter, setFilter] = useState("month");
  const handleFilterChange = (event) => {
    setFilter(event.target.value); // Update state with the selected value
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchData = async (url, setData) => {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setData(data);
      } catch (err) {
        setError(err.message);
      }
    };

    // Fetch totals for non-responded feedback

    fetchData(
      `https://zola.technology/api/company/feedback/issues/resolved/list/?filter=${timeRange}&page=1&page_size=1`,
      (data) => setRespondedTotal(data.total)
    );

    fetchData(
      `https://zola.technology/api/company/feedback/issues/non-resolved/list/?filter=${timeRange}&page=1&page_size=1`,
      (data) => setNonRespondedTotal(data.total)
    );

    // Fetch data for the active tab
    const url =
      activeTab === "responded"
        ? `https://zola.technology/api/company/feedback/issues/resolved/list/?page=${pagination.page}&page_size=${pagination.pageSize}&filter=${timeRange}`
        : `https://zola.technology/api/company/feedback/issues/non-resolved/list/?page=${pagination.page}&page_size=${pagination.pageSize}&filter=${timeRange}`;

    Promise.all([
      fetchData(
        `https://zola.technology/api/company/feedback/get-filtered-feedback/?filter=${timeRange}`,
        setFilteredFeedback1
      ),
      fetchData(
        `https://zola.technology/api/company/feedback/get/filtered-categorized/?filter=${timeRange}`,
        setCategorizedFeedback
      ),
      fetchData(
        url,
        activeTab === "responded"
          ? setResolvedFeedbackData
          : setNonResolvedFeedback
      ),
    ]).finally(() => setLoading(false));
  }, [timeRange, pagination, activeTab]);

  // Calculate grand totals
  const totalFeedback = categorizedFeedback.total_feedback;

  const posPercent = categorizedFeedback.positive?.percentage;
  const negPercent = categorizedFeedback.negative?.percentage;
  const neutPercent = categorizedFeedback.neutral?.percentage;

  const renderStars = (count) => {
    return Array.from({ length: count }, (_, index) => (
      <FaStar key={index} className="text-yellow-500" />
    ));
  };

  // Safely filter resolved and unresolved feedback
  const resolvedFeedback =
    filteredFeedback1[0]?.feedback?.filter(
      (feedback) => feedback.resolved === true
    ) || [];
  const unresolvedFeedback =
    filteredFeedback1[0]?.feedback?.filter(
      (feedback) => feedback.resolved === false
    ) || [];

  // Display based on active tab
  const displayedFeedback =
    activeTab === "responded" ? resolvedFeedbackData : nonResolvedFeedback;

  // Open Modal with selected feedback details
  const openModal = async (feedback) => {
    setSelectedFeedback(feedback);

    const employeeId = feedback?.employee?.id || null;

    if (employeeId) {
      try {
        // Get token from localStorage
        const token = localStorage.getItem("token");

        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/ratings/categorized-totals/${employeeId}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Add token to the Authorization header
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch rating data");
        }

        const ratingData = await response.json();

        // Update the rating section in the modal
        setRatingData(ratingData);
      } catch (error) {
        console.error("Error fetching rating data:", error);
      }
    } else {
      // Provide fallback rating data
      setRatingData({
        rating_of_1_count: 0,
        rating_of_2_count: 0,
        rating_of_3_count: 0,
        rating_of_4_count: 0,
        rating_of_5_count: 0,
      });
    }

    setShowModal(true);
  };

  // Close Modal
  const closeModal = () => {
    setSelectedFeedback(null);
    setShowModal(false);
  };

  // Function to get initials from a full name
  const getInitials = (name) => {
    // Check if the name is a valid string
    if (typeof name !== "string" || !name) return "";

    const nameParts = name.trim().split(" ");

    // If there's only one word, return the first letter of that word
    if (nameParts.length === 1) {
      return nameParts[0][0].toUpperCase();
    }

    // If there are multiple words, take the first letter of the first two words
    const initials = nameParts[0][0] + nameParts[1][0];

    return initials.toUpperCase();
  };

  console.log(resolvedFeedbackData?.data ?? [], "selected feed");

  const maxCount = Math.max(
    ratingData.rating_of_5_count,
    ratingData.rating_of_4_count,
    ratingData.rating_of_3_count,
    ratingData.rating_of_2_count,
    ratingData.rating_of_1_count
  );

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleResolveFeedback = async (feedbackId) => {
    if (!feedbackId) return;

    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `https://zola.technology/api/company/feedback/update-feedback-to-resolved/?feedback_id=${feedbackId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            resolved: true, // Send resolved status
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to resolve feedback.");
      }

      toast.success("Feedback marked as resolved!");

      // Update the UI: Remove from non-resolved and add to resolved list
      setNonResolvedFeedback((prev) =>
        prev.data.filter((feedback) => feedback.id !== feedbackId)
      );

      setResolvedFeedbackData((prev) => ({
        ...prev,
        data: [...prev.data, { ...selectedFeedback, resolved: true }],
      }));

      setShowModal(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="md:p-2 min-h-screen">
      <ToastContainer />
      {isExpired && <Subscription />}
      <Overview title="Feedback" showExportButton={false} />

      <div className="bg-white dark:bg-gray-800 rounded-lg h-auto p-4 shadow-md">
        <div className="w-full grid grid-cols-12 px-6 pt-2">
          {/* Total Feedback */}
          <div className="col-span-12 lg:col-span-3 text-[28px] font-semibold text-gray-900 dark:text-gray-100">
            <p className="text-gray-700 dark:text-gray-400 text-[16px] font-medium">
              Total Feedback
            </p>
            <p className="text-gray-900 dark:text-gray-100 text-[36px] font-semibold">
              {totalFeedback}
            </p>
          </div>

          {/* Feedback Sentiments */}
          <div className="col-span-12 lg:col-span-9 flex flex-col gap-4">
            <div className="flex flex-col md:flex-row justify-between mb-4 gap-4">
              {/* Positive Feedback */}
              <div className="flex flex-col gap-2 items-start w-full md:w-2/3">
                <div className="flex items-center gap-2">
                  <PiThumbsUpFill className="text-green-500 dark:text-green-300 w-[28px] h-[25px]" />
                  <p className="text-gray-600 dark:text-gray-400 text-[14px] font-medium">
                    Positive
                    <p className="text-[20px] font-bold text-gray-900 dark:text-gray-100">
                      {posPercent}%
                    </p>
                  </p>
                </div>
                <div className="w-11/12 bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                  <div
                    className="bg-green-500 dark:bg-green-400 h-2 rounded-full"
                    style={{ width: `${posPercent}%` }}
                  ></div>
                </div>
              </div>

              {/* Negative Feedback */}
              <div className="flex flex-col gap-2 items-start w-full md:w-2/3">
                <div className="flex items-center gap-2">
                  <PiThumbsDownFill className="text-red-500 dark:text-red-300 w-[28px] h-[25px]" />
                  <p className="text-gray-600 dark:text-gray-400 text-[14px] font-medium">
                    Negative
                    <p className="text-[20px] font-bold text-gray-900 dark:text-gray-100">
                      {negPercent}%
                    </p>
                  </p>
                </div>
                <div className="w-11/12 bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                  <div
                    className="bg-red-500 dark:bg-red-400 h-2 rounded-full"
                    style={{ width: `${negPercent}%` }}
                  ></div>
                </div>
              </div>

              {/* Neutral Feedback */}
              <div className="flex flex-col gap-2 items-start w-full md:w-2/3">
                <div className="flex items-center gap-2">
                <TbMoodNeutralFilled  className="text-yellow-500 dark:text-yellow-400 w-[32px] h-[32px]" />
                  <p className="text-gray-600 dark:text-gray-400 text-[14px] font-medium">
                    Neutral
                    <p className="text-[20px] font-bold text-gray-900 dark:text-gray-100">
                      {neutPercent}%
                    </p>
                  </p>
                </div>
                <div className="w-11/12 bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                  <div
                    className="bg-yellow-500 dark:bg-yellow-400 h-2 rounded-full"
                    style={{ width: `${neutPercent}%` }}
                  ></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* Render either FeedbacksAll or FeedbackIssues based on the selected tab */}
      <div className="">
        {selectedFeedbackTab === "issues" ? <FeedbackIssues /> : <FeedbacksAll />}
      </div>
    </div>
  );
}

export default Feedback;
