import { FaRegCreditCard, FaEdit, FaTrash } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";

function PaymentCard({ card, onAddCardClick }) {
  return (
    <div>
      <h2 className="text-xl font-medium text-gray-800 mb-4">Payment method</h2>

      <div className="flex items-center gap-2 mb-3">
        <FaRegCreditCard className="text-blue-600" size={18} />
        <h3 className="text-sm font-semibold text-gray-700">Added Card</h3>
      </div>

      <div className="p-4 border rounded-lg mb-4">
        <div className="flex items-start justify-between mb-4">
          <div className="flex flex-col gap-3">
            <p className="text-xl font-semibold tracking-wider">
              •••• •••• •••• {card.last4}
            </p>
            <p className="text-sm text-gray-500 mb-2">
              {card.exp_month}/{card.exp_year} | {card.brand}
            </p>
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button aria-label="Edit Card" className="px-4 py-2 bg-blue-600 text-white rounded-md flex items-center gap-1 hover:bg-blue-700 transition">
              <FaEdit /> Edit
            </button>
            <button aria-label="Delete Card" className="px-4 py-2 bg-gray-200 text-gray-600 rounded-md flex items-center gap-1 hover:bg-gray-300 transition">
              <FaTrash /> Delete
            </button>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg"
            alt="Card Brand"
            className="w-8"
          />
          <p>{card.brand}</p>
        </div>
      </div>

      <button
        aria-label="Add Card"
        onClick={onAddCardClick}
        className="mt-4 px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg flex items-center justify-center gap-2 hover:bg-blue-700"
      >
        <MdAddCircle size={20} /> Add Card
      </button>
    </div>
  );
}

export default PaymentCard;
