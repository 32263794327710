import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  AlertCircle,
  ChevronLeft,
  ChevronRight,
  Percent,
  CalendarClock,
  Clock,
  Users,
  Activity,
  CheckCircle,
  BadgePercent,
  XCircle,
} from "lucide-react";
import { motion } from "framer-motion";
import { Tooltip } from "react-tooltip";

const CouponDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState(null);
  const [couponCodes, setCouponCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const username = userData?.username || "default-username"; // Fallback if username is missing
  const [selectedCode, setSelectedCode] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [validating, setValidating] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const handleValidateClick = (code) => {
    setSelectedCode(code);
    setShowModal(true);
  };

  useEffect(() => {
    const fetchCoupon = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Unauthorized: No token found");

        const response = await fetch(
          `https://zola.technology/api/company/coupons/id/${id}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch coupon data");

        const data = await response.json();
        setCoupon(data);

        // Fetch coupon codes if type is unique
        if (data.usage_type === "unique") {
          fetchCouponCodes(1); // Initial fetch for page 1
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCoupon();
  }, [id]);

  const fetchCouponCodes = async (pageNum) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Unauthorized: No token found");

      const response = await fetch(
        `https://zola.technology/api/company/coupons/${id}/coupon-codes/list/?page=${pageNum}&page_size=6`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch coupon codes");

      const data = await response.json();
      setCouponCodes(data.data);
      setPage(data.current_page);
      setTotalPages(data.total_pages);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      fetchCouponCodes(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      fetchCouponCodes(page - 1);
    }
  };

  if (loading) {
    return <div className="text-center p-10 text-gray-600">Loading...</div>;
  }

  const confirmValidation = async () => {
    if (!selectedCode || !coupon || !username) return;

    setValidating(true);
    setValidationMessage("");

    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Unauthorized: No token found");

      const response = await fetch(
        // http://zola.technology/api/company/coupons/username/coupon-code/jjjj/validate/
        // http://zola.technology/api/company/i/coupons/coupon-code/i/validate/
        `https://zola.technology/api/company/${username}/coupons/coupon-code/${selectedCode}/validate/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ coupon_id: coupon.id }),
        }
      );

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || "Validation failed");

      setValidationMessage("Coupon code validated successfully!");
    } catch (error) {
      setValidationMessage(error.message);
    } finally {
      setValidating(false);
      setTimeout(() => setShowModal(false), 2000);
    }
  };

  if (error) {
    return (
      <div className="p-10 max-w-4xl mx-auto text-center space-y-6">
        <AlertCircle className="w-12 h-12 mx-auto text-red-500" />
        <h2 className="text-3xl font-bold text-gray-800 dark:text-white">
          Error
        </h2>
        <p className="text-lg text-gray-600 dark:text-gray-300">{error}</p>
        <button
          onClick={() => navigate(-1)}
          className="inline-flex items-center px-5 py-2.5 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Go Back
        </button>
      </div>
    );
  }

  return (
    <div className="grid gap-4">
      <div className="flex justify-start">
        <button
          onClick={() => navigate(-1)}
          className="inline-flex items-center px-5 py-2.5 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Coupons
        </button>
      </div>

      {/* Combined Grid for Coupon Details & Code Stats */}
      <div className="bg-white dark:bg-gray-900 p-8 rounded-2xl shadow-xl space-y-6">
        <h2 className="text-3xl font-bold text-gray-800 dark:text-white">
          {coupon.name}
        </h2>

        <div
          className={`grid gap-6 ${
            coupon.usage_type === "unique" && couponCodes.length > 0
              ? "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
              : "grid-cols-1 sm:grid-cols-2"
          }`}
        >
          {/* Discount */}
          <motion.div
            className="p-5 bg-gray-50 dark:bg-gray-800 rounded-xl shadow-sm border dark:border-gray-700"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
          >
            <div className="flex items-center justify-between mb-1">
              <p
                className="text-sm text-gray-500 dark:text-gray-400"
                data-tooltip-id="discountTip"
              >
                Discount
              </p>
              <Percent className="w-5 h-5 text-indigo-500" />
            </div>
            <p className="text-2xl font-semibold text-gray-800 dark:text-white">
              {coupon.discount_type === "percentage"
                ? `${coupon.discount_value}%`
                : `$${coupon.discount_value}`}
            </p>
            <Tooltip
              id="discountTip"
              content="Amount or percentage off the original price"
            />
          </motion.div>

          {/* Valid From */}
          <motion.div
            className="p-5 bg-gray-50 dark:bg-gray-800 rounded-xl shadow-sm border dark:border-gray-700"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.45 }}
          >
            <div className="flex items-center justify-between mb-1">
              <p
                className="text-sm text-gray-500 dark:text-gray-400"
                data-tooltip-id="validFromTip"
              >
                Valid From
              </p>
              <Clock className="w-5 h-5 text-blue-500" />
            </div>
            <p className="text-lg font-medium text-gray-800 dark:text-white">
              {new Date(coupon.valid_from).toLocaleDateString()}
            </p>
            <Tooltip
              id="validFromTip"
              content="Start date of coupon validity"
            />
          </motion.div>

          {/* Valid Until */}
          <motion.div
            className="p-5 bg-gray-50 dark:bg-gray-800 rounded-xl shadow-sm border dark:border-gray-700"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex items-center justify-between mb-1">
              <p
                className="text-sm text-gray-500 dark:text-gray-400"
                data-tooltip-id="validUntilTip"
              >
                Valid Until
              </p>
              <CalendarClock className="w-5 h-5 text-orange-500" />
            </div>
            <p className="text-lg font-medium text-gray-800 dark:text-white">
              {new Date(coupon.valid_until).toLocaleDateString()}
            </p>
            <Tooltip id="validUntilTip" content="End date of coupon validity" />
          </motion.div>

          {/* Usage Limit */}
          <motion.div
            className="p-5 bg-gray-50 dark:bg-gray-800 rounded-xl shadow-sm border dark:border-gray-700"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.55 }}
          >
            <div className="flex items-center justify-between mb-1">
              <p
                className="text-sm text-gray-500 dark:text-gray-400"
                data-tooltip-id="usageLimitTip"
              >
                Usage Limit
              </p>
              <Users className="w-5 h-5 text-purple-500" />
            </div>
            <p className="text-lg font-medium text-gray-800 dark:text-white">
              {coupon.usage_type === "general" ? coupon.usage_limit : "—"}
            </p>
            <Tooltip
              id="usageLimitTip"
              content="How many times the coupon can be used"
            />
          </motion.div>

          {/* Code Stats */}
          {coupon.usage_type === "unique" && couponCodes.length > 0 && (
            <>
              {/* Active Codes */}
              <motion.div
                className="p-5 bg-green-50 dark:bg-gray-800 border-l-4 border-green-600 rounded-xl shadow-sm"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                <div className="flex items-center justify-between mb-1">
                  <p
                    className="text-sm text-gray-600 dark:text-gray-400"
                    data-tooltip-id="activeCodesTip"
                  >
                    Active Codes
                  </p>
                  <CheckCircle className="w-5 h-5 text-green-600" />
                </div>
                <p className="text-3xl font-bold text-green-600 dark:text-green-400">
                  {couponCodes.filter((code) => !code.is_used).length}
                </p>
                <Tooltip
                  id="activeCodesTip"
                  content="Codes that are available for use"
                />
              </motion.div>

              {/* Used Codes */}
              <motion.div
                className="p-5 bg-red-50 dark:bg-gray-800 border-l-4 border-red-600 rounded-xl shadow-sm"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.65 }}
              >
                <div className="flex items-center justify-between mb-1">
                  <p
                    className="text-sm text-gray-600 dark:text-gray-400"
                    data-tooltip-id="usedCodesTip"
                  >
                    Used Codes
                  </p>
                  <Activity className="w-5 h-5 text-red-600" />
                </div>
                <p className="text-3xl font-bold text-red-600 dark:text-red-400">
                  {couponCodes.filter((code) => code.is_used).length}
                </p>
                {/* Progress Bar */}
                <div className="mt-2 w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-green-500 transition-all"
                    style={{
                      width: `${
                        (couponCodes.filter((code) => code.is_used).length /
                          couponCodes.length) *
                        100
                      }%`,
                    }}
                  />
                </div>
                <Tooltip
                  id="usedCodesTip"
                  content="Codes that have already been used"
                />
              </motion.div>
            </>
          )}
        </div>
      </div>

      {/* Coupon Codes */}
      <div className="bg-white dark:bg-gray-900 p-8 rounded-2xl shadow-xl">
        <h3 className="text-2xl font-bold text-gray-800 dark:text-white mb-6">
          Coupon Codes
        </h3>

        {coupon.usage_type === "general" ? (
          <div className="relative p-6 rounded-2xl bg-gradient-to-br from-gray-50 to-white dark:from-gray-800 dark:to-gray-900 border border-gray-200 dark:border-gray-700 shadow-md text-center">
            <div className="flex justify-center items-center mb-4">
              <BadgePercent className="w-8 h-8 text-indigo-600 dark:text-indigo-400" />
            </div>
            <p className="text-sm uppercase tracking-wide text-gray-500 dark:text-gray-400 mb-1">
              Coupon Code
            </p>
            <p className="text-2xl font-extrabold text-gray-900 dark:text-white tracking-widest">
              {coupon.code}
            </p>
          </div>
        ) : couponCodes.length > 0 ? (
          <>
<div className="overflow-x-auto">
  <table className="min-w-full bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-800 rounded-xl shadow-sm">
    <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800 z-10">
      <tr>
        <th className="px-6 py-4 text-left text-xs font-semibold uppercase text-gray-600 dark:text-gray-300 tracking-wide">
          #
        </th>
        <th className="px-6 py-4 text-left text-xs font-semibold uppercase text-gray-600 dark:text-gray-300 tracking-wide">
          Code
        </th>
        <th className="px-6 py-4 text-left text-xs font-semibold uppercase text-gray-600 dark:text-gray-300 tracking-wide">
          Status
        </th>
        {/* <th className="px-6 py-4 text-left text-xs font-semibold uppercase text-gray-600 dark:text-gray-300 tracking-wide">
          Copy
        </th> */}
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-100 dark:divide-gray-800">
      {couponCodes.map((code, index) => (
        <tr
          key={code.id}
          className="hover:bg-gray-50 dark:hover:bg-gray-800 transition"
        >
          <td className="px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
            {index + 1 + (page - 1) * 6}
          </td>
          <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
            {code.unique_code}
          </td>
          <td className="px-6 py-4">
            <div className="inline-flex items-center gap-2 text-sm font-medium">
              {code.is_used ? (
                <>
                  <XCircle className="w-4 h-4 text-red-500" />
                  <span className="text-red-600 dark:text-red-400">Used</span>
                </>
              ) : (
                <>
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span className="text-green-600 dark:text-green-400">Active</span>
                </>
              )}
            </div>
          </td>
          {/* <td className="px-6 py-4">
            <button
              onClick={() => handleCopy(code.unique_code)}
              className="flex items-center gap-1 text-sm text-indigo-600 hover:text-indigo-500 transition"
            >
              <Clipboard className="w-4 h-4" />
              {copiedCode === code.unique_code ? "Copied" : "Copy"}
            </button>
          </td> */}
        </tr>
      ))}
    </tbody>
  </table>
</div>


            {/* Pagination Controls */}
            <div className="flex justify-between items-center mt-4">
              <button
                disabled={page === 1}
                onClick={handlePrevPage}
                className="px-4 py-2 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-white rounded-md disabled:opacity-50"
              >
                <ChevronLeft className="w-4 h-4" />
              </button>
              <span className="text-gray-800 dark:text-white">
                Page {page} of {totalPages}
              </span>
              <button
                disabled={page === totalPages}
                onClick={handleNextPage}
                className="px-4 py-2 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-white rounded-md disabled:opacity-50"
              >
                <ChevronRight className="w-4 h-4" />
              </button>
            </div>
          </>
        ) : (
          <div className="text-center py-10 text-gray-600 dark:text-gray-400">
            <AlertCircle className="w-10 h-10 mx-auto mb-2" />
            No codes available.
          </div>
        )}
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-bold">Confirm Validation</h3>
            <p className="mt-2 text-gray-600">
              Are you sure you want to validate this coupon?
            </p>
            {validating ? (
              <p className="mt-4 text-blue-600">Validating...</p>
            ) : (
              <>
                {validationMessage && (
                  <p className="mt-4 text-green-600">{validationMessage}</p>
                )}
                <div className="flex justify-center mt-4 space-x-4">
                  <button
                    onClick={confirmValidation}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md"
                  >
                    Confirm
                  </button>
                  <button
                    onClick={() => setShowModal(false)}
                    className="px-4 py-2 bg-red-600 text-white rounded-md"
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CouponDetail;
