import { useEffect, useState } from "react";
import axios from "axios";
import { HiChartPie, HiSparkles, HiLightBulb } from "react-icons/hi";
import Subscription from "./Subscription";
import { useOutletContext } from "react-router-dom";

function AIInsights() {
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [companyFirstName, setCompanyFirstName] = useState("");
  const { isExpired } = useOutletContext();
  const [error, setError] = useState(null);

  useEffect(() => {
    // Load insights from localStorage on mount
    const savedInsights = localStorage.getItem("feedbackinsights");
    if (savedInsights) {
      setInsights(JSON.parse(savedInsights));
    }
  }, []);

  useEffect(() => {
    // Update time and fetch company name
    const updateTime = () => {
      const now = new Date();
      const formattedTime = now.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      setCurrentTime(formattedTime);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    // Get company name from localStorage
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    const companyName = userData.company_name || "Default Company";
    const firstName = companyName.split(" ")[0];
    setCompanyFirstName(firstName);

    return () => clearInterval(interval);
  }, []);

  const fetchInsights = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(
        "https://zola.technology/api/ai-insights/company/get/",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setInsights(response.data);
      localStorage.setItem("feedbackinsights", JSON.stringify(response.data));
    } catch (err) {
      console.error("Failed to fetch insights", err.response?.data?.error);
      setError(err.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-3 md:p-6 bg-white dark:bg-gray-900 h-fit w-full">
      {/* Header */}
      {isExpired && <Subscription />}
      <div className="flex flex-col md:flex-row justify-between gap-4 items-center mb-6">
        <div>
          <h1 className="text-2xl text-center md:text-start font-bold text-gray-800 dark:text-gray-100">
            AI Insights
          </h1>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Zola AI will be your feedback explorer
          </p>
        </div>

        <button
          onClick={fetchInsights}
          disabled={loading}
          className={`px-4 py-2 border rounded-lg flex items-center gap-1 transition ${
            loading
              ? "bg-gray-300 text-gray-600 cursor-not-allowed"
              : "text-blue-500 border-blue-500 hover:bg-blue-100 dark:hover:bg-blue-800 dark:text-blue-400 dark:border-blue-400"
          }`}
        >
          {loading ? "Generating..." : "Generate Insights"}
          <HiSparkles
            className={
              loading
                ? "animate-spin text-gray-500"
                : "text-blue-500 dark:text-blue-400"
            }
          />
        </button>
      </div>

      {/* AI Insights Data */}
      <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow-sm rounded-lg w-full p-3 md:p-6">
        {/* <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-6">
          Summary
        </h2> */}

        {/* Loading State */}
        {loading && (
          <p className="text-gray-600 dark:text-gray-300 text-lg font-medium">
            Generating insights... Please wait.
          </p>
        )}
        {/* Error State */}
        {error && (
          <div className="flex flex-col items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 text-red-500 mb-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01M5.21 17.29A9 9 0 1119.78 6.71 9 9 0 015.21 17.29z"
              />
            </svg>
            <p className="text-red-500 text-base font-medium">{error}</p>
            <p className="text-gray-500 text-sm mt-2">
              Once you get feedbacks, Our AI tool will help you generate
              insights.
            </p>
          </div>
        )}
        {/* No Insights Message */}

        {!loading && !insights && !error && (
          <div className="flex flex-col items-center justify-center p-8">
            <div className="flex items-center justify-center w-16 h-16 bg-blue-100 dark:bg-blue-900 text-blue-500 dark:text-blue-300 rounded-full mb-4">
              <HiLightBulb className="w-8 h-8" />
            </div>
            <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
              No Insights Available
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-300 text-center mt-2">
              Click the <strong>"Generate Insights"</strong> button above to get
              AI-powered feedback analysis. Our AI is ready to help!
            </p>
          </div>
        )}

        {/* Insights Data */}
        {!loading && insights && (
          <div className="space-y-8">
            {/* Sentiment Distribution */}
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 flex items-center gap-2">
                <span className="text-blue-500">
                  <HiChartPie className="w-6 h-6" />
                </span>
                Sentiment Distribution
              </h3>
              <p className="text-gray-700 dark:text-gray-300 flex flex-col md:flex-row mt-4">
                <span className="text-green-500 font-semibold mr-2">
                  Positive:
                </span>{" "}
                {insights?.sentiment_distribution?.positive}
                <span className="hidden md:inline mx-2">|</span>{" "}
                <span className="text-gray-500 font-semibold mr-2">
                  Neutral:
                </span>{" "}
                {insights?.sentiment_distribution?.neutral}
                <span className="hidden md:inline mx-2">|</span>{" "}
                <span className="text-red-500 font-semibold mr-2">
                  Negative:
                </span>{" "}
                {insights?.sentiment_distribution?.negative}
              </p>
            </div>

            {/* Key Themes & Trends */}
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 flex items-center gap-2">
                <span className="text-yellow-500">
                  <HiLightBulb />
                </span>
                Key Themes & Trends
              </h3>
              <ul className="list-disc pl-6 mt-4 text-gray-700 dark:text-gray-300 space-y-2">
                {insights?.key_themes_and_trends?.map((item, index) => (
                  <li key={index}>
                    <span className="font-semibold">{item.theme}:</span>{" "}
                    {item.summary} (
                    <span
                      className={`${
                        item.sentiment === "Positive"
                          ? "text-green-500"
                          : item.sentiment === "Negative"
                          ? "text-red-500"
                          : "text-gray-500"
                      } font-medium`}
                    >
                      {item.sentiment}
                    </span>
                    )
                  </li>
                ))}
              </ul>
            </div>

            {/* Actionable Recommendations */}
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 flex items-center gap-2">
                <span className="text-blue-500">
                  <HiSparkles />
                </span>
                Actionable Recommendations
              </h3>
              <ul className="list-disc pl-6 mt-4 text-gray-700 dark:text-gray-300 space-y-2">
                {insights?.actionable_recommendations?.map(
                  (recommendation, i) => (
                    <li key={i}>{recommendation}</li>
                  )
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AIInsights;
