// ContactModal.js
import { motion } from 'framer-motion';

const ContactModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
      <motion.div
        className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
      >
        <h3 className="text-2xl font-bold mb-4">Contact Us</h3>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Name</label>
            <input
              type="text"
              className="border border-gray-300 p-2 rounded w-full"
              placeholder="Your Name"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Email</label>
            <input
              type="email"
              className="border border-gray-300 p-2 rounded w-full"
              placeholder="Your Email"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Message</label>
            <textarea
              className="border border-gray-300 p-2 rounded w-full"
              rows="4"
              placeholder="Your Message"
              required
            ></textarea>
          </div>
          <button
            aria-label="Send Message"
            type="submit"
            className="bg-teal-500 text-white px-6 py-2 rounded-full hover:bg-teal-600 transition duration-300"
          >
            Send Message
          </button>
        </form>
        <button
          aria-label="Close Modal"
          onClick={onClose}
          className="mt-4 text-gray-500 hover:text-gray-700"
        >
          Close
        </button>
      </motion.div>
    </div>
  );
};

export default ContactModal;
