import { useState } from "react";
import { PackagePlus, Pencil, Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteConfirmationPopup from "../DeletePopUp";
import EditCouponModal from "./EditCouponModal";
import "react-toastify/dist/ReactToastify.css";

const CouponsTable = ({ coupons, fetchCoupons }) => {
  const navigate = useNavigate();
  const [deletingCoupon, setDeletingCoupon] = useState(null);
  const [editingCoupon, setEditingCoupon] = useState(null);

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://zola.technology/api/company/coupons/${id}/delete/`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.ok) {
        toast.success("Coupon deleted successfully!");
        fetchCoupons(); // Refresh coupons after delete
      } else {
        toast.error("Failed to delete coupon.");
      }
    } catch (error) {
      toast.error("Error deleting coupon.");
    }

    setDeletingCoupon(null); // Close the popup
  };

  if (!coupons.length) {
    return (
      <div className="p-8 bg-white dark:bg-gray-900 border rounded-2xl shadow flex flex-col items-center justify-center">
        <PackagePlus className="w-12 h-12 text-blue-600" />
        <h3 className="text-xl font-semibold text-gray-800 dark:text-white">
          No Coupons Found
        </h3>
        <p className="text-gray-600 dark:text-gray-300 max-w-lg">
          Looks like you haven't created any coupons yet. Get started by adding
          your first coupon to reward your customers!
        </p>
      </div>
    );
  }

  return (
    <>
      {/* Table */}
      <div className="overflow-x-auto rounded-xl shadow border dark:border-gray-800">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-900">
          <thead className="sticky top-0 z-10 bg-gray-100 dark:bg-gray-800">
            <tr>
              {[
                "Name",
                "Discount",
                "Cash Off",
                "Duration",
                "Redemptions",
                "Area",
                "Actions",
              ].map((header) => (
                <th
                  key={header}
                  className="px-6 py-3 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100 dark:divide-gray-800">
            {coupons.map((coupon) => (
              <tr
                key={coupon.id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 transition cursor-pointer"
                onClick={() =>
                  navigate(`/dashboard/coupons/${coupon.id}`, {
                    state: { coupon },
                  })
                }
              >
                <td className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                  {coupon.name}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700 dark:text-gray-300">
                  {coupon.percentageDiscount
                    ? `${coupon.percentageDiscount}%`
                    : "—"}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700 dark:text-gray-300">
                  {coupon.cashDiscount ? `$${coupon.cashDiscount}` : "—"}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700 dark:text-gray-300">
                  {coupon.duration || "—"}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700 dark:text-gray-300">
                  {coupon.redemptions ?? 0}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700 dark:text-gray-300">
                  {coupon.applicableArea ?? "—"}
                </td>
                <td className="px-6 py-4 flex items-center gap-3">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingCoupon(coupon);
                    }}
                    className="text-yellow-600 hover:text-yellow-500"
                    title="Edit"
                  >
                    <Pencil className="w-4 h-4" />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeletingCoupon(coupon);
                    }}
                    className="text-red-600 hover:text-red-500"
                    title="Delete"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modals */}
      {editingCoupon && (
        <EditCouponModal
          coupon={editingCoupon}
          onClose={() => setEditingCoupon(null)}
          onUpdate={fetchCoupons}
        />
      )}
      {deletingCoupon && (
        <DeleteConfirmationPopup
          isOpen={!!deletingCoupon}
          onClose={() => setDeletingCoupon(null)}
          onConfirm={() => handleDelete(deletingCoupon.id)}
          item={deletingCoupon.name}
        />
      )}
    </>
  );
};

export default CouponsTable;
