import { FaUser, FaEnvelope, FaArrowRight } from "react-icons/fa";

export default function EmailCard({ data, onViewContacts }) {
  return (
    <div className="flex gap-6 mt-4 bg-gray-100 items-center">
      {/* Recipients Card */}
      <div className="border-2 bg-white rounded-lg p-5 w-1/2 h-44 flex flex-col justify-between">
        <div className="flex items-center gap-3">
          <div className="bg-yellow-200 p-2 rounded-full">
            <FaUser className="text-yellow-700 w-5 h-5" />
          </div>
          <p className="text-lg font-semibold">{data?.contacts?.length} Recipient</p>
        </div>
        <div 
          className="flex justify-between items-center text-blue-500 mt-2 cursor-pointer" 
          onClick={onViewContacts}
        >
          <p className="text-sm">View Contacts</p>
          <FaArrowRight className="w-5 h-5" />
        </div>
      </div>

      {/* Email Details Card */}
      <div className="bg-white shadow-md rounded-lg p-5 w-1/2 h-44 flex flex-col justify-between">
        <div className="flex items-center gap-3">
          <div className="bg-yellow-200 p-2 rounded-full">
            <FaEnvelope className="text-yellow-700 w-5 h-5" />
          </div>
          <p className="text-sm text-gray-500">Audience</p>
        </div>
        <p className="text-lg font-semibold">{data?.message_type === "email" ? data?.contacts[0].email : data?.contacts[0].phone_number}</p>
        <hr className="my-2" />
        <div className="mt-1">
          <p className="text-sm text-gray-500">Sent</p>
          <p className="font-semibold">{new Date(data?.scheduled_at)
              .toLocaleString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
                timeZone: "UTC",
              })
              .replace(",", " -")}</p>
        </div>
      </div>
    </div>
  );
}
