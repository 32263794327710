import { useState, useEffect } from "react";

const CampaignTable = ({ contacts }) => {
  // State for search, filters
  const [searchTerm, setSearchTerm] = useState("");
  const [dateFilter, setDateFilter] = useState("All time");
  const [subscribedFilter, setSubscribedFilter] = useState("All");
  const [filteredData, setFilteredData] = useState(contacts || []);

  // Apply filters when search or filter values change
  useEffect(() => {
    if (!contacts) return; // Ensure contacts exist before filtering
  
    let filtered = [...contacts];
  
    // Filter by Date Range
    if (dateFilter !== "All time") {
      const today = new Date();
      filtered = filtered.filter((contact) => {
        const createdDate = new Date(contact.created_at);
        const timeDifference = (today - createdDate) / (1000 * 60 * 60 * 24); // Convert to days
  
        if (dateFilter === "Last 30 days") return timeDifference <= 30;
        if (dateFilter === "Last 60 days") return timeDifference <= 60;
        if (dateFilter === "Last 90 days") return timeDifference <= 90;
        return true;
      });
    }
  
    // Filter by Subscribed Status
    if (subscribedFilter !== "All") {
      const isSubscribed = subscribedFilter === "Subscribed";
      filtered = filtered.filter((contact) => contact.subscribed === isSubscribed);
    }
  
    // Filter by Search Term (Safe Check for Null Values)
    if (searchTerm) {
      filtered = filtered.filter((contact) => {
        return (
          (contact.name && contact.name.toLowerCase().includes(searchTerm)) ||
          (contact.email && contact.email.toLowerCase().includes(searchTerm)) ||
          (contact.phone_number && contact.phone_number.includes(searchTerm))
        );
      });
    }
  
    setFilteredData(filtered);
  }, [searchTerm, dateFilter, subscribedFilter, contacts]);
  
  

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mt-6">
      {/* Filters and Search */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2">
          {/* Date Filter */}
          <select
            className="px-4 py-2 bg-gray-200 rounded-lg text-sm"
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option>All time</option>
            <option>Last 30 days</option>
            <option>Last 60 days</option>
            <option>Last 90 days</option>
          </select>

          {/* Subscribed Filter */}
          <select
            className="px-4 py-2 bg-gray-200 rounded-lg text-sm"
            value={subscribedFilter}
            onChange={(e) => setSubscribedFilter(e.target.value)}
          >
            <option>All</option>
            <option>Subscribed</option>
            <option>Unsubscribed</option>
          </select>
        </div>

        {/* Search Input */}
        <input
          type="text"
          placeholder="Search"
          className="px-4 py-2 border border-gray-300 rounded-lg"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
        />
      </div>

      {/* Table */}
      <table className="w-full border-collapse">
        <thead>
          <tr className="text-left text-gray-600 border-b">
            <th className="py-2 px-4">Recipient</th>
            <th className="py-2 px-4">Phone</th>
            <th className="py-2 px-4">Email</th>
            <th className="py-2 px-4">Subscribed</th>
            {/* <th className="py-2 px-4">Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            filteredData.map((contact, index) => (
              <tr key={index} className="border-b">
                <td className="py-2 px-4">{contact.name}</td>
                <td className="py-2 px-4">{contact.phone_number}</td>
                <td className="py-2 px-4">{contact.email}</td>
                <td className="py-2 px-4">
                  {contact.subscribed ? (
                    <span className="bg-green-100 text-green-600 px-3 py-1 text-xs font-semibold rounded-full">
                      Yes
                    </span>
                  ) : (
                    <span className="bg-red-100 text-red-600 px-3 py-1 text-xs font-semibold rounded-full">
                      No
                    </span>
                  )}
                </td>
                {/* <td className="py-2 px-4">
                  <button className="text-gray-700 hover:text-gray-900">👁</button>
                </td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center py-4 text-gray-500">
                No records found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CampaignTable;
