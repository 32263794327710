import { useContext, useState, useEffect } from "react";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { BranchContext } from "./../../context/BranchContext"; // Corrected path
import { useTimeRange } from "../../../context/TimeRangeContext";

const StatsCard = () => {
  const { branches } = useContext(BranchContext);
  const { timeRange } = useTimeRange();

  const [employeeTaps, setEmployeeTaps] = useState(null);
  const [feedbackData, setFeedbackData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStatsData = async () => {
      try {
        const token = localStorage.getItem("token"); // Fetch token from local storage
        if (!token) {
          throw new Error("No token found in local storage");
        }

        // Fetch data from both endpoints
        const [tapsResponse, feedbackResponse] = await Promise.all([
          fetch(
            `https://zola.technology/api/hq-company/dashboard/data/employees-taps-issues-performance/?filter=${timeRange}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
          fetch(
            `https://zola.technology/api/hq-company/dashboard/feedback-data/get/?filter=${timeRange}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        ]);

        if (!tapsResponse.ok || !feedbackResponse.ok) {
          throw new Error("Error fetching data from one or more endpoints");
        }

        const tapsData = await tapsResponse.json();
        const feedback = await feedbackResponse.json();

        setEmployeeTaps(tapsData);
        setFeedbackData(feedback);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatsData();
  }, [timeRange]);

  const statsData = [
    {
      title: "Average Performance",
      stat: employeeTaps?.performance?.score,
      growth: employeeTaps?.performance?.change?.total_change, // Replace with actual growth data if available
      percentage: `${employeeTaps?.performance?.change?.percentage_change}% vs last ${timeRange}`, // Replace with actual percentage if available
      change: employeeTaps?.change?.type,
      chartData: [
        { value: 10, change: `${employeeTaps?.performance?.change?.type}` },
        { value: 20, change: `${employeeTaps?.performance?.change?.type}` },
        { value: 15, change: `${employeeTaps?.performance?.change?.type}` },
        { value: 25, change: `${employeeTaps?.performance?.change?.type}` },
      ],
    },
    {
      title: "Active cards",
      stat: branches.length.toString(),
      growth: "", // Replace with actual growth data if available
      percentage: "All time", // Replace with actual percentage if available
      chartData: [
        { value: 15, change: "up" },
        { value: 10, change: "down" },
        { value: 20, change: "up" },
        { value: 30, change: "up" },
      ],
    },
    {
      title: "Feedback count",
      stat: feedbackData?.total_feedback,
      growth: feedbackData?.change?.total_change, // Replace with actual growth data if available
      percentage: `${feedbackData?.change?.percentage_change}% vs last ${timeRange}`, // Replace with actual percentage if available
      change: feedbackData?.change?.type,
      chartData: [
        { value: 10, change: feedbackData?.change?.type },
        { value: 15, change: feedbackData?.change?.type },
        { value: 20, change: feedbackData?.change?.type },
        { value: 25, change: feedbackData?.change?.type },
      ],
    },
    {
      title: "Total taps",
      stat: employeeTaps?.taps?.count,
      growth: employeeTaps?.taps?.change?.total_change, // Replace with actual growth data if available
      percentage: `${employeeTaps?.taps?.change?.percentage_change}% vs last ${timeRange}`, // Replace with actual percentage if available
      change: employeeTaps?.change?.type,
      chartData: [
        { value: 10, change: employeeTaps?.taps?.change?.type },
        { value: 20, change: employeeTaps?.taps?.change?.type },
        { value: 15, change: employeeTaps?.taps?.change?.type },
        { value: 30, change: employeeTaps?.taps?.change?.type },
      ],
    },
  ];

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-4 mb-4">
      {statsData.map((data, index) => (
        <div
          key={index}
          className="bg-white rounded-lg p-6 flex flex-col gap-4 justify-between shadow-sm"
        >
          <h3 className="text-sm font-semibold text-gray-500">{data.title}</h3>
          <div className="flex items-end justify-between">
            {/* Stats Section */}
            <div className="flex flex-col">
              <div className="flex gap-2 items-baseline mb-2">
                <h1 className="text-2xl font-bold text-gray-900">
                  {data.stat}
                </h1>
                <span
                  className={`text-sm font-medium ${
                    data.change === "Increase"
                      ? "text-green-600"
                      : data.change === "Decrease"
                      ? "text-red-600"
                      : "text-gray-600"
                  }`}
                >
                  {data.growth}
                </span>
              </div>
              <p className="text-gray-500 text-xs">{data.percentage}</p>
            </div>

            {/* Graph Section */}
            <div className="relative flex items-end gap-2 ml-6 h-16">
              {data.chartData.map((bar, idx) => (
                <div className="relative" key={idx}>
                  {/* Pointer for Highlighted Bar */}
                  {idx === data.chartData.length - 1 && (
                    <div
                      className={`absolute -top-4 left-1/2 transform -translate-x-1/2 bg-green-100 p-1 rounded-full ${
                        bar.change === "Increase"
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {bar.change === "Increase" ? (
                        <FaArrowCircleUp className="text-xs" />
                      ) : (
                        <FaArrowCircleDown className="text-xs" />
                      )}
                    </div>
                  )}
                  {/* Bar */}
                  <div
                    className={`w-4 rounded-sm ${
                      idx === data.chartData.length - 1
                        ? "bg-indigo-500"
                        : "bg-gray-200"
                    }`}
                    style={{ height: `${bar.value * 3}px` }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatsCard;
