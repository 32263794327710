import { useState } from "react";
import { useCoupons } from "../../context/CouponContext"; // Import CouponContext
import CouponsTable from "./Coupons/CouponsTable";
import CouponsStats from "./Coupons/CouponsStats";
import AddCouponModal from "./Coupons/AddCouponModal";
import { Plus, Search } from "lucide-react";
import { ToastContainer } from "react-toastify";

const Coupons = () => {
  const { coupons, loading, error, fetchCoupons } = useCoupons(); // Use context for coupons
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [filterDuration, setFilterDuration] = useState("");

  const [showAddModal, setShowAddModal] = useState(false);

  // Function to calculate duration
  const calculateDuration = (validFrom, validUntil) => {
    const start = new Date(validFrom);
    const end = new Date(validUntil);
    const difference = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
    return difference > 0
      ? `${difference} day${difference !== 1 ? "s" : ""}`
      : "Expired";
  };

  // Map API data to match the required format
  const formattedCoupons = coupons.map((coupon) => ({
    id: coupon.id,
    name: coupon.name,
    discountType: coupon.discount_type === "percentage" ? "Percentage" : "Cash",
    percentageDiscount:
      coupon.discount_type === "percentage" ? coupon.discount_value : null,
    cashDiscount:
      coupon.discount_type === "fixed" ? coupon.discount_value : null,
    startDate: coupon.valid_from.split("T")[0],
    endDate: coupon.valid_until.split("T")[0],
    duration: calculateDuration(coupon.valid_from, coupon.valid_until),
    redemptions: coupon.used_count,
    usageType: coupon.usage_type,
    applicableArea: coupon.applicable_area,
    usageLimit: coupon.usage_limit,
    codes: [
      { code: coupon.code, status: coupon.is_active ? "Active" : "Expired" },
    ],
  }));

  // Refresh coupons after adding/deleting
  const handleAddCoupon = () => {
    fetchCoupons();
    setShowAddModal(false);
  };

  // Filter and sorting logic
  let filteredCoupons = formattedCoupons
    .filter((coupon) =>
      coupon.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((coupon) => {
      if (!filterOption) return true;
      if (filterOption === "Percentage Discount" && coupon.percentageDiscount)
        return true;
      if (filterOption === "Cash Discount" && coupon.cashDiscount) return true;
      return false;
    })
    .filter((coupon) =>
      filterDuration ? coupon.duration === filterDuration : true
    )
    .sort((a, b) => b.redemptions - a.redemptions); // Sort by redemptions

  return (
    <div>
      <ToastContainer />
      <div className="flex items-center justify-between py-6">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
          Discounts
        </h2>

        <button
          onClick={() => setShowAddModal(true)}
          className="flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium rounded-md shadow transition"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Coupon
        </button>
      </div>

      {/* Show error if any */}
      {error && <p className="text-red-500">{error}</p>}

      {/* Show loading state */}
      {loading ? (
        <p>Loading coupons...</p>
      ) : (
        <>
          <CouponsStats coupons={filteredCoupons} />

          {/* Search & Filter Section */}
          <div className="flex flex-wrap lg:justify-end items-center gap-4 mt-10 p-4">
            {/* Search Input */}
            <div className="relative flex-grow md:flex-grow-0 md:w-1/3">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
              <input
                type="text"
                placeholder="Search coupons..."
                className="pl-10 pr-4 py-2 w-full border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none dark:bg-gray-800 dark:text-white"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {/* Single Filter Dropdown */}
            <div className="flex-grow md:flex-grow-0">
              <select
                className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none dark:bg-gray-800 dark:text-white"
                value={filterOption}
                onChange={(e) => setFilterOption(e.target.value)}
              >
                <option value="">All Coupons</option>
                <option value="Percentage Discount">Percentage Discount</option>
                <option value="Cash Discount">Cash Discount</option>
              </select>
            </div>

            {/* Duration Filter Dropdown */}
            <div className="flex-grow md:flex-grow-0">
              <select
                className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none dark:bg-gray-800 dark:text-white"
                value={filterDuration}
                onChange={(e) => setFilterDuration(e.target.value)}
              >
                <option value="">All Durations</option>
                {Array.from(
                  new Set(formattedCoupons.map((c) => c.duration))
                ).map((dur) => (
                  <option key={dur} value={dur}>
                    {dur}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <CouponsTable
            coupons={filteredCoupons}
            // onEdit={setEditingCoupon}
            fetchCoupons={fetchCoupons}
          />
        </>
      )}

      {/* Add Coupon Modal */}
      {showAddModal && (
        <AddCouponModal
          onClose={() => setShowAddModal(false)}
          onAdd={handleAddCoupon}
        />
      )}

      {/* Edit Coupon Modal
      {editingCoupon && (
        <EditCouponModal
          coupon={editingCoupon}
          onClose={() => setEditingCoupon(null)}
          onUpdate={fetchCoupons}
        />
      )} */}
    </div>
  );
};

export default Coupons;
