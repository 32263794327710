import { createContext, useState, useContext } from "react";

const RatingContext = createContext();

export const useRating = () => useContext(RatingContext);

export const RatingProvider = ({ children }) => {
  const [employeeRating, setEmployeeRating] = useState(0);

  return (
    <RatingContext.Provider value={{ employeeRating, setEmployeeRating }}>
      {children}
    </RatingContext.Provider>
  );
};
