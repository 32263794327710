
const CampaignModal = ({ campaignData, onClose }) => {
  if (!campaignData) return null;

  const { name, message_type, subject, message, contacts } = campaignData;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-[500px] p-6 relative">
        {/* Close Button */}
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          ❌
        </button>

        {/* Title */}
        <h2 className="text-lg font-semibold mb-3">Send to</h2>

        {/* Contacts Section - Scrollable with Fixed Height */}
        <div className="bg-gray-100 p-3 rounded-lg flex flex-wrap gap-2 max-h-24 overflow-y-auto">
          {contacts.map((contact, index) => (
            <span
              key={index}
              className="bg-blue-100 text-blue-600 px-2 py-1 text-sm rounded-md"
            >
              {message_type === "email" ? contact.email : contact.phone_number || "N/A"}
            </span>
          ))}
        </div>

        {/* Name & Subject Section - Hide Subject if SMS */}
        <div className="grid grid-cols-2 gap-3 mt-4">
          <div className="bg-gray-100 p-3 rounded-lg text-gray-700">{name}</div>
          {message_type === "email" && (
            <div className="bg-gray-100 p-3 rounded-lg text-gray-700">{subject}</div>
          )}
        </div>

        {/* Message Section */}
        <div className="bg-gray-100 p-3 rounded-lg text-gray-700 mt-3">
          {message}
        </div>
      </div>
    </div>
  );
};

export default CampaignModal;
