import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentForm = () => {
  const location = useLocation();
  const transactionType = location.state?.transactionType || "payment";

  const [company, setCompany] = useState(null);

  const [formData, setFormData] = useState({
    username: "",
    number_of_cards: 0,
    card_amount: 0,
    number_of_stands: 0,
    stand_amount: 0,
    total_amount: 0,
    transaction_type: transactionType,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch company data when username changes
    if (formData.username.trim()) {
      const fetchCompany = async () => {
        try {
          setLoading(true);
          setError(null); //
          const response = await fetch(
            `https://zola.technology/api/company/get/${formData.username}`
          );
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
              errorData.message || `Error: ${response.statusText}`
            );
          }
          const data = await response.json();
          setCompany(data);
          toast.success("Company Data Retrieved!")
        } catch (err) {
          setError(err);
          setCompany(null);
          toast.error(error)
        } finally {
          setLoading(false);
        }
      };

      fetchCompany();
    } else {
      setCompany(null);
      setError(null);
    }
  }, [formData.username]);

  useEffect(() => {
    // Fetch user data from local storage
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setFormData((prevData) => ({
        ...prevData,
        username: "",
        phone_number: "",
        transaction_type: "payment",
      }));
    }
  }, [transactionType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handlePhoneChange = (value) => {
  //   setFormData({
  //     ...formData,
  //     phone_number: value,
  //   });
  // };

  // const validatePhoneNumber = () => {
  //   const phoneLength = formData.phone_number.replace(/[^0-9]/g, "").length;
  //   return phoneLength >= 10 && phoneLength <= 15;
  // };

  const totalCardAmount =
    parseFloat(formData.number_of_cards) * parseFloat(formData.card_amount) ||
    0;
  const totalStandAmount =
    parseFloat(formData.number_of_stands) * parseFloat(formData.stand_amount) ||
    0;
  const grandTotal = totalCardAmount + totalStandAmount;

  const currency = company?.phone_number.startsWith("254") ? "KES" : "USD";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!formData.username || !company) {
      toast.error("The company does not exist. Please check the username.");
      return;
    }

    const paymentData = {
      username: formData.username,
      phone_number: company?.phone_number,
      unit_amount: grandTotal,
      currency: currency,
      transaction_type: formData.transaction_type,
    };

    try {
      // Make payment
      const paymentResponse = await fetch(
        "https://zola.technology/api/company/make-payment/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      const paymentDataResponse = await paymentResponse.json();

      if (!paymentResponse.ok) {
        toast.error(paymentDataResponse.message || "Payment failed.");
        setLoading(false);
        return;
      }

      toast.success("Payment successful!");

      // Send email
      const emailData = {
        subject: "Complete Payment",
        message: `Please complete your payment using the following link: ${paymentDataResponse.url}`,
        recipients: [company?.email],
      };

      const emailResponse = await fetch(
        "https://zola.technology/api/send-bulk-emails/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        }
      );

      if (emailResponse.ok) {
        toast.success("Email sent successfully!");
      } else {
        const emailErrorData = await emailResponse.json();
        toast.error(emailErrorData.message || "Error sending email.");
      }

      setFormData({
        username: "",
        number_of_cards: 0,
        card_amount: 0,
        number_of_stands: 0,
        stand_amount: 0,
        transaction_type: transactionType,
      });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} />
      <form
        onSubmit={handleSubmit}
        className="space-y-8 max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md"
      >
        <h2 className="text-xl font-semibold text-gray-700">Payment Details</h2>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {/* Username */}
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Enter company username"
              className="input"
              required
            />
          </div>

          {/* Number of Cards */}
          <div>
            <label
              htmlFor="number_of_cards"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Number of Cards
            </label>
            <input
              type="number"
              id="number_of_cards"
              name="number_of_cards"
              value={formData.number_of_cards}
              onChange={handleChange}
              placeholder="Enter number of cards"
              className="input"
            />
          </div>

          {/* Card Amount */}
          <div>
            <label
              htmlFor="card_amount"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Card Amount
            </label>
            <input
              type="number"
              id="card_amount"
              name="card_amount"
              value={formData.card_amount}
              onChange={handleChange}
              placeholder="Enter card amount"
              className="input"
            />
          </div>

          {/* Number of Stands */}
          <div>
            <label
              htmlFor="number_of_stands"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Number of Stands
            </label>
            <input
              type="number"
              id="number_of_stands"
              name="number_of_stands"
              value={formData.number_of_stands}
              onChange={handleChange}
              placeholder="Enter number of stands"
              className="input"
            />
          </div>

          {/* Stand Amount */}
          <div>
            <label
              htmlFor="stand_amount"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Stand Amount
            </label>
            <input
              type="number"
              id="stand_amount"
              name="stand_amount"
              value={formData.stand_amount}
              onChange={handleChange}
              placeholder="Enter stand amount"
              className="input"
            />
          </div>
        </div>

        {/* Grand Total */}
        <div className="mt-4">
          <span className="block text-lg font-semibold">
            Grand Total: {currency} {grandTotal.toFixed(2)}
          </span>
        </div>

        <button
          disabled={loading}
          aria-label="Submit Payment"
          aria-disabled={loading}
          type="submit"
          className="w-full py-3 px-6 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default PaymentForm;
