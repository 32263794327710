import { useState, useEffect } from "react";
import {
  IoMdTrash,
} from "react-icons/io";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { FaExclamationTriangle } from "react-icons/fa";

import { MdVerifiedUser } from "react-icons/md";
import AIQuestionGenerator from "./surveycreation/AIquestionsGenerator";
import ManualSurveyCreator from "./surveycreation/ManualCreation";
import DeleteConfirmationPopup from "./DeletePopUp";
import Subscription from "./Subscription";
import { useSurvey } from "../../context/SurveyContext";

function Survey() {
  const [title, setTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // For the popup modal
  const [currentQuestion, setCurrentQuestion] = useState({
    text: "",
    type: "",
    options: ["", "", "", ""], // Default 4 options for single/multiple choice
  });
  const [creationMode, setCreationMode] = useState(""); // "ai" or "manual"
  const [aiPrompt, setAiPrompt] = useState("");
  const [aiQuestions, setAiQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState({
    id: null,
    active: false,
  });
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const { isExpired } = useOutletContext();
  const { updateActiveSurve, fetchSurveys  } = useSurvey();
  const handleGenerateAIQuestions = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token"); // Fetch token from local storage

      // Construct the URL with the aiPrompt as a query parameter
      const url = `https://zola.technology/api/company/surveys/ai/generate-questions/?industry=${encodeURIComponent(
        aiPrompt
      )}`;

      const response = await fetch(url, {
        method: "GET", // Use GET since the API expects it
        headers: {
          Authorization: `Bearer ${token}`, // Pass token in the Authorization header
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch AI-generated questions");
      }

      const data = await response.json();
      console.log(data);

      // Set the AI-generated questions to state
      setAiQuestions(data.questions);
    } catch (error) {
      console.error("Failed to generate questions:", error);
    } finally {
      setLoading(false);
      setAiPrompt("");
    }
  };

  const fetchAIQuestions = async (prompt) => {
    const response = await axios.post(
      "https://your-ai-api.com/generate",
      { prompt },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };

  const handleRegenerateQuestions = () => {
    setAiPrompt(""); // Clear the current prompt for a new one
    setAiQuestions([]); // Clear the current questions
  };

  const handleSubmitAIQuestions = async () => {
    const token = localStorage.getItem("token");
    const surveyData = {
      title: "This survey aims to gather insightful feedback.",
      questions: aiQuestions.map((q) => ({
        question_type: q.type === "multiple_choice" ? "multi_choice" : q.type,
        question_text: q.question,
        choices: q.choices || [],
      })),
    };
    console.log(surveyData);
    try {
      const response = await fetch(
        "https://zola.technology/api/company/surveys/add-survey/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(surveyData),
        }
      );

      if (response.ok) {
        setIsModalOpen(false); // Close the modal
        toast.success("Survey saved successfully.");
      } else {
        alert("Failed to save survey. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting survey:", error);
      alert("An error occurred while saving the survey.");
    }
  };

  const handleModeSelection = (mode) => {
    setCreationMode(mode);
  };

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    getAllSurveys();
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setCurrentQuestion({ text: "", type: "", options: ["", "", "", ""] }); // Reset current question
  };

  const updateQuestionType = (type) => {
    setCurrentQuestion((prev) => ({
      ...prev,
      type,
      options: type === "paragraph" ? [] : ["", "", "", ""],
    }));
  };

  const addQuestion = () => {
    if (!currentQuestion.text || !currentQuestion.type) {
      alert("Please fill in the question and select a type.");
      return;
    }

    // Handle Numeric Choice Validation
    if (currentQuestion.type === "numeric_choice") {
      if (!currentQuestion.range) {
        alert("Please select a numeric range (1-5 or 1-10).");
        return;
      }
      // Automatically generate options based on the selected range
      const rangeLimit = currentQuestion.range === "1-5" ? 5 : 10;
      currentQuestion.options = Array.from(
        { length: rangeLimit },
        (_, i) => i + 1
      );
    }

    // Add the current question to the list of questions
    setQuestions((prev) => [...prev, currentQuestion]);

    // Reset the currentQuestion state
    setCurrentQuestion({
      text: "",
      type: "",
      options: ["", "", "", ""], // Default for single/multi-choice
      range: "", // Reset range for numeric questions
    });
  };

  const saveCurrentQuestion = () => {
    if (!currentQuestion.text || !currentQuestion.type) {
      alert("Please complete the question details before saving.");
      return;
    }
    setQuestions((prev) => [...prev, currentQuestion]);
    toggleModal(); // Close modal
  };

  const saveSurvey = async () => {
    const formattedQuestions = questions.map((q) => ({
      question_text: q.text,
      question_type: q.type === "single" ? "single_choice" : q.type,
      choices: q.type === "paragraph" ? [] : [...q.options], // Save choices as an array
    }));

    const surveyData = { title, questions: formattedQuestions };

    try {
      const response = await axios.post(
        "https://zola.technology/api/company/surveys/add-survey/",
        surveyData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Survey saved successfully!");
      getAllSurveys();
      setTitle("");
      setQuestions([]);
      setIsModalOpen(false); // Close the modal
      toast.success("Survey saved successfully.");
    } catch (error) {
      console.error("Error saving survey:", error);
      toast.error("Failed to save survey.");
    }
  };

  const getAllSurveys = async () => {
    try {
      const response = await axios.get(
        "https://zola.technology/api/company/surveys/get-all/",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSurveys(response.data);
    } catch (error) {
      console.error("Error fetching surveys:", error);
      console.log("Failed to fetch surveys.");
    }
  };

  const handleToggle = async (active, surveyId) => {
    const activateEndpoint = `https://zola.technology/api/company/surveys/survey/activate/?survey_id=${surveyId}`;
    const deactivateEndpoint = `https://zola.technology/api/company/surveys/survey/deactivate/?survey_id=${surveyId}`;
    const token = localStorage.getItem("token");

    try {
      let response;

      if (active) {
        response = await axios.patch(
          activateEndpoint,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
        console.log("Activate Response:", response.data);
      } else {
        response = await axios.patch(
          deactivateEndpoint,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
        console.log("Deactivate Response:", response.data);
      }

      // 🔹 Ensure API responded with success
      if (response.status >= 200 && response.status < 300) {
        await fetchSurveys (); // Re-fetch active survey

        // 🔹 Update UI state
        setSurveys((prevSurveys) =>
          prevSurveys.map((survey) => ({
            ...survey,
            active: survey.id === surveyId, // Mark active survey correctly
          }))
        );

        toast.success(
          `Survey ${active ? "activated" : "deactivated"} successfully.`
        );
      } else {
        throw new Error(`Unexpected API response: ${response.status}`);
      }
    } catch (error) {
      console.error("Error updating survey status:", error);
      toast.error("Failed to update survey.");
    }
  };

  const handleCheckboxChange = (active, surveyId) => {
    setSelectedSurvey({ id: surveyId, active }); // Store the survey ID and active state
    setShowPopUp(true); // Show the popup
  };

  const handleConfirmToggle = async () => {
    const { id, active } = selectedSurvey; // Retrieve the stored survey ID and active state
    await handleToggle(active, id); // Call the existing function
    setShowPopUp(false); // Close the popup
  };

  const paginatedSurveys = surveys.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(surveys.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const handleDeleteSurvey = async () => {
    if (!surveyToDelete) return;

    try {
      await axios.delete(
        `https://zola.technology/api/company/surveys/survey/delete/${surveyToDelete}/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Survey deleted successfully.");
      setSurveys(surveys.filter((survey) => survey.id !== surveyToDelete));
    } catch (error) {
      console.error("Error deleting survey:", error);
      toast.error("Failed to delete survey.");
    } finally {
      setShowDeletePopUp(false);
      setSurveyToDelete(null);
    }
  };

  const handleDeleteConfirmation = (surveyId) => {
    setSurveyToDelete(surveyId);
    setShowDeletePopUp(true);
  };

  return (
    <>
      <div className="p-6 bg-white dark:bg-gray-800 shadow-md rounded-lg">
        <ToastContainer position="top-right" autoClose={3000} />

        {/* Header Section */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
            Survey
          </h2>
          <button
            onClick={toggleModal}
            className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition"
          >
            + Create New
          </button>
        </div>

        {/* Survey Table */}
        <table className="min-w-full table-auto border-separate border-spacing-y-2">
          <thead>
            <tr className="text-gray-700 dark:text-gray-300 text-[16px]">
              <th className="py-3 px-4 border-b text-left">No</th>
              <th className="py-3 px-4 border-b text-left">Survey Title</th>
              <th className="py-3 px-4 border-b text-left">Created Date</th>
              <th className="py-3 px-4 border-b text-left">Status</th>
              <th className="py-3 px-4 border-b text-center">Action</th>
              <th className="py-3 px-4 border-b text-center">Active</th>
            </tr>
          </thead>
          <tbody>
            {paginatedSurveys.length > 0 ? (
              paginatedSurveys.map((survey, index) => (
                <tr
                  key={survey.id}
                  className=" text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-600 transition"
                >
                  <td className="py-3 px-4 border-b">
                    {String(index + 1).padStart(2, "0")}
                  </td>
                  <td className="py-3 px-4 border-b text-ellipsis whitespace-nowrap overflow-hidden">
                    {survey.title}
                  </td>
                  <td className="py-3 px-4 border-b">
                    {new Date(survey.created_at).toLocaleString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </td>
                  <td className="py-3 px-4 border-b">
                    <span
                      className={`px-4 py-1 rounded-full text-sm font-medium ${
                        survey.active
                          ? "bg-green-100 dark:bg-green-800 text-green-600 dark:text-green-300"
                          : "bg-yellow-100 dark:bg-yellow-800 text-yellow-600 dark:text-yellow-300"
                      }`}
                    >
                      {survey.active ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td className="py-3 px-4 border-b text-center">
                    <div className="flex items-center justify-center gap-3">
                      <button
                        onClick={() =>
                          navigate(`/dashboard/survey/${survey.id}`)
                        }
                        className="text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-200 transition"
                      >
                        👁
                      </button>
                      <button
                        onClick={() => handleDeleteConfirmation(survey.id)}
                        className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300 transition"
                      >
                        <IoMdTrash size={20} />
                      </button>
                    </div>
                  </td>
                  <td className="py-3 px-4 border-b text-center">
                    <input
                      type="checkbox"
                      className="theme-checkbox"
                      checked={survey.active}
                      onChange={(e) =>
                        handleCheckboxChange(e.target.checked, survey.id)
                      }
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  className="text-center py-4 text-gray-700 dark:text-gray-300"
                >
                  {loading ? "Loading..." : "No surveys available. Click on 'create new' to create survey manually or generate using AI."}
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          {/* Left Section: Showing Entries */}
          <div className="text-sm text-gray-500 dark:text-gray-300">
            Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
            {Math.min(currentPage * itemsPerPage, surveys.length)} of{" "}
            {surveys.length} entries
          </div>

          {/* Center Section: Pagination */}
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
              className="flex items-center justify-center w-8 h-8 bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition"
            >
              &lt;
            </button>

            {Array.from(
              { length: Math.min(3, totalPages) },
              (_, i) => currentPage - 1 + i
            )
              .filter((page) => page > 0 && page <= totalPages)
              .map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  className={`flex items-center justify-center w-8 h-8 ${
                    currentPage === page
                      ? "bg-blue-600 text-white"
                      : "bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300"
                  } rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition`}
                >
                  {page}
                </button>
              ))}

            <button
              onClick={() =>
                handlePageChange(Math.min(currentPage + 1, totalPages))
              }
              className="flex items-center justify-center w-8 h-8 bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition"
            >
              &gt;
            </button>
          </div>

          {/* Right Section: Entries Dropdown */}
          <div className="flex items-center">
            <span className="text-sm text-gray-500 dark:text-gray-300 mr-2">
              Show
            </span>
            <select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="border border-gray-300 dark:border-gray-600 text-sm text-gray-600 dark:text-gray-300 rounded-md bg-white dark:bg-gray-700 focus:ring-blue-500 focus:border-blue-500 transition"
            >
              {[8, 16, 24, 32].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50 p-8 md:p-0">
          <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-3xl p-8 shadow-lg relative">
            {/* Modal Header */}
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                {creationMode
                  ? creationMode === "ai"
                    ? "AI-Generated Questions"
                    : "Create Survey Manually"
                  : "How do you want to create your survey?"}
              </h2>
              <button
                onClick={toggleModal}
                className="text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-200 transition duration-150"
                aria-label="Close Modal"
              >
                ✕
              </button>
            </div>

            {/* Initial Mode Selection */}
            {!creationMode && (
              <div className="flex flex-col space-y-4">
                <button
                  onClick={() => handleModeSelection("ai")}
                  className="px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 dark:hover:bg-green-500 transition"
                >
                  Use AI to Generate Questions
                </button>
                <button
                  onClick={() => handleModeSelection("manual")}
                  className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 dark:hover:bg-blue-500 transition"
                >
                  Create Manually
                </button>
              </div>
            )}

            {/* AI-Generated Questions Section */}
            {creationMode === "ai" && (
              <AIQuestionGenerator
                onQuestionsGenerated={() => setIsModalOpen(false)}
                onCancel={() => setCreationMode(null)}
                onSurveySaved={getAllSurveys}
              />
            )}

            {/* Manual Question Creation Section */}
            {creationMode === "manual" && (
              <ManualSurveyCreator
                onCancel={() => setCreationMode(null)}
                onSurveySaved={getAllSurveys}
              />
            )}
          </div>
        </div>
      )}

      {showPopUp && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div
            className="bg-white dark:bg-gray-800 rounded-xl p-6 w-[90%] max-w-sm text-center space-y-4 shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            {!selectedSurvey.active ? (
              <>
                <FaExclamationTriangle className="text-red-500 dark:text-red-400 text-5xl mx-auto" />
                <h2 className="text-2xl text-gray-900 dark:text-gray-100 font-semibold">
                  Deactivate Survey?
                </h2>
                <p className="text-gray-600 dark:text-gray-300">
                  This action will deactivate the selected survey.
                </p>
              </>
            ) : (
              <>
                <MdVerifiedUser className="text-green-500 dark:text-green-400 text-5xl mx-auto" />
                <h2 className="text-2xl text-gray-900 dark:text-gray-100 font-semibold">
                  Activate Survey?
                </h2>
                <p className="text-gray-600 dark:text-gray-300">
                  This action will activate the selected survey and deactivate
                  any active survey.
                </p>
              </>
            )}

            {/* Buttons Section */}
            <div className="flex justify-center space-x-4 mt-4">
              <button
                onClick={handleConfirmToggle}
                className="bg-blue-600 dark:bg-blue-500 text-white font-semibold px-8 py-2 rounded-[4px] hover:bg-blue-700 dark:hover:bg-blue-400 transition"
              >
                Yes
              </button>
              <button
                className="border border-red-400 dark:border-red-500 text-red-500 dark:text-red-400 font-semibold px-8 py-2 rounded-[4px] hover:bg-red-600 dark:hover:bg-red-500 hover:text-white transition"
                onClick={() => setShowPopUp(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Popup */}
      <DeleteConfirmationPopup
        isOpen={showDeletePopUp}
        onClose={() => setShowDeletePopUp(false)}
        onConfirm={handleDeleteSurvey}
        item={"Survey"}
      />

      {isExpired && <Subscription />}
    </>
  );
}

export default Survey;
