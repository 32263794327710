import { Link } from "react-router-dom";

function Subscription() {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 backdrop-blur-sm z-40 p-4">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 sm:p-8 text-center shadow-lg w-full max-w-md lg:max-w-lg">
        <h2 className="text-xl sm:text-2xl font-bold mb-4 text-gray-900 dark:text-white">
          Subscription Expired
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-6 text-sm sm:text-base">
          Your subscription has expired. Please renew to regain full access to the dashboard.
        </p>
        <Link to="/dashboard/setting/billing">
          <button
            aria-label="Go to Billing"
            className="bg-blue-500 text-white py-3 px-6 w-full sm:w-auto rounded-md hover:bg-blue-600 transition-colors"
          >
            Go to Billing
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Subscription;
