import { FaEnvelope, FaSms } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";

const CampaignSelectionModal = ({ onClose, onSelect }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 p-4">
      <div className="bg-white rounded-lg p-6 w-[600px] shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Choose email campaign type</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 text-xl">&times;</button>
        </div>
        <p className="text-gray-600 mb-4">
          Select one of the campaign types depending on your campaign goals.
        </p>

        <div className="grid grid-cols-2 gap-4">
          {/* Email Campaign Card */}
          <div
            className="border border-gray-300 p-4 rounded-lg flex flex-col hover:shadow-lg cursor-pointer transition"
            onClick={() => {
              onSelect("email");
              onClose();
            }}
          >
            <div className="flex items-center space-x-2">
              <span className="bg-yellow-100 p-2 rounded-lg">
                <FaEnvelope className="text-yellow-500 text-xl" />
              </span>
              <h3 className="text-lg font-semibold">Email Campaign</h3>
            </div>
            <p className="text-gray-500 mt-2 text-sm">
              To the subscriber you have chosen, create and send a normal email campaign.
            </p>
            <div className="flex justify-end mt-2">
              <IoIosArrowForward className="text-gray-500 text-lg" />
            </div>
          </div>

          {/* SMS Campaign Card */}
          <div
            className="border border-gray-300 p-4 rounded-lg flex flex-col hover:shadow-lg cursor-pointer transition"
            onClick={() => {
              onSelect("sms");
              onClose();
            }}
          >
            <div className="flex items-center space-x-2">
              <span className="bg-blue-100 p-2 rounded-lg">
                <FaSms className="text-blue-500 text-xl" />
              </span>
              <h3 className="text-lg font-semibold">SMS Campaign</h3>
            </div>
            <p className="text-gray-500 mt-2 text-sm">
              To the subscriber you have chosen, create and send a normal SMS campaign.
            </p>
            <div className="flex justify-end mt-2">
              <IoIosArrowForward className="text-gray-500 text-lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignSelectionModal;
