import { Info } from "lucide-react";
import { useEffect, useState } from "react";
import WordCloud from "react-d3-cloud";
import { useTimeRange } from "../../context/TimeRangeContext";
const WordCloudChart = ({ data }) => {
  const [max, setMax] = useState(100);
  useEffect(() => {
    if (data.length > 0) {
      const values = data.map((item) => item.value);
      setMax(Math.max(...values));
    }
  }, [data]);

  const fontSize = (word) => (100 * word.value) / max;
  const rotate = () => 0;

  return (
    <WordCloud
      width={1000}
      height={400}
      data={data}
      fontSize={fontSize}
      rotate={rotate}
      padding={3}
      spiral="rectangular"
      random={Math.random}
    />
  );
};

const WordChart = () => {
  const [wordData, setWordData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { timeRange } = useTimeRange();

  useEffect(() => {
    const fetchWordData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `https://zola.technology/api/company/surveys/word-analysis/?filter=${timeRange}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch word analysis data");
        }
        const data = await response.json();

        // ✅ Transform API response to array format
        const transformedData = Object.entries(data.word_frequencies || {}).map(
          ([text, value]) => ({
            text,
            value,
          })
        );

        console.log(transformedData);

        setWordData(transformedData);
      } catch (error) {
        console.error("Error fetching word data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWordData();
  }, [timeRange]);

  return (
    <div className="mx-auto bg-white dark:bg-gray-900 shadow-lg rounded-lg p-6">
      {/* Header with gradient background */}
      <div className="flex justify-between items-center text-gray-600 dark:text-gray-300 mb-2">
        <h3 className="text-lg font-semibold">Word Analysis Chart</h3>
        <span className="text-xl cursor-pointer dark:text-gray-400">⋮</span>
      </div>
      {loading ? (
        <p className="text-center text-gray-600">Loading word cloud...</p>
      ) : wordData.length > 0 ? (
        <WordCloudChart data={wordData} />
      ) : (
        <div className="flex flex-col items-center justify-center text-center dark:bg-gray-800 p-6 space-y-4">
          <div className="flex items-center justify-center bg-blue-100 dark:bg-blue-900 p-3 rounded-full">
            <Info className="w-10 h-10 text-blue-500 dark:text-blue-400" />
          </div>

          <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">
            No Word Cloud Data Available
          </h3>

          <p className="text-gray-600 dark:text-gray-400">
            The word cloud data is currently unavailable. This could be due to
            no survey submissions {timeRange === "day" ? "today" : `this ${timeRange}`}.
          </p>
        </div>
      )}
    </div>
  );
};

export default WordChart;
