import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getStoredTokens,
  storeTokens,
  clearTokens,
  refreshTokenRequest,
} from "./authHelpers";

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("token"));
  const [loggedOut, setLoggedOut] = useState(false);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);

  const navigate = useNavigate();

  let intervalId;
  let inactivityTimeoutId;
  let modalClearTimeoutId;

  const INACTIVITY_LIMIT = 60 * 60 * 1000; // 1 hour
  const SESSION_EXPIRED_MODAL_DURATION = 1 * 60 * 1000; // 5 minutes

  const refreshToken = async () => {
    const success = await refreshTokenRequest();
    if (!success) logout();
  };

  const checkTokenExpiry = () => {
    const { expiryTime } = getStoredTokens();
    const currentTime = new Date().getTime();

    if (expiryTime && currentTime >= expiryTime - 10 * 60 * 1000) {
      refreshToken();
    }
  };

  const checkTokensAndStartCountdown = () => {
    const { token, refreshToken } = getStoredTokens();
    if (token && refreshToken) {
      setIsAuthenticated(true);
      checkTokenExpiry();
      intervalId = setInterval(checkTokenExpiry, 5 * 60 * 1000);
    }
  };

  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeoutId);
    inactivityTimeoutId = setTimeout(() => {
      logout(true); // due to inactivity
    }, INACTIVITY_LIMIT);
  };

  const setupInactivityListeners = () => {
    const events = ["mousemove", "keydown", "mousedown", "scroll", "touchstart"];
    events.forEach((event) => window.addEventListener(event, resetInactivityTimeout));
    resetInactivityTimeout(); // initial start
  };

  const removeInactivityListeners = () => {
    const events = ["mousemove", "keydown", "mousedown", "scroll", "touchstart"];
    events.forEach((event) => window.removeEventListener(event, resetInactivityTimeout));
    clearTimeout(inactivityTimeoutId);
  };

  const login = ({ username, token, firstName, rtoken }) => {
    setCurrentUser({ username, token, firstName });
    setIsAuthenticated(true);
    storeTokens(token, rtoken);
    setLoggedOut(false);
    checkTokenExpiry();
    setupInactivityListeners();
  };

  const logout = (dueToInactivity = false) => {
    setCurrentUser(null);
    setIsAuthenticated(false);
    clearTokens();
    clearInterval(intervalId);
    clearTimeout(inactivityTimeoutId);
    removeInactivityListeners();
    localStorage.setItem("logout", Date.now());
    setLoggedOut(true);

    if (dueToInactivity) {
      setShowSessionExpiredModal(true);

      // Delay navigation until modal duration ends
      modalClearTimeoutId = setTimeout(() => {
        setShowSessionExpiredModal(false);
        navigate("/"); // only navigate after modal disappears
      }, SESSION_EXPIRED_MODAL_DURATION);
    } else {
      setShowSessionExpiredModal(false);
      navigate("/");
    }
  };

  useEffect(() => {
    checkTokensAndStartCountdown();
    return () => {
      clearInterval(intervalId);
      clearTimeout(modalClearTimeoutId);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setupInactivityListeners();
    }
    return () => {
      removeInactivityListeners();
    };
  }, [isAuthenticated]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "logout") {
        logout();
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  return (
    <AuthContext.Provider
      value={{ currentUser, setCurrentUser, login, logout, isAuthenticated }}
    >
      {children}

      {showSessionExpiredModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h2>Session Expired</h2>
            <p>You were logged out after 1 hour of inactivity.</p>
          </div>
        </div>
      )}
    </AuthContext.Provider>
  );
};

// Simple inline modal styling
const styles = {
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "8px",
    textAlign: "center",
    width: "300px",
    boxShadow: "0 0 10px rgba(0,0,0,0.2)",
  },
};
