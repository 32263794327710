import { Outlet } from "react-router-dom";
import MainLayout from "./HQDashboard/components/dashboard";
import { BranchProvider } from "./HQDashboard/context/BranchContext";
import DashboardProviders from "./DashboardProviders";

function HQDashboardLayout() {
  return (
    <DashboardProviders>
      <MainLayout>
        <BranchProvider>
          <Outlet />
        </BranchProvider>
      </MainLayout>
    </DashboardProviders>
  );
}

export default HQDashboardLayout;
