import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import OverallPerformance from "./TeamPerformance/OverallPerformance";
import EmployeeOverallPerformance from "./TeamPerformance/EmployeeOverallPerformance";
import Overview from "./Overview";
import FeedbackSection from "./Analytics/FeedbackSection";
import AnalyticsStatCard from "./AnalyticsStatCards";
import { toPng, toCanvas } from "html-to-image";
import Subscription from "./Subscription";
import AreaChart from "./Surveyanalytics/SurveyChart";
import CsatChart from "./Surveyanalytics/CsatChart";

const Analytics = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const { isExpired } = useOutletContext();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.href = "/login";
    }

    const getFeedBackData = async () => {
      try {
        const response = await fetch(
          "https://zola.technology/api/company/feedback/list-categorised/",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setFeedbackData(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching feedback data:", error);
      }
    };

    getFeedBackData();
  }, []);

  // Retrieve userData from localStorage
  const userData = JSON.parse(localStorage.getItem("userData"));

  // Get company_type from userData (default to an empty string if userData is null)
  const companyType = userData?.company_type || "";

  const handleExport = async (format) => {
    const element = document.getElementById("exportable-analytics-content");

    if (format === "pdf") {
      const { default: jsPDF } = await import("jspdf");

      const pdf = new jsPDF();
      const canvas = await toCanvas(element);
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
      pdf.save("analytics.pdf");
    } else if (format === "doc") {
      const htmlContent = element.innerHTML;
      const blob = new Blob([htmlContent], { type: "application/msword" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "analytics.doc";
      link.click();
    } else if (format === "image") {
      const dataUrl = await toPng(element);
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "analytics.png";
      link.click();
    }
  };

  return (
    <div
      className="md:p-2 bg-[#F5F8FF] dark:bg-gray-900"
      id="exportable-analytics-content"
    >
      <div className={`relative ${isExpired ? "blur-sm" : ""}`}>
        <Overview title="Analytics" onExportClick={handleExport} />

        <FeedbackSection />

        <OverallPerformance />

        <AnalyticsStatCard />

        {companyType === "team" && <EmployeeOverallPerformance />}

        {/* <h1 className="text-lg font-semibold text-gray-800 bg-white mb-4 p-4">Survey Analysis</h1> */}
        <div className="bg-white dark:bg-gray-800 p-4 my-8 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
            Net Promoter Score (NPS)
          </h2>
          <AreaChart />
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
            Customer Satisfaction (CSAT)
          </h2>
          <CsatChart />
        </div>

        <div className="grid gap-2"></div>
      </div>
      {isExpired && <Subscription />}
    </div>
  );
};

export default Analytics;
