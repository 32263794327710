import { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { BranchContext } from "../../context/BranchContext";

const TotalTapsChart = () => {
  const { spreadTaps, spreadTapsLoading } = useContext(BranchContext);
  const [chartData, setChartData] = useState({
    categories: [],
    data: [],
  });

  useEffect(() => {
    if (!spreadTapsLoading && spreadTaps && spreadTaps.periods) {
      // Ensure spreadTaps is valid and contains periods
      const categories = spreadTaps.periods.map((item) => item.period || "Unknown");
      const data = spreadTaps.periods.map((item) => item.taps || 0);

      setChartData({
        categories,
        data,
      });
    }
  }, [spreadTaps, spreadTapsLoading]);

  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false, // Hides the toolbar
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false, // Disables data labels
    },
    tooltip: {
      enabled: true,
      theme: "dark", // Makes the tooltip dark
    },
    xaxis: {
      categories: chartData.categories, // Correctly mapped categories
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => Math.round(val), // Formats the y-axis labels
      },
      title: {
        text: "Taps",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
    },
    title: {
      text: "Total Taps",
      align: "center",
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    fill: {
      colors: ["#7a5af8"], // Sets the bar color
    },
  };

  const chartSeries = [
    {
      name: "Total taps",
      data: chartData.data, // Correctly mapped data
    },
  ];

  return (
    <div className="flex p-6 justify-center items-center bg-white rounded-lg">
      <div className="w-full">
        {spreadTapsLoading ? (
          <p>Loading...</p>
        ) : chartData.categories.length > 0 ? (
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={350}
            width="100%" // Ensures the chart takes the full width of the container
          />
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
};

export default TotalTapsChart;
