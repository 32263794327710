import { useEffect, useState } from "react";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { useTimeRange } from "../../context/TimeRangeContext";

const AnalyticsStatCard = () => {
  const [performanceData, setPerformanceData] = useState();
  const [reviewsData, setReviewsData] = useState();
  const [spreadTaps, setSpreadTaps] = useState();
  const { timeRange } = useTimeRange();
  const [rating, setRating] = useState(0);

  const itemKey = "userData"; // Key used to store the object in localStorage
  const storedItem = localStorage.getItem(itemKey);
  const userData = storedItem ? JSON.parse(storedItem) : null;

  if (!storedItem) {
    console.warn("No item found in localStorage with the key:", itemKey);
  }

  const fetchAnalyticsData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found in localStorage");
        return;
      }

      // Fetch performance and rating data
      const analyticsResponse = await fetch(
        "https://zola.technology/api/company/analytics/performance-and-rating/get/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (analyticsResponse.ok) {
        const analyticsData = await analyticsResponse.json();
        console.log(analyticsData);
        setPerformanceData(analyticsData.performance_score);
      } else {
        console.error(
          "Failed to fetch analytics data",
          analyticsResponse.status
        );
      }

      // Fetch reviews data
      const reviewsResponse = await fetch(
        "https://zola.technology/api/company/reviews/list",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (reviewsResponse.ok) {
        const reviewData = await reviewsResponse.json();
        console.log(reviewData);
        setReviewsData(reviewData.reviews);
        setRating(reviewData.rating || 0);
      } else {
        console.error("Failed to fetch reviews data", reviewsResponse.status);
      }

      // Fetch spread taps data with dynamic filter
      const spreadTapsResponse = await fetch(
        `https://zola.technology/api/company/dashboard/data/employees-taps-issues-performance/?filter=${timeRange}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (spreadTapsResponse.ok) {
        const spreadTapsData = await spreadTapsResponse.json();
        console.log(spreadTapsData);
        setSpreadTaps(spreadTapsData);
      } else {
        console.error(
          "Failed to fetch spread taps data",
          spreadTapsResponse.status
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAnalyticsData();
  }, [timeRange]);

  console.log(spreadTaps?.performance, "spread Taps performance");
  const statsData = [
    {
      title: "Average Performance",
      stat: `${spreadTaps?.performance.score || 0}%`,
      growth: spreadTaps?.performance.change.total_change,
      percentage: `${spreadTaps?.performance.change.percentage_change}% vs last ${timeRange}`,
      change: spreadTaps?.performance.change.type,

      chartData: [
        { value: 10, change: spreadTaps?.performance.change.type },
        { value: 20, change: spreadTaps?.performance.change.type },
        { value: 15, change: spreadTaps?.performance.change.type },
        { value: 25, change: spreadTaps?.performance.change.type },
      ],
    },
    {
      title: "Active Cards",
      stat: `${userData?.number_of_cards || 0}`,
      growth: "",
      percentage: "Total cards",
      chartData: [
        { value: 15, change: "up" },
        { value: 10, change: "down" },
        { value: 20, change: "up" },
        { value: 30, change: "up" },
      ],
    },
    {
      title: "Review Count",
      stat: `${reviewsData || 0}`,
      growth: `${rating}`,
      percentage: `All time reviews`,
      chartData: [
        { value: 10, change: "down" },
        { value: 15, change: "up" },
        { value: 20, change: "up" },
        { value: 25, change: "up" },
      ],
    },
    {
      title: "Total Taps",
      stat: `${spreadTaps?.taps.count || 0}`,
      growth: spreadTaps?.taps.change.total_change,
      percentage: `${spreadTaps?.taps.change.percentage_change}% vs last ${timeRange}`,
      change: spreadTaps?.taps.change.type,
      chartData: [
        { value: 10, change: spreadTaps?.taps.change.type },
        { value: 20, change: spreadTaps?.taps.change.type },
        { value: 15, change: spreadTaps?.taps.change.type },
        { value: 30, change: spreadTaps?.taps.change.type },
      ],
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-8">
      {statsData.map((data, index) => (
        <div
          key={index}
          className="bg-white dark:bg-gray-800 rounded-lg p-6 flex flex-col gap-4 justify-between shadow-sm"
        >
          <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">
            {data.title}
          </h3>

          <div className="flex items-end justify-between">
            <div className="flex flex-col">
              <div className="flex gap-2 items-baseline mb-2">
                <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                  {data.stat}
                </h1>
                <span
                  className={`text-sm font-medium ${
                    data.change === "Increase"
                      ? "text-green-600 dark:text-green-400"
                      : data.change === "Decrease"
                      ? "text-red-600 dark:text-red-400"
                      : "text-gray-600 dark:text-gray-400"
                  }`}
                >
                  {data.growth}
                </span>
              </div>
              <p className="text-gray-500 dark:text-gray-400 text-xs">
                {data.percentage}
              </p>
            </div>

            <div className="relative flex items-end gap-2 ml-6 h-16">
              {data.chartData.map((bar, idx) => (
                <div className="relative" key={idx}>
                  {idx === data.chartData.length - 1 && (
                    <div
                      className={`absolute -top-4 left-1/2 transform -translate-x-1/2 bg-green-100 dark:bg-green-900 p-1 rounded-full ${
                        bar.change === "Increase"
                          ? "text-green-600 dark:text-green-400"
                          : "text-red-600 dark:text-red-400"
                      }`}
                    >
                      {bar.change === "Increase" ? (
                        <FaArrowCircleUp className="text-xs" />
                      ) : (
                        <FaArrowCircleDown className="text-xs" />
                      )}
                    </div>
                  )}
                  <div
                    className={`w-4 rounded-sm ${
                      idx === data.chartData.length - 1
                        ? "bg-indigo-500 dark:bg-indigo-400"
                        : "bg-gray-200 dark:bg-gray-700"
                    }`}
                    style={{ height: `${bar.value * 3}px` }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AnalyticsStatCard;
