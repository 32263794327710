import { createContext, useState, useEffect, useContext } from "react";
import { useAuth } from "./AuthContext"; // Import useAuth hook

const CampaignContext = createContext();

export const CampaignProvider = ({ children }) => {
  const { isAuthenticated, currentUser } = useAuth(); // Get auth status & user data
  const [campaigns, setCampaigns] = useState([]);
  const [paginatedCampaigns, setPaginatedCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const fetchAllCampaigns = async () => {
    if (!isAuthenticated || !currentUser?.token) return; // Ensure authentication & token

    try {
      const response = await fetch("https://zola.technology/api/company/campaigns/list/", {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) throw new Error("Failed to fetch campaigns");

      const data = await response.json();
      console.log("Fetched Campaigns Data:", data); // Debugging log

      setCampaigns(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching all campaigns:", error);
    }
  };

  const fetchPaginatedCampaigns = async (page = currentPage) => {
    if (!isAuthenticated || !currentUser?.token) return; // Ensure authentication & token

    setLoading(true);
    try {
      const response = await fetch(
        `https://zola.technology/api/company/campaigns/list/?page=${page}&page_size=5`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch paginated campaigns");

      const data = await response.json();
      console.log("Fetched Paginated Campaigns:", data); // Debugging

      setPaginatedCampaigns(data?.data || []);
      setTotalPages(data?.total_pages || 1);
    } catch (error) {
      console.error("Error fetching paginated campaigns:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCampaigns();
  }, [isAuthenticated, currentUser]); // Fetch campaigns when auth status or token changes

  useEffect(() => {
    fetchPaginatedCampaigns();
  }, [currentPage, isAuthenticated, currentUser]); // Fetch paginated campaigns when page or auth state changes

  return (
    <CampaignContext.Provider
      value={{
        campaigns,
        paginatedCampaigns,
        currentPage,
        setCurrentPage,
        totalPages,
        fetchAllCampaigns,
        fetchPaginatedCampaigns,
        loading,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

export const useCampaigns = () => {
  return useContext(CampaignContext);
};
