import { Link } from "react-router-dom";
import zolaLogo from "./zolalogo.png";
import { FaFacebookF, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-white text-gray-700 py-10 px-6 font-figtree">
      <div className="px-8 mx-auto flex flex-col md:flex-row justify-between items-center md:items-start space-y-8 md:space-y-0">
        <div className="flex flex-col items-center gap-4 md:items-start ml-4">
          <img src={zolaLogo} alt="Logo" className="w-48 object-cover mb-4" />
          <p className="font-bold text-2xl text-center md:text-start">
            Improve Client Engagement, Amplify Your Success.
          </p>
          <div className="flex space-x-4 gap-4">
            <a
              href="https://www.linkedin.com/company/zola-reviews/"
              target="_blank"
              className="text-gray-700 text-3xl hover:text-gray-900"
              aria-label="Visit Zola Reviews on LinkedIn"
            >
              <FaLinkedin />
            </a>

            <a
              href="https://web.facebook.com/profile.php?id=61561627283137"
              target="_blank"
              className="text-gray-700 text-3xl hover:text-gray-900"
              aria-label="Visit Zola Reviews on Facebook"
            >
              <FaFacebookF />
            </a>
          </div>

          <p className="text-sm text-black leading-[18px] mt-4">
            © {new Date().getFullYear()} Zola. All rights reserved.
          </p>
        </div>

        {/* Middle Section: Sitemap */}
        <div className="flex flex-col items-center md:items-start">
          <h5 className="font-semibold text-[20px] mb-4">Sitemap</h5>
          <ul className="grid gap-2 space-y-2">
            {/* <li><a href="#" className="text-[20px] font-medium leading-6 hover:underline">How it Works</a></li> */}
            {/* <li><a href="#" className="text-[20px] font-medium leading-6 hover:underline">Reviews</a></li> */}
            {/* <li><a href="#" className="text-[20px] font-medium leading-6 hover:underline">Careers</a></li> */}
            <li>
              <Link
                to="/terms-of-service"
                className="text-[20px] font-medium leading-6 hover:underline"
              >
                Terms of Service
              </Link>
            </li>
            {/* <li><a href="#" className="text-[20px] font-medium leading-6 hover:underline">Company Terms of Use</a></li> */}
            {/* <li><a href="#" className="text-[20px] font-medium leading-6 hover:underline">Privacy</a></li> */}
            {/* <li>
              <Link
                to="/privacy"
                className="text-[20px] font-medium leading-6 hover:underline"
              >
                Privacy Policy
              </Link>
            </li> */}
          </ul>
        </div>

        {/* Right Section: Contact */}
        <div className="flex flex-col items-center md:items-start">
          <h5 className="font-semibold text-[20px] mb-4">Contact</h5>
          <div className="grid gap-2 space-y-2">
            <p>
              <a
                href="mailto:sales@zolatap.com"
                className="text-[20px] font-medium leading-6 hover:underline"
              >
                salesteam@zolatap.com
              </a>
            </p>
            <p>
              <a
                href="tel:+18013618524"
                className="text-[20px] font-medium leading-6 hover:underline"
              >
                (801) 361-8524
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;