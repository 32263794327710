import { useState } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// API endpoint mapping for different accounts
const API_ENDPOINTS = {
  "Google Business Profile":
    "https://zola.technology/api/company/google-reviews/add-google-profile-url/",
  Yelp: "https://zola.technology/api/company/yelp/add-review-url/",

  Tripadvisor:
    "https://zola.technology/api/company/trip-advisor/add-location-id/",
    "Apartments.com": "https://zola.technology/api/company/apartments-com-reviews/add-property-listing-key/",
    
};

// Sign-in URLs for each account
const SIGN_IN_URLS = {
  "Google Business Profile": "https://zola.technology/api/google/login/",
  Facebook: "https://www.facebook.com/login",
  Yelp: "https://www.yelp.com/login",
  Zillow: "https://www.zillow.com/user/login/",
  Tripadvisor: "#", // Disabled Sign-in for TripAdvisor
  Healthgrades: "https://www.healthgrades.com/login",
};

const ConnectionModal = ({ selectedAccount, onClose, onSuccess }) => {
  const [profileUrl, setProfileUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  if (!selectedAccount) return null;

  // Get the correct API endpoint for the selected account
  const apiEndpoint = API_ENDPOINTS[selectedAccount.name];
  const signInUrl = SIGN_IN_URLS[selectedAccount.name];

  // Check if input should be disabled (Google Business Profile)
  const isGoogleBusiness = selectedAccount.name === "Google Business Profile";
  const isTripAdvisor = selectedAccount.name === "Tripadvisor";
  const isYelp = selectedAccount.name === "Yelp";
  const isApartment = selectedAccount.name === "Apartments.com";

  // Function to handle Google sign-in
  const handleGoogleSignIn = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("Authentication token not found. Please log in.");
      return;
    }

    try {
      // Fetch the Google login URL from the API
      const response = await fetch(
        "https://zola.technology/api/google/login/",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok && data?.google_login_url) {
        console.log("Google Login URL:", data.google_login_url); // Log the login URL
        window.location.href = data.google_login_url; // Redirect user to Google login
      } else {
        throw new Error(
          data?.message || "Failed to retrieve Google login URL."
        );
      }
    } catch (error) {
      console.error("Error fetching Google login URL:", error.message);
      toast.error(error.message || "An error occurred while processing login.");
    }
  };

  // Handle POST request when Enter is pressed
  const handleSubmit = async () => {
    if (!profileUrl.trim() && !isGoogleBusiness) {
      setError("Profile URL cannot be empty.");
      return;
    }
  
    setLoading(true);
  
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Authentication token not found. Please log in.");
      setLoading(false);
      return;
    }
  
    try {
      // Determine the correct field name for API request
      const requestBody =
        isTripAdvisor
          ? { tripadvisor_url: profileUrl }
          : isYelp
          ? { yelp_url: profileUrl }
          : isGoogleBusiness
          ? { google_profile_url: profileUrl }
          : isApartment
          ? { apartments_url: profileUrl } // Apartments.com field
          : { profile_url: profileUrl };
  
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        toast.success(
          `${selectedAccount.name} Profile URL linked successfully!`
        );
        setProfileUrl("");
        onClose();
        onSuccess();
      } else {
        throw new Error(
          data?.tripadvisor_url?.[0] ||
            data?.google_profile_url?.[0] ||
            data?.yelp_url?.[0] ||
            data?.apartments_url?.[0] || // Handle Apartments.com errors
            data?.detail?.split('.')[0] ||
            `Failed to link ${selectedAccount.name} profile.`
        );
      }
    } catch (error) {
      toast.error(
        error.message ||
          `An error occurred while linking ${selectedAccount.name} profile.`
      );
    } finally {
      setLoading(false);
    }
  };
  

  const getInfoBox = () => {
    if (isGoogleBusiness) {
      return (
        <div className="bg-blue-100 border-l-4 border-blue-500 p-3 text-blue-700 rounded-md text-sm mb-4">
          <p className="font-semibold">
            How to get your Google Business review link:
          </p>
          <ol className="list-decimal list-inside mt-2">
            <li>
              Go to{" "}
              <a
                href="https://www.google.com/business/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                Google Business Profile Manager
              </a>
            </li>
            <li>Sign in with the Google account that manages your business</li>
            <li>Search for your business</li>
            <li>Copy "ask for reviews" link</li>
            <li>Paste link below</li>
          </ol>
        </div>
      );
    } else if (isTripAdvisor) {
      return (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 p-3 text-yellow-700 rounded-md text-sm mb-4">
          <p className="font-semibold">
            How to find your TripAdvisor profile URL:
          </p>
          <ol className="list-decimal list-inside mt-2">
            <li>
              Visit{" "}
              <a
                href="https://www.tripadvisor.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                TripAdvisor.com
              </a>
            </li>
            <li>Search for your company name</li>
            <li>Click on your business page</li>
            <li>Click the "Review" button</li>
            <li>
              Copy the URL from your browser's search bar and paste it below
            </li>
          </ol>
        </div>
      );
    } else if (isYelp) {
      return (
        <div className="bg-red-100 border-l-4 border-red-500 p-3 text-red-700 rounded-md text-sm mb-4">
          <p className="font-semibold">How to find your Yelp review link:</p>
          <ol className="list-decimal list-inside mt-2">
            <li>
              Visit{" "}
              <a
                href="https://www.yelp.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                Yelp.com
              </a>
            </li>
            <li>Search for your business</li>
            <li>Click on your business listing</li>
            <li>Click the "Write a Review" button</li>
            <li>
              Copy the URL from your browser’s search bar and paste it below
            </li>
          </ol>
        </div>
      );
    } else if (selectedAccount.name === "Apartments.com") {
      return (
        <div className="bg-green-100 border-l-4 border-green-500 p-3 text-green-700 rounded-md text-sm mb-4">
          <p className="font-semibold">
            How to find your Apartments.com listing key:
          </p>
          <ol className="list-decimal list-inside mt-2">
            <li>
              Visit{" "}
              <a
                href="https://www.apartments.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                Apartments.com
              </a>
            </li>
            <li>Search for your property listing</li>
            <li>Click on your listing</li>
            <li>Find the "Property Listing Key" in the URL or page details</li>
            <li>Copy and paste it below</li>
          </ol>
        </div>
      );
    }
    return null;
  };
  

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full relative">
        <button
          className="absolute top-3 right-3 text-gray-600 text-xl"
          onClick={onClose}
        >
          <IoClose />
        </button>

        <h2 className="text-xl font-semibold text-center mb-4">
          How would you like to link your {selectedAccount.name}?
        </h2>

        {/* Sign-in Button */}
        {isGoogleBusiness ? (
          <button
            onClick={handleGoogleSignIn}
            className="flex items-center justify-center w-full py-2 rounded-lg font-medium text-lg bg-black text-white"
          >
            <span className="mr-2 text-2xl">{selectedAccount.icon}</span>
            Sign in with {selectedAccount.name}
          </button>
        ) : (
          <a
            href={signInUrl}
            target={isTripAdvisor ? "_self" : "_blank"}
            rel="noopener noreferrer"
            // className={`flex items-center justify-center w-full py-2 rounded-lg font-medium text-lg ${
            //   isTripAdvisor
            //     ? "bg-gray-300 text-gray-500 cursor-not-allowed"
            //     : "bg-black text-white"
            // }`}
            disabled={isTripAdvisor}
          >
            {/* <span className="mr-2 text-2xl">{selectedAccount.icon}</span> */}
            {isTripAdvisor || isYelp
              ? ""
              : `Sign in with ${selectedAccount.name}`}
          </a>
        )}

        {!isTripAdvisor ||
          (!isYelp && (
            <p className="text-sm text-gray-600 text-center mt-2">
              This method will allow you to respond to {selectedAccount.name}{" "}
              reviews from your dashboard.
            </p>
          ))}

        {/* TripAdvisor and yelp Instructions */}
        {getInfoBox()}

        {/* Input Field */}
        <div className="mt-4">
          <input
            type="text"
            placeholder={`Paste your ${selectedAccount.name} profile URL here...`}
            className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
            value={profileUrl}
            onChange={(e) => setProfileUrl(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
            disabled={loading}
          />
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
          <button
            className="mt-2 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 disabled:bg-gray-300"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Linking..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnectionModal;
