import Chart from "react-apexcharts";
import { useTimeRange } from "../../../context/TimeRangeContext";

const EmployeePerformanceChart = ({ data }) => {
  const { timeRange } = useTimeRange(); // Get timeRange from context
  const periods = data?.periods || [];

  // Format labels based on timeRange
  const formattedLabels = periods.map((item) => {
    if (!item.period) return "Unknown";

    if (timeRange === "month") {
      // "Week 08: 01 March 2025" → "Week 08"
      return item.period.split(":")[0].trim();
    }

    if (timeRange === "week") {
      // "Monday 17 March 2025" → "Mon"
      return item.period.split(" ")[0].slice(0, 3);
    }

    if (timeRange === "year") {
      // "January 2025" or "January" → "Jan"
      return item.period.split(" ")[0].slice(0, 3);
    }

    return item.period; // fallback
  });

  const performanceData = periods.map((item) => item.taps);

  const series = [
    {
      name: "Performance",
      data: performanceData,
    },
  ];

  const options = {
    chart: {
      type: "area",
      height: 350,
      toolbar: { show: false },
      zoom: { enabled: false },
      pan: { enabled: false },
    },
    stroke: {
      curve: "smooth",
      width: 3,
      colors: ["#1565C0"],
    },
    markers: {
      size: 5,
      colors: ["#fff"],
      strokeColors: "#1565C0",
      strokeWidth: 3,
      hover: { size: 7 },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 1,
        gradientToColors: ["#64B5F6"],
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
    tooltip: {
      theme: "dark",
      marker: { show: false },
      y: {
        formatter: (val) => `${val.toLocaleString()}`,
      },
    },
    xaxis: {
      categories: formattedLabels,
      labels: { style: { colors: "#666", fontSize: "12px" } },
    },
    yaxis: {
      labels: {
        formatter: (val) => `${val.toLocaleString()}`,
      },
    },
    
    grid: {
      borderColor: "#E0E0E0",
      strokeDashArray: 5,
    },
  };

  return (
    <div style={{ width: "100%", background: "#fff", padding: "20px", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
      <h3 style={{ marginBottom: "10px", color: "#333" }}>Employee Performance Overview</h3>
      <Chart options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default EmployeePerformanceChart;
