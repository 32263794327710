import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import { FiHome, FiMessageCircle, FiHelpCircle } from "react-icons/fi";

const Chatbot = () => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [visitedQuestions, setVisitedQuestions] = useState([]); // Track visited questions
  const [activeTab, setActiveTab] = useState("home"); // State to track the active tab

  const handleSend = (e) => {
    e.preventDefault();
    if (input.trim()) {
      const newMessage = { text: input, sender: "user" };
      setMessages((prev) => [...prev, newMessage]);
      setInput("");

      const responseMessage = {
        text: "Your query is received. Awaiting support team's response.",
        sender: "bot",
      };
      setMessages((prev) => [...prev, responseMessage]);
    }
  };

  const handlePresetQuestion = (question) => {
    setVisitedQuestions((prev) => [...prev, question]); // Mark question as visited

    const newMessage = { text: question, sender: "user" };
    setMessages((prev) => [...prev, newMessage]);

    let botResponse = "I'm not sure how to respond to that.";
    if (question === "What are the hours of operation?") {
      botResponse =
        "Our hours of operation are 24/7. You can reach us anytime!";
    } else if (question === "How do I schedule an appointment?") {
      botResponse =
        "You can schedule an appointment through our website or by calling our office at **(123) 456-7890**.";
    } else if (question === "How can I provide feedback?") {
      botResponse =
        "You can provide feedback through our feedback form on the website or email us at **feedback@example.com**.";
    } else if (question === "What services do you offer?") {
      botResponse =
        "We offer a range of services, including:\n- Routine check-ups\n- Emergency care\n- Specialized consultations\n- Telehealth services";
    } else if (question === "How can I contact support?") {
      botResponse =
        "You can contact support via email at **support@example.com** or call **(123) 456-7890**.";
    }

    const responseMessage = { text: botResponse, sender: "bot" };
    setMessages((prev) => [...prev, responseMessage]);
  };

  const handleClickToggle = () => {
    setOpen((prevOpen) => {
      if (prevOpen) {
        setMessages([]); // Reset messages if closing the chatbot
        setVisitedQuestions([]); // Reset visited questions
      }
      return !prevOpen; // Toggle the open state
    });
  };

  return (
    <div>
      <button
        className="fixed bottom-4 right-4 z-10 flex items-center justify-center p-4 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out"
        onClick={handleClickToggle}
        aria-label={open ? "Close message" : "Open message"}
        aria-expanded={open ? "true" : "false"}
      >
        {open ? (
          <FaTimes className="text-2xl" />
        ) : (
          <FaMessage className="text-2xl" />
        )}
      </button>

      {open && (
        <div className="fixed bottom-20 right-4 z-10 bg-white shadow-lg rounded-lg w-[32rem] h-[520px] flex flex-col">
          {/* Header */}
          <div className="bg-blue-600 text-white rounded-t-lg p-4 flex items-center justify-between">
            <div>
              <h3 className="font-bold">Hi there 👋</h3>
              <p className="text-sm">How can we help?</p>
            </div>
            <div className="flex -space-x-2">
              <img
                src="https://via.placeholder.com/24"
                alt="profile1"
                className="w-8 h-8 rounded-full border-2 border-white"
              />
              <img
                src="https://via.placeholder.com/24"
                alt="profile2"
                className="w-8 h-8 rounded-full border-2 border-white"
              />
            </div>
          </div>

          {/* Content Area */}
          <div className="h-full overflow-y-auto p-2 border border-gray-200 flex-grow">
            {activeTab === "home" && (
              <>
                <h4 className="text-lg font-bold mb-2">Select a Question:</h4>
                <ul className="list-disc ml-5 space-y-2">
                  {[
                    "What are the hours of operation?",
                    "How do I schedule an appointment?",
                    "How can I provide feedback?",
                    "What services do you offer?",
                    "How can I contact support?",
                  ].map((question, index) => (
                    <li
                      key={index}
                      onClick={() => handlePresetQuestion(question)}
                      className={`cursor-pointer ${
                        visitedQuestions.includes(question)
                          ? "text-gray-400"
                          : "text-blue-600"
                      } hover:underline`}
                    >
                      {question}
                    </li>
                  ))}
                </ul>
              </>
            )}

            {activeTab === "messages" && (
              <>
                {messages.length === 0 ? (
                  <p className="text-gray-500">
                    How can we assist you today? Type a message below to start.
                  </p>
                ) : (
                  messages.map((msg, index) => (
                    <p
                      key={index}
                      className={`p-2 my-1 rounded-lg ${
                        msg.sender === "user"
                          ? "bg-blue-100 text-blue-600 self-end"
                          : "bg-gray-100 text-gray-800"
                      }`}
                    >
                      {msg.text}
                    </p>
                  ))
                )}
              </>
            )}

            {activeTab === "help" && (
              <div>
                <h4 className="text-lg font-bold">Help Section</h4>
                <p>
                  Find answers to common questions here, or ask the chatbot
                  directly.
                </p>
              </div>
            )}
          </div>

          {/* Footer with Input and Tabs */}
          <div className="p-4 border-t border-gray-200 flex justify-between items-center">
            {activeTab === "messages" && (
              <form onSubmit={handleSend} className="flex w-full">
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  className="flex-grow border border-gray-300 rounded-lg p-2 mr-2"
                  placeholder="Type a message..."
                />
                <button
                  type="submit"
                  className="bg-blue-600 text-white rounded-lg p-2 hover:bg-blue-700 transition duration-300 ease-in-out"
                  aria-label="Send message"
                >
                  Send
                </button>
              </form>
            )}
          </div>

          {/* Footer with Tabs */}
          <div className="flex justify-around mt-2">
            <button
              onClick={() => setActiveTab("home")}
              className={`flex flex-col items-center ${
                activeTab === "home" ? "text-blue-600" : "text-gray-600"
              }`}
              aria-label="Home"
              role="tab"
              aria-selected={activeTab === "home"}
            >
              <FiHome className="text-2xl" />
              <span className="text-sm">Home</span>
            </button>

            <button
              onClick={() => setActiveTab("messages")}
              className={`flex flex-col items-center ${
                activeTab === "messages" ? "text-blue-600" : "text-gray-600"
              }`}
              aria-label="Messages"
              role="tab"
              aria-selected={activeTab === "messages"}
            >
              <FiMessageCircle className="text-2xl" />
              <span className="text-sm">Messages</span>
            </button>

            <button
              onClick={() => setActiveTab("help")}
              className={`flex flex-col items-center ${
                activeTab === "help" ? "text-blue-600" : "text-gray-600"
              }`}
              aria-label="Help"
              role="tab"
              aria-selected={activeTab === "help"}
            >
              <FiHelpCircle className="text-2xl" />
              <span className="text-sm">Help</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
