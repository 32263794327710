import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import axios from "axios";
import { useRating } from "../../context/RatingContext";
import { toast, ToastContainer } from "react-toastify";
import { motion } from "framer-motion";
import apartmentLogo from "./apartments-com.svg";
import EmployeeFeedbackSkeleton from "./EmployeeFeedbackSkeleton";
import ExternalRedirectTracker from "./ExternalRedirectTracker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const shortenCompanyName = (fullCompanyName) => {
  let mainName = fullCompanyName.split(" - ")[0];
  if (mainName === fullCompanyName) {
    mainName = fullCompanyName.split(",")[0];
  }
  return mainName;
};

const incrementTapCount = async (company_username, employee_username) => {
  try {
    const response = await fetch(
      `https://zola.technology/api/company/employees/update/${company_username}/${employee_username}/increment-taps/`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      console.log("Tap count incremented successfully");
    } else {
      console.error("Failed to increment tap count");
    }
  } catch (error) {
    console.error("Error while sending tap data to backend", error);
  }
};

function EmployeeRating() {
  const { company_username, employee_username, channel } = useParams();
  const { setEmployeeRating } = useRating();
  const [hoverRating, setHoverRating] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [preferredBusinessName, setPreferredBusinessName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [imageLoading, setImageLoading] = useState(true);
  const [employeeName, setEmployeeName] = useState("");
  const [companyRateStyle, setCompanyRateStyle] = useState("stars");
  const [selectedTeamRating, setSelectedTeamRating] = useState(0); // Team rating
  const [selectedExperienceRating, setSelectedExperienceRating] = useState(0); // Experience rating
  const [hoverExperienceRating, setHoverExperienceRating] = useState(0); // Hover effect for experience rating
  const [placeId, setPlaceId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [bgColor, setBgColor] = useState(null);
  const [textColor, setTextColor] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [showReviewPopup, setShowReviewPopup] = useState(false);
  const [couponAvailable, setCouponAvailable] = useState(null);

  const navigate = useNavigate();

  const location = useLocation();
  const reviewChannel = location.state?.reviewChannel?.trim()
    ? location.state.reviewChannel
    : "Google";

  console.log("Received State:", { reviewChannel });

  // Channel mapping
  const channelMapping = {
    n: "nfc_card",
    w: "whatsapp",
    q: "qr_code",
    s: "sms",
    l: "link",
    e: "email",
  };

  // Extract and resolve the channel from query params
  const resolvedChannel = channelMapping[channel || "n"] || "nfc_card";

  useEffect(() => {
    console.log("Resolved Channel:", resolvedChannel);
  }, [resolvedChannel]);

  const [googlePlaceId, setGooglePlaceId] = useState(null);
  const [googleReviewURL, setGoogleReviewURL] = useState(null);
  const [tripadvisorReviewURL, setTripadvisorReviewURL] = useState(null);
  const [yelpReviewURL, setYelpReviewURL] = useState(null);
  const [apartmentReviewURL, setApartmentReviewURL] = useState(null);
  const [fallbackGoogleReviewURL, setFallbackGoogleReviewURL] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // const fetchAllData = async () => {
    //   try {
    //     const [businessRes, employeeRes, integratedRes, placeIdRes] =
    //       await Promise.all([
    //         axios.get(
    //           `https://zola.technology/api/company/get/${company_username}`
    //         ),
    //         axios.get(
    //           `https://zola.technology/api/company/employees/get/${company_username}/${employee_username}/employee-details/`
    //         ),
    //         axios.get(
    //           `https://zola.technology/api/company/${company_username}/integrated-accounts/get/`
    //         ),
    //         axios.get(
    //           `https://zola.technology/api/company/reviews/${company_username}`
    //         ),
    //       ]);

    //     // Set Business Data
    //     const businessData = businessRes.data;
    //     setPreferredBusinessName(
    //       businessData?.preferred_business_name || "Company"
    //     );
    //     setCompanyName(
    //       shortenCompanyName(businessData?.company_name || "Unnamed Company")
    //     );
    //     setProfileImage(businessData?.profile_image || "");
    //     setCompanyRateStyle(businessData?.company_rate_style || "stars");

    //     // Set Employee Data
    //     const employeeData = employeeRes.data;
    //     setEmployeeDetails(employeeData);
    //     setEmployeeName(employeeData.name);

    //     // Set Integrated Accounts
    //     integratedRes.data.forEach((account) => {
    //       if (account.is_connected) {
    //         switch (account.service_type) {
    //           case "google_my_business":
    //             if (account.location_id_or_place_id) {
    //               setGooglePlaceId(account.location_id_or_place_id);
    //             }
    //             if (account.review_url) {
    //               setGoogleReviewURL(account.review_url);
    //               setFallbackGoogleReviewURL(account.review_url);
    //             }
    //             break;
    //           case "tripadvisor":
    //             setTripadvisorReviewURL(account.review_url);
    //             break;
    //           case "yelp":
    //             setYelpReviewURL(account.review_url);
    //             break;
    //           case "apartments_reviews":
    //             setApartmentReviewURL(account.review_url);
    //             break;
    //           default:
    //             break;
    //         }
    //       }
    //     });

    //     // Set Place ID
    //     setPlaceId(placeIdRes.data);

    //     // Mobile preview questions
    //     try {
    //       const questionsRes = await axios.get(
    //         `https://zola.technology/api/company/${company_username}/feedback/mobile/get-mobile-page/`
    //       );

    //       const { bg_color, text_color, cover_image, company_question } =
    //         questionsRes.data;

    //       setBgColor(bg_color || "blue-500");
    //       setTextColor(text_color || "#ffffff");
    //       setCoverImage(cover_image || "");

    //       const getFirstName = (name) => name?.split(" ")[0] || "";
    //       const name = getFirstName(employeeData.name);
    //       const formattedQuestions = [
    //         { id: 1, question: `How was your experience with ${name}?` },
    //         {
    //           id: 2,
    //           question: company_question || "How was your experience today?",
    //         },
    //       ];

    //       setQuestions(formattedQuestions);
    //     } catch {
    //       const fallbackName =
    //         employeeData.name || preferredBusinessName || companyName;
    //       setQuestions([
    //         {
    //           id: 1,
    //           question: `How was your experience with ${fallbackName}?`,
    //         },
    //         { id: 2, question: "How was your experience today?" },
    //       ]);
    //     }
    //   } catch (error) {
    //     console.error("Error loading all data:", error);
    //     toast.error("Failed to load company and employee details.");
    //   } finally {
    //     setIsLoading(false); // Done loading
    //   }
    // };

    const fetchAllData = async () => {
      try {
        const [businessRes, integratedRes, placeIdRes] = await Promise.all([
          axios.get(`https://zola.technology/api/company/get/${company_username}`),
          axios.get(
            `https://zola.technology/api/company/${company_username}/integrated-accounts/get/`
          ),
          axios.get(
            `https://zola.technology/api/company/reviews/${company_username}`
          ),
        ]);
    
        const businessData = businessRes.data;
        setPreferredBusinessName(businessData?.preferred_business_name || "Company");
        setCompanyName(shortenCompanyName(businessData?.company_name || "Unnamed Company"));
        setProfileImage(businessData?.profile_image || "");
        setCompanyRateStyle(businessData?.company_rate_style || "stars");
    
        // 🟡 Handle Solo Employee Case
        if (company_username === employee_username) {
          // use company data to simulate employee data
          setEmployeeDetails({
            name: `${businessData.first_name} ${businessData.last_name}`,
            profile_image: businessData.profile_image,
            cover_image: businessData.cover_image || "", // if available
          });
          setEmployeeName(`${businessData.first_name} ${businessData.last_name}`);
        } else {
          // 🔵 Fetch employee details as normal
          const employeeRes = await axios.get(
            `https://zola.technology/api/company/employees/get/${company_username}/${employee_username}/employee-details/`
          );
          const employeeData = employeeRes.data;
          setEmployeeDetails(employeeData);
          setEmployeeName(employeeData.name);
        }
    
        // Set Integrated Accounts
        integratedRes.data.forEach((account) => {
          if (account.is_connected) {
            switch (account.service_type) {
              case "google_my_business":
                if (account.location_id_or_place_id) {
                  setGooglePlaceId(account.location_id_or_place_id);
                }
                if (account.review_url) {
                  setGoogleReviewURL(account.review_url);
                  setFallbackGoogleReviewURL(account.review_url);
                }
                break;
              case "tripadvisor":
                setTripadvisorReviewURL(account.review_url);
                break;
              case "yelp":
                setYelpReviewURL(account.review_url);
                break;
              case "apartments_reviews":
                setApartmentReviewURL(account.review_url);
                break;
              default:
                break;
            }
          }
        });
    
        setPlaceId(placeIdRes.data);
    
        // Mobile preview questions
        try {
          const questionsRes = await axios.get(
            `https://zola.technology/api/company/${company_username}/feedback/mobile/get-mobile-page/`
          );
    
          const {
            bg_color,
            text_color,
            cover_image,
            company_question,
          } = questionsRes.data;
    
          setBgColor(bg_color || "blue-500");
          setTextColor(text_color || "#ffffff");
          setCoverImage(cover_image || "");
    
          const name = employee_username === company_username
            ? businessData.first_name
            : employeeRes?.data?.name?.split(" ")[0] || "";
    
          const formattedQuestions = [
            { id: 1, question: `How was your experience with ${name}?` },
            {
              id: 2,
              question: company_question || "How was your experience today?",
            },
          ];
          setQuestions(formattedQuestions);
        } catch {
          const fallbackName =
            employeeName || preferredBusinessName || companyName;
          setQuestions([
            { id: 1, question: `How was your experience with ${fallbackName}?` },
            { id: 2, question: "How was your experience today?" },
          ]);
        }
      } catch (error) {
        console.error("Error loading all data:", error);
        toast.error("Failed to load company and employee details.");
      } finally {
        setIsLoading(false); // Done loading
      }
    };
    

    fetchAllData();
  }, [company_username, employee_username]);

  useEffect(() => {
    const checkCouponAvailability = async () => {
      if (showReviewPopup) {
        try {
          const response = await fetch(
            `https://zola.technology/api/company/${company_username}/coupons/check-valid-coupon-exists/?applicable_area=feedback`
          );
          const data = await response.json();
          setCouponAvailable(data.has_valid_coupon);
        } catch (error) {
          console.error("Failed to check coupon availability:", error);
          setCouponAvailable(false); // default to false if error
        }
      }
    };

    checkCouponAvailability();
  }, [showReviewPopup]);

  useEffect(() => {
    if (selectedTeamRating > 0 && selectedExperienceRating > 0) {
      handleRatingSubmission();
    }
  }, [selectedTeamRating, selectedExperienceRating]);

  useEffect(() => {
    const checkCouponThenDecide = async () => {
      if (selectedTeamRating > 3 && selectedExperienceRating > 3) {
        try {
          const response = await fetch(
            `https://zola.technology/api/company/${company_username}/coupons/check-valid-coupon-exists/?applicable_area=feedback`
          );
          const data = await response.json();
          if (data.has_valid_coupon) {
            setCouponAvailable(true);
            setShowReviewPopup(true);
          } else {
            setCouponAvailable(false);
          }
        } catch (error) {
          console.error("Failed to check coupon availability:", error);
          setCouponAvailable(false);
        }
      }
    };

    checkCouponThenDecide();
  }, [selectedTeamRating, selectedExperienceRating]);

  const handleRatingSubmission = async () => {
    const averageRating = selectedExperienceRating;
    const feedbackType =
      averageRating > 3 ? "google_review" : "direct_feedback";

    const payload = {
      employee_username,
      employee_rating: selectedExperienceRating,
      rating: averageRating,
      username: company_username,
      feedback_type: feedbackType,
      author_name: "Anonymous",
      phone_number: +9999999999,
      feedback_channel: resolvedChannel,
    };

    try {
      if (averageRating <= 3) {
        const emoji = renderEmojiRating(selectedExperienceRating);
        navigate(
          `/feedback/${encodeURIComponent(
            company_username
          )}/${encodeURIComponent(employee_username)}/${encodeURIComponent(
            emoji
          )}/${channel || "n"}/make-a-zola-review`,
          {
            state: {
              selectedExperienceRating,
              selectedTeamRating,
            },
          }
        );
      }

      // Post feedback to backend
      await axios.post(
        `https://zola.technology/api/company/feedback/add/`,
        payload,
        {
          params: { feedback_channel: resolvedChannel },
        }
      );

      console.log("Feedback posted successfully");
    } catch (error) {
      console.error("Error posting feedback:", error);
    }
  };

  const handleRedirectClick = () => {
    if (reviewChannel === "Google") {
      if (placeId) {
        localStorage.setItem("zola_coupon_granted", "true");
        localStorage.setItem("zola_redirect_timer_start", Date.now());
        window.location.href = generateGoogleReviewURL(placeId);
      } else if (googleReviewURL) {
        localStorage.setItem("zola_coupon_granted", "true");
        localStorage.setItem("zola_redirect_timer_start", Date.now());
        window.location.href = googleReviewURL;
      } else if (fallbackGoogleReviewURL) {
        localStorage.setItem("zola_coupon_granted", "true");
        localStorage.setItem("zola_redirect_timer_start", Date.now());
        window.location.href = fallbackGoogleReviewURL;
      } else {
        toast.error("No valid Google Review URL found.");
      }
    } else if (reviewChannel === "tripadvisor" && tripadvisorReviewURL) {
      localStorage.setItem("zola_coupon_granted", "true");
      localStorage.setItem("zola_redirect_timer_start", Date.now());
      window.location.href = tripadvisorReviewURL;
    } else if (reviewChannel === "yelp" && yelpReviewURL) {
      localStorage.setItem("zola_coupon_granted", "true");
      localStorage.setItem("zola_redirect_timer_start", Date.now());
      window.location.href = yelpReviewURL;
    } else if (reviewChannel === "apartments_reviews" && apartmentReviewURL) {
      localStorage.setItem("zola_coupon_granted", "true");
      localStorage.setItem("zola_redirect_timer_start", Date.now());
      window.location.href = apartmentReviewURL;
    } else {
      toast.error("No valid review URL found.");
    }
  };

  const generateGoogleReviewURL = (placeId) => {
    return `https://search.google.com/local/writereview?placeid=${placeId}`;
  };

  const handleStarClick = (selectedRating) => {
    setEmployeeRating(selectedRating);
  };

  const handleTeamRatingClick = (rating) => {
    setSelectedTeamRating(rating);
    // Trigger increment only if both ratings are filled
    if (rating > 0 && selectedExperienceRating > 0) {
      incrementTapCount(company_username, employee_username);
    }
  };

  const handleExperienceRatingClick = (rating) => {
    setSelectedExperienceRating(rating);
    // Trigger increment only if both ratings are filled
    if (rating > 0 && selectedTeamRating > 0) {
      incrementTapCount(company_username, employee_username);
    }
  };

  const renderEmojiRating = (rating) => {
    const emojis = ["😡", "😞", "😐", "😊", "😍"];
    return emojis[rating - 1];
  };

  const displayName =
    employeeName || preferredBusinessName || companyName || "Team Member";

  if (isLoading) {
    return <EmployeeFeedbackSkeleton />;
  }

  const submitReviewForm = async () => {
    // if (!userName || !userPhone) {
    //   toast.error("Please provide your name and phone number.");
    //   return;
    // }

    const averageRating = selectedExperienceRating;
    const feedbackType =
      averageRating > 3 ? "google_review" : "direct_feedback";

    const payload = {
      employee_username,
      employee_rating: selectedExperienceRating,
      rating: averageRating,
      username: company_username,
      feedback_type: feedbackType,
      // author_name: userName,
      // phone_number: userPhone,
      author_name: "Anonymous",
      phone_number: "+1999999999",
      feedback_channel: resolvedChannel,
    };

    try {
      await axios.post(
        `https://zola.technology/api/company/feedback/add/`,
        payload,
        {
          params: { feedback_channel: resolvedChannel },
        }
      );

      toast.success("Thank you! Redirecting you...");

      setTimeout(() => {
        if (reviewChannel === "Google") {
          if (placeId) {
            localStorage.setItem("zola_coupon_granted", "true");
            localStorage.setItem("zola_redirect_timer_start", Date.now());
            window.location.href = generateGoogleReviewURL(placeId);
          } else if (googleReviewURL || fallbackGoogleReviewURL) {
            localStorage.setItem("zola_coupon_granted", "true");
            localStorage.setItem("zola_redirect_timer_start", Date.now());
            window.location.href = googleReviewURL || fallbackGoogleReviewURL;
          }
        } else if (reviewChannel === "tripadvisor" && tripadvisorReviewURL) {
          localStorage.setItem("zola_coupon_granted", "true");
          localStorage.setItem("zola_redirect_timer_start", Date.now());
          window.location.href = tripadvisorReviewURL;
        } else if (reviewChannel === "yelp" && yelpReviewURL) {
          localStorage.setItem("zola_coupon_granted", "true");
          localStorage.setItem("zola_redirect_timer_start", Date.now());
          window.location.href = yelpReviewURL;
        } else if (
          reviewChannel === "apartments_reviews" &&
          apartmentReviewURL
        ) {
          localStorage.setItem("zola_coupon_granted", "true");
          localStorage.setItem("zola_redirect_timer_start", Date.now());
          window.location.href = apartmentReviewURL;
        } else {
          toast.error("No valid review URL found.");
        }
      }, 1000); // Delay for toast

      setShowReviewPopup(false);
    } catch (error) {
      console.error("Error submitting review:", error);
      toast.error("Something went wrong. Try again.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="relative">
        {employeeDetails.cover_image || coverImage ? (
          <motion.img
            src={employeeDetails.cover_image || coverImage}
            alt="Company Cover"
            className="w-full h-48 object-cover"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
        ) : (
          <motion.div
            style={{
              backgroundColor: bgColor || "#ffffff",
              color: textColor || "#000000",
            }}
            className="w-full h-48 object-cover"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
        )}

        <motion.img
          src={employeeDetails.profile_image || profileImage}
          alt={employeeName || preferredBusinessName || companyName}
          className={`absolute -bottom-16 left-4 bg-white w-36 h-36 rounded-full border-4 border-white shadow-lg object-cover transition-opacity duration-500 ${
            imageLoading ? "opacity-0 blur-md" : "opacity-100"
          }`}
          onLoad={() => setImageLoading(false)}
          onError={() => {
            setImageLoading(false);
            setEmployeeDetails((prev) => ({
              ...prev,
              profile_image: profileImage,
            }));
          }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        />
      </div>
      <div className="mt-16 text-center px-6 text-black">
        <h1 className="text-3xl font-bold drop-shadow-md">{displayName}</h1>

        <h2 className="text-lg font-medium opacity-90">
          {preferredBusinessName || companyName}
        </h2>
      </div>
      <div className="">
        {/* Team Member Rating Section */}
        <h2 className="text-xl font-normal text-gray-900 text-center mt-6">
          {questions[0]?.question}
        </h2>
        <div className="px-6 py-2 max-w-md mx-auto transform transition duration-500 hover:scale-105">
          <div className="flex justify-center gap-3">
            {companyRateStyle === "stars" ? (
              [...Array(5)].map((_, index) => {
                const starValue = index + 1;
                const isSelected = starValue <= selectedTeamRating;
                return (
                  <FaStar
                    key={starValue}
                    size={40}
                    className={`cursor-pointer transition-transform duration-300 ${
                      isSelected
                        ? "text-yellow-500 scale-125"
                        : "text-gray-300 opacity-50"
                    }`}
                    onClick={() => handleTeamRatingClick(starValue)}
                    onMouseEnter={() => setHoverRating(starValue)}
                    onMouseLeave={() => setHoverRating(0)}
                  />
                );
              })
            ) : companyRateStyle === "emojis" ? (
              [...Array(5)].map((_, index) => {
                const emojiValue = index + 1;
                const isSelected = emojiValue === selectedTeamRating;
                return (
                  <span
                    key={emojiValue}
                    className={`cursor-pointer transition-transform duration-300 text-[40px] ${
                      selectedTeamRating === 0
                        ? "text-gray-700 opacity-100"
                        : isSelected
                        ? "text-yellow-500 scale-125 opacity-100"
                        : "opacity-50"
                    }`}
                    onClick={() => handleTeamRatingClick(emojiValue)}
                    onMouseEnter={() => setHoverRating(emojiValue)}
                    onMouseLeave={() => setHoverRating(0)}
                  >
                    {renderEmojiRating(emojiValue)}
                  </span>
                );
              })
            ) : (
              <p>Rating style not available</p>
            )}
          </div>
        </div>

        {/* Experience Rating Section */}
        <h2 className="text-xl font-normal text-gray-900 text-center">
          {questions[1]?.question}
        </h2>
        <div className="px-6 py-2 max-w-md mx-auto transform transition duration-500 hover:scale-105">
          <div className="flex justify-center gap-3">
            {companyRateStyle === "stars" ? (
              [...Array(5)].map((_, index) => {
                const starValue = index + 1;
                const isSelected = starValue <= selectedExperienceRating;
                return (
                  <FaStar
                    key={starValue}
                    size={40}
                    className={`cursor-pointer transition-transform duration-300 ${
                      isSelected
                        ? "text-yellow-500 scale-125"
                        : "text-gray-300 opacity-50"
                    }`}
                    onClick={() => handleExperienceRatingClick(starValue)}
                    onMouseEnter={() => setHoverExperienceRating(starValue)}
                    onMouseLeave={() => setHoverExperienceRating(0)}
                  />
                );
              })
            ) : companyRateStyle === "emojis" ? (
              [...Array(5)].map((_, index) => {
                const emojiValue = index + 1;
                const isSelected = emojiValue === selectedExperienceRating;
                return (
                  <span
                    key={emojiValue}
                    className={`cursor-pointer transition-transform duration-300 text-[40px] ${
                      selectedExperienceRating === 0
                        ? "text-gray-700 opacity-100"
                        : isSelected
                        ? "text-yellow-500 scale-125 opacity-100"
                        : "opacity-50"
                    }`}
                    onClick={() => handleExperienceRatingClick(emojiValue)}
                    onMouseEnter={() => setHoverExperienceRating(emojiValue)}
                    onMouseLeave={() => setHoverExperienceRating(0)}
                  >
                    {renderEmojiRating(emojiValue)}
                  </span>
                );
              })
            ) : (
              <p>Rating style not available</p>
            )}
          </div>
        </div>

        {selectedTeamRating > 3 &&
          selectedExperienceRating > 3 &&
          reviewChannel &&
          !showReviewPopup && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="text-center mt-6 px-6"
            >
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleRedirectClick}
                className={`text-white font-semibold px-6 py-3 rounded-full shadow-md transition-all duration-300 ${
                  reviewChannel === "Google"
                    ? "bg-[#4285F4] hover:bg-[#357ae8]"
                    : reviewChannel === "yelp"
                    ? "bg-[#D32323] hover:bg-[#b11c1c]"
                    : reviewChannel === "tripadvisor"
                    ? "bg-[#34E0A1] hover:bg-[#2bc490] text-black"
                    : reviewChannel === "apartments_reviews"
                    ? "bg-[#72b944] hover:bg-[#5da232]"
                    : "bg-gray-800 hover:bg-gray-700"
                }`}
              >
                {reviewChannel === "Google" && (
                  <span className="flex items-center justify-center gap-2">
                    <img
                      className="w-12"
                      src="https://img.icons8.com/fluency/48/google-logo.png"
                      alt="google-logo"
                    />
                    Leave a Google Review
                  </span>
                )}
                {reviewChannel === "yelp" && (
                  <span className="flex items-center justify-center gap-2">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/a/ad/Yelp_Logo.svg"
                      alt="Yelp"
                      className="w-12"
                    />
                    Leave a Yelp Review
                  </span>
                )}
                {reviewChannel === "tripadvisor" && (
                  <span className="flex items-center justify-center gap-2">
                    <img
                      className="w-12"
                      src="https://img.icons8.com/ios-filled/50/tripadvisor.png"
                      alt="tripadvisor"
                    />
                    Leave a Tripadvisor Review
                  </span>
                )}
                {reviewChannel === "apartments_reviews" && (
                  <span className="flex items-center justify-center gap-2">
                    Leave an Apartment Review
                  </span>
                )}
              </motion.button>
            </motion.div>
          )}
      </div>

      {showReviewPopup && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.4 }}
          className="fixed bottom-0 left-0 w-full bg-white shadow-2xl rounded-t-2xl z-50 p-6 border-t border-gray-300"
        >
          <h3 className="text-sm font-semibold text-center mb-4">
            {couponAvailable === null ? (
              "Checking for available rewards..."
            ) : couponAvailable ? (
              <>
                Leave a quick review to help us grow 🙌
                <br />
                Come back right after to unlock your exclusive coupon 🎁
              </>
            ) : (
              <>
                We’d love your feedback to help us improve
                <br />
                and keeping delivering great service🙏
              </>
            )}
          </h3>

          <div className="space-y-4">
            {/* <input
              type="text"
              placeholder="Your Name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            <PhoneInput
              country={"us"}
              onlyCountries={["us"]}
              value={userPhone}
              onChange={(phone) => setUserPhone(phone)}
              inputProps={{
                name: "phone",
                required: true,
                className:
                  "w-full px-12 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400",
              }}
            /> */}
            <button
              onClick={submitReviewForm}
              className={`text-white font-semibold px-6 py-3 rounded-full w-full shadow-md transition-all duration-300 ${
                reviewChannel === "Google"
                  ? "bg-[#4285F4] hover:bg-[#357ae8]"
                  : reviewChannel === "yelp"
                  ? "bg-[#D32323] hover:bg-[#b11c1c]"
                  : reviewChannel === "tripadvisor"
                  ? "bg-[#34E0A1] hover:bg-[#2bc490] text-black"
                  : reviewChannel === "apartments_reviews"
                  ? "bg-[#72b944] hover:bg-[#5da232]"
                  : "bg-gray-800 hover:bg-gray-700"
              }`}
            >
              {reviewChannel === "Google" && (
                <span className="flex items-center justify-center gap-2">
                  <img
                    className="w-12"
                    src="https://img.icons8.com/fluency/48/google-logo.png"
                    alt="google-logo"
                  />
                  Leave a Google Review
                </span>
              )}
              {reviewChannel === "yelp" && (
                <span className="flex items-center justify-center gap-2">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/a/ad/Yelp_Logo.svg"
                    alt="Yelp"
                    className="w-12"
                  />
                  Leave a Yelp Review
                </span>
              )}
              {reviewChannel === "tripadvisor" && (
                <span className="flex items-center justify-center gap-2">
                  <img
                    className="w-12"
                    src="https://img.icons8.com/ios-filled/50/tripadvisor.png"
                    alt="tripadvisor"
                  />
                  Leave a Tripadvisor Review
                </span>
              )}
              {reviewChannel === "apartments_reviews" && (
                <span className="flex items-center justify-center gap-2">
                  Leave an Apartment Review
                </span>
              )}
            </button>
            <button
              onClick={() => setShowReviewPopup(false)}
              className="w-full text-gray-600 hover:text-gray-900 text-sm mt-2"
            >
              Cancel
            </button>
          </div>
        </motion.div>
      )}

      <ExternalRedirectTracker
        onReturn={async () => {
          const granted = localStorage.getItem("zola_coupon_granted");
          const redirectTime = localStorage.getItem(
            "zola_redirect_timer_start"
          );
          const elapsed = redirectTime
            ? (Date.now() - parseInt(redirectTime, 10)) / 1000
            : 0;

          if (granted === "true" && elapsed > 8) {
            try {
              const couponResponse = await axios.post(
                `https://zola.technology/api/company/${company_username}/coupon/codes/generate/?applicable_area=feedback`,
                {},
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              if (couponResponse.data) {
                localStorage.setItem(
                  "coupon_data",
                  JSON.stringify(couponResponse.data)
                );
                navigate("/redeem-coupon");
              } else {
                navigate(`/feedback/${company_username}/${employee_username}`);
              }
            } catch (couponError) {
              console.error("Coupon generation error:", couponError);
              navigate(`/feedback/${company_username}/${employee_username}`);
            } finally {
              localStorage.removeItem("zola_coupon_granted");
              localStorage.removeItem("zola_redirect_timer_start");
            }
          } else {
            // Reset if user came back too fast (didn't actually leave)
            localStorage.removeItem("zola_coupon_granted");
            localStorage.removeItem("zola_redirect_timer_start");
          }
        }}
      />
    </>
  );
}

export default EmployeeRating;
