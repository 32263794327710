import DashboardHeader from './DashboardHeader'

function Sentiment() {
  return (
    <div>
      <DashboardHeader title="Sentiment" />
    </div>
  )
}

export default Sentiment