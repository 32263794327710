import { useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";

const Filters = ({ isOpen, onClose, applyFilters }) => {
  const [filters, setFilters] = useState({
    includesName: false,
    includesPhone: false,
    includesEmail: false,
    contactedBefore: false,
    notFullyInteracted: false,
  });

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setFilters((prev) => ({ ...prev, [name]: checked }));
  };

  if (!isOpen) return null; // Prevents rendering when modal is closed

  return (
    <div className="absolute -top-3 right-0 bg-white shadow-lg rounded-lg p-4 w-72 border">
      <h3 className="text-lg font-semibold mb-3">Contacts Details</h3>

      {/* Filter Options */}
      {[
        { name: "includesName", label: "Includes name" },
        { name: "includesPhone", label: "Includes phone" },
        { name: "includesEmail", label: "Includes email" },
        { name: "contactedBefore", label: "Contacted before" },
      ].map((filter) => (
        <label key={filter.name} className="flex items-center space-x-2 mb-2">
          <input
            type="checkbox"
            name={filter.name}
            checked={filters[filter.name]}
            onChange={handleChange}
            className="w-5 h-5"
          />
          <span>{filter.label}</span>
        </label>
      ))}

      {/* Divider */}
      <hr className="my-3" />

      {/* Not Fully Interacted with Tooltip */}
      <label className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          name="notFullyInteracted"
          checked={filters.notFullyInteracted}
          onChange={handleChange}
          className="w-5 h-5"
        />
        <span>Not fully interacted</span>
        <IoInformationCircleOutline className="text-gray-500 text-lg cursor-pointer" />
      </label>

      {/* Apply Filters Button */}
      <button
        className="w-full bg-blue-500 text-white py-2 rounded-lg text-lg font-semibold"
        onClick={() => applyFilters(filters)}
      >
        Apply filters
      </button>
    </div>
  );
};

export default Filters;
