import { createContext, useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext"; // Import the AuthContext hook

export const CustomerContactsContext = createContext();

export const CustomerContactsProvider = ({ children }) => {
  const { currentUser, isAuthenticated } = useAuth(); // Use auth context
  const [contacts, setContacts] = useState([]);
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [emailContacts, setEmailContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const refreshContacts = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    const fetchContacts = async () => {
      if (!isAuthenticated || !currentUser?.token) {
        setError("User not authenticated");
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await fetch("https://zola.technology/api/company/customers/get-contacts/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUser.token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch customer contacts");
        }

        const data = await response.json();

        // Categorize contacts into phone and email contacts
        const phoneContactsList = data.filter(contact => contact.phone_number);
        const emailContactsList = data.filter(contact => contact.email);

        setContacts(data);
        setPhoneContacts(phoneContactsList);
        setEmailContacts(emailContactsList);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, [refreshTrigger, isAuthenticated, currentUser]);

  return (
    <CustomerContactsContext.Provider value={{ contacts, phoneContacts, emailContacts, loading, error, refreshContacts }}>
      {children}
    </CustomerContactsContext.Provider>
  );
};
