import { useEffect, useState } from "react";
import axios from "axios";
import { FaStar } from "react-icons/fa";
import { useTimeRange } from "../../../context/TimeRangeContext";
import { useNavigate } from "react-router-dom";

const EmployeeRewardsTable = () => {
  const [rewardData, setRewardData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { timeRange } = useTimeRange();
  const navigate = useNavigate();
  const pageSize = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState(""); // 🔹 State for Sorting
  const [sortDirection, setSortDirection] = useState("asc"); // 🔹 State for Sort Order

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/employees/performance/rewards/?filter=${timeRange}&page=${currentPage}&page_size=${pageSize}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setRewardData(response.data.data);
        setTotalPages(response.data.total_pages);
        setFilteredData(response.data.data); // Set filtered data initially
      } catch (error) {
        console.error("Error fetching rewards data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timeRange, currentPage]);

  // 🔹 Search Functionality
  useEffect(() => {
    const filteredResults = rewardData.filter((employeeData) =>
      employeeData.employee.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
    setFilteredData(filteredResults);
  }, [searchQuery, rewardData]);

  // 🔹 Sorting Functionality
  useEffect(() => {
    if (!sortOption) return;

    const sortedData = [...filteredData].sort((a, b) => {
      let valueA = a[sortOption];
      let valueB = b[sortOption];

      if (typeof valueA === "string") valueA = valueA.toLowerCase();
      if (typeof valueB === "string") valueB = valueB.toLowerCase();

      return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
    });

    setFilteredData(sortedData);
  }, [sortOption]);

  return (
    <div>
      <div className="bg-white dark:bg-gray-800 p-4 w-full rounded-lg shadow-md">
        {/* Grid Container for Better Alignment */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
          {/* 🔹 Title */}
          <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
            Employee Rewards
          </h2>

          {/* 🔹 Search Input */}
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Search by name..."
              className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md w-full dark:bg-gray-700 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* 🔹 Sort Dropdown & Update Button */}
          <div className="grid grid-cols-2 gap-4">
            {/* Sort Dropdown */}
            <select
              className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <option value="">Sort by...</option>
              <option value="performance_score">Performance</option>
              <option value="total_feedback">Feedbacks</option>
              <option value="reward_total">Rewards</option>
              <option value="average_rating">Rating</option>
            </select>

            {/* Update Button */}
            <button
              onClick={() => navigate("/dashboard/setting/reward")}
              className="px-4 py-2 bg-blue-600 hover:bg-blue-700 transition text-white rounded-md shadow-md"
            >
              Update Reward
            </button>
          </div>
        </div>
      </div>

      {/* Rewards Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-separate border-spacing-y-3">
          <thead className="text-gray-700 dark:text-gray-300 text-[16px] bg-gray-100 dark:bg-gray-900">
            <tr>
              <th className="px-4 py-2 text-left font-medium">S/N</th>
              <th className="px-4 py-2 text-left font-medium">Name</th>
              <th className="px-4 py-2 text-left font-medium">Performance</th>
              <th className="px-4 py-2 text-left font-medium">Feedback</th>
              <th className="px-4 py-2 text-left font-medium">Rewards</th>
              <th className="px-4 py-2 text-left font-medium">Rating</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((employeeData, index) => (
                <tr
                  key={employeeData.employee.id}
                  className="bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-[16px] hover:shadow-md dark:hover:bg-gray-600 transition"
                >
                  <td data-cell="S/N">
                    {(currentPage - 1) * pageSize + index + 1}
                  </td>
                  <td data-cell="Name" className="flex items-center space-x-2">
                    <img
                      src={employeeData.employee.company.profile_image}
                      alt={employeeData.employee.name}
                      className="w-10 h-10 rounded-full object-contain border border-gray-300 dark:border-gray-600"
                    />
                    <span className="font-semibold">
                      {employeeData.employee.name}
                    </span>
                  </td>
                  <td data-cell="Performance" className="font-semibold">
                    {employeeData.performance_score}%
                  </td>
                  <td data-cell="Feedbacks" className="font-semibold">
                    {employeeData.total_feedback}
                  </td>
                  <td data-cell="Rewards" className="font-semibold">
                    ${employeeData.reward_total}
                  </td>
                  <td data-cell="Rating" className="font-semibold">
                    <span className="inline-flex items-center font-semibold">
                      {employeeData.average_rating}
                      <FaStar className="text-yellow-400 ml-1" />
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  className="text-center py-4 text-gray-700 dark:text-gray-300"
                >
                  {loading ? "Loading..." : "Employee Performance will appear here."}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {totalPages > 0 && (
        <div className="flex justify-between items-center mt-4">
          <button
            className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 disabled:opacity-50"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>

          <span className="text-gray-700 dark:text-gray-300">
            Page {currentPage} of {totalPages}
          </span>

          <button
            className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 disabled:opacity-50"
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default EmployeeRewardsTable;
