import { FaDownload } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { useTimeRange } from "../../context/TimeRangeContext";
import { useEffect, useState } from "react";
import QRCodeModal from "./QRCodeModal";
import AddAccounts from "./AccountConnections/ReviewAccounts"; // Import AddAccounts Component
import Modal from "./Modal"; // Import Modal Component

function Overview({
  title = "Overview",
  showExportButton = true,
  onExportClick = null, // Accept a callback for the export button
  hideTimeRange = false,
  allowCsvExport = false,
  showManageAccounts = false,
  showQRCode = false,
  showCreateCampaignButton = false, // New Prop: Default is hidden
  onCreateCampaignClick = () => {}, // Callback when the button is clicked
}) {
  const { timeRange, setTimeRange } = useTimeRange();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [showConnectionsModal, setShowConnectionsModal] = useState(false); // For AddAccounts popup
  const [companyUrl, setCompanyUrl] = useState("");

  useEffect(() => {
    const fetchCompanyProfile = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      try {
        const response = await fetch(
          "https://zola.technology/api/company/profile/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setCompanyUrl(data.company_url || "");
      } catch (error) {
        console.error("Error fetching company profile:", error);
      }
    };

    fetchCompanyProfile();
  }, []);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setTimeRange(selectedValue);
    localStorage.setItem("filter", selectedValue);
  };

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleQrModal = () => setShowQrModal(!showQrModal);
  const toggleConnectionsModal = () =>
    setShowConnectionsModal(!showConnectionsModal); // Toggle AddAccounts Modal

  return (
    <div className="flex flex-col md:flex-row gap-4 items-start md:items-center justify-between py-6">
      {/* Left Section */}
      <div className="text-start w-full md:w-auto">
        <h1 className="text-2xl font-medium text-gray-900 dark:text-gray-100">
          {title}
        </h1>
      </div>

      {/* Right Section - Wrapped for Small Screens */}
      <div className="flex flex-wrap gap-3 w-full md:w-auto items-center justify-start md:justify-end">
        {/* Dropdown for Time Range */}
        {!hideTimeRange && (
          <div className="relative w-full sm:w-40">
            <select
              value={timeRange}
              onChange={handleSelectChange}
              className="appearance-none w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg px-4 py-2 pr-10 text-gray-700 dark:text-gray-100 font-medium focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500"
            >
              <option value="">All Time</option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
            <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
              <IoIosArrowDown className="text-gray-500 dark:text-gray-400 text-sm" />
            </div>
          </div>
        )}

        {/* Manage Connections Button */}
        {showManageAccounts && (
          <button
            onClick={toggleConnectionsModal}
            className="bg-blue-600 text-white px-4 py-2 rounded-md flex items-center justify-center hover:bg-blue-700 transition w-full sm:w-auto"
          >
            Manage Connections
          </button>
        )}

        {/* Show QR Code Button */}
        {showQRCode && (
          <button
            onClick={toggleQrModal}
            className="bg-green-600 text-white px-4 py-2 rounded-md flex items-center justify-center hover:bg-green-700 transition w-full sm:w-auto"
          >
            Show QR Code
          </button>
        )}

        {showCreateCampaignButton && (
          <button
            onClick={onCreateCampaignClick}
            className="bg-blue-600 text-white px-5 py-2 rounded-lg flex items-center justify-center hover:bg-blue-700 transition w-full sm:w-auto font-medium"
          >
            <span className="mr-2 text-xl">+</span> Create Campaigns
          </button>
        )}

        {/* Export Button */}
        {showExportButton && (
          <div className="relative w-full sm:w-auto">
            <button
              onClick={toggleDropdown}
              className="bg-purple-600 dark:bg-purple-700 text-white px-4 py-2 rounded-md flex items-center justify-center hover:bg-purple-700 dark:hover:bg-purple-800 transition w-full sm:w-auto"
            >
              <FaDownload className="mr-2" /> Export
              <IoIosArrowDown className="ml-2" />
            </button>

            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg w-40 z-10">
                <button
                  className="block px-4 py-2 w-full text-left hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-gray-100"
                  onClick={() => {
                    onExportClick("pdf");
                    setIsDropdownOpen(false);
                  }}
                >
                  Export as PDF
                </button>
                <button
                  className="block px-4 py-2 w-full text-left hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-gray-100"
                  onClick={() => {
                    onExportClick("image");
                    setIsDropdownOpen(false);
                  }}
                >
                  Export as Image
                </button>
                {allowCsvExport && (
                  <button
                    className="block px-4 py-2 w-full text-left hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-gray-100"
                    onClick={() => {
                      onExportClick("csv");
                      setIsDropdownOpen(false);
                    }}
                  >
                    Export as CSV
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* QR Code Modal */}
      <QRCodeModal
        showModal={showQrModal}
        setShowModal={setShowQrModal}
        companyUrl={companyUrl}
      />

      {/* Connections Modal */}
      <Modal showModal={showConnectionsModal} onClose={toggleConnectionsModal}>
        <AddAccounts />
      </Modal>
    </div>
  );
}

export default Overview;
