import { useEffect, useContext } from 'react';
import { RiVerifiedBadgeFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';

const Success = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext); // Assuming you have a login function in AuthContext

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/login'); // Redirect to login if no token found
    }
  }, [navigate]);

  const handleDashboardRedirect = () => {
    const token = localStorage.getItem('authToken');
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (token && userData) {
      // Restore login context if needed
      login({
        username: userData.username,
        token: token,
        firstName: userData.first_name,
        rtoken: localStorage.getItem('refreshToken'),
      });

      navigate('/dashboard');
    } else {
      toast.error('Session expired. Please log in again.');
      navigate('/login');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-green-100">
      <div className="bg-white border border-green-400 text-green-700 p-6 rounded shadow-lg">
        <div className="flex items-center mb-4">
          <RiVerifiedBadgeFill className="text-green-500 text-4xl" />
          <span className="text-xl ml-2">Success!</span>
        </div>
        <p className="text-lg mb-4">Your payment was successful.</p>
        <div className="flex justify-center">
          <button
            onClick={handleDashboardRedirect}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            aria-label="Continue Button"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
