import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Performance() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1, // Trigger animation when 10% of the component is visible
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  return (
    <div className="py-12 h-auto md:mx-auto flex flex-col my-8 font-figtree p-4">
      <h2 className="text-center text-2xl sm:text-3xl md:text-4xl lg:text-[38px] font-semibold mb-8 leading-tight">
        Performance you can measure
      </h2>
      <motion.div
        className="grid grid-cols-1 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-12"
        ref={ref}
        variants={containerVariants}
        initial="hidden"
        animate={controls}
      >
        <motion.div
          className="
          bg-[#eff2fb] text-center py-6 px-4 md:py-8 md:px-6 
          lg:py-10 lg:px-8 rounded-2xl flex-1 w-full"
          variants={itemVariants}
        >
          <h3 className="text-4xl md:text-[40px] lg:text-[48px] leading-tight mb-4 font-semibold">
            Millions
          </h3>
          <p className="text-xl md:text-base font-medium leading-relaxed">
            Of dollars in revenue driven for businesses using Zola
          </p>
        </motion.div>
        <motion.div
          className="bg-[#eff2fb] text-center py-6 px-4 md:py-8 md:px-6 lg:py-10 lg:px-8 rounded-2xl flex-1  w-full"
          variants={itemVariants}
        >
          <h3 className="text-4xl md:text-[40px] lg:text-[48px] leading-tight mb-4 font-semibold">
            100%+
          </h3>
          <p className="text-xl md:text-base font-medium leading-relaxed">
            Over 100% feedbacks are read within 3 minutes of being received.
          </p>
        </motion.div>
        <motion.div
          className="bg-[#eff2fb] text-center py-6 px-4 md:py-8 md:px-6 lg:py-10 lg:px-8 rounded-2xl flex-1  w-full"
          variants={itemVariants}
        >
          <h3 className="text-4xl md:text-[40px] lg:text-[48px] leading-tight mb-4 font-semibold">
            Thousands
          </h3>
          <p className="text-xl md:text-base font-medium leading-relaxed">
            Of reviews generated for businesses using Zola
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Performance;