import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import SurveySuggestions from "./SurveySection";
import IncentiveSection from "./IncentiveSection";
import { FaHandFist } from "react-icons/fa6";
import { useTimeRange } from "../../../context/TimeRangeContext";


// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function FeedbackSection({ feedbackData = [], totalreward, survey }) {

  const { timeRange } = useTimeRange();

  // Calculate totals directly from feedbackData prop
  const totals = {
    positive: feedbackData?.reduce(
      (sum, item) => sum + item.positive_feedback_count,
      0
    ),
    neutral: feedbackData?.reduce(
      (sum, item) => sum + item.neutral_feedback_count,
      0
    ),
    negative: feedbackData?.reduce(
      (sum, item) => sum + item.negative_feedback_count,
      0
    ),
  };

  if (!feedbackData || feedbackData?.length === 0) {
    return <></>;
  }

  // Format labels based on period string
  const formattedLabels = feedbackData.map((item) => {
    if (!item.period) return "Unknown";
  
    if (timeRange === "month") {
      // Example: "Week 08: 01 March 2025" → "Week 08"
      return item.period.split(":")[0].trim();
    }
  
    if (timeRange === "week") {
      // Example: "Monday 17 March 2025" → "Mon"
      return item.period.split(" ")[0].slice(0, 3);
    }
  
    if (timeRange === "year") {
      // Example: "January 2025" or "January" → "Jan"
      return item.period.split(" ")[0].slice(0, 3);
    }
  
    return item.period; // fallback
  });
  

  // Stacked bar chart data and options
  const chartData = {
    labels: formattedLabels,
    datasets: [
      {
        label: "Negative",
        data: feedbackData.map((item) => item.negative_feedback_count),
        backgroundColor: "#d50000", // Red
      },
      {
        label: "Neutral",
        data: feedbackData.map((item) => item.neutral_feedback_count),
        backgroundColor: "#ffc400", // Yellow
      },
      {
        label: "Positive",
        data: feedbackData.map((item) => item.positive_feedback_count),
        backgroundColor: "#00c853", // Green
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <div className="flex w-full flex-col lg:flex-row gap-6 mt-7  lg:h-[590px]">
      {/* Feedback Chart Section */}
      <div className="w-full lg:w-3/5 flex-1 bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md h-[580px]">
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-semibold text-gray-900 dark:text-gray-100 text-xl">
            Feedback
          </h3>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 justify-start gap-6 md:gap-8 mb-6">
          {/* Positive Feedback */}
          <div className="flex items-center gap-4 w-full sm:w-auto">
            <span className="text-green-600 text-3xl sm:text-4xl bg-gray-200 dark:bg-gray-700 text-center rounded-full p-4">
              <FaRegThumbsUp />
            </span>
            <div>
              <p className="text-gray-600 dark:text-gray-400 text-sm">
                Positive
              </p>
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-gray-100">
                {totals.positive}
              </h3>
            </div>
          </div>

          {/* Neutral Feedback */}
          <div className="flex items-center gap-4 w-full sm:w-auto">
            <span className="text-yellow-500 text-3xl sm:text-4xl bg-gray-200 dark:bg-gray-700 text-center rounded-full p-4">
              <FaHandFist />
            </span>
            <div>
              <p className="text-gray-600 dark:text-gray-400 text-sm">
                Neutral
              </p>
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-gray-100">
                {totals.neutral}
              </h3>
            </div>
          </div>

          {/* Negative Feedback */}
          <div className="flex items-center gap-4 w-full sm:w-auto">
            <span className="text-red-600 text-3xl sm:text-4xl bg-gray-200 dark:bg-gray-700 text-center rounded-full p-4">
              <FaRegThumbsDown />
            </span>
            <div>
              <p className="text-gray-600 dark:text-gray-400 text-sm">
                Negative
              </p>
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-gray-100">
                {totals.negative}
              </h3>
            </div>
          </div>
        </div>

        <div className="w-[95%] md:w-full h-[250px] sm:h-[300px] lg:h-[400px]">
          <Bar data={chartData} options={chartOptions} />
        </div>
      </div>

      <div className="grid grid-rows-[1fr_1fr] gap-4 w-full lg:w-2/5">
        <SurveySuggestions activesurvey={survey}/>
        <IncentiveSection rewardAmount={totalreward?.total_rewards} />
      </div>
    </div>
  );
}

export default FeedbackSection;
