import { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchPlacePredictions } from "../../pages/SignUp/googlePlaces";
// Import icons
import { X, Eye, EyeOff } from "lucide-react"

function CreateBranchModal({ isOpen, onClose, onSubmit }) {
  const [formData, setFormData] = useState({
    username: "",
    company_name: "",
    first_name: "",
    last_name: "",
    email: "",
    preferred_business_name: "",
    phone_number: "",
    company_type: 'team', // Default to "team"
    password: '',
    confirm_password: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const suggestionsRef = useRef();

  useEffect(() => {
    const { company_name } = formData;
    if (company_name.length > 2) {
      const fetchSuggestions = async () => {
        const suggestions = await fetchPlacePredictions(company_name);
        setPlaceSuggestions(suggestions);
      };
      fetchSuggestions();
    } else {
      setPlaceSuggestions([]);
    }
  }, [formData.company_name]);

  const handlePlaceSelect = (description) => {
    setFormData((prev) => ({
      ...prev,
      company_name: description,
    }));
    setPlaceSuggestions([]);
  };

  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setPlaceSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setPasswordType((prev) => (prev === 'password' ? 'text' : 'password'));
    } else if (field === 'confirm_password') {
      setConfirmPasswordType((prev) => (prev === 'password' ? 'text' : 'password'));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});
  
    if (formData.password !== formData.confirm_password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirm_password: ["Passwords do not match"],
      }));
      toast.error("Passwords do not match!");
      setLoading(false);
      return;
    }
  
    try {
      const token = localStorage.getItem("token");
      const { confirm_password, ...submitData } = formData; // Exclude confirm_password
  
      const response = await fetch(
        "https://zola.technology/api/hq-company/create-branch-company/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(submitData),
        }
      );
  
      if (response.ok) {
        const data = await response.json();
        onSubmit(data);
        toast.success(
          "Branch created successfully! Please log in to the new account to complete signup."
        );
        setTimeout(() => {
          onClose();
        }, 3000);
      } else {
        const errorData = await response.json();
        setErrors(errorData);
        toast.error(errorData.error || "Something went wrong!");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex justify-center items-center z-50 p-4">

  <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-3xl">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-2xl font-normal text-gray-900">Create New Location</h2>
      <X 
      onClick={onClose}
       className="font-light cursor-pointer" />
    </div>

        <form onSubmit={handleFormSubmit}>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.keys(formData).map((key) => (
              <div key={key} className="relative mb-4">
                 <label className="block text-gray-700 dark:text-gray-300 capitalize">
                  {key.replace(/_/g, ' ')}
                </label>
                {key === 'company_type' ? (
                  <select
                    name="company_type"
                    value={formData.company_type}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 dark:border-white rounded-lg bg-transparent text-gray-900 dark:text-white w-full"
                  >
                    <option value="team">Team</option>
                    <option value="one_person">One Person</option>
                  </select>
                ) : (
                  <div className="relative flex items-center">
                    <input
                      type={key === 'password' ? passwordType : key === 'confirm_password' ? confirmPasswordType : key === 'email' ? 'email' : 'text'}
                      name={key}
                      value={formData[key] || ''}
                      onChange={handleInputChange}
                      className="p-2 border border-gray-300 dark:border-white rounded-lg bg-transparent text-gray-900 dark:text-white w-full"
                    />
                    {(key === 'password' || key === 'confirm_password') && (
                      <button
                        type="button"
                        onClick={() => togglePasswordVisibility(key)}
                        className="absolute right-3 text-gray-600 dark:text-gray-300"
                      >
                        {(key === 'password' && passwordType === 'password') || (key === 'confirm_password' && confirmPasswordType === 'password') ? <Eye /> : <EyeOff/>}
                      </button>
                    )}
                  </div>
                )}
                {errors[key] && (
                  <p className="text-red-500 mt-1">{errors[key][0]}</p>
                )}

                {key === "company_name" && placeSuggestions.length > 0 && (
                  <div
                    ref={suggestionsRef}
                    className="absolute z-10 bg-white text-black dark:bg-gray-700 dark:text-white mt-1 rounded-md shadow-md w-full max-h-40 overflow-y-auto"
                  >
                    {placeSuggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                        onClick={() =>
                          handlePlaceSelect(suggestion.description)
                        }
                      >
                        {suggestion.description}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          {errors.non_field_errors && (
            <div className="text-red-500 mb-4">
              {errors.non_field_errors[0]}
            </div>
          )}

          <div className="flex justify-end gap-4 mt-2">
            <button
              type="button"
              aria-label="Cancel"
              onClick={onClose}
              className="px-5 py-2 border border-gray-200 text-gray-600 rounded-lg hover:bg-gray-300 transition"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              aria-label="Create Location"
              type="submit"
              className="px-5 py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Create Location"}
            </button>
          </div>
        </form>

        <ToastContainer />
      </div>
    </div>
  );
}

export default CreateBranchModal;
