import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { registerUser, getToken } from "../../api";
import { fetchPlacePredictions } from "./googlePlaces";
import Select from "react-select";

function Signup() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialEmail = searchParams.get("email") || "";

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState(initialEmail);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [zolaBusinessName, setZolaBusinessName] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const suggestionsRef = useRef();
  const [numberOfLocations, setNumberOfLocations] = useState(1);
  const [industryType, setIndustryType] = useState(""); // 'one_person' or 'team'
  const [numberOfEmployees, setNumberOfEmployees] = useState("");

  useEffect(() => {
    if (companyName.length > 2) {
      const fetchSuggestions = async () => {
        const suggestions = await fetchPlacePredictions(companyName);
        setPlaceSuggestions(suggestions);
      };
      fetchSuggestions();
    } else {
      setPlaceSuggestions([]);
    }
  }, [companyName]);

  const handlePlaceSelect = (description) => {
    setCompanyName(description);
    setPlaceSuggestions([]);
  };

  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setPlaceSuggestions([]);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10,15}$/;
    return phoneRegex.test(phoneNumber);
  };

  const disposableEmailDomains = [
    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "tempmail.com",
    "yopmail.com",
    // Add more disposable email domains as needed
  ];

  const validateDisposableEmail = (email) => {
    const emailDomain = email.split("@")[1];
    return disposableEmailDomains.includes(emailDomain);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setErrors({});
    setLoading(true);

    try {
      const validationErrors = {};
      // Validate fields
      if (!firstName) {
        validationErrors.firstName = "First name is required";
      } else if (firstName.length > 30) {
        validationErrors.firstName =
          "First name must be less than 30 characters";
      }

      if (!lastName) {
        validationErrors.lastName = "Last name is required";
      } else if (lastName.length > 30) {
        validationErrors.lastName = "Last name must be less than 30 characters";
      }

      if (!companyName) {
        validationErrors.companyName = "Company name is required";
      }
      if (!username) {
        validationErrors.username = "Username is required";
      } else if (username.length < 6 || username.length > 20) {
        validationErrors.username =
          "Username must be between 6 to 20 characters";
      }

      if (!email) {
        validationErrors.email = "Email is required";
      } else if (!validateEmail(email)) {
        validationErrors.email = "Invalid email format";
      } else if (email.length > 30) {
        validationErrors.email = "Email must be less than 30 characters";
      } else if (validateDisposableEmail(email)) {
        validationErrors.email = "Temporary email is not allowed";
      }

      if (!phoneNumber) {
        validationErrors.phoneNumber = "Phone number is required";
      } else if (!validatePhoneNumber(phoneNumber)) {
        validationErrors.phoneNumber = "Invalid phone number format";
      } else if (phoneNumber.length < 10 || phoneNumber.length > 15) {
        validationErrors.phoneNumber =
          "Phone number must be between 10 to 15 digits";
      }

      if (!password) {
        validationErrors.password = "Password is required";
      } else if (password.length < 8 || password.length > 128) {
        validationErrors.password =
          "Password must be between 8 to 128 characters";
      }

      if (password !== confirmPassword) {
        validationErrors.confirmPassword = "Passwords do not match";
      }

      if (!zolaBusinessName) {
        validationErrors.zolaBusinessName = "Zola Business Name is required";
      }

      if (!numberOfLocations) {
        validationErrors.numberOfLocations = "Number of locations is required";
      } else if (numberOfLocations < 1) {
        validationErrors.numberOfLocations =
          "Number of location(s) should be greater than 0";
      }
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        setLoading(false);
        return;
      }

      const response = await registerUser({
        first_name: firstName,
        last_name: lastName,
        company_name: companyName,
        email: email,
        username: username,
        phone_number: phoneNumber,
        password: password,
        preferred_business_name: zolaBusinessName,
        number_of_locations: numberOfLocations,
        number_of_employees:
          industryType === "one_person" ? 1 : numberOfEmployees,
        company_type: industryType, // ✅ This line fixes it
      });

      console.table(response);

      // 3. Get the login token using email and password
      const credentials = { username, password };
      const tokenResponse = await getToken(credentials);

      // 4. Save the token and user data to localStorage
      const token = tokenResponse.data.access; // Assuming the response has `access` as the token field
      localStorage.setItem("authToken", token);
      localStorage.setItem("userData", JSON.stringify(response.data)); // Save user data
      // 5. Handle signup success and navigate to the setup form
      toast.success("Signup successful! Redirecting to setup...");
      // Handle signup success, navigate to sign up success page
      navigate("/setup-form");
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        const errorMessage =
          error.response.data.message || error.response.statusText;
        toast.error(`Server error: ${errorMessage}`);
        // setErrors({
        //   apiError: `Server error: ${errorMessage}`,
        // });
      } else if (error.request) {
        // Request was made but no response was received
        const errorMessage = "Network error: Please check your connection.";
        toast.error(errorMessage);
        // setErrors({ apiError: errorMessage });
      } else {
        // Something else happened
        const errorMessage = `${error.message}`;
        toast.error(errorMessage);
        // setErrors({ apiError: errorMessage });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const accountTypeOptions = [
    { value: "one_person", label: "Individual" },
    { value: "team", label: "Team" },
  ];

  return (
    <section className="min-h-screen p-4 bg-gray-100 flex items-center justify-center w-full h-auto mt-16 md:mt-0 lg:mt-0 lg:h-auto">
      <div className="w-full max-w-5xl bg-white shadow-md rounded-lg p-8 flex flex-col lg:flex-row gap-8">
        <div className="w-full lg:w-1/3 bg-blue-50 p-6 rounded-lg flex flex-col items-center justify-center text-center">
          <h3 className="text-xl font-semibold text-blue-600 mb-4">
            Unlock Exclusive Access to Premium Features
          </h3>
          <p className="text-gray-700 mb-6">
            Join today and experience the full power of our platform, designed
            to enhance your business operations and drive growth.
          </p>
          <p className="text-sm text-gray-500">
            Discover tools and insights crafted to improve efficiency, boost
            team performance, and elevate customer satisfaction.
          </p>
        </div>
        <div className="w-full lg:w-2/3">
          <p className="text-2xl font-bold text-center mb-6">
            Sign Up for an Account
          </p>
          <form className="flex flex-col gap-6" onSubmit={handleSignUp}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {[
                {
                  id: "firstName",
                  label: "First Name",
                  value: firstName,
                  setValue: setFirstName,
                  type: "text",
                  span: "md:col-span-1 col-span-2",
                },
                {
                  id: "lastName",
                  label: "Last Name",
                  value: lastName,
                  setValue: setLastName,
                  type: "text",
                  span: "md:col-span-1 col-span-2",
                },
                {
                  id: "companyName",
                  label: "Google Business Name",
                  value: companyName,
                  setValue: setCompanyName,
                  type: "text",
                  span: "sm:col-span-2 col-span-1",
                },
                {
                  id: "numberOfLocations",
                  label: "Number of Locations",
                  value: numberOfLocations,
                  setValue: setNumberOfLocations,
                  type: "number",
                  span: "sm:col-span-2 col-span-1",
                  readOnly: true,
                },

                {
                  id: "zolaBusinessName",
                  label: "Business Name",
                  value: zolaBusinessName,
                  setValue: setZolaBusinessName,
                  type: "text",
                  span: "md:col-span-1 col-span-2",
                },

                {
                  id: "email",
                  label: "Email",
                  value: email,
                  setValue: setEmail,
                  type: "email",
                  span: "md:col-span-1 col-span-2",
                },
                {
                  id: "username",
                  label: "Username (at least 6 characters)",
                  value: username,
                  setValue: setUsername,
                  type: "text",
                  span: "md:col-span-1 col-span-2",
                },
                {
                  id: "phoneNumber",
                  label: "Phone Number",
                  value: phoneNumber,
                  setValue: setPhoneNumber,
                  type: "text",
                  component: PhoneInput,
                  span: "md:col-span-1 col-span-2",
                  props: {
                    onlyCountries: ["us"], // Restrict to US only
                    country: "us", // Default to US
                  },
                },
                {
                  id: "password",
                  label: "Password",
                  value: password,
                  setValue: setPassword,
                  type: "password",
                  span: "md:col-span-1 col-span-2",
                },
                {
                  id: "confirmPassword",
                  label: "Confirm Password",
                  value: confirmPassword,
                  setValue: setConfirmPassword,
                  type: "password",
                  span: "md:col-span-1 col-span-2",
                },
              ].map(
                ({
                  id,
                  label,
                  value,
                  setValue,
                  type,
                  component: Component,
                  span,
                  readOnly,
                  props,
                }) => (
                  <div
                    key={id}
                    className={`relative flex flex-col ${span || ""}`}
                  >
                    <label htmlFor={id} className="mb-2 font-semibold">
                      {label}
                    </label>
                    {Component ? (
                      <Component
                        // country={"us"}
                        {...(props || {})}
                        id={id}
                        value={value}
                        onChange={setValue}
                        inputClass={`border w-full rounded-md p-3 text-sm bg-gray-50 focus:bg-white focus:border-gray-300 transition-colors ${
                          errors[id] ? "border-red-500" : ""
                        }`}
                        required
                      />
                    ) : (
                      <input
                        type={type}
                        id={id}
                        className={`border rounded-md p-3 text-sm bg-gray-50 focus:bg-white focus:border-gray-300 transition-colors ${
                          errors[id] ? "border-red-500" : ""
                        }`}
                        placeholder={label}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        required
                        readOnly={id === "numberOfLocations"}
                      />
                    )}
                    {id === "companyName" && placeSuggestions.length > 1 && (
                      <ul
                        ref={suggestionsRef}
                        className="absolute top-[70px] z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto"
                      >
                        {placeSuggestions.map((suggestion) => (
                          <li
                            key={suggestion.place_id}
                            className="p-2 cursor-pointer hover:bg-gray-200"
                            onClick={() =>
                              handlePlaceSelect(suggestion.description)
                            }
                          >
                            {suggestion.description}
                          </li>
                        ))}
                      </ul>
                    )}
                    {errors[id] && (
                      <p className="text-red-500 text-sm mt-1">{errors[id]}</p>
                    )}
                  </div>
                )
              )}
            </div>

            <div
              className={`grid gap-6 ${
                industryType === "team"
                  ? "grid-cols-1 sm:grid-cols-2"
                  : "grid-cols-1"
              }`}
            >
              {/* Account Type */}
              <div className="form-field w-full">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Account Type <span className="text-red-500">*</span>
                </label>
                <Select
                  id="industry-type"
                  name="industryType"
                  value={
                    industryType
                      ? accountTypeOptions.find(
                          (opt) => opt.value === industryType
                        )
                      : null
                  }
                  onChange={(selectedOption) =>
                    setIndustryType(selectedOption?.value)
                  }
                  options={[
                    {
                      value: "",
                      label: "Select an account type",
                      isDisabled: true,
                    },
                    { value: "one_person", label: "Individual" },
                    { value: "team", label: "Team" },
                  ]}
                  placeholder="Select an account type"
                  className="mt-1"
                  classNamePrefix="react-select"
                />
              </div>

              {/* Number of Employees (conditionally shown) */}
              {industryType === "team" && (
                <div className="form-field w-full">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Number of Employees <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    value={numberOfEmployees}
                    onChange={(e) => setNumberOfEmployees(e.target.value)}
                    required
                    placeholder="Number of Employees"
                    className="w-full p-2 border rounded bg-gray-50 focus:bg-white"
                  />
                </div>
              )}
            </div>

            <button
              className="bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition-all duration-300"
              type="submit"
              disabled={loading}
              aria-disabled={loading}
              aria-label={
                loading
                  ? "Account creation in progress"
                  : "Submit form to sign up"
              }
            >
              {loading ? "Creating Account..." : "Sign Up"}
            </button>
            {errors.apiError && (
              <p className="text-red-500 text-center mt-2">{errors.apiError}</p>
            )}
          </form>
          <p className="text-center text-sm text-gray-600 mt-4">
            Already have an account?{" "}
            <Link
              to="/login"
              className="text-blue-500 hover:underline transition-all"
            >
              Login here
            </Link>
          </p>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}

export default Signup;
