import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaStar,
  FaExclamationTriangle,
} from "react-icons/fa";
import { MdError, MdVerified } from "react-icons/md";
import { PiFunnelBold } from "react-icons/pi";
import { IoCaretDownOutline, IoCaretUpOutline } from "react-icons/io5";
import EditForm from "./EditForm";
import { ToastContainer, toast } from "react-toastify";
import ReactApexChart from "react-apexcharts";
import { PiCopyBold } from "react-icons/pi";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { TiArrowSortedDown } from "react-icons/ti";
import { useMediaQuery } from "react-responsive";

const Employees = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10; // Items per page
  const [isFetching, setIsFetching] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 640px)" });
  const isMediumScreen = useMediaQuery({ query: "(min-width: 768px) and (max-width: 1024px)" });

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(timer);
  }, [currentPage]);

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    setLoading(true);

  
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/get-all/?page=${currentPage}&page_size=${pageSize}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const result = await response.json();
      console.log(result);
      setData(result.data);
      setTableData(result.data);

      setTotalPages(result.total_pages);
    } catch (error) {
      setError(error.message);
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
      setIsFetching(false); // Reset state
    }
  };

  const handleUpdate = (index) => {
    setSelectedItemIndex(index);
    // console.log(index);
    setShowPopup(true);
  };

  const handleEditFormClose = () => setShowPopup(false);

  const handleUpdateData = async (updatedValues) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }
    // console.log(data[selectedItemIndex].employee.id, "selected employee id");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/update/${data[selectedItemIndex].employee.id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: updatedValues.name,
            username: updatedValues.username,
          }),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const updatedData = [...data];
      updatedData[selectedItemIndex] = {
        ...updatedData[selectedItemIndex],
        name: updatedValues.name,
        username: updatedValues.username,
      };
      setData(updatedData);
      toast.success("Employee data updated successfully!");
    } catch (error) {
      setError(error.message);
      toast.error(`Update Error: ${error.message}`);
    }
  };



  const filteredTableData =
    tableData?.filter((item) =>
      item?.employee?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

  const cardData = data?.filter((item) => item?.employee) || [];

  const [isOpen, setIsOpen] = useState(false);
  const [sortOption, setSortOption] = useState("Sort by");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    name: "",
    username: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleDeleteClick = (employee) => {
    // console.log(employee.employee.id);
    setSelectedEmployee(employee.employee);
    setIsDeleteModalOpen(true); // Open the delete modal
  };

  const handleDeleteConfirm = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    setLoading(true);
    setDeletingIndex(selectedEmployee.id);

    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/delete/${selectedEmployee?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Filter out the deleted employee from data and tableData
        const updatedData = data.filter(
          (item) => item.employee.id !== selectedEmployee.id
        );
        setData(updatedData);
        setTableData(updatedData);

        toast.success(`${selectedEmployee?.name} deleted successfully!`);
      } else {
        throw new Error("Failed to delete employee");
      }
    } catch (error) {
      setError(error.message);
      toast.error(`Delete Error: ${error.message}`);
    } finally {
      setLoading(false);
      setDeletingIndex(null);
      setIsDeleteModalOpen(false); // Close the modal after deletion
      setSelectedEmployee(null); // Reset the selected employee
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false); // Close the delete modal
    setSelectedEmployee(null); // Reset the selected employee
  };

  const handleModalToggle = () => setIsModalOpen(!isModalOpen);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        "https://zola.technology/api/company/employees/add/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(employeeData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.username[0] || "Error adding employee");
        throw new Error("Network response was not ok");
      }

      // Success notification
      toast.success("Employee added successfully");
      setIsModalOpen(false);
      setEmployeeData({ name: "", username: "" });

      // Redirect to team management page
      setTimeout(() => navigate("/dashboard/members"), 500); // Wait for 2 seconds before redirecting
    } catch (error) {
      // console.error("Error adding employee:", error);
      toast.error("Error adding employee");
    }
  };

  // Ensure scrolling is locked properly when the modal is open
  useEffect(() => {
    const lockScroll = () => {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden"; // Lock html as well
    };

    const unlockScroll = () => {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto"; // Restore html scroll
    };

    if (isModalOpen) {
      lockScroll();
    } else {
      unlockScroll();
    }

    // Cleanup to ensure scrolling is restored when component unmounts
    return () => unlockScroll();
  }, [isModalOpen]);

  const labels = data.map((item) => item.employee?.username || "");
  const chartdata = data.map((item) => item.employee?.number_of_taps || 0);
// {
//   // Chart data for Chart.js
//   const chartData = {
//     labels: labels,
//     datasets: [
//       {
//         label: "Performance",
//         data: chartdata,
//         borderColor: "#22c55e",
//         backgroundColor: "rgba(34, 197, 94, 0.2)",
//         pointBorderColor: "#22c55e",
//         pointBackgroundColor: "#22c55e",
//         tension: 0.4, // Smooth curves
//         fill: true,
//       },
//     ],
//   };

//   const chartOptions = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: {
//         display: false,
//       },
//     },
//     scales: {
//       y: {
//         beginAtZero: true,
//       },
//     },
//   };}
// Chart data for ApexCharts



const chartData = useMemo(() => {
  return {
    series: [
      {
        name: "Performance",
        data: chartdata,
      },
    ],
    options: {
      chart: {
        type: 'area',
        height: 300,
        toolbar: {
          show: false,
        },
        dataLabels: {
          enabled: false, 
        },  
       
      },
      grid: {
        show: true,
        borderColor: '#EAEAEC',
        strokeDashArray: 2,
        position: 'back',
        dataLabels: {
          enabled: false,
        },
       
      
      
      },
  
      markers: {
        size: 0,
        hover: { size: 0 } 
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: labels,
        labels: {
          style: {
            colors: '#6F707E',
            fontSize: '14px',
            fontWeight: 500,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#969696',
            fontSize: '12px',
          },
          formatter: (value) => `${value}`,
        },
        beginAtZero: true,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      fill: {
        type: "gradient",
        colors: ["#D8FECA"],
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 0.2,
          stops: [0, 100],
        },
      },
      colors: ["#00BA34"],
      tooltip: {
        y: {
          formatter: (val) => `${val}`,
        },
      },
    
    },
  };
}, [labels, chartdata]);

const handleViewAllToggle = () => setShowAll(!showAll);
 // Determine the number of cards to display based on screen size
 const numCardsToShow = showAll ? cardData.length : isSmallScreen ? 1 : isMediumScreen ? 2 : 4;


const displayedCardData = showAll
? cardData
: cardData.slice(0, numCardsToShow); // Adjust the number based on screen size if needed




  const handleSelect = (option) => {
    setSortOption(option); // Set the selected sort option
    setIsOpen(false); // Close the dropdown after selecting

    let sortedData = [...tableData];

    switch (option) {
      case "Date":
        sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
        break;

      case "High performance":
        sortedData.sort((a, b) => b.performance_score - a.performance_score);
        break;

      case "Name":
        sortedData.sort((a, b) =>
          a.employee.name.localeCompare(b.employee.name)
        );
        break;

      case "Issues":
        sortedData.sort((a, b) => b.issues - a.issues);
        break;

      case "Engagement":
        sortedData.sort(
          (a, b) => b.employee.number_of_taps - a.employee.number_of_taps
        );
        break;

      case "Rewards":
        sortedData.sort((a, b) => b.reward_total - a.reward_total);
        break;

      default:
        break;
    }

    setTableData(sortedData); // Update tableData with the sorted data
  };

  return (
    <div className="px-2 min-h-screen w-full">
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        pauseOnHover
      />
        {isFetching ? (
      <div className="flex justify-center items-center min-h-screen mt-[-150px] ">
        <div className="loader "></div>
        </div>
    ) : (
      <>
      {/* Header Section */}

      <div className="space-y-4 flex justify-between mb-3">
        <div>
        <h2 className="text-[20px] font-medium text-[#454861]">Employees</h2>
        <button
          aria-label="Add Employee"
          onClick={handleModalToggle}
          className="bg-[#0C4EF8] font-bold text-[16px] text-white px-4 py-2 mt-4 rounded-[12px] shadow-sm flex items-center gap-2 hover:bg-blue-700 transition"
          style={{
            boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
            animationDuration: '0ms',
          }}
        >
         {/* { <FaUserPlus />}  */}
         Add Employee
        </button>
        </div>
      <div className="flex ">
      <button
        className="text-[#0C4EF8] font-semibold text-[12px] justify-bottom mt-8 mr-2  pt-5 "
        onClick={handleViewAllToggle}
        aria-expanded={showAll}
        aria-label={showAll ? "Collapse content" : "Expand content"}
      >
        {showAll ? "Show Less" : "View All"}
      </button>
      </div>

      </div>

      {/* Add Employee Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50"
        onClick={handleModalToggle} // Close modal when overlay is clicked
        >
          <div className="bg-white rounded-xl overflow-hidden w-[90%] max-w-sm relative"
           onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
           >
            <div className="bg-[#0C4EF8] p-4">
              <h2 className="text-white font-semibold text-[20px">Add Employee</h2>
            </div>
            <form className="space-y-4 mt-4 p-4">
              <div>
                <label className="block text-[16px] font-medium text-[#282A3E] mb-1">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={employeeData.name}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-[#EAEAEC] rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-[16px] font-medium text-[#282A3E] mb-1">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  value={employeeData.username}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-[#EAEAEC] rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex  space-x-2 mt-6">
               
                <button
                  aria-label="Update Employee"
                  type="submit"
                  className="bg-[#0C4EF8] text-white text-[16px] font-bold px-4 py-2 rounded-lg shadow-sm hover:bg-blue-700 transition w-full"
                  onClick={handleSubmit}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Employee Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 mb-6 w-full">
        {displayedCardData.length === 0 ? (
          <p className="text-gray-500 text-center col-span-full">
            Employees details will appear here
          </p>
        ) : (
          displayedCardData.map((item, index) => (
            <div
              key={index}
              className="bg-white p-4 rounded-xl flex flex-col items-start space-y-3 cursor-pointer hover transition"
              onClick={() =>
                navigate(`/dashboard/employee-profile/${item.employee.id}`)
              }
            >
              <div className="flex items-center gap-2">
                <p className="w-12 h-12 rounded-full flex items-center justify-center text-white font-bold text-xl bg-blue-600">
                  {`${item.employee.name.split(" ")[0][0]}${
                    item.employee.name.split(" ")[1]
                      ? item.employee.name.split(" ")[1][0]
                      : ""
                  }`}
                </p>

                <h3 className="text-[16px] font-medium text-[#454861]">
                  {item.employee.name?.split(" ")[0]} <br />
                  {item.employee.name?.split(" ")[1]}
                </h3>
              </div>
              <div
                className={`text-lg flex items-center space-x-2 ${
                  item.performance_label === "Poor Employee"
                    ? "bg-red-100 text-red-600"
                    : "bg-blue-100 text-blue-600"
                } px-3 py-0 rounded-full`}
              >
                <div className="flex items-center space-x-1 rounded-full">
                  {item.performance_label === "Poor Employee" ? (
                    <MdError className="text-red-600 " />
                  ) : (
                    <MdVerified className="text-blue-600 w-[24px]" />
                  )}
                  <span className="text-[14px] font-medium">{item.performance_label}</span>
                </div>
              </div>
              <div className="flex items-center space-x-2 mt-1">
                <span className="text-gray-700 font-medium text-xl">
                  {item.employee.number_of_taps}
                  <span className="text-sm ml-2">taps</span>
                </span>
                <div className="flex items-center space-x-1">
                  <span className="text-[#1E1E1E] font-medium text-xl">
                    {item.average_rating}
                  </span>
                  <FaStar className="text-yellow-500" />
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Chart Section */}
      <div className="bg-white p-6 rounded-lg mb-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-[20px] text-[#292D32] font-medium">Employee <br/> Overview</h3>
          <div className="relative">
            <div className="border border-[#EAEAEC] rounded-md p-2">
            <select
              className="bg-white   text-[16px] focus:outline-none font-semibold cursor-pointer  text-[#282A3E] "
              defaultValue="Monthly"
            >
              <option value="Monthly">Monthly</option>
              {/* <option value="Weekly">Weekly</option>
              <option value="Daily">Daily</option> */}
            </select>
            </div>
           
          </div>
        </div>
        <div className="w-full h-66">
        {/* {  <Line data={chartData} options={chartOptions} />} */}
        <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="area"
                height={300}
              />
        </div>
      </div>

      {/* Employee Table */}
      <div className="w-full">
        <div className="bg-white p-4 flex md:space-x-2 sm:space-x-4 xs:space-x-12 items-center w-full rounded-t-[8px]">
          <h3 className="text-[24px] font-semibold text-[#4C4C4C]  md:w-2/12  sm:w-3/12 xs:w-6/12">Employees</h3>
          <div className="flex items-center md:space-x-8 sm:space-x-2 md:w-10/12 sm:w-11-/12 xs:w-full">
            {/* Sort Dropdown */}
            <div className="relative inline-block text-left md:w-3/12 sm:w-5/12 xs:w-11/12">
              <button
                aria-label="Sort"
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center font-medium md:space-x-4 xs:space-x-2 md:py-1 xs:py-2 md:px-6 xs:px-2 border border-[#EAEAEC] rounded-[9px] bg-[#F8FAFF] text-[#454861] hover:bg-gray-100 transition"
              >
                <PiFunnelBold className="text-lg" />
                <span>{sortOption}</span>
                {isOpen ? (
                  <IoCaretUpOutline className="text-sm" />
                ) : (
                  <IoCaretDownOutline className="text-sm" />
                )}
              </button>

              {isOpen && (
                <div className="absolute z-10 mt-2 w-48 bg-white rounded-lg">
                  <ul className="py-1 text-sm text-gray-700">
                    {[
                      "Date",
                      "High performance",
                      "Name",
                      "Issues",
                      "Engagement",
                      "Rewards",
                    ].map((option) => (
                      <li
                        key={option}
                        onClick={() => handleSelect(option)}
                        className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Search Input */}
            <div className="hidden sm:flex relative border items-center py-1 px-2 rounded-[51px] w-7/12  ">
            <HiOutlineMagnifyingGlass className="  text-[#343330] w-5 h-5" />
              <input
                type="text"
                placeholder=""
                className="px-4 py-1  focus:outline-none  w-auto w-full text-[14px]"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              
            </div>

           <div className="hidden sm:flex md:w-2/12 sm:w-4/12">
           <button aria-label="See all" className="bg-[#F5F8FF] flex gap-2 font-bold sm:w-full text-[#282A3E] items-center px-4 py-1 border border-[#EAEAEC] rounded-[8px] hover:bg-gray-200 transition">
              See all
              <TiArrowSortedDown />
            </button>
           </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-separate border-spacing-y-3">
            <thead>
              <tr className="text-left text-[#6F707E] text-[16px] font-medium">
                <th className="px-4 py-2 font-normal">S/N</th>
                <th className="px-4 py-2 font-normal">Name</th>
                <th className="px-4 py-2 font-normal">Card URL</th>
                <th className="px-4 py-2 font-normal">Feedback</th>
                <th className="px-4 py-2 font-normal">Rewards</th>
                <th className="px-4 py-2 font-normal">Rating</th>
                <th className="px-4 py-2 font-normal">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredTableData.map((item, index) => (
                <tr
                  key={index}
                  className="bg-white text-[#282A3E] rounded-lg hover transition "
                >
                  <td data-cell="S/N" className="md:px-2 py-2 ">
                    <p className="flex item-center md:justify-center text-[#535565] ">{index + 1}
                    </p></td>
                  {/* Name with Avatar */}
                  <td data-cell="Name" className="px-4 py-2 ">
                    <div className="flex items-center space-x-4 ">
                      <div className="p-2 w-9 rounded-full bg-blue-600">
                        <p className="flex items-center justify-center text-white font-semibold text-[12px] ">
                      {`${item.employee.name.split(" ")[0][0]}${
                        item.employee.name.split(" ")[1]
                          ? item.employee.name.split(" ")[1][0]
                          : ""
                      }`}
                    </p></div>
                    <span className=" text-[16px] font-semibold"
                    >{item.employee.name}</span>
                    </div>
                  </td>
                  {/* Card URL with Copy Icon */}
                  <td data-cell="card URL" className="px-2 py-2 ">
                    <div className="nfc-url flex  items-center gap-3">
                      <a
                        href={item.employee.employee_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className=" w-48 text-[14px] break-words italic font-medium"
                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal',
                        }}
                      >
                        {item.employee.employee_url}
                      </a>
                      <button
                          aria-label="Copy URL"
                          className="text-[#0C4EF8]"
                          onClick={() => {
                            navigator.clipboard.writeText(item.employee.employee_url)
                              .then(() => {
                                // Show the toast notification after successfully copying
                                toast.success("Employee URL copied to clipboard!");
                                alert("Link copied succesffully")
                              })
                              .catch(() => {
                                // Optional: Show error if copying fails
                                toast.error("Failed to copy URL to clipboard.");
                              });
                          }}
                        >
                          <PiCopyBold className=" w-4 h-4" />
                        </button>
                    </div>
                  </td>
                  <td data-cell="Feedback"  className="px-4 py-2 font-semibold text-[16px]">{item.total_feedback}</td>
                  <td data-cell="Rewards" className="px-4 py-2 font-semibold text-[16px]">
                    {item.reward_currency} {item.reward_total}
                  </td>
                  {/* Rating with Star Icon */}
                  <td data-cell="Rating" className="px-4 py-2 font-semibold text-[16px]">
                    <div className="flex  items-center gap-2">
                      <p className="w-4">{item.average_rating}</p>
                      <FaStar className="text-yellow-500" />
                    </div>
                  </td>
                  {/* Action Buttons */}
                  <td   data-cell="Action" className="md:px-8  py-2   ">
                   <div className="space-x-2 flex flex-row">
                   <button
                      aria-label="Update"
                      className=" py-1 bg-[#0C4EF8] font-semibold text-[#FEFEFF] text-[14px] rounded-[4px] hover:bg-blue-600 transition xs:w-5/12 md:w-6/12 flex justify-center items-center"
                      onClick={() => handleUpdate(index)}
                    >
                      Update
                    </button>
                    <button
                      aria-label="Delete"
                      onClick={() => handleDeleteClick(item)}
                      className=" py-1 bg-[#FDEAEB] font-semibold text-[#E6283B] rounded-[4px]  hover:bg-red-600 transition xs:w-5/12 md:w-6/12"
                    >
                      Delete
                    </button>
                   </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showPopup && selectedItemIndex !== null && data[selectedItemIndex] ? (
        <EditForm
          selectedItem={data[selectedItemIndex]?.employee} // Use optional chaining
          onUpdate={handleUpdateData}
          onClose={handleEditFormClose}
        />
      ) : null}

      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50"
        onClick={handleDeleteCancel}
        >
          <div className="bg-white rounded-xl p-6 w-[90%] max-w-sm text-center space-y-4"
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <FaExclamationTriangle className="text-[#EE6F7C] text-5xl mx-auto" />
            <h2 className="text-2xl text-[#454861] font-semibold">Delete Employee?</h2>
            <p className="text-[#6F707E] font-normal text-[20px]" >This action cannot be undone.</p>
            <div className="flex justify-center space-x-4 mt-4">
              <button
                aria-label="Cancel"
                className="bg-[#0C4EF8] text-[#FEFEFF] font-semibold px-8 py-1 rounded-[4px] hover:bg-blue-700 transition"
                onClick={handleDeleteCancel}
              >
                Cancel
              </button>
              <button
                aria-label="Delete"
                className=" border border-[#F7BCC2] text-[#E6283B] font-semibold px-8 py-1 rounded-[4px] hover:bg-red-600 hover:text-white transition"
                onClick={handleDeleteConfirm}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
             )}
      </>
      )}
    </div>
  );
};

export default Employees;
