import { Bar } from "react-chartjs-2";
import { FaArrowUp } from "react-icons/fa";
import { useTimeRange } from "../../../../context/TimeRangeContext";

const IncentivesSection = () => {
  const { timeRange } = useTimeRange();

  const incentivesData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "Incentives",
        data: [50, 70, 80, 90, 100, 120, 150],
        backgroundColor: [
          "#e4e8f3",
          "#e4e8f3",
          "#e4e8f3",
          "#e4e8f3",
          "#e4e8f3",
          "#e4e8f3",
          "#007bff",
        ],
        borderRadius: 10,
        barThickness: 16,
      },
    ],
  };

  const incentivesOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        display: false,
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        display: false,
      },
    },
  };

  return (
    <div
      className={`bg-white shadow-md rounded-xl p-6 flex flex-col justify-between`}
    >
      <h4 className="text-sm font-medium text-gray-500">Total Incentive</h4>
      <div className="flex justify-between items-center">
        {/* Left Side Content */}
        <div className="flex flex-col gap-2">
          <h2 className="text-4xl font-bold flex items-center gap-1 text-gray-900">
            0 <span className="text-green-500 text-sm font-medium">usd</span>
          </h2>
          <p className="text-base font-light flex items-center gap-1 text-gray-900">
            {/* <span className="text-green-500 text-sm font-medium">+15% </span> */}
            {/* vs last month */}
          </p>
          <p className="text-green-500 text-sm font-medium"></p>
        </div>

        {/* Right Side Chart with Arrow */}
        <div className="relative flex items-center h-20 w-32">
          {/* Chart */}
          <Bar data={incentivesData} options={incentivesOptions} />

          {/* Up Arrow */}
          <div className="absolute top-0 right-0 bg-green-100 p-1 rounded-full flex items-center justify-center">
            <FaArrowUp className="text-green-500 text-sm" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncentivesSection;
