import { useContext } from "react";
import { Bar } from "react-chartjs-2";
import { FaRegStar } from "react-icons/fa";
import { BranchContext } from "../../../context/BranchContext"; // Adjust path if necessary
import { useTimeRange } from "../../../../context/TimeRangeContext";


const ReviewsSection = ({ rowSpan }) => {
  const { timeRange } = useTimeRange();
  
  const { googleReviewsData, reviewsLoading } = useContext(BranchContext);
console.log(googleReviewsData)
  const reviewsData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Reviews",
        data: [50, 70, 60, 90, 100, 80, 110],
        backgroundColor: "#007bff",
        borderRadius: 4,
      },
    ],
  };

  const reviewsOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        display: false,
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        display: false,
      },
    },
  };

  return (
    <div
      className={`bg-white relative shadow-sm rounded-xl p-6 flex flex-col gap-2 ${rowSpan}`}
    >
      {/* Header Section */}
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className="bg-yellow-100 text-yellow-500 p-2 rounded-full">
            <FaRegStar />
          </span>
          <h4 className="text-lg font-semibold text-gray-700">Total Reviews</h4>
        </div>
      </div>

      {/* Data Section */}
      <div className="flex items-center gap-4">
        <h2 className="text-4xl font-bold text-gray-900">{new Intl.NumberFormat().format(googleReviewsData.total_reviews)}</h2>
        {/* <p className="bg-blue-100 text-blue-500 px-2 py-1 rounded-md text-sm font-medium inline-block">
          +10.4%
        </p> */}
      </div>

      <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between gap-4">
        {/* Bar Chart */}
        <div className="h-20 flex-1">
          <Bar data={reviewsData} options={reviewsOptions} />
        </div>

        {/* Footer Text */}
        <p className="text-sm text-gray-500">
          Increase in data by {googleReviewsData.total_reviews} in the last {timeRange}
        </p>
      </div>
    </div>
  );
};

export default ReviewsSection;
