// import { lazy } from "react";

// const DashboardPage = lazy(() => import("./New-Dashboard/components/DashboardPage"));
// const Reviews = lazy(() => import("./New-Dashboard/components/Reviews"));
// const Feedback = lazy(() => import("./New-Dashboard/components/Feedback"));
// const FeedbacksAll = lazy(() => import("./New-Dashboard/components/FeedbacksAll"));
// const Reward = lazy(() => import("./New-Dashboard/components/Reward"));
// const Sentiment = lazy(() => import("./New-Dashboard/components/Sentiment"));
// const Analytics = lazy(() => import("./New-Dashboard/components/Analytics"));
// const TeamPerformance = lazy(() => import("./New-Dashboard/components/TeamPerformance"));
// const MobilePreviewPage = lazy(() => import("./New-Dashboard/components/MobilePreviewPage"));
// const CustomerTable = lazy(() => import("./New-Dashboard/components/CustomerTable"));
// const CompanyProfile = lazy(() => import("./companydashboard/CompanyProfile/CompanyProfile"));
// const ContactsPage = lazy(() => import("./New-Dashboard/components/Contacts"));
// const TeamManagement = lazy(() => import("./companydashboard/TeamManagement/TeamManagement"));
// const AddEmployee = lazy(() => import("./companydashboard/AddEmployee/AddEmployee"));
// const CreateAccount = lazy(() => import("./New-Dashboard/components/CreateAccount/CreateAccount"));
// const Payment = lazy(() => import("./New-Dashboard/components/Payment/Payment"));
// const Subscriptions = lazy(() => import("./New-Dashboard/components/Subscriptions/Subscriptions"));
// const HelpAndSupport = lazy(() => import("./New-Dashboard/components/HelpAndSupport"));
// const CompanyEmployees = lazy(() => import("./New-Dashboard/components/CompanyEmployees"));
// const HelpDetails = lazy(() => import("./New-Dashboard/components/HelpDetails"));
// const Employees = lazy(() => import("./New-Dashboard/components/Employees"));
// const AnalyticsDashboard = lazy(() => import("./New-Dashboard/components/Surveyanalytics/Analytics"));
// const SurveyDashboard = lazy(() => import("./New-Dashboard/components/SurveyDashboard"));
// const SurveyRequest = lazy(() => import("./New-Dashboard/components/SurveyRequest"));
// const AIInsightsSurvey = lazy(() => import("./New-Dashboard/components/AIInsightsSurvey"));
// const Companies = lazy(() => import("./New-Dashboard/components/Companies"));
// const CompanyDetail = lazy(() => import("./New-Dashboard/components/CompanyDetail"));
// const AIInsights = lazy(() => import("./New-Dashboard/components/AIInsights"));
// const AIChat = lazy(() => import("./New-Dashboard/components/AIChat"));
// const RequestFeedback = lazy(() => import("./New-Dashboard/components/RequestFeedback"));
// const ManageFeedback = lazy(() => import("./New-Dashboard/components/ManageFeedback"));
// const Coupons = lazy(() => import("./New-Dashboard/components/Coupons"));
// const CouponDetail = lazy(() => import("./New-Dashboard/components/Coupons/CouponDetail"));
// const Campaigns = lazy(() => import("./New-Dashboard/components/Campaigns"));
// const CampaignDashboard = lazy(() => import("./New-Dashboard/components/Campaigns/CampaignDashboard"));
// const EmployeeProfile = lazy(() => import("./New-Dashboard/components/EmployeeProfile"));
// const Survey = lazy(() => import("./New-Dashboard/components/Survey"));
// const SurveyAnalysis = lazy(() => import("./New-Dashboard/components/SurveyAnalysis"));
// const SurveyResponses = lazy(() => import("./New-Dashboard/components/SurveyResponses"));
// const SurveyResponseDetails = lazy(() => import("./New-Dashboard/components/SurveyResponseDetails"));
// const SurveyDetail = lazy(() => import("./New-Dashboard/components/SurveyDetail"));
// const SurveySingleResponse = lazy(() => import("./New-Dashboard/components/SurveySingleResponse"));
// const Branch = lazy(() => import("./New-Dashboard/components/Branch"));
// const LeaderBoard = lazy(() => import("./New-Dashboard/components/LeaderBoard"));
// const AccountSettings = lazy(() => import("./New-Dashboard/components/Setting"));
// const Account = lazy(() => import("./New-Dashboard/components/AccountSettings/Account"));
// const Notification = lazy(() => import("./New-Dashboard/components/AccountSettings/Notification"));
// const Language = lazy(() => import("./New-Dashboard/components/AccountSettings/LanguageComponent"));
// const Billing = lazy(() => import("./New-Dashboard/components/AccountSettings/BillingComponent"));
// const PaymentPage = lazy(() => import("./New-Dashboard/components/AccountSettings/PaymentDetailsPage"));
// const PasswordSettings = lazy(() => import("./New-Dashboard/components/AccountSettings/PasswordSettings"));
// const RewardAmountUpdater = lazy(() => import("./New-Dashboard/components/RewardSection/AddReward"));
// const SubmitCardsComponent = lazy(() => import("./New-Dashboard/components/Payment/CardPayment"));
// const ReviewManagement  = lazy(() => import("./New-Dashboard/components/ReviewManagement"));

import DashboardPage from "./New-Dashboard/components/DashboardPage";
import Reviews from "./New-Dashboard/components/Reviews";
import Feedback from "./New-Dashboard/components/Feedback";
import FeedbacksAll from "./New-Dashboard/components/FeedbacksAll";
import Reward from "./New-Dashboard/components/Reward";
import Sentiment from "./New-Dashboard/components/Sentiment";
import Analytics from "./New-Dashboard/components/Analytics";
import TeamPerformance from "./New-Dashboard/components/TeamPerformance";
import MobilePreviewPage from "./New-Dashboard/components/MobilePreviewPage";
import CustomerTable from "./New-Dashboard/components/CustomerTable";
import CompanyProfile from "./companydashboard/CompanyProfile/CompanyProfile";
import ContactsPage from "./New-Dashboard/components/Contacts";
import TeamManagement from "./companydashboard/TeamManagement/TeamManagement";
import AddEmployee from "./companydashboard/AddEmployee/AddEmployee";
import CreateAccount from "./New-Dashboard/components/CreateAccount/CreateAccount";
import Payment from "./New-Dashboard/components/Payment/Payment";
import Subscriptions from "./New-Dashboard/components/Subscriptions/Subscriptions";
import HelpAndSupport from "./New-Dashboard/components/HelpAndSupport";
import CompanyEmployees from "./New-Dashboard/components/CompanyEmployees";
import HelpDetails from "./New-Dashboard/components/HelpDetails";
import Employees from "./New-Dashboard/components/Employees";
import AnalyticsDashboard from "./New-Dashboard/components/Surveyanalytics/Analytics";
import SurveyDashboard from "./New-Dashboard/components/SurveyDashboard";
import SurveyRequest from "./New-Dashboard/components/SurveyRequest";
import AIInsightsSurvey from "./New-Dashboard/components/AIInsightsSurvey";
import Companies from "./New-Dashboard/components/Companies";
import CompanyDetail from "./New-Dashboard/components/CompanyDetail";
import AIInsights from "./New-Dashboard/components/AIInsights";
import AIChat from "./New-Dashboard/components/AIChat";
import RequestFeedback from "./New-Dashboard/components/RequestFeedback";
import ManageFeedback from "./New-Dashboard/components/ManageFeedback";
import Coupons from "./New-Dashboard/components/Coupons";
import CouponDetail from "./New-Dashboard/components/Coupons/CouponDetail";
import Campaigns from "./New-Dashboard/components/Campaigns";
import CampaignDashboard from "./New-Dashboard/components/Campaigns/CampaignDashboard";
import EmployeeProfile from "./New-Dashboard/components/EmployeeProfile";
import Survey from "./New-Dashboard/components/Survey";
import SurveyAnalysis from "./New-Dashboard/components/SurveyAnalysis";
import SurveyResponses from "./New-Dashboard/components/SurveyResponses";
import SurveyResponseDetails from "./New-Dashboard/components/SurveyResponseDetails";
import SurveyDetail from "./New-Dashboard/components/SurveyDetail";
import SurveySingleResponse from "./New-Dashboard/components/SurveySingleResponse";
import Branch from "./New-Dashboard/components/Branch";
import LeaderBoard from "./New-Dashboard/components/LeaderBoard";
import AccountSettings from "./New-Dashboard/components/Setting";
import Account from "./New-Dashboard/components/AccountSettings/Account";
import Notification from "./New-Dashboard/components/AccountSettings/Notification";
import Language from "./New-Dashboard/components/AccountSettings/LanguageComponent";
import Billing from "./New-Dashboard/components/AccountSettings/BillingComponent";
import PaymentPage from "./New-Dashboard/components/AccountSettings/PaymentDetailsPage";
import PasswordSettings from "./New-Dashboard/components/AccountSettings/PasswordSettings";
import RewardAmountUpdater from "./New-Dashboard/components/RewardSection/AddReward";
import SubmitCardsComponent from "./New-Dashboard/components/Payment/CardPayment";
// import ReviewManagement from "./New-Dashboard/components/ReviewManagement";


export const DashboardRoutes = [
  { path: "", element: <DashboardPage /> },
  { path: "reviews", element: <Reviews /> },
  { path: "feedback", element: <Feedback /> },
  { path: "feedbacks", element: <FeedbacksAll /> },
  { path: "reward", element: <Reward /> },
  { path: "sentiment", element: <Sentiment /> },
  { path: "analytics", element: <Analytics /> },
  { path: "team-performance", element: <TeamPerformance /> },
  { path: "mobile-preview", element: <MobilePreviewPage /> },
  { path: "customer-data", element: <CustomerTable /> },
  { path: "companyprofile", element: <CompanyProfile /> },
  { path: "contacts", element: <ContactsPage /> },
  { path: "teammanagement", element: <TeamManagement /> },
  { path: "addemployee", element: <AddEmployee /> },
  { path: "create-account", element: <CreateAccount /> },
  { path: "payment", element: <Payment /> },
  { path: "subscriptions", element: <Subscriptions /> },
  { path: "help", element: <HelpAndSupport /> },
  { path: "companyemployees", element: <CompanyEmployees /> },
  { path: "help/:id", element: <HelpDetails /> },
  { path: "employees", element: <Employees /> },
  { path: "surveyanalytics", element: <AnalyticsDashboard /> },
  { path: "surveydashboard", element: <SurveyDashboard /> },
  { path: "survey-request", element: <SurveyRequest /> },
  { path: "survey-ai-insights", element: <AIInsightsSurvey /> },
  { path: "companies", element: <Companies /> },
  { path: "companies/:id", element: <CompanyDetail /> },
  { path: "ai-insights", element: <AIInsights /> },
  { path: "ai-chat", element: <AIChat /> },
  { path: "request-feedback", element: <RequestFeedback /> },
  { path: "review-management", element: <ManageFeedback /> },
  { path: "coupons", element: <Coupons /> },
  { path: "coupons/:id", element: <CouponDetail /> },
  // { path: "review-management", element: <ReviewManagement /> },
  { path: "campaigns", element: <Campaigns /> },
  { path: "campaign-details/:id", element: <CampaignDashboard /> },
  { path: "employee-profile/:employeeId", element: <EmployeeProfile /> },
  { path: "survey", element: <Survey /> },
  { path: "survey-analysis", element: <SurveyAnalysis /> },
  { path: "survey-responses", element: <SurveyResponses /> },
  { path: "survey-response/:id", element: <SurveyResponseDetails /> },
  { path: "survey/:surveyId", element: <SurveyDetail /> },
  {
    path: "survey-response-details/:responseId",
    element: <SurveySingleResponse />,
  },
  { path: "branch", element: <Branch /> },
  { path: "leaderboard", element: <LeaderBoard /> },
  {
    path: "setting",
    element: <AccountSettings />, // Parent Route
    children: [
      { path: "", element: <Account /> }, // Default when accessing /dashboard/setting
      { path: "notification", element: <Notification /> },
      { path: "language", element: <Language /> },
      { path: "billing", element: <Billing /> },
      { path: "update-details", element: <PaymentPage /> },
      { path: "security", element: <PasswordSettings /> },
      { path: "reward", element: <RewardAmountUpdater /> },
      { path: "cards", element: <SubmitCardsComponent /> },
    ],
  },
];
