import { useState, useEffect, useRef } from "react";
import { X } from "lucide-react";
import confetti from "canvas-confetti"; // 👈 import confetti
import html2canvas from "html2canvas";
import QRCode from "qrcode";

function CouponRedemption({ onClose }) {
  const [couponData, setCouponData] = useState(null);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const couponRef = useRef(null);
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  useEffect(() => {
    const loadCouponData = () => {
      try {
        const storedCoupon = localStorage.getItem("coupon_data");

        if (!storedCoupon) {
          throw new Error("No coupon data found in local storage.");
        }

        const parsedCoupon = JSON.parse(storedCoupon);

        if (!parsedCoupon.unique_code) {
          throw new Error("Invalid coupon data.");
        }

        setCouponData(parsedCoupon);

        QRCode.toDataURL(parsedCoupon.unique_code)
          .then((url) => {
            setQrCodeUrl(url);
          })
          .catch((err) => {
            console.error("Failed to generate QR code:", err);
          });

        confetti({
          particleCount: 900,
          spread: 400,
          origin: { y: 0.3 },
        });
      } catch (err) {
        console.error("Error loading coupon data:", err);
        setError("Failed to load coupon");
      } finally {
        setLoading(false);
      }
    };

    loadCouponData();
  }, []);

  // ✅ This effect clears localStorage only *after* couponData is fully set
  useEffect(() => {
    if (couponData) {
      const timeout = setTimeout(() => {
        localStorage.removeItem("coupon_data");
        localStorage.removeItem("zola_redirect_timer_start");
      }, 3000); // wait a little to ensure data has been used

      return () => clearTimeout(timeout);
    }
  }, [couponData]);

  const handleCopy = () => {
    if (couponData?.unique_code) {
      navigator.clipboard.writeText(couponData.unique_code);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  // Extract discount amount and type
  let discountAmount = couponData?.unique_code?.split("-")[1] || "Unknown";

  // Check if it's a percentage or a dollar value
  let discountDisplay = "Unknown OFF";
  if (discountAmount.includes("%")) {
    discountDisplay = `${discountAmount} OFF`;
  } else if (discountAmount.toUpperCase().includes("USD")) {
    discountDisplay = `$${discountAmount.replace("USD", "").trim()} OFF`;
  }

  const handleDownload = async () => {
    if (!couponRef.current) return;

    try {
      await document.fonts.ready; // wait for fonts
      const canvas = await html2canvas(couponRef.current, {
        useCORS: true,
        backgroundColor: "#fff",
        scale: 2, // make it sharper
      });

      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "your_coupon.png";
      link.click();
    } catch (err) {
      console.error("Download failed:", err);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm z-50">
      <div
        ref={couponRef}
        className="relative bg-white p-8 rounded-3xl shadow-2xl text-center max-w-md w-full animate-fadeIn"
      >
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
          aria-label="Close"
        >
          <X className="w-6 h-6" />
        </button>

        <h2 className="text-3xl font-extrabold mb-2 text-gray-900 dark:text-white">
          THANK YOU
        </h2>
        <p className="text-sm text-gray-500 dark:text-gray-400 mb-6">
          for being a great customer
        </p>

        <p className="text-5xl font-black text-purple-600 mb-2">
          {discountDisplay}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 mb-6">
          on your next order with us
        </p>

        {qrCodeUrl && (
          <div className="flex justify-center my-4">
            <img src={qrCodeUrl} alt="Coupon QR Code" className="w-28 h-28" />
          </div>
        )}

        {loading ? (
          <p className="text-gray-500 dark:text-gray-400">Loading coupon...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <>
            <button
              onClick={handleCopy}
              className="w-full py-3 bg-white dark:bg-gray-800 border-2 border-dashed border-purple-500 text-purple-700 dark:text-purple-400 font-bold rounded-full mb-2 hover:bg-purple-50 dark:hover:bg-gray-700 transition-all"
            >
              {couponData.unique_code}
            </button>
            <p className="text-xs text-purple-500 mt-1">
              {copied ? "✅ Code copied!" : "Tap to copy code"}
            </p>

            <p className="text-xs text-gray-500 dark:text-gray-400 mt-4">
              {couponData.user_email && `Issued to: ${couponData.user_email}`}
            </p>

            {couponData.is_used ? (
              <p className="text-xs text-red-500 mt-2">
                ⚠️ Coupon already used
              </p>
            ) : (
              <p className="text-xs text-green-500 mt-2">✅ Coupon is valid</p>
            )}
          </>
        )}

        <button
          onClick={handleDownload}
          className="w-full mt-4 py-2 text-sm bg-blue-600 hover:bg-blue-700 text-white rounded-full transition"
        >
          Download Coupon
        </button>

        {couponData?.coupon?.valid_until && (
          <p className="text-xs text-gray-400 dark:text-gray-500 mt-8">
            Offer valid until{" "}
            {new Date(couponData.coupon.valid_until).toLocaleDateString(
              undefined,
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}
          </p>
        )}
      </div>
    </div>
  );
}

export default CouponRedemption;
