import { useState } from "react";
import { MdClose } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddContactModal = ({ isOpen, onClose, onContactAdded }) => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Authentication token not found.");
      return;
    }
  
    setLoading(true);
  
    try {
      const requestBody = { name: formData.name };
  
      if (formData.phoneNumber) {
        requestBody.phone_number = formData.phoneNumber;
      }
      
      if (formData.email) {
        requestBody.email = formData.email;
      }
  
      const response = await fetch("https://zola.technology/api/company/customers/contact/create/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        toast.success("Contact added successfully!");
        setFormData({ name: "", phoneNumber: null, email: null });
        onContactAdded();
        onClose(); // Close modal after successful submission
      } else {
        // Check for specific error messages
        if (data?.detail === "A contact with this email or phone number already exists for your company.") {
          toast.error("This email or phone number is already in use.");
        } else {
          toast.error(data?.phone_number?.[0] || data?.email?.[0] || "Failed to add contact.");
        }
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
          <button className="absolute top-3 right-3 text-gray-600" onClick={onClose}>
            <MdClose size={20} />
          </button>
          <h2 className="text-blue-600 text-xl font-semibold text-center">
            Create a Contact
          </h2>

          {/* Name Field */}
          <div className="mt-6">
            <label className="text-sm font-medium">Name</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="w-full border-b-2 border-blue-600 outline-none p-1"
            />
          </div>

          {/* Phone Number Field */}
          <div className="mt-4">
            <label className="text-sm font-medium text-gray-500">Phone Number</label>
            <input
              type="text"
              value={formData.phoneNumber}
              onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
              className="w-full border-b border-gray-300 outline-none p-1"
            />
          </div>

          {/* Email Field */}
          <div className="mt-4">
            <label className="text-sm font-medium text-gray-500">Email</label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="w-full border-b border-gray-300 outline-none p-1"
            />
          </div>

          {/* Add Button */}
          <button
            onClick={handleSubmit}
            className="w-full bg-blue-500 text-white py-2 rounded-lg mt-6 text-lg font-semibold disabled:bg-blue-300"
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Contact"}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddContactModal;
