import { motion } from 'framer-motion';
import logo1 from './Google.png';
import logo2 from './Google.png';
import logo3 from './Google.png';
import logo4 from './Google.png';
import logo5 from './Google.png';
import logo6 from './Google.png';

// Array of logos to easily add or update
const logos = [logo1, logo2, logo3, logo4, logo5, logo6];

const OurCustomers = () => {
  return (
    <div className="bg-[#0B3A6E] py-16 my-8 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col gap-8 lg:flex-row justify-between items-center">
        {/* Left Column: Logo Section */}
        <div className="flex flex-wrap justify-center lg:w-1/2 mb-12 lg:mb-0">
          {logos.map((logo, index) => (
            <motion.div
              key={index}
              className="flex justify-center items-center w-36 p-4 bg-transparent"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <img
                src={logo}
                alt={`Customer logo ${index + 1}`}
                className="h-16 md:h-20 object-contain"
              />
            </motion.div>
          ))}
        </div>

        {/* Right Column: Text Section */}
        <div className="lg:w-1/2 text-center lg:text-left text-white">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Our Customers
          </h2>
          <p className="text-base md:text-lg">
            Join hundreds of other companies using Zola to supercharge their customers&apos; experiences.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurCustomers;
