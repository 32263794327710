import { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const EmployeeOverallPerformance = () => {
  const [filter, setFilter] = useState(
    localStorage.getItem("filter") || "month"
  );
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Employee Performance",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false, // 👈 disables scroll/zoom
        },
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: "#8c9098",
            fontSize: "12px",
          },
        },
        tickPlacement: "on",
      },
      yaxis: {
        min: 0,
        forceNiceScale: true,
        labels: {
          style: {
            colors: "#8c9098",
            fontSize: "12px",
          },
          formatter: (value) => value.toLocaleString(),
        },
        title: {
          text: "Performance (in taps)",
          style: {
            color: "#8c9098",
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        theme: "dark",
        marker: {
          show: true,
        },
        y: {
          formatter: (value) => value.toLocaleString(),
        },
      },
      markers: {
        size: 6,
        colors: ["#fff"],
        strokeColors: "#0055ff",
        strokeWidth: 2,
        hover: {
          size: 8,
        },
      },
      fill: {
        type: "full",
        // gradient: {
        //   shade: "light",
        //   gradientToColors: ["#cce5ff"],
        //   shadeIntensity: 1,
        //   type: "vertical",
        //   inverseColors: false,
        //   opacityFrom: 0.5,
        //   opacityTo: 0.1,
        //   stops: [0, 100],
        // },
      },
      grid: {
        borderColor: "#e7e7e7",
      },
      colors: ["blue"],
    },
  });

  const [loading, setLoading] = useState(true);

  const fetchPerformanceData = async (currentFilter) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/analytics/get-all-with-taps/?filter=${currentFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        const data = result.employees.map(
          (item) => item.filtered_taps_count || 0
        );
        const categories = result.employees.map(
          (item) => item.name || "Unknown"
        );

        setChartData((prevData) => ({
          ...prevData,
          series: [
            {
              name: "Employee Performance (taps)",
              data,
            },
          ],
          options: {
            ...prevData.options,
            xaxis: {
              ...prevData.options.xaxis,
              categories,
            },
          },
        }));
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching performance data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPerformanceData(filter);

    const handleStorageChange = (event) => {
      if (event.key === "filter") {
        setFilter(event.newValue || "month");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    const interval = setInterval(() => {
      const currentFilter = localStorage.getItem("filter");
      if (currentFilter !== filter) {
        setFilter(currentFilter || "month");
      }
    }, 500);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      clearInterval(interval);
    };
  }, [filter]);

  useEffect(() => {
    fetchPerformanceData(filter);
  }, [filter]);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 mb-4 shadow-md">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
        Employee Performance Overview
      </h2>
      {loading ? (
        <p className="text-gray-700 dark:text-gray-300">Loading...</p>
      ) : (
        <div className="w-[95%] md:w-full h-[350px] lg:h-[400px]">
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={350}
          />
        </div>
      )}
    </div>
  );
};

export default EmployeeOverallPerformance;
