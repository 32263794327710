import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import badgeCover1 from "../assets/badgeCover1.png";
import badgeCover2 from "../assets/badgeCover2.png";
import badgeCover3 from "../assets/badgeCover3.png";
import { useTimeRange } from "../../context/TimeRangeContext";


// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title
);

import badge1 from "../assets/Badge1.png"; // Adjust the path as necessary
import badge2 from "../assets/Badge2.png"; // Adjust the path as necessary
import badge3 from "../assets/badge3.png";


import right from "../assets/right.png";
import left from "../assets/left.png";
import EmployeeRewardsTable from "./RewardSection/RewardTable";
const badges = [badge1, badge1, badge2, badge3];

function Reward() {
  const [topUsers, setTopUsers] = useState([]);
  const [otherUsers, setOtherUsers] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const [reviewChange, setReviewChange] = useState("");
  const [rating, setRating] = useState(0);
  const [pastWinners, setPastWinners] = useState([]);
  const [currentWinnerIndex, setCurrentWinnerIndex] = useState(0);
  const [rewardData, setRewardData] = useState();
  const { timeRange } = useTimeRange();

  const [reviewData, setReviewData] = useState({
    series: [{ name: "Feedbacks Generated", data: [] }],
    options: {
      chart: {
        type: "area",
        height: 300,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [], // Placeholder for user names
        labels: {
          style: {
            colors: "#6F707E",
          },
        },
      },

      yaxis: {
        labels: {
          style: {
            colors: "#6F707E",
          },
        },
      },
      stroke: {
        curve: "straight",
        width: 2,
      },
      markers: {
        size: 0,
        hover: { size: 0 },
      },
      dataLabels: {
        enabled: false,
      },

      grid: {
        color: "#e7e7e7",
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.2,
          stops: [0, 90, 100],
        },
      },
      colors: ["#00BA34"],
      tooltip: {
        theme: "dark",
      },
    },
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://zola.technology/api/company/employees/rewards/present-and-past/?filter=${timeRange}`, // Updated API endpoint with dynamic filter
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const mappedTopUsers = data.current_period_winners.slice(0, 3).map((user, index) => ({ // Extracting current winners only
          name: user.employee.name,
          reviews: user.reward_total,
          position: index === 0 ? "1st" : index === 1 ? "2nd" : "3rd",
          avatar:
            user.employee.avatar ||
            "https://t3.ftcdn.net/jpg/06/33/54/78/360_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg",
          color:
            index === 0
              ? "bg-yellow-400"
              : index === 1
              ? "bg-red-400"
              : "bg-blue-400",
        }));
  
        setTopUsers(mappedTopUsers);
      } catch (error) {
        toast.error("Failed to fetch employee rewards data.");
      }
    };
  
    fetchUserData();
  }, [timeRange])

  const reorderedUsers = [
    topUsers[0] || null,
    topUsers[1] || null,
    ...topUsers.slice(2),
  ].filter(Boolean);
  console.log(reorderedUsers);
  const nextWinner = () => {
    setCurrentWinnerIndex((prevIndex) => (prevIndex + 1) % pastWinners.length);
  };

  const prevWinner = () => {
    setCurrentWinnerIndex(
      (prevIndex) => (prevIndex - 1 + pastWinners.length) % pastWinners.length
    );
  };

  const [chartDimensions, setChartDimensions] = useState({
    height: "400px",
    width: "100%",
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setChartDimensions({ height: "200px", width: "100%" }); // Small screens
      } else if (window.innerWidth < 1024) {
        setChartDimensions({ height: "400px", width: "80%" }); // Medium screens
      } else {
        setChartDimensions({ height: "300px", width: "60%" }); // Large screens
      }
    };

    handleResize(); // Set initial size
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="min-h-screen md:py-6">
      <div className="w-full overflow-hidden">
        <div className="flex justify-center flex-col hidden lg:block items-center gap-8 p-6 bg-white dark:bg-gray-800 transition-colors duration-300 ease-in-out relative rounded-[16px] border border-gray-300 dark:border-gray-600 shadow-md">
          <h2 className="md:text-[24px] text-center text-gray-900 dark:text-gray-100 mb-[120px] font-medium">
            {timeRange === "" ? "Top 3 employees" : `Top 3 employees of the ${timeRange}`}
          </h2>
          <img
            src={right}
            alt="rightIcon"
            className="absolute right-0 bottom-0 -z-0"
          />
          <img
            src={left}
            alt="leftIcon"
            className="absolute left-0 bottom-0 -z-0"
          />
          <div className="flex justify-center items-center space-x-10 p-4">
            {reorderedUsers[1] && (
              <div className="relative w-[220px] md:w-[160px] lg:w-[155px] h-[280px] flex flex-col items-center border border-gray-300 dark:border-gray-600 gap-4 justify-center bg-white dark:bg-gray-700 rounded-2xl transition-transform duration-300 hover:scale-105 shadow-md dark:shadow-lg">
                <img
                  src={badges[2]}
                  alt="2nd Place"
                  className="w-[70px] h-[80px]"
                />
                <p className="text-[12px] font-semibold text-gray-900 dark:text-gray-100 mt-[-5px]">
                  {reorderedUsers[1]?.name}
                </p>
                <div
                  className="w-[112px] p-2 text-center rounded-t-lg flex flex-col h-1/2 justify-between text-white z-10 mt-[-12px]"
                  style={{
                    backgroundImage: `url(${badgeCover2})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <p className="text-[26px] font-semibold">
                    2nd <br /> Place
                  </p>
                  <p className="text-[12px] mt-1 font-semibold">
                    ${reorderedUsers[1]?.reviews} Reward <br />
                    Total
                  </p>
                </div>
              </div>
            )}

            {reorderedUsers[0] && (
              <div className="relative w-[193px] md:w-[160px] lg:w-[193px] h-[300px] flex flex-col items-center border border-gray-300 dark:border-gray-600 gap-4 justify-center bg-white dark:bg-gray-700 rounded-2xl transition-transform duration-300 hover:scale-105 scale-110 shadow-md dark:shadow-lg">
                <img
                  src={badges[1]}
                  alt="1st Place"
                  className="w-[90px] h-[90px]"
                />
                <p className="text-[14px] font-semibold text-gray-900 dark:text-gray-100 mt-[-15px]">
                  {reorderedUsers[0]?.name}
                </p>
                <div
                  className="w-[140px] p-2 text-center rounded-t-lg flex flex-col h-1/2 justify-between text-white z-0 mt-[-14px]"
                  style={{
                    backgroundImage: `url(${badgeCover1})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <p className="text-[32px] font-semibold">
                    1st <br /> Place
                  </p>
                  <p className="text-[14px] mt-1 font-semibold">
                    ${reorderedUsers[0]?.reviews} Reward <br />
                    Total
                  </p>
                </div>
              </div>
            )}

            {reorderedUsers[2] && (
              <div className="relative w-[155px] md:w-[155px] lg:w-[155px] h-[280px] flex flex-col items-center border border-gray-300 dark:border-gray-600 gap-4 justify-center bg-white dark:bg-gray-700 rounded-2xl transition-transform duration-300 hover:scale-105 shadow-md dark:shadow-lg">
                <img
                  src={badges[3]}
                  alt="3rd Place"
                  className="w-[80px] h-[80px]"
                />
                <p className="text-[12px] font-semibold text-gray-900 dark:text-gray-100 mt-[-8px]">
                  {reorderedUsers[2]?.name}
                </p>
                <div
                  className="w-[112px] p-2 text-center rounded-t-lg flex flex-col h-3/4 justify-between text-white z-0 mt-[-12px]"
                  style={{
                    backgroundImage: `url(${badgeCover3})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <p className="text-[26px] font-semibold">
                    3rd <br />
                    Place
                  </p>
                  <p className="text-[12px] mt-1 font-semibold">
                    ${reorderedUsers[2]?.reviews} Reward <br /> Total
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <PastWinnersComponent
          totalReviews={totalReviews}
          reviewData={reviewData}
          pastWinners={pastWinners}
          currentWinnerIndex={currentWinnerIndex}
          prevWinner={prevWinner}
          nextWinner={nextWinner}
          badgeCover1={badgeCover1}
        /> */}

        <EmployeeRewardsTable />
        {/* <LeaderBoard /> */}
      </div>
    </div>
  );
}

export default Reward;
