import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { TotalRevenue } from "./TeamPerformance/index";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useOutletContext } from "react-router-dom";
import Reward from "./Reward";
import { useTimeRange } from "../../context/TimeRangeContext";
import Overview from "./Overview";
import Subscription from "./Subscription";

function TeamPerformance() {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const { timeRange } = useTimeRange();
  const [totalPagescount, setTotalPagesCount] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const { isExpired } = useOutletContext();
  const [isMobileScreen, setIsMobileScreen] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPagescount) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const userData = JSON.parse(localStorage.getItem("userData"));
  const companyType = userData.company_type;

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/filtered-taps/?filter=${timeRange}&page=${currentPage}&page_size=${itemsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setEmployees(data.data);
        setTotalPagesCount(data.total_pages);
      } catch (error) {
        console.error("Error fetching employees data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [timeRange, currentPage]);

  const getCategories = (taps) => {
    if (timeRange === "week") {
      return ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];
    } else if (timeRange === "year") {
      return [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
    } else {
      return taps.map((tap, index) => `Week ${index + 1}`);
    }
  };

  const generateChartData1 = (taps) => {
    const categories = getCategories(taps);
    const dataValues = taps.map((tap) => tap.taps);

    // Ensure Dark Mode Detection is Reliable
    const isDarkMode =
      typeof window !== "undefined" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;

    return {
      options: {
        chart: {
          type: "area",
          height: isMobileScreen ? 150 : 200,
          toolbar: { show: false },
          background: "transparent",
        },
        stroke: {
          curve: "smooth",
          width: 1.5,
        },
        colors: ["#4D81E7"], // Keep primary color consistent
        dataLabels: { enabled: false },
        xaxis: {
          categories,
          labels: {
            style: {
              colors: isDarkMode ? "#E5E7EB" : "#374151", // Dark mode: gray-200, Light mode: gray-700
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => (val % 1 === 0 ? val : ""),
            style: {
              colors: isDarkMode ? "#E5E7EB" : "#374151", // Dark mode: gray-200, Light mode: gray-700
            },
          },
        },
        grid: {
          borderColor: isDarkMode ? "#4B5563" : "#E5E7EB", // Dark mode: gray-600, Light mode: gray-300
          strokeDashArray: 1,
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: isDarkMode ? "dark" : "light",
            shadeIntensity: 1,
            opacityFrom: isDarkMode ? 0.3 : 0.4,
            opacityTo: 0,
            stops: [0, 100],
          },
        },
      },
      series: [{ name: "Taps", data: dataValues }],
    };
  };

  return (
    <div className="p-2 text-black dark:bg-gray-800 dark:text-white min-h-screen xs:w-full lg:w-full">
      {loading ? (
        <div className="flex justify-center items-center min-h-screen mt-[-150px]">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className={`relative ${isExpired ? "blur-sm" : ""}`}>
            <Overview title="Team Performance" showExportButton={false} />
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full">
              {employees.map((employee) => {
                return (
                  <div
                    key={employee.id}
                    className="p-4 h-80 dark:border-gray-600 bg-white dark:bg-gray-800 rounded-[12px] lg:w-full xs:w-full"
                  >
                    <div className="flex justify-between">
                      <div className="flex items-center w-1/2">
                        <div className="w-3/6 flex items-center">
                          <p className="w-[40px] h-[40px] rounded-full flex items-center justify-center text-white font-bold text-[12px] p-4 bg-blue-600">
                            {`${employee.name.split(" ")[0][0].toUpperCase()}${
                              employee.name.split(" ")[1]
                                ? employee.name.split(" ")[1][0].toUpperCase()
                                : ""
                            }`}
                          </p>
                          <h3 className="text-[14px] font-semibold ml-2 text-gray-900 dark:text-gray-100">
                            {employee.name.split(" ")[0]}{" "}
                            {employee.name.split(" ")[1]}
                          </h3>
                        </div>
                      </div>
                      <div className="flex items-start flex-col">
                        <p className="text-[14px] font-semibold text-gray-700 dark:text-gray-300">
                          Taps:{" "}
                          {employee.taps.reduce(
                            (total, tap) => total + tap.taps,
                            0
                          )}
                          {employee.change.type === "Increase" ||
                          employee.change.type === "Decrease" ? (
                            <span
                              className={`text-[12px] ml-2 ${
                                employee.change.type === "Decrease"
                                  ? "text-red-500 dark:text-red-400"
                                  : "text-green-500 dark:text-green-400"
                              }`}
                            >
                              {employee.change.total_change}
                            </span>
                          ) : (
                            <span className="text-[12px] text-gray-700 dark:text-gray-300 ml-2">
                              {employee.change.total_change}
                            </span>
                          )}
                        </p>

                        <p className="text-[12px] font-semibold text-gray-500 dark:text-gray-400">
                          {employee.change.type === "Increase" ||
                          employee.change.type === "Decrease" ? (
                            <span
                              className={`text-[12px] ${
                                employee.change.type === "Decrease"
                                  ? "text-red-500 dark:text-red-400"
                                  : "text-green-500 dark:text-green-400"
                              }`}
                            >
                              {employee.change.percentage_change}%
                            </span>
                          ) : (
                            <span className="text-[12px] text-gray-500 dark:text-gray-400">
                              {employee.change.percentage_change}%
                            </span>
                          )}{" "}
                          vs last {timeRange}
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-full xs:w-full">
                      <ReactApexChart
                        options={generateChartData1(employee.taps).options}
                        series={generateChartData1(employee.taps).series}
                        type="area"
                        height={isMobileScreen ? 150 : 200}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex justify-center items-center space-x-4 my-4">
              {/* Previous Button */}
              <button
                aria-label="Previous Page"
                className="flex items-center px-4 py-2 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600 shadow-sm hover:bg-gray-200 dark:hover:bg-gray-600 transition disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={currentPage === 1}
                onClick={handlePrevious}
              >
                <FaArrowLeft className="mr-2" /> Previous
              </button>

              {/* Pagination Numbers with Dots for Small Screens */}
              <div className="flex space-x-2">
                {Array.from({ length: totalPagescount }, (_, index) => {
                  const pageNumber = index + 1;

                  return (
                    <button
                      aria-label={`Go to page ${pageNumber}`}
                      key={pageNumber}
                      className={`px-4 py-2 mx-1 rounded border border-gray-300 dark:border-gray-600 shadow-sm transition ${
                        pageNumber === currentPage
                          ? "bg-blue-500 text-white"
                          : "bg-white dark:bg-gray-700 text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600"
                      } ${
                        totalPagescount > 5 && pageNumber !== currentPage
                          ? "hidden md:inline-block"
                          : ""
                      }`}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  );
                })}
              </div>

              {/* Next Button */}
              <button
                aria-label="Next Page"
                className="flex items-center px-4 py-2 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600 shadow-sm hover:bg-gray-200 dark:hover:bg-gray-600 transition disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={currentPage === totalPagescount}
                onClick={handleNext}
              >
                Next <FaArrowRight className="ml-2" />
              </button>
            </div>

            {companyType === "team" && <TotalRevenue />}
            {companyType === "team" && <Reward />}
          </div>
        </>
      )}
      {isExpired && <Subscription />}
    </div>
  );
}

export default TeamPerformance;
