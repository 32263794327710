import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTimeRange } from "../../../context/TimeRangeContext";

const AreaChart = () => {
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { timeRange } = useTimeRange();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem("token");
      if (!token) {
        setError("Authorization token not found.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://zola.technology/api/company/surveys/get-data/nps/spread-data/?filter=${timeRange}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP Error: ${response.status}`);
        }

        const data = await response.json();

        let categories = [];
        let seriesData = [];

        if (data.periods && data.periods.length > 0) {
          categories = data.periods.map((item) => {
            const period = item.period;
            if (!period) return "Unknown";

            if (timeRange === "month") {
              // Example: "Week 08: 01 March 2025" → "Week 08"
              return period.split(":")[0].trim();
            }

            if (timeRange === "week") {
              // Example: "Monday 17 March 2025" → "Mon"
              return period.split(" ")[0].slice(0, 3);
            }

            if (timeRange === "year") {
              // Example: "January 2025" → "Jan"
              return period.split(" ")[0].slice(0, 3);
            }

            return period; // fallback
          });

          seriesData = data.periods.map((item) => item.nps_score);
        }

        setChartData({
          categories,
          series: [{ name: "NPS", data: seriesData }],
        });
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timeRange]);

  const chartOptions = {
    chart: {
      type: "area",
      toolbar: { show: false },
      zoom: {
        enabled: false, // 👈 disables scroll/zoom
      },
    },
    stroke: { curve: "smooth", width: 2 },
    xaxis: {
      categories: chartData.categories,
      labels: { style: { colors: "#6B7280", fontSize: "12px" } },
    },
    yaxis: {
      min: undefined, // Allow dynamic adjustment to handle negative values
      labels: { style: { colors: "#6B7280", fontSize: "12px" } },
    },
    colors: ["#3B82F6"],
    dataLabels: { enabled: false },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#E0F2FE"], // Keep gradient effect
        inverseColors: false,
        opacityFrom: 0.7,
        opacityTo: 0.1,
        stops: [0, 90, 100],
      },
    },
    markers: {
      size: 4,
      colors: ["#3B82F6"],
      strokeColors: "#fff",
      strokeWidth: 2,
    },
    tooltip: { theme: "light" },
  };

  return (
    <div>
      {loading ? (
        <p>Loading chart data...</p>
      ) : error ? (
        <p style={{ color: "red" }}>Error: {error}</p>
      ) : (
        <Chart
          options={chartOptions}
          series={chartData.series}
          type="area"
          height={300}
        />
      )}
    </div>
  );
};

export default AreaChart;
