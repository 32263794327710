import { useState, useEffect } from "react";
import { useTimeRange } from "../../../context/TimeRangeContext";

const NpsSummaryCard = ({ colSpan }) => {
  const { timeRange } = useTimeRange(); // Get selected time range
  const [npsData, setNpsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNpsData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Authentication token not found.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://zola.technology/api/company/surveys/get-data/nps/?filter=${timeRange}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setNpsData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNpsData();
  }, [timeRange]); // Fetch data when time range changes

  // If loading or error, display messages
  if (loading) return <p className="text-gray-500 text-center">Loading...</p>;
  if (error) return <p className="text-red-500 text-center">{error}</p>;

  // Extract values from fetched data
  const { nps_score, promoters, passives, detractors } = npsData || {};
  const totalResponses =
    (promoters?.count || 0) + (passives?.count || 0) + (detractors?.count || 0);
  
  const promotersPercentage = promoters?.percentage || 0;
  const passivesPercentage = passives?.percentage || 0;
  const detractorsPercentage = detractors?.percentage || 0;

  return (
    <div className={`bg-white dark:bg-gray-900 shadow-md rounded-lg p-4 ${colSpan}`}>
  {/* Header */}
  <div className="flex justify-between items-center text-gray-600 dark:text-gray-300 mb-2">
    <h3 className="text-lg font-semibold">NPS Summary</h3>
    <span className="text-xl cursor-pointer dark:text-gray-400">⋮</span>
  </div>

  {/* Segmented Progress Bar */}
  <div className="flex w-full h-8 rounded-full overflow-hidden">
    <div
      className="bg-red-500 text-white text-xs flex items-center justify-center dark:bg-red-600"
      style={{ width: `${detractorsPercentage}%` }}
    >
      {detractorsPercentage}%
    </div>
    <div
      className="bg-gray-400 text-white text-xs flex items-center justify-center dark:bg-gray-500"
      style={{ width: `${passivesPercentage}%` }}
    >
      {passivesPercentage}%
    </div>
    <div
      className="bg-green-500 text-white text-xs flex items-center justify-center dark:bg-green-600"
      style={{ width: `${promotersPercentage}%` }}
    >
      {promotersPercentage}%
    </div>
  </div>

  {/* NPS Score */}
  <div className="flex justify-between items-center text-gray-700 dark:text-gray-200 mt-3">
    <p className="text-sm">NPS</p>
    <h2 className="text-lg font-bold">{nps_score}</h2>
  </div>

  {/* NPS Breakdown */}
  <div className="mt-2 text-gray-700 dark:text-gray-300">
    <div className="flex justify-between py-1 border-b dark:border-gray-700">
      <p>Total Responses</p>
      <p className="font-bold">{totalResponses}</p>
    </div>
    <div className="flex justify-between py-1 border-b dark:border-gray-700">
      <p>Detractors</p>
      <p className="font-bold text-red-500">
        {detractors?.count}{" "}
        <span className="text-gray-500 dark:text-gray-400">
          ({detractorsPercentage}%)
        </span>
      </p>
    </div>
    <div className="flex justify-between py-1 border-b dark:border-gray-700">
      <p>Passives</p>
      <p className="font-bold text-gray-500 dark:text-gray-400">
        {passives?.count}{" "}
        <span className="text-gray-500 dark:text-gray-400">
          ({passivesPercentage}%)
        </span>
      </p>
    </div>
    <div className="flex justify-between py-1 border-b dark:border-gray-700">
      <p>Promoters</p>
      <p className="font-bold text-green-500">
        {promoters?.count}{" "}
        <span className="text-gray-500 dark:text-gray-400">
          ({promotersPercentage}%)
        </span>
      </p>
    </div>
  </div>
</div>

  );
};

export default NpsSummaryCard;
