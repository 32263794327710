import React, { useState, useEffect, useRef } from "react";
import { useCompanyProfile } from "../../../context/CompanyProfile";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { fetchPlacePredictions } from "../../../pages/SignUp/googlePlaces";
import imageCompression from "browser-image-compression";

const Account = () => {
  const { profile, loading, error, fetchProfile } = useCompanyProfile(); // Use context
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // Controlled state for editing
  const [firstName, setFirstName] = useState(profile?.first_name || "");
  const [lastName, setLastName] = useState(profile?.last_name || "");
  const [email, setEmail] = useState(profile?.email || "");
  const [username, setUsername] = useState(profile?.username || "");
  const [preferredBusinessName, setPreferredBusinessName] = useState(
    profile?.preferred_business_name || ""
  );
  const [companyName, setCompanyName] = useState(profile?.company_name || "");
  const [companyType, setCompanyType] = useState(profile?.company_type || "");
  const [companyUrl, setCompanyUrl] = useState(profile?.company_url || "");
  const [numberOfEmployees, setNumberOfEmployees] = useState(
    profile?.number_of_employees || 0
  );
  const [numberOfCards, setNumberOfCards] = useState(
    profile?.number_of_cards || 0
  );
  const [phoneNumber, setPhoneNumber] = useState(profile?.phone_number || "");
  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const suggestionsRef = useRef();

  console.log(profile);
  // Sync state when profile updates
  React.useEffect(() => {
    if (profile) {
      setFirstName(profile.first_name || "");
      setLastName(profile.last_name || "");
      setEmail(profile.email || "");
      setUsername(profile.username || "");
      setPreferredBusinessName(profile.preferred_business_name || "");
      setCompanyName(profile.company_name || "");
      setCompanyType(profile.company_type || "");
      setCompanyUrl(profile.company_url || "");
      setNumberOfEmployees(profile.number_of_employees || 0);
      setNumberOfCards(profile.number_of_cards || 0);
      setPhoneNumber(profile.phone_number || "");
    }
  }, [profile]);

  // useEffect(() => {
  //   if (companyName.length > 2 && isEditingProfile) {
  //     const fetchSuggestions = async () => {
  //       const suggestions = await fetchPlacePredictions(companyName);
  //       setPlaceSuggestions(suggestions);
  //     };
  //     fetchSuggestions();
  //   } else {
  //     setPlaceSuggestions([]);
  //   }
  // }, [companyName, isEditingProfile]);

  const handleSaveProfile = async () => {
    const token = localStorage.getItem("token");
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      username,
      preferred_business_name: preferredBusinessName,
      company_name: companyName,
      company_type: companyType,
      company_url: companyUrl,
      number_of_employees: numberOfEmployees,
      number_of_cards: numberOfCards,
      phone_number: phoneNumber,
    };

    try {
      await axios.patch("https://zola.technology/api/company/update/", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Profile updated successfully!");
      setIsEditingProfile(false);
      fetchProfile(); // Refetch updated profile
    } catch (error) {
      console.error("Error updating profile!", error);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
    if (!allowedTypes.includes(file.type)) {
      toast.error("Only JPEG, PNG, or WEBP images are allowed.");
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error("Image size must be less than or equal to 5MB.");
      return;
    }

    const token = localStorage.getItem("token");
    setIsUploading(true);

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);

      // Generate a unique filename
      const uniqueName = `profile_${Date.now()}_${Math.floor(
        Math.random() * 10000
      )}.jpg`;
      const compressedWithName = new File([compressedFile], uniqueName, {
        type: compressedFile.type,
      });

      const formData = new FormData();
      formData.append("file", compressedWithName);

      const uploadResponse = await axios.post(
        "https://zola.technology/api/upload-file/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const imageUrl = uploadResponse.data.file_url;

      await axios.patch(
        "https://zola.technology/api/company/update/",
        { profile_image: imageUrl },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Image uploaded and profile updated successfully!");
      fetchProfile(); // Refetch updated profile
    } catch (error) {
      console.error("Error uploading image!", error);
      toast.error("Failed to upload image. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleImageRemove = async () => {
    const token = localStorage.getItem("token");

    try {
      await axios.delete(
        `https://zola.technology/api/delete-file/?file_url=${encodeURIComponent(
          profile.profile_image
        )}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      await axios.patch(
        "https://zola.technology/api/company/update/",
        { profile_image: "" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Image removed successfully!");
      fetchProfile(); // Refetch updated profile
    } catch (error) {
      console.error("Error removing image!", error);
      toast.error("Failed to remove image. Please try again.");
    }
  };

  const handlePlaceSelect = (description) => {
    setCompanyName(description);
    setPlaceSuggestions([]);
  };

  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setPlaceSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCompanyNameChange = async (value) => {
    setCompanyName(value);
    if (value.length > 2) {
      const suggestions = await fetchPlacePredictions(value);
      setPlaceSuggestions(suggestions);
    } else {
      setPlaceSuggestions([]);
    }
  };

  if (loading) return <p>Loading profile...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <div className="flex items-start justify-between mb-6">
        <div className="flex items-start flex-col">
          {profile?.profile_image ? (
            <img
              className="w-32 h-32 rounded-full object-contain border ring-4 ring-offset-2 ring-blue-500 mb-4"
              src={profile.profile_image}
              alt={`${profile.first_name} ${profile.last_name}`}
            />
          ) : (
            <div
              className="w-32 h-32 rounded-full bg-gray-300 flex items-center justify-center cursor-pointer hover:bg-gray-400 transition"
              onClick={() => document.getElementById("imageUpload").click()}
            >
              <span className="text-gray-700">Upload</span>
            </div>
          )}
          <div>
            <input
              type="file"
              id="imageUpload"
              className="hidden"
              accept="image/*"
              onChange={handleImageUpload}
            />
            {isEditingProfile && (
              <div className="flex space-x-2">
                <button
                  onClick={() => document.getElementById("imageUpload").click()}
                  className="px-4 py-2 mt-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
                  disabled={isUploading}
                >
                  {isUploading ? "Uploading..." : "Change Image"}
                </button>
                {profile?.profile_image && (
                  <button
                    onClick={handleImageRemove}
                    className="px-4 py-2 mt-4 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-200 transition"
                    disabled={isUploading}
                  >
                    Remove Image
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <button
          onClick={() => setIsEditingProfile((prev) => !prev)}
          className="px-4 py-2 bg-blue-600 text-white rounded-md"
        >
          {isEditingProfile ? "Cancel" : "Edit Profile"}
        </button>
      </div>

      {[
        { label: "First Name", value: firstName, setter: setFirstName },
        { label: "Last Name", value: lastName, setter: setLastName },
        { label: "Email", value: email, setter: setEmail },
        { label: "Username", value: username, setter: setUsername },
        {
          label: "Preferred Business Name",
          value: preferredBusinessName,
          setter: setPreferredBusinessName,
        },
        {
          label: "Company Type",
          value: companyType,
          setter: setCompanyType,
          readOnly: true,
        },
        { label: "Company URL", value: companyUrl, setter: setCompanyUrl },
        {
          label: "Number of Employees",
          value: numberOfEmployees,
          setter: setNumberOfEmployees,
          type: "number",
        },
        {
          label: "Number of Cards",
          value: numberOfCards,
          setter: setNumberOfCards,
          type: "number",
        },
      ].map((field, index) => (
        <div key={index} className="mb-6">
          <label className="block text-gray-700 mb-2">{field.label}</label>
          <input
            type={field.type || "text"}
            value={field.value}
            onChange={(e) =>
              field.setter(
                field.type === "number"
                  ? Number(e.target.value)
                  : e.target.value
              )
            }
            className={`w-full px-3 py-2 border rounded-md transition ${
              isEditingProfile && !field.readOnly
                ? "bg-white"
                : "bg-gray-100 cursor-not-allowed"
            }`}
            readOnly={!isEditingProfile || field.readOnly}
          />
        </div>
      ))}

      <div className="mb-6 relative">
        <label className="block text-gray-700 mb-2">Company Name</label>
        <input
          type="text"
          value={companyName}
          onChange={(e) => handleCompanyNameChange(e.target.value)}
          className={`w-full px-3 py-2 border rounded-md transition ${
            isEditingProfile ? "bg-white" : "bg-gray-100 cursor-not-allowed"
          }`}
          readOnly={!isEditingProfile}
        />
        {placeSuggestions.length > 0 && isEditingProfile && (
          <ul
            ref={suggestionsRef}
            className="absolute top-[70px] z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto"
          >
            {placeSuggestions.map((suggestion) => (
              <li
                key={suggestion.place_id}
                className="p-2 cursor-pointer hover:bg-gray-200"
                onClick={() => handlePlaceSelect(suggestion.description)}
              >
                {suggestion.description}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 mb-2">Phone Number</label>
        <PhoneInput
          country={"us"} // default to US with +1
          onlyCountries={["us"]}
          value={phoneNumber}
          onChange={setPhoneNumber}
          inputProps={{
            name: "phone",
            required: true,
            readOnly: !isEditingProfile,
          }}
          inputClass={`w-full px-3 py-2 border rounded-md transition ${
            isEditingProfile ? "bg-white" : "bg-gray-100"
          }`}
          disabled={!isEditingProfile}
        />
      </div>

      {isEditingProfile && (
        <button
          onClick={handleSaveProfile}
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-md"
        >
          Save Changes
        </button>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        pauseOnHover
      />
    </>
  );
};

export default Account;
