import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Multi.css";
import card from "./zola_card.jpg";
import stand from "./zola_stand.jpg";
import { makePayment } from "../../api";
import Zola from "./zola.jpg";
import axios from "axios";
import SubscriptionComponent from "./Subscription";
import { toast, ToastContainer } from "react-toastify";

const StepThree = ({ nextStep, prevStep }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [cardPrice, setCardPrice] = useState(4);
  const [standPrice, setStandPrice] = useState(10);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [shippingPrice, setShippingPrice] = useState("5");
  const [subscriptionType, setSubscriptionType] = useState("year");
  const [transactionType, setTransactionType] = useState("both");
  const [subscriptionAmount, setSubscriptionAmount] = useState(0);
  const [monthSubscription, setMonthSubscription] = useState(79);
  const [yearSubscription, setYearSubscription] = useState(840);
  const [selection, setSelection] = useState("both");
  const discountPercentage = 0;
  const numberOfLocations = userData.number_of_locations;

  useEffect(() => {
    async function fetchExchangeRate() {
      try {
        const response = await axios.get(
          `https://open.er-api.com/v6/latest/USD`
        );
        setExchangeRate(response.data.rates.KES);
      } catch (error) {
        console.error("Failed to fetch exchange rate:", error);
      }
    }
    fetchExchangeRate();
  }, []);

  const [cardCount, setCardCount] = useState(() => {
    return localStorage.getItem("numberOfEmployees")
      ? parseInt(localStorage.getItem("numberOfEmployees"), 10)
      : 1;
  });
  const [standCount, setStandCount] = useState(1);
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(false);

  useEffect(() => {
    if (subscriptionType === "month") {
      setSubscriptionAmount(numberOfLocations * monthSubscription);
    } else if (subscriptionType === "year") {
      setSubscriptionAmount(numberOfLocations * yearSubscription);
    } else {
      setSubscriptionAmount(0);
    }
  }, [subscriptionType]);

  const handleCheckout = async () => {
    try {
      setIsCheckoutDisabled(true);

      const username = userData.username;
      const phone_number = userData.phone_number;
      const total = calculateTotal();

      const paymentUrl = await makePayment(
        username,
        total,
        phone_number,
        transactionType,
        subscriptionType,
        subscriptionAmount
      );

      const data = {
        number_of_cards: cardCount,
        number_of_stands: standCount,
        unit_amount: total,
        company_username: username,
        phone_number: phone_number,
        subscription_interval: subscriptionType,
        subscription_amount: subscriptionAmount,
      };

      const response = await fetch(
        `https://zola.technology/api/company/update/username/${username}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        nextStep(paymentUrl);
      } else {
        console.error("Failed to update company data");
      }
    } catch (error) {
      console.error("Error making payment:", error.message);
      toast.error(error.message);
      setIsCheckoutDisabled(false);
    }
  };

  const calculateTotal = () => {
    let subtotal = cardCount * cardPrice + standCount * standPrice;
    const shipping =
      cardCount === 0 && standCount === 0 ? 0 : Number(shippingPrice);
    const total = subtotal + shipping + subscriptionAmount;
    return isNaN(total) ? 0 : total;
  };

  const formatCurrency = (value, symbol) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: symbol === "$" ? "USD" : "KES",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  return (
    <div className="w-full mx-auto p-6 flex flex-col items-center bg-white flex-1 md:ml-[75%] lg:ml-[50%]">
      <ToastContainer />
      <img
        src={Zola}
        alt="Zola Logo"
        className="w-24 h-24 object-cover rounded-full"
      />
      <h2 className="text-4xl font-normal text-gray-800 mt-4">
        Recommended Package
      </h2>
      <p className="text-xl mt-2 font-light text-gray-600 text-center">
        (We recommend providing one card for each customer-facing employee and
        one display per location.)
      </p>

      <div className="flex flex-col md:flex-row md:space-x-6 my-4">
        <div className="bg-black p-4 rounded-xl overflow-hidden shadow-lg flex-1">
          <img
            className="w-full h-64 object-cover rounded-lg"
            src={card}
            alt="NFC Card"
          />
          <div className="flex flex-col gap-0 my-4">
            <div className="flex justify-between text-white font-bold text-xl">
              <p>NFC Cards</p>
              <p>{formatCurrency(cardCount * cardPrice, currencySymbol)}</p>
            </div>
            <p className="text-gray-400 text-base">
              {formatCurrency(cardPrice, currencySymbol)} per Card
            </p>
          </div>

          <div className="flex items-center bg-gray-800 rounded-full w-fit overflow-hidden">
            <button
              className="px-4 py-2 text-white focus:outline-none hover:bg-gray-700"
              onClick={() => setCardCount(Math.max(cardCount - 1, 0))}
            >
              -
            </button>
            <input
              type="number"
              className="w-12 text-lg font-semibold text-white text-center bg-transparent border-0 focus:ring-0 focus:outline-none"
              value={cardCount}
              onChange={(e) =>
                setCardCount(Math.max(parseInt(e.target.value, 10) || 1, 1))
              }
              min={0}
            />
            <button
              className="px-4 py-2 text-white focus:outline-none hover:bg-gray-700"
              onClick={() => setCardCount(cardCount + 1)}
            >
              +
            </button>
          </div>
        </div>

        <div className="bg-black p-4 rounded-xl overflow-hidden shadow-lg flex-1">
          <img
            className="w-full h-64 object-cover rounded-lg"
            src={stand}
            alt="NFC Stand"
          />
          <div className="flex flex-col gap-0 my-4">
            <div className="flex justify-between text-white font-bold text-xl">
              <p>NFC Stands</p>
              <p>{formatCurrency(standCount * standPrice, currencySymbol)}</p>
            </div>
            <p className="text-gray-400 text-base">
              {formatCurrency(standPrice, currencySymbol)} per Stand
            </p>
          </div>

          <div className="flex items-center bg-gray-800 rounded-full w-fit overflow-hidden">
            <button
              className="px-4 py-2 text-white focus:outline-none hover:bg-gray-700"
              onClick={() => setStandCount(Math.max(standCount - 1, 0))}
            >
              -
            </button>
            <input
              type="number"
              className="w-12 text-lg font-semibold text-white text-center bg-transparent border-0 focus:ring-0 focus:outline-none"
              value={standCount}
              onChange={(e) =>
                setStandCount(Math.max(parseInt(e.target.value, 10) || 0, 0))
              }
              min={0}
            />
            <button
              className="px-4 py-2 text-white focus:outline-none hover:bg-gray-700"
              onClick={() => setStandCount(standCount + 1)}
            >
              +
            </button>
          </div>
        </div>
      </div>

      <div className="w-full mx-auto rounded-lg bg-white overflow-hidden shadow-lg border border-gray-300">
        <div className="px-6 py-5">
          <div className="font-bold text-2xl text-gray-900 mb-5 border-b border-gray-200 pb-3">
            Checkout
          </div>
          <div className="text-gray-800 mb-4">
            {(cardCount > 0 || standCount > 0) && (
              <div className="text-gray-800 mb-4">
                {cardCount > 0 && (
                  <div className="flex justify-between mb-2">
                    <span className="text-sm">Card Total:</span>
                    <span className="font-semibold">
                      {formatCurrency(cardCount * cardPrice, currencySymbol)}
                    </span>
                  </div>
                )}
                {standCount > 0 && (
                  <div className="flex justify-between mb-2">
                    <span className="text-sm">Stand Total:</span>
                    <span className="font-semibold">
                      {formatCurrency(standCount * standPrice, currencySymbol)}
                    </span>
                  </div>
                )}
                <div className="flex justify-between mb-2">
                  <span className="text-sm">Shipping fees:</span>
                  <span className="font-semibold">
                    {formatCurrency(Number(shippingPrice), currencySymbol)}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm">Your cart subtotal:</span>
                  <span className="font-semibold">
                    {formatCurrency(
                      calculateTotal() - subscriptionAmount,
                      currencySymbol
                    )}
                  </span>
                </div>
              </div>
            )}

            <div className="flex justify-between mb-4">
              <span className="text-sm">Subscription amount:</span>
              <span className="font-semibold">
                {formatCurrency(subscriptionAmount, currencySymbol)}
              </span>
            </div>
          </div>

          <SubscriptionComponent
            setSubscriptionType={setSubscriptionType}
            setTransactionType={setTransactionType}
            subscriptionType={subscriptionType}
            transactionType={transactionType}
            currencySymbol={currencySymbol}
            monthlySubscription={monthSubscription}
            yearlySubscription={yearSubscription}
          />
        </div>

        <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
          <div className="flex justify-between items-center text-gray-900">
            <span className="text-lg font-semibold">Grand Total:</span>
            <span className="text-2xl font-bold text-blue-900">
              <sup className="text-lg">{currencySymbol}</sup>
              {calculateTotal().toFixed(2)}
            </span>
          </div>
        </div>

        <div className="px-6 py-4 flex justify-between bg-gray-50 border-t border-gray-200">
          <button
            type="button"
            onClick={prevStep}
            aria-label="Go to the previous step"
            className="px-5 py-2 bg-gray-800 text-white text-sm font-medium rounded-md shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-800"
          >
            Back
          </button>
          <button
            type="button"
            onClick={handleCheckout}
            className="px-5 py-2 bg-blue-700 text-white text-sm font-medium rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-700"
            disabled={isCheckoutDisabled}
            aria-label="Proceed to checkout"
            aria-disabled={isCheckoutDisabled}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

StepThree.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default StepThree;
