import NavbarMenu from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer-V3';
import { Outlet, useLocation } from 'react-router-dom';

const PublicLayout = () => {
  const location = useLocation();
  const { pathname } = location;

  const hideLayoutPrefixes = [
    "/redeem-coupon",
    "/setup-form",
    "/success-page",
    "/success",
    "/failed",
    "/mobile-survey",
    "/signup-success",
    "/feedback",
    "/employee_rating",
    "/emoji",
    "/review-prompt",
    "/negative-review-prompt",
    "/survey",
    "/mobile-survey",
    "/feedback", // include all feedback related paths
  ];

  const shouldHideLayout = hideLayoutPrefixes.some(prefix => pathname.startsWith(prefix));

  return (
    <div className="relative">
      {!shouldHideLayout && <NavbarMenu />}
      <div className={shouldHideLayout ? '' : 'absolute md:top-24 w-full'}>
        <Outlet />
        {!shouldHideLayout && <Footer />}
      </div>
    </div>
  );
};

export default PublicLayout;
