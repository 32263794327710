import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Zola from "./zola.jpg";
const industryDetails = {
  Manufacturing:
    "Involves the production of goods from raw materials through various processes. Examples: Automotive, electronics, textile, food and beverage manufacturing.",
  Technology:
    "Encompasses companies involved in the development and distribution of technology products and services. Examples: Software development, IT services, hardware production, internet services.",
  Healthcare:
    "Includes medical services, manufacturing of medical equipment, and pharmaceuticals. Examples: Hospitals, clinics, pharmaceutical companies, medical device manufacturers.",
  "Finance and Banking":
    "Involves managing money, investments, and financial transactions. Examples: Banks, insurance companies, investment firms, financial advisors.",
  "Retail and Consumer Goods":
    "Involves the sale of goods directly to consumers. Examples: Supermarkets, e-commerce platforms, fashion retailers, electronics stores.",
  Energy:
    "Covers the production and distribution of energy. Examples: Oil and gas, renewable energy, utilities.",
  "Transportation and Logistics":
    "Involves the movement of goods and people. Examples: Airlines, shipping companies, freight and logistics services.",
  "Real Estate":
    "Encompasses the buying, selling, and development of properties. Examples: Residential, commercial, and industrial real estate development and management.",
  Telecommunications:
    "Involves the transmission of information over distances. Examples: Mobile network operators, internet service providers, satellite communications.",
  "Hospitality and Tourism":
    "Includes services related to travel and accommodation. Examples: Hotels, restaurants, travel agencies, tourism services.",
  Education:
    "Covers institutions and services providing education and training. Examples: Schools, universities, online learning platforms, vocational training centers.",
  Agriculture:
    "Involves the production of food, fiber, and other goods through farming and forestry. Examples: Crop production, livestock farming, agribusiness companies.",
  "Entertainment and Media":
    "Includes the creation and distribution of content for entertainment and information. Examples: Film studios, TV networks, streaming platforms, publishing companies.",
  "Automotive and Aerospace":
    "Covers the design, manufacturing, and maintenance of vehicles and aircraft. Examples: Car manufacturers, aerospace companies, auto repair services.",
  "Construction and Engineering":
    "Involves the planning, design, and building of infrastructure and structures. Examples: Construction firms, civil engineering companies, architectural services.",
  "Pharmaceuticals and Biotechnology":
    "Focuses on the development and production of drugs and biological products. Examples: Drug manufacturers, biotech research firms, clinical trial organizations.",
  "Environmental and Sustainability":
    "Encompasses industries focused on environmental protection and sustainable practices. Examples: Renewable energy companies, waste management, eco-friendly product manufacturers.",
  "Legal and Professional Services":
    "Provides specialized services to individuals and businesses. Examples: Law firms, consulting agencies, accounting services.",
  "Nonprofit and Social Services":
    "Includes organizations focused on social, charitable, or community goals. Examples: Charities, NGOs, community development organizations.",
  "Sports and Fitness":
    "Covers activities and businesses related to physical fitness and sports. Examples: Gyms, sports teams, fitness equipment manufacturers.",
  "Arts and Culture":
    "Involves the creation and promotion of artistic and cultural works. Examples: Museums, art galleries, theaters, cultural festivals.",
  "Food and Beverage":
    "Encompasses the production and sale of food and drink products. Examples: Restaurants, breweries, food processing companies.",
  "Chemicals and Materials":
    "Involves the production of chemicals and advanced materials. Examples: Chemical manufacturers, plastics producers, specialty materials companies.",
  "Defense and Security":
    "Covers industries related to national defense and public safety. Examples: Defense contractors, cybersecurity firms, surveillance technology providers.",
  "E-commerce and Digital Marketplaces":
    "Focuses on online buying and selling of goods and services. Examples: Online retail platforms, digital payment systems, auction websites.",
  "Gaming and Esports":
    "Includes the development and promotion of video games and competitive gaming. Examples: Game studios, esports leagues, gaming hardware manufacturers.",
  "Space Exploration and Satellite Technology":
    "Covers industries involved in space research and satellite services. Examples: Space agencies, satellite manufacturers, space tourism companies.",
  "Artificial Intelligence and Robotics":
    "Encompasses the development and application of AI and robotics technologies. Examples: AI software developers, robotics manufacturers, automation services.",
  "Blockchain and Cryptocurrency":
    "Involves the development and use of blockchain technology and digital currencies. Examples: Cryptocurrency exchanges, blockchain startups, decentralized finance platforms.",
  "Fashion and Apparel":
    "Covers the design, production, and sale of clothing and accessories. Examples: Fashion brands, textile manufacturers, luxury goods companies.",
  "Maritime and Shipping":
    "Involves industries related to sea transport and marine activities. Examples: Shipping companies, port operators, marine engineering firms.",
  "Music and Audio Production":
    "Includes the creation and distribution of music and audio content. Examples: Record labels, audio equipment manufacturers, music streaming platforms.",
  "Petrochemicals and Refining":
    "Focuses on the production of fuels and chemicals from petroleum. Examples: Oil refineries, petrochemical plants, fuel distributors.",
  "Public Sector and Government":
    "Encompasses services and organizations operated by the government. Examples: Public administration, defense, public health services.",
  "Veterinary and Animal Care":
    "Includes services related to animal health and welfare. Examples: Veterinary clinics, pet care services, animal pharmaceutical companies.",
  "Waste Management and Recycling":
    "Involves the collection, processing, and disposal of waste materials. Examples: Recycling companies, waste treatment plants, environmental cleanup services.",
  "Water and Utilities":
    "Covers the provision of water and other essential utilities. Examples: Water treatment plants, utility companies, irrigation services.",
  "Wellness and Beauty":
    "Includes industries focused on personal care and wellness. Examples: Cosmetics companies, spas, wellness retreats.",
  "Wood and Paper Products":
    "Involves the production of goods from wood and paper materials. Examples: Paper manufacturers, furniture makers, packaging companies.",
  Hotels:
    "Encompasses services related to lodging, accommodation, and hospitality. Examples: Hotels, motels, resorts, bed-and-breakfast establishments.",
  "Plumbing Services":
    "Includes services related to the installation, maintenance, and repair of plumbing systems. Examples: Pipe repair, drainage services, water supply installation.",
  "Salons and Spas":
    "Encompasses services related to beauty, grooming, and personal care. Examples: Hair salons, nail salons, massage spas, beauty treatment centers.",
  "Dental Services":
    "Includes services related to oral health and hygiene. Examples: Dental clinics, orthodontics, dental surgeries, teeth whitening services.",
  "Car Services":
    "Encompasses services related to vehicle maintenance and repair. Examples: Auto repair shops, car detailing, tire services, oil change services.",
};

const StepTwo = ({ nextStep, handleChange, formData }) => {
  const { industryType, numberOfEmployees, businessIndustry } = formData;

  const [isFormValid, setIsFormValid] = useState(false);

  const renderEmployeeAndCardFields = () => {
    if (industryType === "one_person" || industryType === "") {
      return null;
    }
    return (
      <div className="flex flex-col md:flex-row md:space-x-4 w-full">
        <div className="form-field flex-1">
          <label
            htmlFor="number-of-employees"
            className="mt-8 block text-sm font-medium text-gray-700"
          >
            Number of Employees <span className="text-red-500">*</span>
          </label>
          <input
            id="number-of-employees"
            type="text"
            name="numberOfEmployees"
            value={numberOfEmployees}
            onChange={handleChange("numberOfEmployees")}
            required
            className="mt-1 p-2 block w-full rounded-md border-gray-500 shadow-md focus:border-0 focus:ring-0"
          />
        </div>
        {/* <div className="form-field flex-1">
          <label
            htmlFor="number-of-cards"
            className="mt-8 block text-sm font-medium text-gray-700"
          >
            Number of Cards <span className="text-red-500">*</span>
          </label>
          <input
            id="number-of-cards"
            type="text"
            name="
            value=}
            onChange={handleChange(")}
            required
            className="mt-1 block w-full rounded-md border-gray-500 shadow-md focus:border-0 focus:ring-0"
          />
        </div> */}
      </div>
    );
  };

  const validateForm = () => {
    const isValid =
      industryType.trim() !== "" &&
      businessIndustry.trim() !== "" &&
      (industryType === "one_person" ||
        (industryType === "team" && numberOfEmployees.trim() !== ""));

    setIsFormValid(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [industryType, numberOfEmployees, businessIndustry]);

  const handleNextClick = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const { username } = userData;

    const data = {
      company_type: industryType,
      number_of_employees:
        industryType === "one_person" ? 1 : numberOfEmployees,
      industry_type: businessIndustry,
      number_of_cards: industryType === "one_person" ? 1 : numberOfEmployees,
    };

    // Save numberOfEmployees to localStorage
    localStorage.setItem("numberOfEmployees", data.number_of_employees);

    try {
      const response = await fetch(
        `https://zola.technology/api/company/update/username/${username}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        nextStep();
      } else {
        console.error("Failed to update company data");
      }
    } catch (error) {
      console.error("An error occurred while updating company data", error);
    }
  };

  const industryOptions = Object.keys(industryDetails)
    .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
    .map((industry) => ({
      value: industry,
      label: industry,
    }));

  const handleIndustryChange = (selectedOption) => {
    handleChange("businessIndustry")({
      target: { name: "businessIndustry", value: selectedOption?.value },
    });
  };

  return (
    <section className="w-full p-6 flex flex-col items-center bg-white flex-1 md:ml-[75%] lg:ml-[50%]">
      <img
        src={Zola}
        alt="Zola Logo"
        className="w-24 h-24 object-cover rounded-full"
      />
      <h2 className="text-4xl font-normal text-gray-800 mt-4">
        Company Details
      </h2>
      <p className="text-xl mt-2 font-light text-gray-600 text-center">
        Please provide your company details
      </p>

      <div className="form-field w-full mt-6">
        <label
          htmlFor="industry-type"
          className="block text-sm font-medium text-gray-700"
        >
          Account Type <span className="text-red-500">*</span>
        </label>
        <Select
          id="industry-type"
          name="industryType"
          value={industryOptions.find(
            (option) => option.value === industryType
          )}
          onChange={(selectedOption) =>
            handleChange("industryType")({
              target: { name: "industryType", value: selectedOption.value },
            })
          }
          options={[
            { value: "one_person", label: "Individual" },
            { value: "team", label: "Team" },
          ]}
          required
          className="mt-1"
          classNamePrefix="react-select"
          placeholder="Select"
        />
      </div>

      {renderEmployeeAndCardFields()}

      {/* <div className="form-field w-full mt-6">
        <label
          htmlFor="business-industry"
          className="block text-sm font-medium text-gray-700"
        >
          Business Industry <span className="text-red-500">*</span>
        </label>
        <select
          id="business-industry"
          name="businessIndustry"
          value={businessIndustry}
          onChange={handleChange("businessIndustry")}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        >
          <option value="">Select</option>
          {Object.keys(industryDetails).map((industry) => (
            <option key={industry} value={industry}>
              {industry}
            </option>
          ))}
        </select>
      </div> */}
      <div className="form-field w-full mt-6">
        <label
          htmlFor="business-industry"
          className="block text-sm font-medium text-gray-700"
        >
          Business Industry <span className="text-red-500">*</span>
        </label>
        <Select
          id="business-industry"
          name="businessIndustry"
          value={industryOptions.find(
            (option) => option.value === businessIndustry
          )}
          onChange={handleIndustryChange}
          options={industryOptions}
          isSearchable
          placeholder="Select..."
          className="mt-1"
          classNamePrefix="react-select"
        />
      </div>

      {businessIndustry && (
        <div className="mt-4 p-4 bg-gray-100 rounded-lg shadow-inner w-full">
          <h3 className="text-xl font-medium text-gray-800">
            {businessIndustry}
          </h3>
          <p className="text-sm text-gray-600 mt-2">
            {industryDetails[businessIndustry]}
          </p>
        </div>
      )}

      <div className="flex justify-between mt-8 w-full">
        <button
          type="button"
          onClick={handleNextClick}
          disabled={!isFormValid}
          aria-label="Go to the next step"
          className={`px-4 py-2 w-full text-white text-sm font-medium rounded-md shadow focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
            isFormValid
              ? "bg-indigo-600 hover:bg-indigo-500"
              : "bg-black cursor-not-allowed"
          }`}
        >
          Next
        </button>
      </div>
    </section>
  );
};

StepTwo.propTypes = {
  nextStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    industryType: PropTypes.string.isRequired,
    numberOfEmployees: PropTypes.string,
    businessIndustry: PropTypes.string.isRequired,
  }).isRequired,
};

export default StepTwo;
