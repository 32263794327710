import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Subscription from "./Subscription";

function SurveyResponses() {
  const [surveys, setSurveys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const { isExpired } = useOutletContext();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    getAllSurveys();
  }, []);

  const getAllSurveys = async () => {
    try {
      const response = await axios.get(
        "https://zola.technology/api/company/surveys/get-all/",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data)
      setSurveys(response.data);
    } catch (error) {
      console.error("Error fetching surveys:", error);
      console.log("Failed to fetch surveys.");
    }
  };

  const paginatedSurveys = surveys.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(surveys.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const handleSurveyClick = (id) => {
    navigate(`/dashboard/survey-response/${id}`);
  };


  return (
    <>
      <div className="p-6 bg-white">
      <ToastContainer position="top-right" autoClose={3000} />
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">Survey Responses</h2>
        </div>

        <table className="min-w-full table-auto border-separate border-spacing-y-2">
          <thead>
            <tr>
              <th className="py-3 px-4 border-b text-left">No</th>
              <th className="py-3 px-4 border-b text-left">Survey Title</th>
              <th className="py-3 px-4 border-b text-left">Created Date</th>
              <th className="py-3 px-4 border-b text-left">Number of Questions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedSurveys.map((survey, index) => (
              <tr key={survey.id} onClick={() => handleSurveyClick(survey.id)} className="hover:bg-gray-50">
                <td className="py-3 px-4 border-b">
                  {String(index + 1).padStart(2, "0")}
                </td>
                <td className="py-3 px-4 border-b text-ellipsis whitespace-nowrap overflow-hidden">
                  {survey.title}
                </td>
                <td className="py-3 px-4 border-b">
                  {new Date(survey.created_at).toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </td>
                <td className="py-3 px-4 border-b">
                  {survey.questions.length}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          {/* Left Section: Showing Entries */}
          <div className="text-sm text-gray-500">
            Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
            {Math.min(currentPage * itemsPerPage, surveys.length)} of{" "}
            {surveys.length} entries
          </div>

          {/* Center Section: Pagination */}
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
              className="flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition"
            >
              &lt;
            </button>

            {Array.from(
              { length: Math.min(3, totalPages) },
              (_, i) => currentPage - 1 + i
            )
              .filter((page) => page > 0 && page <= totalPages)
              .map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  className={`flex items-center justify-center w-8 h-8 ${
                    currentPage === page
                      ? "bg-blue-600 text-white"
                      : "bg-gray-100 text-gray-600"
                  } rounded-md hover:bg-gray-200 transition`}
                >
                  {page}
                </button>
              ))}

            <button
              onClick={() =>
                handlePageChange(Math.min(currentPage + 1, totalPages))
              }
              className="flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition"
            >
              &gt;
            </button>
          </div>

          {/* Right Section: Entries Dropdown */}
          <div className="flex items-center">
            <span className="text-sm text-gray-500 mr-2">Show</span>
            <select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="border border-gray-300 text-sm text-gray-600 rounded-md focus:ring-blue-500 focus:border-blue-500 transition"
            >
              {[8, 16, 24, 32].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>


      {isExpired && (
        <Subscription />
      )}
    </>
  );
}

export default SurveyResponses;
