import { useState, useEffect } from "react";
import CampaignTable from "./Campaigns/CampaignTable";
import CreateCampaignModal from "./Campaigns/CreateCampaignModal";
import Overview from "./Overview";
import CampaignSelectionModal from "./Campaigns/CampaignSelectionModal";
import ScheduleCampaign from "./Campaigns/ScheduleCampaign";
import { ToastContainer } from "react-toastify";
import { useCampaigns } from "../../context/CampaignContext";

export default function Campaigns() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [campaignType, setCampaignType] = useState("");

  const { paginatedCampaigns, currentPage, setCurrentPage, totalPages, fetchPaginatedCampaigns, fetchAllCampaigns } =
    useCampaigns();

  useEffect(() => {
    console.log("Selected Campaign Type:", campaignType);
  }, [campaignType]);

  // Function to refetch all campaigns (Paginated + All)
  const refetchCampaigns = () => {
    fetchPaginatedCampaigns();
    fetchAllCampaigns();
  };

  return (
    <div className="grid gap-8">
      <ToastContainer position="top-right" autoClose={3000} />

      <Overview
        title="Campaigns"
        showCreateCampaignButton={true}
        showExportButton={false}
        hideTimeRange={true}
        onCreateCampaignClick={() => setShowModal(true)}
      />

      {/* Stats */}
      {/* <CampaignStats
        statsData={{
          delivered: 2420,
          opened: 1210,
          clicked: 316,
          bounced: 2420,
        }}
      /> */}

      <div className="grid grid-cols-12 gap-6">
        <CampaignTable
          className="col-span-12 lg:col-span-8"
          campaigns={paginatedCampaigns.map((campaign) => ({
            id: campaign.id,
            name: campaign.name,
            subject: campaign.subject,
            message: campaign.message,
            date: new Date(campaign.scheduled_at).toLocaleString(),
            scheduled_at: campaign.scheduled_at,
            delivered: campaign.contacts.length,
            clicked: 0,
            status: campaign.status === "sent" ? "Completed" : "Pending",
          }))}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          refetchCampaigns={refetchCampaigns} // Pass the combined refetch function
        />
        <ScheduleCampaign className="col-span-12 lg:col-span-4" />
      </div>

      {/* Modal for Creating Campaign */}
      {isModalOpen && (
        <CreateCampaignModal
          onClose={() => setIsModalOpen(false)}
          campaignType={campaignType}
          refetchCampaigns={refetchCampaigns} // Pass refetch to trigger on success
        />
      )}

      {/* Modal for Selecting Campaign */}
      {showModal && (
        <CampaignSelectionModal
          onClose={() => setShowModal(false)}
          onSelect={(type) => {
            setCampaignType(type);
            setIsModalOpen(true);
          }}
        />
      )}
    </div>
  );
}
