import { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { MdSend } from "react-icons/md";

const messagesList = [
  {
    id: 1,
    name: "Java Branch1",
    message: "Hello I'm trying to get feedback...",
    time: "10:10am",
    unreadCount: 4,
  },
  {
    id: 2,
    name: "Python Branch2",
    message: "Please provide the documents...",
    time: "10:15am",
    unreadCount: 0,
  },
  {
    id: 3,
    name: "Ruby Branch3",
    message: "Kindly review the attached file.",
    time: "10:30am",
    unreadCount: 2,
  },
];

const chatMessagesList = {
  1: [
    {
      sender: "Phoenix Baker",
      message: "Hey Olivia, can you please review the latest design?",
      time: "Friday 2:20pm",
      isUser: false,
    },
    {
      sender: "You",
      message: "Sure thing, I'll have a look today.",
      time: "Friday 2:25pm",
      isUser: true,
    },
  ],
  2: [
    {
      sender: "Python Branch2",
      message: "Please review the documents I attached earlier.",
      time: "Thursday 1:15pm",
      isUser: false,
    },
  ],
  3: [
    {
      sender: "Ruby Branch3",
      message: "Here is the attached file for review.",
      time: "Wednesday 4:30pm",
      isUser: false,
    },
    {
      sender: "You",
      message: "I will review and get back to you.",
      time: "Wednesday 4:35pm",
      isUser: true,
    },
  ],
};

function Messages() {
  const [selectedChat, setSelectedChat] = useState(null); // Set to null initially
  const [filter, setFilter] = useState("all");

  // Close conversation on Escape key press
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        setSelectedChat(null); // Close the chat
      }
    };

    // Attach event listener
    document.addEventListener("keydown", handleEscape);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  // Filter messages based on "all" or "unread" selection
  const filteredMessages =
    filter === "all"
      ? messagesList
      : messagesList.filter((msg) => msg.unreadCount > 0);

  const chatMessages = chatMessagesList[selectedChat?.id] || [];

  return (
    <div className="h-[89vh] overflow-hidden grid grid-cols-12 bg-white">
      {/* Left: Message List */}
      <div className="col-span-4 overflow-y-scroll overflow-x-hidden no-scrollbar">
        <div className="sticky top-0 bg-white z-10 shadow-sm">
          <div className="flex items-center justify-between gap-4 p-4">
            <h2 className="text-xl font-semibold text-gray-800">Messages</h2>
            <button
              aria-label="Message"
              className="flex items-center bg-blue-600 text-white rounded-full px-4 py-1 text-sm font-medium gap-1 shadow-md hover:bg-blue-700 transition"
            >
              Sms
              <span className="text-xl">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 12C2.25 13.9284 2.82183 15.8134 3.89317 17.4168C4.96451 19.0202 6.48726 20.2699 8.26884 21.0078C10.0504 21.7458 12.0108 21.9389 13.9021 21.5627C15.7934 21.1864 17.5307 20.2578 18.8943 18.8943C20.2579 17.5307 21.1865 15.7934 21.5627 13.9021C21.9389 12.0108 21.7458 10.0504 21.0078 8.26883C20.2699 6.48725 19.0202 4.96451 17.4168 3.89317C15.8134 2.82182 13.9284 2.25 12 2.25C9.41498 2.25273 6.93661 3.28083 5.10872 5.10872C3.28083 6.93661 2.25273 9.41497 2.25 12ZM14.7806 8.46937L17.7806 11.4694C17.8504 11.539 17.9057 11.6217 17.9434 11.7128C17.9812 11.8038 18.0006 11.9014 18.0006 12C18.0006 12.0986 17.9812 12.1962 17.9434 12.2872C17.9057 12.3782 17.8504 12.461 17.7806 12.5306L14.7806 15.5306C14.6399 15.6714 14.449 15.7504 14.25 15.7504C14.051 15.7504 13.8601 15.6714 13.7194 15.5306C13.5786 15.3899 13.4996 15.199 13.4996 15C13.4996 14.801 13.5786 14.6101 13.7194 14.4694L16.1897 12L13.7194 9.53062C13.5786 9.38989 13.4996 9.19902 13.4996 9C13.4996 8.80097 13.5786 8.6101 13.7194 8.46937C13.8601 8.32864 14.051 8.24958 14.25 8.24958C14.449 8.24958 14.6399 8.32864 14.7806 8.46937ZM10.2806 8.46937C10.3504 8.53902 10.4057 8.62174 10.4434 8.71279C10.4812 8.80384 10.5006 8.90143 10.5006 9C10.5006 9.09856 10.4812 9.19615 10.4434 9.2872C10.4057 9.37825 10.3504 9.46097 10.2806 9.53062L7.81031 12L10.2806 14.4694C10.4214 14.6101 10.5004 14.801 10.5004 15C10.5004 15.199 10.4214 15.3899 10.2806 15.5306C10.1399 15.6714 9.94902 15.7504 9.75 15.7504C9.55098 15.7504 9.3601 15.6714 9.21937 15.5306L6.21937 12.5306C6.14964 12.461 6.09432 12.3782 6.05658 12.2872C6.01884 12.1962 5.99941 12.0986 5.99941 12C5.99941 11.9014 6.01884 11.8038 6.05658 11.7128C6.09432 11.6217 6.14964 11.539 6.21937 11.4694L9.21937 8.46937C9.28903 8.39964 9.37175 8.34432 9.46279 8.30658C9.55384 8.26883 9.65144 8.24941 9.75 8.24941C9.84856 8.24941 9.94616 8.26883 10.0372 8.30658C10.1283 8.34432 10.211 8.39964 10.2806 8.46937Z"
                    fill="#FEFEFF"
                  />
                </svg>
              </span>
            </button>
          </div>

          <div className="flex items-center mx-4 bg-[#f5f8ff] rounded-full px-4 py-2 mb-4 shadow-sm w-[93%]">
            <FaSearch className="text-gray-400 mr-2 text-lg" />
            <input
              type="text"
              placeholder="Search messages"
              className="w-full bg-transparent focus:outline-none"
            />
          </div>

          {/* Filter Tabs */}
          <div className="flex bg-[#f5f8ff] p-1 mx-4 mb-2 w-full max-w-sm rounded-full">
            <button
              aria-label="All messages"
              onClick={() => setFilter("all")}
              className={`flex-1 text-center py-2 font-semibold rounded-full transition-all duration-300 ${
                filter === "all"
                  ? "bg-white text-black shadow-md"
                  : "text-gray-500 hover:bg-white/80"
              }`}
            >
              All
            </button>
            <button
              aria-label="Unread messages"
              onClick={() => setFilter("unread")}
              className={`flex-1 text-center py-2 font-semibold rounded-full transition-all duration-300 ${
                filter === "unread"
                  ? "bg-white text-black shadow-md"
                  : "text-gray-500 hover:bg-white/80"
              }`}
            >
              Unread
            </button>
          </div>
        </div>

        {filteredMessages.map((msg) => (
          <div
            key={msg.id}
            onClick={() => setSelectedChat(msg)}
            className={`flex items-center space-x-4 p-4 cursor-pointer transition-all duration-300 border-b border-gray-200 ${
              selectedChat?.id === msg.id
                ? "bg-blue-50 shadow-md"
                : "hover:bg-gray-100"
            }`}
          >
            <div className="bg-blue-500 text-white w-12 h-12 rounded-full flex items-center justify-center font-semibold">
              {msg.name.charAt(0).toUpperCase()}
            </div>
            <div className="flex-1">
              <h4 className="text-base font-medium text-gray-900">
                {msg.name}
              </h4>
              <p className="text-sm text-gray-500 truncate">{msg.message}</p>
            </div>
            <div className="flex flex-col items-end space-y-1">
              <p className="text-xs text-gray-400">{msg.time}</p>
              {msg.unreadCount > 0 && (
                <span className="text-xs bg-blue-500 text-white px-2 py-1 rounded-full">
                  {msg.unreadCount}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="col-span-8 p-6 flex flex-col items-center justify-center">
        {selectedChat ? (
          <div className="w-full h-full flex flex-col">
            {/* Chat Header */}
            <div className="flex items-center justify-between pb-4 border-b border-gray-200">
              <div className="flex items-center space-x-4">
                <div className="w-12 h-12 rounded-full bg-gray-300" />
                <div>
                  <h4 className="text-lg font-semibold">{selectedChat.name}</h4>
                </div>
              </div>
            </div>

            {/* Chat Messages */}
            <div className="flex-1 py-4 space-y-6 overflow-y-auto">
              {chatMessages.map((msg, index) => (
                <div
                  key={index}
                  className={`flex ${
                    msg.isUser ? "justify-end" : "justify-start"
                  }`}
                >
                  <div
                    className={`max-w-lg p-4 rounded-lg shadow ${
                      msg.isUser
                        ? "bg-blue-600 text-white"
                        : "bg-gray-100 text-gray-700"
                    }`}
                  >
                    <p>{msg.message}</p>
                    <p className="text-xs text-gray-400 mt-2">{msg.time}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Message Input */}
            <div className="flex items-center bg-[#f5f8ff] rounded-full px-4 py-2 shadow-sm w-full mt-4">
              <input
                type="text"
                placeholder="Write a message"
                className="flex-1 bg-transparent text-gray-600 placeholder-gray-400 focus:outline-none px-4"
              />
              <button
                aria-label="Send"
                className="bg-blue-600 text-white px-4 py-2 rounded-full flex items-center space-x-2 shadow-md hover:bg-blue-700 transition"
              >
                <span>Send</span>
                <MdSend className="text-xl" />
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full text-center">
            <div className="text-gray-400 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-20 w-20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2z"></path>
              </svg>
            </div>
            <h2 className="text-xl font-semibold text-gray-600">
              No Chat Selected
            </h2>
            <p className="text-gray-500 mt-2">
              Select a message from the list to start chatting
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Messages;
