import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

function Ready() {
  const controls = useAnimation();
  const navigate = useNavigate();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    navigate(`/signup?email=${encodeURIComponent(email)}`);
  };

  return (
    <motion.div
      ref={ref}
      variants={containerVariants}
      initial="hidden"
      animate={controls}
      className="bg-gradient-to-r from-indigo-900 to-purple-900 text-white py-8 px-6 rounded-3xl flex flex-col md:flex-row items-center md:justify-between mx-auto my-16 font-figtree"
    >
      <motion.div
        variants={itemVariants}
        className="font-medium text-[56px] leading-[67px]"
      >
        <h2>Ready to See Zola in Action?</h2>
      </motion.div>
      <motion.div variants={itemVariants} className="w-full md:w-auto">
        <p className="font-normal text-2xl leading-9 mb-2">
          Start collecting reviews and feedback in under a minute. Sign up for
          Zola today!
        </p>
        <motion.form
          onSubmit={handleFormSubmit}
          variants={itemVariants}
          className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 space-x-0 lg:space-x-4"
        >
          <input
            type="email"
            name="email"
            placeholder="name@email.com"
            className="flex-1 py-2 px-4 rounded-lg border text-gray-700 outline-none"
          />
          <button
            aria-label="Get Started Today"
            type="submit"
            className="bg-purple-500 hover:bg-purple-600 text-white py-4 px-4 rounded-lg transition duration-200"
          >
            Start using Zola today for free
          </button>
        </motion.form>
      </motion.div>
    </motion.div>
  );
}

export default Ready;
