import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext"; // Import useAuth hook

const IntegratedAccountsContext = createContext();

export const IntegratedAccountsProvider = ({ children }) => {
  const { isAuthenticated } = useAuth(); // Get authentication status
  const [integratedAccounts, setIntegratedAccounts] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  // Fetch integrated accounts
  useEffect(() => {
    const fetchIntegratedAccounts = async () => {
      const token = localStorage.getItem("token");
      if (!isAuthenticated || !token) return; // Ensure user is authenticated

      try {
        const response = await fetch(
          "https://zola.technology/api/company/integrated-accounts/get-all/",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch integrated accounts");
        }
        const data = await response.json();
        setIntegratedAccounts(data);
      } catch (error) {
        console.error("Error fetching integrated accounts:", error);
      }
    };

    fetchIntegratedAccounts();
  }, [refreshKey, isAuthenticated]); // Refetch when refreshKey changes or auth status updates

  // Function to trigger refetch
  const refreshAccounts = () => {
    setRefreshKey((prev) => prev + 1);
  };

  return (
    <IntegratedAccountsContext.Provider value={{ integratedAccounts, refreshAccounts }}>
      {children}
    </IntegratedAccountsContext.Provider>
  );
};

export const useIntegratedAccounts = () => {
  return useContext(IntegratedAccountsContext);
};
