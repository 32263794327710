import { motion } from 'framer-motion';
import { FaClock, FaChartLine, FaHandshake, FaCommentDots } from 'react-icons/fa';

const benefits = [
  {
    icon: <FaClock className="text-white text-3xl" />,
    title: 'Fast and Easy',
    description: 'Collect feedback in under a minute, streamlining the review process.',
  },
  {
    icon: <FaChartLine className="text-white text-3xl" />,
    title: 'Valuable Insights',
    description: 'Gain actionable feedback to improve your business and enhance client satisfaction.',
  },
  {
    icon: <FaHandshake className="text-white text-3xl" />,
    title: 'Increased Conversions',
    description: 'Showcase authentic reviews to attract new clients and boost credibility.',
  },
  {
    icon: <FaCommentDots className="text-white text-3xl" />,
    title: 'Personalized Interaction',
    description: 'Strengthen client relationships with personalized, in-person feedback collection.',
  },
];

const KeyBenefits = () => {
  return (
    <section className="mx-4 md:mx-auto bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-2xl py-12 h-auto font-figtree">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-[38px] font-bold mb-12">Key Benefits</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              className="bg-white/10 rounded-lg p-6 hover:bg-white/20 transition-colors duration-300"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <div className="flex justify-center mb-4">
                {benefit.icon}
              </div>
              <h3 className="text-2xl font-semibold mb-2">{benefit.title}</h3>
              <p className="text-sm">{benefit.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyBenefits;