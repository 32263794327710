export const commonFonts = [
    "Arial",
    "Times New Roman",
    "Helvetica",
    "Calibri",
    "Verdana",
    "Garamond",
    "Courier New",
    "Georgia",
    "Trebuchet MS",
    "Roboto",
    "Tahoma",
    "Century Gothic",
    "Futura",
    "Baskerville",
    "Impact",
    "Franklin Gothic",
    "Palatino Linotype",
    "Lucida Sans",
    "Comic Sans MS",
    "Open Sans",
  ];
  