import { useState, useEffect } from "react";
import { FaSort, FaEnvelope, FaPhone, FaTimes } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SurveyRequest = () => {
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [requestContact, setRequestContact] = useState(null);
  const [error, setError] = useState("");

  const token = localStorage.getItem("token");

  // Fetch contacts from API
  const fetchContacts = async (page = 1) => {
    setLoading(true);
    setError("");

    try {
      const response = await fetch(
        `https://zola.technology/api/company/customers/get-contacts/?page=${page}&page_size=${itemsPerPage}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch contacts");
      }

      const data = await response.json();
      console.log(data);
      setContacts(data.data || []);
      setTotalPages(data.total_pages || 1);
    } catch (error) {
      console.error("Error fetching contacts:", error);
      setError("Failed to load contacts. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts(currentPage);
  }, [currentPage, itemsPerPage]);

  // Sorting function
  const sortData = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sorted = [...contacts].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setContacts(sorted);
    setSortConfig({ key, direction });
  };

  // Handle checkbox selection
  const handleSelectContact = (id) => {
    setSelectedContacts((prev) =>
      prev.includes(id) ? prev.filter((c) => c !== id) : [...prev, id]
    );
  };

  // Handle Select All
  const handleSelectAll = () => {
    if (selectedContacts.length === contacts.length) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts(contacts.map((contact) => contact.id));
    }
  };

  // Open Request Modal
  const handleRequestFeedback = (contact) => {
    setRequestContact(contact);
    setShowRequestModal(true);
  };

  // Send Bulk SMS
  const sendBulkSMS = async () => {
    const selected = selectedContacts.length
      ? contacts.filter((contact) => selectedContacts.includes(contact.id)) // Bulk selection
      : requestContact
      ? [requestContact] // Individual selection
      : [];

    if (selected.length === 0) {
      toast.error("No contacts selected for SMS!");
      return;
    }

    const contactDetails = selected.map((contact) => ({
      phone_number: contact.phone_number,
      name: contact.name,
    }));

    const requestBody = {
      sms_type: "survey",
      contact_details: contactDetails,
    };

    try {
      const response = await fetch(
        "https://zola.technology/api/company/send-bulk-sms/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        // 🔹 Handle specific phone number errors
        if (responseData.contact_details) {
          const errorMessages = Object.entries(responseData.contact_details)
            .map(([_, detail]) => detail.phone_number?.join(", "))
            .filter(Boolean)
            .join("\n");

          toast.error(errorMessages || "Failed to send some SMS.");
        } else {
          toast.error(responseData.message || "Failed to send bulk SMS.");
        }
        return;
      }

      // 🔹 Check for failed messages even if response is ok
      const failedMessages = responseData.details?.filter(
        (detail) => detail.status === "Failed"
      );

      if (failedMessages?.length > 0) {
        const failedNames = failedMessages
          .map((detail) => {
            const contact = selected.find(
              (c) => c.phone_number === detail.phone_number
            );
            return contact
              ? `${contact.name}: ${detail.error}`
              : `Unknown Contact: ${detail.error}`;
          })
          .join("\n");

        toast.success("Some messages were sent successfully.");
        toast.warning(`Failed SMS:\n${failedNames}`);
        return;
      }

      // 🔹 Success message if all messages were sent
      toast.success(
        selected.length === 1
          ? `SMS Sent to ${selected[0].name}`
          : "All SMSs Sent Successfully"
      );

      setShowRequestModal(false);
      setSelectedContacts([]); // Reset selection after sending
    } catch (error) {
      console.error("Error sending bulk SMS:", error);
      toast.error("Failed to send bulk SMS.");
    }
  };

  const sendBulkEmails = async () => {
    const selected = selectedContacts.length
      ? contacts.filter((contact) => selectedContacts.includes(contact.id)) // Bulk selection
      : requestContact
      ? [requestContact] // Individual selection
      : [];

    if (selected.length === 0) {
      toast.error("No contacts selected for Email!");
      return;
    }

    const contactDetails = selected.map((contact) => ({
      email: contact.email,
      name: contact.name,
    }));

    try {
      const response = await fetch(
        "https://zola.technology/api/company/send-bulk-emails/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email_type: "survey",
            contact_details: contactDetails,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send bulk emails.");
      }

      await response.json();
      toast.success(
        selected.length === 1
          ? `Email Sent to ${selected[0].name}`
          : "Emails Sent Successfully"
      );

      setShowRequestModal(false);
      setSelectedContacts([]); // Clear selection after sending
    } catch (error) {
      console.error("Error sending bulk emails:", error);
      toast.error("Failed to send bulk emails.");
    }
  };

  // Handle Pagination (Next & Previous)
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="lg:p-6 bg-gray-50 dark:bg-gray-900 min-h-screen transition-colors duration-300">
      <ToastContainer position="top-right" autoClose={3000} />

      <div className="w-full mx-auto bg-white dark:bg-gray-800 p-6 rounded-md shadow-md">
        {/* Header */}
        <div className="flex flex-col md:flex-row gap-4 justify-between items-center mb-6">
          <div>
            <h2 className="text-2xl font-semibold dark:text-white">
              Survey Requests
            </h2>
            <p className="text-gray-500 dark:text-gray-400">
              Manage your Survey request contacts.
            </p>
          </div>
        </div>

        {/* Bulk Actions Dashboard */}
        {selectedContacts.length > 0 && (
          <div className="bg-blue-100 dark:bg-blue-700 p-4 rounded-md mb-4 flex justify-between items-center">
            <p className="text-blue-600 dark:text-white font-medium">
              {selectedContacts.length} contacts selected
            </p>
            <div className="flex gap-4">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center gap-2"
                onClick={sendBulkEmails}
              >
                <FaEnvelope /> Send Email
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md flex items-center gap-2"
                onClick={sendBulkSMS}
              >
                <FaPhone /> Send SMS
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md flex items-center gap-2"
                onClick={() => setSelectedContacts([])}
              >
                <FaTimes /> Clear Selection
              </button>
            </div>
          </div>
        )}

        {/* Table */}
        <div className="overflow-x-auto">
          {loading ? (
            <p className="text-center text-gray-500 dark:text-gray-400">
              Loading...
            </p>
          ) : contacts.length === 0 ? (
            <div className="text-center text-gray-500 dark:text-gray-400 py-4">
              <p>No contacts available</p>
              <a
                href="/dashboard/contacts"
                className="mt-2 inline-block bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
              >
                Add New Contacts
              </a>
            </div>
          ) : (
            <table className="min-w-full table-auto border-separate border-spacing-y-2">
              <thead>
                <tr className="bg-gray-100 dark:bg-gray-700">
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={selectedContacts.length === contacts.length}
                    />
                  </th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                    <div className="flex items-center gap-2">
                      Name
                      <button
                        onClick={() => sortData("name")}
                        className="text-gray-600 dark:text-gray-400 hover:text-gray-800"
                      >
                        <FaSort className="text-lg" />
                      </button>
                    </div>
                  </th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                    Email
                  </th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                    Phone
                  </th>
                  <th className="px-4 py-3 text-center text-sm font-medium text-gray-700 dark:text-gray-300">
                    Request Survey
                  </th>
                </tr>
              </thead>

              <tbody>
                {contacts.map((contact) => (
                  <tr
                    key={contact.id}
                    className="hover:bg-gray-50 dark:hover:bg-gray-700"
                  >
                    <td className="px-4 py-3">
                      <input
                        type="checkbox"
                        checked={selectedContacts.includes(contact.id)}
                        onChange={() => handleSelectContact(contact.id)}
                      />
                    </td>
                    <td className="px-4 py-3 text-gray-700 dark:text-gray-300">
                      {contact.name}
                    </td>
                    <td className="px-4 py-3 text-gray-700 dark:text-gray-300">
                      {contact.email || "-"}
                    </td>
                    <td className="px-4 py-3 text-gray-700 dark:text-gray-300">
                      {contact.phone_number || "-"}
                    </td>
                    <td className="px-4 py-3 text-center">
                      <button
                        className="bg-blue-600 text-white px-3 py-2 rounded-md hover:bg-blue-700"
                        onClick={() => handleRequestFeedback(contact)}
                      >
                        Send Request
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={`px-4 py-2 rounded-md ${
            currentPage === 1
              ? "bg-gray-300 text-gray-600 dark:bg-gray-700 dark:text-gray-400"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
        >
          Previous
        </button>
        <span className="text-gray-700 dark:text-gray-300">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 rounded-md ${
            currentPage === totalPages
              ? "bg-gray-300 text-gray-600 dark:bg-gray-700 dark:text-gray-400"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
        >
          Next
        </button>
      </div>

      {/* Request Modal */}
      {showRequestModal && requestContact && (
        <div className="fixed inset-0 bg-black bg-opacity-60 dark:bg-opacity-80 flex justify-center items-center z-50 p-4">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-md shadow-md w-96">
            <h3 className="text-xl font-semibold dark:text-white mb-4">
              Send Request
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Choose how to send feedback request to{" "}
              <b>{requestContact.name}</b>:
            </p>

            <div className="flex flex-col gap-4">
              {requestContact.email ? (
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center gap-2 hover:bg-blue-600"
                  onClick={sendBulkEmails}
                >
                  <FaEnvelope /> Send via Email
                </button>
              ) : null}

              {requestContact.phone_number ? (
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded-md flex items-center gap-2 hover:bg-green-600"
                  onClick={sendBulkSMS}
                >
                  <FaPhone /> Send via SMS
                </button>
              ) : null}

              {!requestContact.email && !requestContact.phone_number && (
                <p className="text-gray-500 text-center dark:text-gray-400">
                  No contact method available for this user.
                </p>
              )}
            </div>

            <button
              onClick={() => setShowRequestModal(false)}
              className="mt-4 w-full bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SurveyRequest;
