import Features from "../Features/Features";
import howItWorks from './how-it-works.svg'

function HowItWorks() {
  return (
    <Features
      title="How It Works"
      subtitle="Deploy NFC Cards with Ease"
      description="Distribute NFC-enabled cards to your customers to facilitate seamless feedback collection.
      Simply tap the card with a smartphone to instantly access the feedback form, making it effortless for customers to provide input"
      image={howItWorks}
      buttonText1="Try Out"
      buttonText2="Learn More"
      button1Style={{ background: "#000", color: "#fff" }}
    />
  )
}

export default HowItWorks