import { createContext, useContext, useState } from "react";

const TimeRangeContext = createContext();

export const TimeRangeProvider = ({ children }) => {
  const [timeRange, setTimeRange] = useState("week");

  return (
    <TimeRangeContext.Provider value={{ timeRange, setTimeRange }}>
      {children}
    </TimeRangeContext.Provider>
  );
};

// Custom hook to use the context
export const useTimeRange = () => useContext(TimeRangeContext);
