import PropTypes from "prop-types";
import ZolaDashboard from "./Dashboard.png";

const Timeline = ({ currentStep }) => {
  const steps = [
    // { id: 1, description: "Company Details" },
    { id: 1, description: "Card Details" },
    { id: 2, description: "Payment" },
  ];

  const progressPercentage = Math.round((currentStep / steps.length) * 100);

  return (
    <div className="hidden md:flex flex-col items-center fixed md:w-3/4 lg:w-1/2 py-4 bg-gradient-to-r from-black to-gray-900 text-white min-h-screen">
      <div className="w-full relative">
        <div className="flex w-full lg:w-3/4 m-auto justify-between items-center relative trackers">
          {steps.map((step, index) => (
            <div key={step.id} className="flex flex-col items-center relative w-full">
              <span
                className={`relative flex items-center justify-center w-3 h-3 p-5 rounded-full ${
                  step.id <= currentStep ? "bg-yellow-500" : "bg-gray-500"
                }`}
              >
                {step.id}
              </span>
              <p>{step.description}</p>
              {index < steps.length - 1 && (
                <span
                  className={`absolute top-1/3 left-full w-full h-1 ${
                    step.id < currentStep ? "bg-yellow-500" : "bg-gray-500"
                  }`}
                  style={{ transform: "translateX(-50%) translateY(-50%)", width: "79%" }}
                ></span>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center p-6 text-center">
        <h2 className="text-4xl font-semibold text-white mb-4">
          Zola - Turn Feedback into 5-Star Growth
        </h2>
        {/* <p className="text-lg font-light text-gray-400 mb-4">
          Join Zola now to expand your revenue for future!
        </p> */}
        <img
          src={ZolaDashboard}
          className="w-full object-cover h-full mx-auto rounded-lg shadow-md"
          alt="Zola Dashboard"
        />
        <div className="w-full lg:w-3/4 bg-gray-700 rounded-full h-2.5 my-4">
          <div
            className="bg-yellow-400 h-2.5 rounded-full transition-all duration-300"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
        <p className="text-lg font-light text-gray-400">
          {progressPercentage}% completed
        </p>
      </div>
    </div>
  );
};

Timeline.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default Timeline;
