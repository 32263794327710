import { useState, useEffect } from "react";
import ConnectionModal from "./ConnectionModal";
import UpdateModal from "./UpdateModal";
import DisconnectionModal from "./DisconnectModal";
import { FaSearch, FaGoogle, FaYelp, FaTripadvisor } from "react-icons/fa";
import { IoFlash } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
// import { PiPlugsConnectedBold } from "react-icons/pi";
import { MdOutlineLink, MdOutlineLinkOff } from "react-icons/md";
const accounts = [
  {
    name: "Google Business Profile",
    type: "google",
    icon: <FaGoogle className="text-red-500" />,
    connected: false,
  },
  {
    name: "Yelp",
    type: "yelp",
    icon: <FaYelp className="text-red-600" />,
    connected: false,
  },
  {
    name: "Tripadvisor",
    type: "tripadvisor",
    icon: <FaTripadvisor className="text-green-500" />,
    connected: false,
  },
  {
    name: "Apartments.com",
    type: "Apartments.com",
    icon: <img src="https://www.apartments.com/favicon.ico" alt="Apartments.com Icon" />,
    connected: false,
  },
];

export default function AddAccounts() {
  const [search, setSearch] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [updateAccount, setUpdateAccount] = useState(null);
  const [disconnectAccount, setDisconnectAccount] = useState(null);
  const [integratedAccounts, setIntegratedAccounts] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0); // Refresh trigger state

  // Fetch integrated accounts, runs on mount & refreshKey change
  useEffect(() => {
    const fetchIntegratedAccounts = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const response = await fetch(
          "https://zola.technology/api/company/integrated-accounts/get-all/",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const data = await response.json();
        console.log("Fetched Integrated Accounts:", data);
        setIntegratedAccounts(data);
      } catch (error) {
        console.error("Error fetching integrated accounts:", error);
      }
    };

    fetchIntegratedAccounts();
  }, [refreshKey]); // Depend on refreshKey to refetch

  // Handle disconnect action
  const handleDisconnect = async () => {
    if (!disconnectAccount) return;

    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Unauthorized. Please log in.");
      return;
    }

    const endpointMap = {
      google:
        "https://zola.technology/api/company/google-reviews/remove-account/",
      tripadvisor:
        "https://zola.technology/api/company/trip-advisor/remove-account/",
      yelp: "https://zola.technology/api/company/yelp/remove-account/",
    };

    const endpoint = endpointMap[disconnectAccount.type];
    if (!endpoint) {
      toast.error("Unknown service type.");
      return;
    }

    try {
      const response = await fetch(endpoint, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.detail || "Failed to disconnect account.");
      }

      toast.success(`${disconnectAccount.name} disconnected successfully.`);
      setRefreshKey((prev) => prev + 1); // Trigger refresh
    } catch (error) {
      console.error("Error disconnecting account:", error);
      toast.error(error.message);
    } finally {
      setDisconnectAccount(null);
    }
  };

  const filteredAccounts = accounts
    .map((account) => {
      const matchedAccount = integratedAccounts.find(
        (ia) =>
          ia.service_type === account.type ||
          (ia.service_type === "google_my_business" &&
            account.type === "google") ||
            (ia.service_type === "apartments_reviews" &&
              account.type === "Apartments.com")
      );
      return matchedAccount
        ? { ...account, connected: true, review_url: matchedAccount.review_url }
        : account;
    })
    .filter((account) =>
      account.name.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <div className="w-full mx-auto p-6">
      {/* <ToastContainer/> */}
      <h2 className="text-2xl font-semibold text-center text-blue-700">
        Add Accounts
      </h2>

      {/* Search Bar */}
      <div className="relative mt-4">
        <FaSearch className="absolute left-3 top-3 text-gray-500" />
        <input
          type="text"
          placeholder="Search for review websites"
          className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {/* Account List */}
      <div className="mt-4">
        {filteredAccounts.map((account, index) => (
          <div
            key={index}
            className="flex items-center justify-between bg-gray-100 rounded-lg p-3 mt-2"
          >
            <div className="flex items-center space-x-3">
              <span className="text-xl">{account.icon}</span>
              <p className="font-medium">{account.name}</p>
            </div>

            <div className="flex items-center space-x-2">
              <IoFlash className="text-green-500" />
              {account.connected ? (
                <div className="flex space-x-3 items-center">
                  {/* Update Account Button with Tooltip */}
                  <div className="relative group">
                    <button
                      className="bg-blue-600 text-white p-1 rounded-lg hover:bg-blue-700 transition duration-300"
                      onClick={() => setUpdateAccount(account)}
                    >
                      <MdOutlineLink size={24} />
                    </button>
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:flex items-center justify-center bg-gray-900 text-white text-xs rounded-md px-4 py-1 shadow-lg min-w-[160px] whitespace-nowrap text-center">
                      Update Account Link
                    </span>
                  </div>

                  {/* Disconnect Account Button with Tooltip */}
                  <div className="relative group">
                    <button
                      className="bg-red-500 text-white p-1 rounded-lg hover:bg-red-600 transition duration-300"
                      onClick={() => setDisconnectAccount(account)}
                    >
                      <MdOutlineLinkOff size={24} />
                    </button>
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:flex items-center justify-center bg-gray-900 text-white text-xs rounded-md px-4 py-1 shadow-lg min-w-[160px] whitespace-nowrap text-center">
                      Disconnect Account
                    </span>
                  </div>
                </div>
              ) : (
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                  onClick={() => setSelectedAccount(account)}
                >
                  CONNECT
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Connection Modal */}
      {selectedAccount && (
        <ConnectionModal
          selectedAccount={selectedAccount}
          onClose={() => setSelectedAccount(null)}
          onSuccess={() => setRefreshKey((prev) => prev + 1)} // Refresh after connection
        />
      )}

      {/* Update Modal */}
      {updateAccount && (
        <UpdateModal
          account={updateAccount}
          onClose={() => setUpdateAccount(null)}
          onUpdate={() => setRefreshKey((prev) => prev + 1)} // Refresh after update
        />
      )}

      {/* Disconnection Modal */}
      {disconnectAccount && (
        <DisconnectionModal
          disconnectAccount={disconnectAccount}
          onClose={() => setDisconnectAccount(null)}
          onConfirm={handleDisconnect} // Handle disconnection
        />
      )}

      {/* Toast Notifications */}
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}
