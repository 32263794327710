import { useContext } from "react";
import { BranchContext } from "../context/BranchContext";
import { FaStar } from "react-icons/fa";

const BranchList = () => {
  const { branches, loading } = useContext(BranchContext);

  console.log(branches)

  if (loading) return <p>Loading branches...</p>;

  // Sort branches by performance score and pick the top 3
  const topBranches = [...branches]
    .sort((a, b) => b.performance - a.performance)
    .slice(0, 3);

  return (
    <div>
      <h3 className="text-lg font-semibold mb-2">Top Branches</h3>
      <div className="flex space-x-4 justify-evenly">
        {topBranches.map((branch) => {
          const nameParts = branch.name.split("-");
          let initials = "";
          let displayName = "";

          if (nameParts.length > 1) {
            const wordsAfterHyphen = nameParts[1].trim().split(" ");
            displayName = wordsAfterHyphen[0];
            initials = displayName[0].toUpperCase();
          } else {
            const words = branch.name.trim().split(" ");
            displayName = words[0];
            initials = displayName[0].toUpperCase();
          }

          return (
            <div key={branch.id} className="flex flex-col items-center">
              <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-500 text-white text-lg font-bold">
                {initials}
              </div>
              <p className="text-sm mt-1 text-center">{displayName}</p>
              <div className="flex items-center text-sm">
                {branch.performance.toFixed(1)} <FaStar className="text-yellow-500 ml-1" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BranchList;
