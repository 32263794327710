import { useEffect, useState } from "react";
import { FaExclamationTriangle, FaStar, FaDownload } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import EditForm from "./EditForm";
import { ToastContainer, toast } from "react-toastify";
import QRCode from "qrcode";

const EmployeeProfile = () => {
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const [employeeData, setEmployeeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await fetch(
          `https://zola.technology/api/company/employees/details/get/${employeeId}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        setEmployeeData(data);

        // Generate QR Code for employee profile URL
        if (data.employee.employee_url) {
          const qrCode = await QRCode.toDataURL(data.employee.employee_url);
          setQrCodeData(qrCode);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeData();
  }, [employeeId]);

  const handleUpdate = (index) => {
    setSelectedItemIndex(index);
    // console.log(index);
    setShowPopup(true);
  };

  const handleEditFormClose = () => setShowPopup(false);

  const handleUpdateData = async (updatedValues) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }
  
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/update/${employeeData.employee.id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: updatedValues.name,
            username: updatedValues.username,
            profile_image: updatedValues.profile_image || employeeData.employee.profile_image,
          }),
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(
          errorData?.username?.[0] ||
          errorData?.message ||
          "Error updating employee"
        );
        throw new Error("Network response was not ok");
      }
  
      // ✅ Correctly updating the object (not treating as an array)
      const updatedData = {
        ...employeeData,
        employee: { ...employeeData.employee, ...updatedValues },
      };
      setEmployeeData(updatedData);
      toast.success("Employee data updated successfully!");
    } catch (error) {
      setError(error.message);
      toast.error(`Update Error: ${error.message}`);
    }
  };
  

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true); // Open the delete modal
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false); // Close the delete modal
  };

  const handleDelete = () => {
    // console.log(`Employee ${employeeData?.employee.name} has been deleted!`);
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    setLoading(true);
    try {
      const response = fetch(
        `https://zola.technology/api/company/employees/delete/${employeeData?.employee.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // if (response.status !== 204) throw new Error("Error deleting employee");

      // setData(data.filter((_, i) => i !== index));
      toast.success(`${employeeData?.employee.name} deleted  successfully!`);
      navigate(-1);
    } catch (error) {
      setError(error.message);
      toast.error(`Delete Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="min-h-screen">
      <ToastContainer position="top-right" autoClose={3000} />

      {/* Header Section */}
      <div className="bg-white rounded-xl p-6 flex flex-col md:flex-row items-center md:items-start gap-6 mb-8">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <p className="w-32 h-32 rounded-full flex items-center justify-center text-white font-bold text-3xl bg-blue-600 shadow-lg ring-4 ring-offset-2 ring-white overflow-hidden">
            {employeeData?.employee?.profile_image ? (
              <img
                src={employeeData?.employee?.profile_image}
                alt={employeeData?.employee?.name}
                className="w-full h-full object-cover rounded-full"
              />
            ) : (
              `${employeeData?.employee?.name
                .split(" ")
                .slice(0, 2)
                .map((word) => word[0].toUpperCase())
                .join("")}`
            )}
          </p>

          <div className="mt-3">
            <h1 className="text-3xl font-semibold text-gray-800">
              {employeeData.employee.name}
            </h1>
            <div
              className={`text-md flex items-center justify-center lg:justify-start px-4 py-1 rounded-lg font-medium mt-2`}
            >
              {/* {employeeData.performance_label === "Poor Employee" ? (
                <MdError className="text-red-600 mr-2" />
              ) : (
                <MdVerified className="text-blue-600 mr-2" />
              )} */}
              <span className="text-center">
                {/* {employeeData.performance_label} */}
              </span>
            </div>
            <div className="flex flex-row gap-3 mt-4">
              <button
                aria-label="Update"
                className="bg-blue-500 text-white px-12 lg:px-5 py-2 rounded-lg hover:bg-blue-600 transition"
                onClick={() => setShowPopup(true)}
              >
                Update
              </button>
              <button
                aria-label="Delete"
                className="bg-red-500 text-white px-12 lg:px-5 py-2 rounded-lg hover:bg-red-600 transition"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center md:items-end flex-grow">
          <div className="bg-gray-100 p-3 rounded-lg text-sm">
            <a
              href={employeeData.employee.employee_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 font-medium text-xs md:text-sm hover:underline"
            >
              {employeeData.employee.employee_url}
            </a>
          </div>

          {qrCodeData && (
            <div className="mt-4 p-4 bg-gray-50 rounded-lg shadow-md flex flex-col items-center">
              <img
                src={qrCodeData}
                alt="Employee QR Code"
                className="w-32 h-32 rounded-lg shadow-md"
              />
              <button
                className="mt-3 flex items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
                onClick={() => {
                  const link = document.createElement("a");
                  link.href = qrCodeData;
                  link.download = `qr-code-${employeeData.employee.name}.png`;
                  link.click();
                }}
              >
                <FaDownload className="mr-2" /> Download QR Code
              </button>
            </div>
          )}
        </div>
      </div>
      {/* 
      </div> */}

      {/* Statistics Section */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <div className="p-4 bg-white rounded-xl">
          <h2 className="text-sm font-medium text-gray-500">Ranking</h2>
          <p className="text-2xl font-semibold mt-1">{employeeData.rank}</p>
        </div>

        <div className="p-4 bg-white rounded-xl">
          <h2 className="text-sm font-medium text-gray-500">Average Rating</h2>
          <div className="flex items-center space-x-1 mt-1">
            <span className="text-2xl font-semibold">
              {employeeData.average_rating}
            </span>
            <FaStar className="text-yellow-500" />
          </div>
        </div>

        <div className="p-4 bg-white rounded-xl">
          <h2 className="text-sm font-medium text-gray-500">Rewards</h2>
          <p className="text-2xl font-semibold mt-1">
            {employeeData.reward_currency} {employeeData.reward_total}
          </p>
        </div>

        <div className="p-4 bg-white rounded-xl">
          <h2 className="text-sm font-medium text-gray-500">Feedbacks</h2>
          <p className="text-2xl font-semibold mt-1">
            {employeeData.total_feedback}
          </p>
        </div>
      </div>

      {/* Reviews Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {employeeData.feedback.length > 0 ? (
          employeeData.feedback.map((review, index) => (
            <div key={index} className="p-4 bg-white rounded-lg">
              <h3 className="text-md font-semibold">{review.author_name}</h3>
              <p className="text-sm text-gray-700 mb-2">{review.text_info}</p>
              <div className="flex items-center space-x-1">
                <span>{review.rating}</span>
                <FaStar className="text-yellow-500" />
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500">
            Employee&apos;s reviews will appear here
          </p>
        )}
      </div>

      {showPopup && employeeData ? (
        <EditForm
          selectedItem={employeeData.employee} // Pass the selected item
          onUpdate={handleUpdateData}
          onClose={handleEditFormClose}
        />
      ) : null}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl p-6 w-[90%] max-w-sm text-center space-y-4">
            <FaExclamationTriangle className="text-red-500 text-5xl mx-auto" />
            <h2 className="text-2xl font-semibold">Delete Employee?</h2>
            <p className="text-gray-600">This action cannot be undone.</p>
            <div className="flex justify-center space-x-4 mt-4">
              <button
                aria-label="Cancel"
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition"
                onClick={handleDeleteCancel} // Closes the delete confirmation modal
              >
                Cancel
              </button>
              <button
                aria-label="Delete"
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
                onClick={handleDelete} // Confirms deletion of the selected employee
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeProfile;
