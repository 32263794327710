import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { FaStar } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

ChartJS.register(ArcElement, Tooltip, Legend);

const AverageRating = ({ google, tripadvisor }) => {
  const googleRating = google?.rating || 0;
  const googleReviews = google?.reviews || 0;
  const tripAdvisorRating = parseFloat(tripadvisor?.data?.rating || 0);
  const tripAdvisorReviews = parseInt(tripadvisor?.data?.review_count || 0);

  const totalReviews = googleReviews + tripAdvisorReviews;
  const averageRating = totalReviews
    ? (googleRating * googleReviews + tripAdvisorRating * tripAdvisorReviews) / totalReviews
    : 0;

  const ratings = [
    { stars: 5, count: Math.round(totalReviews * 0.45), percentage: 45 },
    { stars: 4, count: Math.round(totalReviews * 0.25), percentage: 25 },
    { stars: 3, count: Math.round(totalReviews * 0.15), percentage: 15 },
    { stars: 2, count: Math.round(totalReviews * 0.10), percentage: 10 },
    { stars: 1, count: Math.round(totalReviews * 0.05), percentage: 5 },
  ];

  const data = {
    labels: ratings.map((r) => `${r.stars}★`),
    datasets: [
      {
        data: ratings.map((r) => r.count),
        backgroundColor: [
          "#4c8bf5",
          "#6ea8ff",
          "#98c5ff",
          "#c0dbff",
          "#e8f1ff",
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: "70%",
    width: 400,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-5 w-full">
      <div className="flex justify-between items-center mb-8">
        <h3 className="text-lg font-semibold">Average Rating</h3>
      </div>

      <div className="flex flex-col lg:flex-row gap-4 justify-center items-center">
        <div className="relative flex justify-center w-auto">
          <Doughnut data={data} options={options} />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            <p className="text-2xl font-bold">{averageRating.toFixed(2)}</p>
            <p className="text-gray-500 text-sm">{totalReviews} Reviews</p>
          </div>
        </div>
      </div>

      <div className="mt-4 flex flex-wrap items-center justify-evenly gap-6 text-sm">
        {/* Google */}
        {googleReviews > 0 && (
          <span className="flex items-center gap-1 text-gray-700">
            <FcGoogle className="text-[#4285F4] text-lg" /> {googleRating.toFixed(2)}
            <FaStar className="text-yellow-500 text-xs" />
          </span>
        )}

        {/* TripAdvisor */}
        {tripAdvisorReviews > 0 && (
          <span className="flex items-center gap-1 text-gray-700">
            <FaStar className="text-green-500 text-lg" /> {tripAdvisorRating.toFixed(2)}
          </span>
        )}
      </div>
    </div>
  );
};

export default AverageRating;
