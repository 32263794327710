import { useNavigate } from "react-router-dom";

const ComingSoon = ({ onGoBack, companyUsername, employeeUsername }) => {
  const navigate = useNavigate(); // Hook to navigate

  const handleGoBack = () => {
    onGoBack(); // Call the function to reset state
    navigate(`/company_details/${companyUsername}/${employeeUsername}`); // Go to a specific route
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white text-center">
      <h1 className="text-3xl font-bold mb-4">Coming Soon!</h1>
      <p className="text-lg text-gray-600 mb-6">
        Stay tuned for exciting updates. We're working hard to bring you new features!
      </p>
      <button
        aria-label="Go Back"
        onClick={handleGoBack} // Use the function to navigate
        className="mt-4 py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
      >
        Go Back
      </button>
    </div>
  );
};

export default ComingSoon;
