import axios from "axios";
import { useEffect, useState } from "react";
import { HiChartPie, HiSparkles, HiLightBulb } from "react-icons/hi";
import "chart.js/auto";

function AIInsightsSurvey() {
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Load insights from local storage on mount
  useEffect(() => {
    const savedInsights = localStorage.getItem("insights");
    if (savedInsights) {
      setInsights(JSON.parse(savedInsights));
    }
  }, []);

  const fetchInsights = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(
        "https://zola.technology/api/company/surveys/ai-analysis/get/",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: { filter: "month" },
        }
      );

      setInsights(response.data);
      localStorage.setItem("insights", JSON.stringify(response.data));
      // console.log("Fetched Insights:", response.data);
    } catch (err) {
      // console.error("Failed to fetch insights", err.response?.data?.detail);
      setError(err.response?.data?.detail);
      setInsights(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 w-full bg-white dark:bg-gray-900 dark:text-gray-200 rounded-lg mx-auto transition-colors duration-300">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-3xl font-bold text-gray-800 dark:text-gray-100">
            AI Survey Insights
          </h1>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            Gain deep insights from customer feedback.
          </p>
        </div>

        <button
          onClick={fetchInsights}
          disabled={loading}
          className={`px-5 py-2 rounded-lg flex items-center gap-2 transition border ${
            loading
              ? "bg-gray-300 text-gray-600 cursor-not-allowed"
              : "text-blue-500 border-blue-500 hover:bg-blue-100"
          }`}
        >
          {loading ? "Generating..." : "Generate Insights"}
          <HiSparkles
            className={`text-lg ${loading ? "animate-spin" : "text-blue-500"}`}
          />
        </button>
      </div>

      {/* Error State */}
      {error && (
          <div className="flex flex-col items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 text-red-500 mb-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01M5.21 17.29A9 9 0 1119.78 6.71 9 9 0 015.21 17.29z"
              />
            </svg>
            <p className="text-red-500 text-base font-medium">{error}</p>
            <p className="text-gray-500 text-sm mt-2">
              Once you get survey responses, Our AI tool will help you generate insights.
            </p>
          </div>
        )}

      {/* No Insights Yet Message */}
      {!loading && !insights && !error && (
        <div className="flex flex-col items-center justify-center bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 shadow-md">
          <div className="flex items-center justify-center w-16 h-16 bg-blue-100 dark:bg-blue-900 text-blue-500 dark:text-blue-300 rounded-full mb-4">
            <HiLightBulb className="w-8 h-8" />
          </div>
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
            No Insights Available
          </h3>
          <p className="text-sm text-gray-600 dark:text-gray-300 text-center mt-2">
            Click the <strong>"Generate Insights"</strong> button above to
            create a new summary for your business. Our AI is ready to help!
          </p>
        </div>
      )}

      {/* Loading State */}
      {loading && (
        <p className="text-gray-600 text-lg font-medium">
          Generating insights... Please wait.
        </p>
      )}

      {/* Insights Data */}
      {/* Insights Data */}
      {!loading && insights && (
        <div className="space-y-8">
          {/* Sentiment Analysis */}
          <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
            <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 flex items-center gap-2">
              <HiChartPie className="w-6 h-6 text-blue-500" />
              Sentiment Analysis
            </h3>
            <p className="text-gray-700 dark:text-gray-300 flex flex-col md:flex-row mt-4">
              <span className="text-green-500 font-semibold mr-2">
                Positive:
              </span>{" "}
              {insights?.textual_analysis?.sentiment?.positive?.length || 0}
              <span className="hidden md:inline mx-2">|</span>
              <span className="text-gray-500 font-semibold mr-2">
                Neutral:
              </span>{" "}
              {insights?.textual_analysis?.sentiment?.neutral?.length || 0}
              <span className="hidden md:inline mx-2">|</span>
              <span className="text-red-500 font-semibold mr-2">
                Negative:
              </span>{" "}
              {insights?.textual_analysis?.sentiment?.negative?.length || 0}
            </p>
          </div>

          {/* Key Themes & Emotional Language */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Key Themes */}
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100">
                Key Themes
              </h3>
              <ul className="list-disc pl-6 mt-4 text-gray-700 dark:text-gray-300 space-y-2">
                {insights?.textual_analysis?.themes?.length > 0 ? (
                  insights.textual_analysis.themes.map((theme, index) => (
                    <li key={index}>{theme}</li>
                  ))
                ) : (
                  <p className="text-gray-500">No themes found.</p>
                )}
              </ul>
            </div>

            {/* Emotional Language */}
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100">
                Emotional Language
              </h3>
              <p className="mt-4 text-gray-700 dark:text-gray-300">
                {insights?.textual_analysis?.emotional_language?.length > 0
                  ? insights.textual_analysis.emotional_language.join(", ")
                  : "No emotional language detected."}
              </p>
            </div>
          </div>

          {/* Numeric Analysis */}
          <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
            <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100">
              Numeric Analysis
            </h3>
            <p className="mt-4">
              <span className="font-medium">Recommendation Score:</span>{" "}
              {insights?.numeric_analysis?.average?.recommendation_score ||
                "N/A"}{" "}
              <br />
              <span className="font-medium">Satisfaction Score:</span>{" "}
              {insights?.numeric_analysis?.average?.satisfaction_score || "N/A"}
            </p>
          </div>

          {/* Trends & Recommendations */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Trends */}
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100">
                Trends
              </h3>
              <ul className="list-disc pl-6 mt-4 text-gray-700 dark:text-gray-300 space-y-2">
                {insights?.numeric_analysis?.trends?.length > 0
                  ? insights.numeric_analysis.trends.map((trend, index) => (
                      <li key={index}>{trend}</li>
                    ))
                  : "No trends found."}
              </ul>
            </div>

            {/* Recommendations */}
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100">
                Recommendations
              </h3>
              <ul className="list-disc pl-6 mt-4 text-gray-700 dark:text-gray-300 space-y-2">
                {insights?.overall_trends?.recommendations?.length > 0
                  ? insights.overall_trends.recommendations.map(
                      (rec, index) => <li key={index}>{rec}</li>
                    )
                  : "No recommendations available."}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AIInsightsSurvey;
