import { useState } from "react";
import axios from "axios";

const SubmitCardsComponent = () => {
    // State to manage form data
    const [formData, setFormData] = useState({
        number_of_cards: 0,
        card_amount: 0,
        number_of_stands: 0,
        stand_amount: 0,
    });

    // State to handle submission status
    const [status, setStatus] = useState("");

    // Get data from localStorage
    const userData = JSON.parse(localStorage.getItem("userData"));
    const username = userData?.username || "";
    const phoneNumber = userData?.phoneNumber || "";
    const currentNumberOfCards = userData?.number_of_cards || 0;
    const currentNumberOfStands = userData?.number_of_stands || 0;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            username,
            number_of_cards: parseInt(formData.number_of_cards, 10),
            card_amount: parseFloat(formData.card_amount),
            number_of_stands: parseInt(formData.number_of_stands, 10),
            stand_amount: parseFloat(formData.stand_amount),
            transaction_type: "Payment",
        };

        try {
            const response = await axios.post("http://example.com/api/submit-cards", payload);
            setStatus(`Success: ${response.data.message}`);
        } catch (error) {
            setStatus(`Error: ${error.response?.data?.message || error.message}`);
        }
    };

    return (
        <div className="mx-auto p-6 bg-white rounded-md flex flex-col lg:flex-row">
            {/* Form Section */}
            <div className="w-full lg:w-1/2 lg:pr-6 mb-6 lg:mb-0">
                <h2 className="text-2xl font-bold mb-4 text-center lg:text-left">Submit Cards</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-gray-700">Number of Cards:</label>
                        <input
                            type="number"
                            name="number_of_cards"
                            value={formData.number_of_cards}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Card Price:</label>
                        <input
                            type="number"
                            name="card_amount"
                            value={formData.card_amount}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Number of Stands:</label>
                        <input
                            type="number"
                            name="number_of_stands"
                            value={formData.number_of_stands}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Stand Price:</label>
                        <input
                            type="number"
                            name="stand_amount"
                            value={formData.stand_amount}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        />
                    </div>
                    <button 
                        type="submit" 
                        className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300">
                        Submit
                    </button>
                </form>
                {status && <p className="mt-4 text-center text-gray-700">{status}</p>}
            </div>

            {/* Current Data Section */}
            <div className="w-full lg:w-1/2 lg:pl-6">
                <h2 className="text-2xl font-bold mb-4 text-center lg:text-left">Current Data</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="p-4 border border-gray-300 rounded-md">
                        <h3 className="text-lg font-semibold">Current Number of Cards:</h3>
                        <p className="text-gray-700 text-center sm:text-left">{currentNumberOfCards}</p>
                    </div>
                    <div className="p-4 border border-gray-300 rounded-md">
                        <h3 className="text-lg font-semibold">Current Number of Stands:</h3>
                        <p className="text-gray-700 text-center sm:text-left">{currentNumberOfStands}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubmitCardsComponent;
