const SurveySuggestions = ({ activesurvey }) => {
  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md w-full h-96 overflow-y-auto scrollbar-hide">
      <h2 className="text-lg font-semibold mb-2">{activesurvey?.title}</h2>
      <p className="text-sm text-gray-700 mb-4">
        Here are some survey questions designed to gather actionable feedback:
      </p>

      {activesurvey?.questions?.map((question, index) => (
        <div
          key={question.id}
          className="bg-white p-3 rounded-lg shadow-sm mb-3"
        >
          <h3 className="font-semibold text-base mb-1">
            {index + 1}. {question.question_text}
          </h3>

          {question.choices && question.choices.length > 0 && (
            <div
              className={`mt-1 text-sm ${
                question.question_type === "numeric_choice"
                  ? "flex flex-wrap gap-2"
                  : "list-disc pl-5 text-gray-600"
              }`}
            >
              {question.question_type === "numeric_choice" ? (
                question.choices.map((choice, idx) => (
                  <div
                    key={idx}
                    className="px-2 py-1 bg-gray-200 rounded-md text-gray-700"
                  >
                    {choice}
                  </div>
                ))
              ) : (
                <ul>
                  {question.choices.map((choice, idx) => (
                    <li key={idx}>{choice}</li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SurveySuggestions;
