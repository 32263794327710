import { useState, useEffect } from "react";
import { FiDownload } from "react-icons/fi";
import ZolaLogo from "../../HQDashboard/components/sidebar/Layer_1.png"; // Imported logo

import QRCode from "qrcode";

function QRCodeModal({ showModal, setShowModal, companyUrl }) {
  const [qrCodeUrl, setQrCodeUrl] = useState(null);

  useEffect(() => {
    if (companyUrl) {
      QRCode.toDataURL(companyUrl).then((qrDataUrl) => {
        setQrCodeUrl(qrDataUrl);
      });
    }
  }, [companyUrl]);
  const handleDownload = () => {
    if (qrCodeUrl) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
  
      // Create the QR code image
      const qrImg = new Image();
      qrImg.src = qrCodeUrl;
      qrImg.onload = () => {
        // Set canvas width based on JSX width (192px)
        const qrSize = 192;  // Same width as in your JSX (w-48 = 12rem, 1rem = 16px, so 12 * 16px = 192px)
        const logoSize = qrSize / 2; // Set logo width to half of the QR code size
        const canvasWidth = qrSize;
        const canvasHeight = qrSize + logoSize + 10; // Space for the logo at the bottom
  
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
  
        // Set white background for the canvas
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
  
        // Draw the QR code on the canvas
        ctx.drawImage(qrImg, 0, 0, qrSize, qrSize);
  
        // Create the logo image
        const logoImg = new Image();
        logoImg.src = ZolaLogo;
        logoImg.onload = () => {
          // Draw the logo with width = qrSize / 2 and let the height be auto
          const logoX = (canvasWidth - logoSize) / 2;
          const logoY = qrSize + 5; // Position logo 5px below QR code
  
          // Draw the logo image while maintaining aspect ratio (height is auto-calculated)
          ctx.drawImage(logoImg, logoX, logoY, logoSize, logoImg.height * (logoSize / logoImg.width));
  
          // Download the canvas as an image
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          link.download = "company-qrcode-with-logo.png";
          link.click();
        };
      };
    }
  };
  

  if (!showModal) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 p-4">
      <div className="bg-white dark:bg-gray-900 p-6 rounded-lg shadow-lg w-96 grid">
        <h2 className="text-xl text-center font-semibold text-gray-900 dark:text-gray-100 mb-4">
          Company QR Code
        </h2>
        {qrCodeUrl ? (
          <div className="flex flex-col items-center">
            <div className="relative p-4">
              <img
                src={qrCodeUrl}
                alt="Company QR Code"
                className="w-48 h-48 rounded-lg shadow-lg mb-4"
              />
              {/* Logo positioned at the bottom of QR Code */}
              <img
                src={ZolaLogo}
                alt="Zola Logo"
                className="w-48 mb-2"
              />
            </div>
            <button
              onClick={handleDownload}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition duration-300"
              aria-label="Download QR Code"
            >
              <span className="flex items-center justify-center">
                <FiDownload />
                Download QR Code
              </span>
            </button>
          </div>
        ) : (
          <p className="text-gray-500 dark:text-gray-400">
            Generating QR Code...
          </p>
        )}
        <button
          onClick={() => setShowModal(false)}
          className="mt-4 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default QRCodeModal;
