import Homepage from "./pages/Homepage";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import Pricing from "./pages/Pricing/Pricing";
import Features from "./pages/Features/Features";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import Emoji from "./pages/Emojis/Emoji";
import FeedbackPage from "./pages/Feedback-Page/FeedbackPage";
import SignupSuccess from "./pages/SignUp/SignUpSuccess";

import ReviewPrompt from "./pages/Emojis/PositiveReview";
import NegativeReviewPrompt from "./pages/Emojis/NegativeReview";
import EmployeeRating from "./pages/Emojis/EmployeeRating";
import Blog from "./pages/Blog/Blog";
import BlogDetails from "./pages/Blog/BlogDetails";
import WhyZola from "./pages/WhyZola/WhyZola";

import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import OtpVerification from "./pages/ForgotPassword/Otp";
import ForgetPassword from "./pages/ForgotPassword";
import AccountTypePage from "./pages/SignUp/AccountTypePage";
import BookingPage from "./pages/SignUp/BookingPage";
import Customers from "./pages/Customers/Customers";
import CompanyDetails from "./pages/Emojis/CompanyDetails";
import Form from "./components/Form/Form";

import NFCReaderComponent from "./components/NFCReader/NFCReader";
import MultiStepForm from "./components/MultiStep-Form/MultiStepForm";
import MobileSurvey from "./pages/SurveyPreview/MobileSurvey";
import AuthenticatedNavbar from "./components/AuthenticatedNavbar/AuthNavbar";
import SuccessPage from "./components/Form/Success";
import Success from "./components/PaymentStatus/Success";
import Failed from "./components/PaymentStatus/Failed";
import PrivacyPolicy from "./pages/TermsOfService/PrivacyPolicy";
import CouponRedemption from "./pages/CouponRedemption/CouponRedemption";
import SuccessSurveyMessage from "./pages/SurveyPreview/SuccessSurveyMessage";
import MobileSurveyPrompt from "./mobile/pages/MobileSurvey";

const paymentStatus = "success"; // or 'failure'

// import { lazy } from "react";

// const Homepage = lazy(() => import("./pages/Homepage"));
// const Login = lazy(() => import("./pages/Login/Login"));
// const SignUp = lazy(() => import("./pages/SignUp/SignUp"));
// const Pricing = lazy(() => import("./pages/Pricing/Pricing"));
// const Features = lazy(() => import("./pages/Features/Features"));
// const TermsOfService = lazy(() => import("./pages/TermsOfService/TermsOfService"));
// const Emoji = lazy(() => import("./pages/Emojis/Emoji"));
// const FeedbackPage = lazy(() => import("./pages/Feedback-Page/FeedbackPage"));
// const SignupSuccess = lazy(() => import("./pages/SignUp/SignUpSuccess"));
// const ReviewPrompt = lazy(() => import("./pages/Emojis/PositiveReview"));
// const NegativeReviewPrompt = lazy(() => import("./pages/Emojis/NegativeReview"));
// const EmployeeRating = lazy(() => import("./pages/Emojis/EmployeeRating"));
// const Blog = lazy(() => import("./pages/Blog/Blog"));
// const BlogDetails = lazy(() => import("./pages/Blog/BlogDetails"));
// const WhyZola = lazy(() => import("./pages/WhyZola/WhyZola"));
// const ForgotPassword = lazy(() => import("./pages/ForgotPassword/ForgotPassword"));
// const OtpVerification = lazy(() => import("./pages/ForgotPassword/Otp"));
// const ForgetPassword = lazy(() => import("./pages/ForgotPassword"));
// const AccountTypePage = lazy(() => import("./pages/SignUp/AccountTypePage"));
// const BookingPage = lazy(() => import("./pages/SignUp/BookingPage"));
// const Customers = lazy(() => import("./pages/Customers/Customers"));
// const CompanyDetails = lazy(() => import("./pages/Emojis/CompanyDetails"));
// const Form = lazy(() => import("./components/Form/Form"));
// const NFCReaderComponent = lazy(() => import("./components/NFCReader/NFCReader"));
// const MultiStepForm = lazy(() => import("./components/MultiStep-Form/MultiStepForm"));
// const MobileSurvey = lazy(() => import("./pages/SurveyPreview/MobileSurvey"));
// const AuthenticatedNavbar = lazy(() => import("./components/AuthenticatedNavbar/AuthNavbar"));
// const SuccessPage = lazy(() => import("./components/Form/Success"));
// const Success = lazy(() => import("./components/PaymentStatus/Success"));
// const Failed = lazy(() => import("./components/PaymentStatus/Failed"));
// const PrivacyPolicy = lazy(() => import("./pages/TermsOfService/PrivacyPolicy"));
// const CouponRedemption = lazy(() => import("./pages/CouponRedemption/CouponRedemption"));
// const SuccessSurveyMessage = lazy(() => import("./pages/SurveyPreview/SuccessSurveyMessage"));
// const MobileSurveyPrompt = lazy(() => import("./mobile/pages/MobileSurvey"));

export const PublicRoutes = [
  { path: "/", element: <Homepage /> }, // <Route index element={<Homepage />} />
  { path: "/login", element: <Login /> }, // <Route path="/login" element={<Login />} />
  { path: "/signup", element: <SignUp /> }, // <Route path="/signup" element={<SignUp />} />
  { path: "/account-type", element: <AccountTypePage /> }, // <Route path="/account-type" element={<AccountTypePage />} />
  { path: "/book", element: <BookingPage /> }, // <Route path="/book" element={<BookingPage />} />
  { path: "/forgot-password", element: <ForgotPassword /> }, // <Route path="/forgot-password" element={<ForgotPassword />} />
  { path: "/terms-of-service", element: <TermsOfService /> }, // <Route path="/terms-of-service" element={<TermsOfService />} />
  { path: "/privacy", element: <PrivacyPolicy /> }, // <Route path="/privacy" element={<PrivacyPolicy />} />
  { path: "/customers", element: <Customers /> }, // <Route path="/customers" element={<Customers />} />
  { path: "/blog", element: <Blog /> }, // <Route path="/blog" element={<Blog />} />
  { path: "/blog/:id", element: <BlogDetails /> }, // <Route path="/blog/:id" element={<BlogDetails />} />
  { path: "/pricing", element: <Pricing /> }, // <Route path="/pricing" element={<Pricing />} />
  { path: "/why-zola", element: <WhyZola /> }, // <Route path="/why-zola" element={<WhyZola />} />
  { path: "/features", element: <Features /> }, // <Route path="/features" element={<Features />} />
  { path: "/scan", element: <NFCReaderComponent /> }, // <Route path="/scan" element={<NFCReaderComponent />} />
  { path: "/success", element: <Success status={"success"} /> }, // <Route path="/success" element={<Success status={paymentStatus} />} />
  { path: "/failed", element: <Failed status={"failure"} /> }, // <Route path="/failed" element={<Failed status={paymentStatus} />} />
  { path: "/mobile-survey/:company_username/:channel?", element: <MobileSurveyPrompt />},
  {
    path: "/signup-success",
    element: (
      <>
        {" "}
        <AuthenticatedNavbar /> <SignupSuccess />{" "}
      </>
    ),
  }, // <Route path="/signup-success" element={<SignupSuccess />} />
  {
    path: "/feedback/:company_username/:employee_username/:channel?",
    element: <CompanyDetails />,
  }, // <Route path="/feedback/:company_username/:employee_username/:channel?" element={<CompanyDetails />} />
  {
    path: "/employee_rating/:company_username/:employee_username/:channel?",
    element: <EmployeeRating />,
  }, // <Route path="/employee_rating/:company_username/:employee_username/:channel?" element={<EmployeeRating />} />
  {
    path: "/emoji/:company_username/:employee_username/:channel?",
    element: <Emoji />,
  }, // <Route path="/emoji/:company_username/:employee_username/:channel?" element={<Emoji />} />
  {
    path: "/feedback/:company_username/:employee_username/:emoji/:channel?",
    element: <FeedbackPage />,
  }, // <Route path="/feedback/:company_username/:employee_username/:emoji/:channel?" element={<FeedbackPage />} />
  {
    path: "/review-prompt/:company_username/:employee_username/:emoji/:channel?",
    element: <ReviewPrompt />,
  }, // <Route path="/review-prompt/:company_username/:employee_username/:emoji/:channel?" element={<ReviewPrompt />} />
  {
    path: "/negative-review-prompt/:company_username/:employee_username/:emoji/:channel?",
    element: <NegativeReviewPrompt />,
  }, // <Route path="/negative-review-prompt/:company_username/:employee_username/:emoji/:channel?" element={<NegativeReviewPrompt />} />
  { path: "/survey/:company_username/:channel?", element: <MobileSurvey /> }, // <Route path="/survey/:company_username/:channel?" element={<MobileSurvey />} />
  { path: "/survey/success", element: <SuccessSurveyMessage /> }, // <Route path="/survey/success" element={<SuccessSurveyMessage />} />
  {
    path: "/feedback/:company_username/:employee_username/:emoji/:channel?/make-a-zola-review",
    element: <Form />,
  }, // <Route path="/feedback/:company_username/:employee_username/:emoji/:channel?/make-a-zola-review" element={<Form />} />
  { path: "/success-page", element: <SuccessPage /> }, // <Route path="/success-page" element={<SuccessPage />} />
  { path: "/forgotpassword", element: <ForgetPassword /> }, // <Route path="/forgotpassword" element={<ForgetPassword />} />
  { path: "/otppage", element: <OtpVerification /> }, // <Route path="/otppage" element={<OtpVerification />} />
  {
    path: "/setup-form",
    element: (
      <>
        {" "}
        <AuthenticatedNavbar /> <MultiStepForm />{" "}
      </>
    ),
  }, // <Route path="/setup-form" element={<MultiStepForm />} />
  { path: "/redeem-coupon", element: <CouponRedemption /> }, // <Route path="/redeem-coupon" element={<CouponRedemption />} />
];
