import PropTypes from "prop-types";
import "./Button.css";
import { Link } from "react-router-dom";

const Button = ({ text, to }) => {
  return (
    <>
      <button className="banner-btn">
        <Link to={to}>
        <span></span> {text} 
        {/* <img src={topArrow} alt="" /> */}
        </Link>
      </button>
    </>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Button;
