import { useState } from "react";
import EmojiStarMobile from "./EmojiStarMobile";
import MobilePreview from "./MobilePreview";


function MobilePreviewPage() {
  const [showEmojiStar, setShowEmojiStar] = useState(false);

  return (
    <>
      <div className="items-center md:px-6 flex">
        {/* Back Button */}
        {/* <Link
          to="/dashboard"
          className="flex items-center text-gray-900 dark:text-white mt-[-15px]"
        >
          <GoArrowLeft className="w-12 h-6 p-0" />
        </Link> */}

        {/* Toggle Switch */}
        <div className="relative md:w-8/12 w-full h-10 rounded-full flex bg-[#F5F8FF] dark:bg-gray-800 mb-4 transition-all duration-300">
          {/* Customize Preview Button */}
          <label
            className="flex-1 flex items-center justify-center cursor-pointer transition-all duration-300"
            onClick={() => setShowEmojiStar(false)}
          >
            <input
              type="radio"
              name="toggle"
              value="MobilePreview"
              className="sr-only peer"
              checked={!showEmojiStar}
              readOnly
            />
            <div
              className={`w-full h-full flex items-center justify-center rounded-[58px]
          peer-checked:bg-white dark:peer-checked:bg-gray-700 
          peer-checked:text-blue-500 text-gray-500 
          hover:bg-gray-200 dark:hover:bg-gray-600 transition-all duration-300`}
              style={{
                boxShadow: showEmojiStar
                  ? ""
                  : "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
              }}
            >
              <span className="text-[12px] md:text-[16px] font-medium tracking-wide text-gray-900 dark:text-gray-100">
                Customize Preview
              </span>
            </div>
          </label>

          {/* Review Method Button */}
          <label
            className="flex-1 flex items-center justify-center cursor-pointer transition-all duration-300"
            onClick={() => setShowEmojiStar(true)}
          >
            <input
              type="radio"
              name="toggle"
              value="EmojiStar"
              className="sr-only peer"
              checked={showEmojiStar}
              readOnly
            />
            <div
              className={`w-full h-full flex items-center justify-center rounded-[58px]
          peer-checked:bg-white dark:peer-checked:bg-gray-700 
          peer-checked:text-blue-500 text-gray-500 
          hover:bg-gray-200 dark:hover:bg-gray-600 transition-all duration-300`}
              style={{
                boxShadow: showEmojiStar
                  ? "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814"
                  : "",
              }}
            >
              <span className="text-[12px] md:text-[16px] font-medium tracking-wide text-gray-900 dark:text-gray-100">
                Edit Review Questions
              </span>
            </div>
          </label>
        </div>
      </div>

      {/* Component Display */}
      <div className="">
        {showEmojiStar ? <EmojiStarMobile /> : <MobilePreview />}
      </div>
    </>
  );
}

export default MobilePreviewPage;
