import {
  Link,
  useLocation,
  Outlet,
} from "react-router-dom";



const AccountSettings = () => {
  const location = useLocation();
  // Enhanced logic to detect the active tab
  const activeTab = (path) =>
    location.pathname === path ||
    (path === "/dashboard/setting/" &&
      location.pathname === "/dashboard/setting");

  // Retrieve userData from localStorage
  const userData = JSON.parse(localStorage.getItem("userData"));

  // Get company_type from userData (default to an empty string if userData is null)
  const companyType = userData?.company_type || "";

  return (
    <div className="min-h-screen md:p-4 flex flex-col">
      {/* Title Section */}
      <div className="w-full">
        {/* Page Title */}
        <h2 className="text-2xl md:text-3xl font-semibold text-gray-900 dark:text-gray-100 mb-6">
          Settings
        </h2>

        {/* Subtext for clarity */}
        <p className="text-gray-600 dark:text-gray-300 text-sm md:text-base mb-4">
          Manage your account preferences and application settings here.
        </p>

        {/* Divider Line */}
        <hr className="border-gray-300 dark:border-gray-600 mb-6" />
      </div>

      {/* Tabs Navigation */}
      <div className="w-full md:w-2/3 p-2 md:p-4 rounded-xl mb-6">
        <nav className="flex flex-wrap justify-around gap-2 md:gap-4">
          {[
            { label: "Account", path: "/dashboard/setting/" },
            { label: "Billing", path: "/dashboard/setting/billing" },
            {
              label: "Payment Details",
              path: "/dashboard/setting/update-details",
            },
            { label: "Security", path: "/dashboard/setting/security" },
            companyType === "team" && {
              label: "Reward",
              path: "/dashboard/setting/reward",
            },
          ]
            .filter(Boolean)
            .map(({ label, path }) => (
              <Link
                key={path}
                to={path}
                aria-label={`Go to ${label}`}
                className={`flex-grow text-center py-2 md:py-3 px-2 text-base md:text-lg font-medium transition-all duration-300 rounded-full ${
                  activeTab(path)
                    ? "bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 shadow-lg"
                    : "text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
                }`}
              >
                {label}
              </Link>
            ))}
        </nav>
      </div>

      {/* Dynamic Content Section */}
      <div className="w-full md:w-2/3 bg-white dark:bg-gray-800 rounded-lg p-4 md:p-8 shadow-md border border-gray-200 dark:border-gray-700 transition-all duration-300">
        <Outlet />
      </div>
    </div>
  );
};

export default AccountSettings;
