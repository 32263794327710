import { useEffect, useState, useContext } from "react";
import { MdDelete } from "react-icons/md";
import AddContactModal from "./CreateContact/CreateModal";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import EditContactModal from "./CreateContact/EditContact";
import SearchActions from "./CreateContact/SearchActions";
import Overview from "./Overview";
import html2canvas from "html2canvas";
import { CustomerContactsContext } from "../../context/CustomerContactsContext";
import DeleteConfirmationPopup from "./DeletePopUp";
// import AddContactModal from "./AddContactModal"; // Import modal component
// Import the RequestedFeedbacks component

const ContactsPage = () => {
  const [activeTab, setActiveTab] = useState("contacts");
  const [showAddContact, setShowAddContact] = useState(false);
  const [showEditContact, setShowEditContact] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [appliedFilters, setAppliedFilters] = useState({});
  const [contacts, setContacts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);
  const { refreshContacts } = useContext(CustomerContactsContext);
  const [refreshTrigger, setRefreshTrigger] = useState(0); // Add refresh trigger

  const token = localStorage.getItem("token"); // Retrieve token from localStorage

  const fetchContacts = async (page = 1) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://zola.technology/api/company/customers/get-contacts/?page=${page}&page_size=10`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch contacts");
      }

      const data = await response.json();
      setContacts(data.data);
      setTotalPages(data.total_pages);
      setCurrentPage(data.current_page);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch contacts when the component mounts or page changes
  useEffect(() => {
    if (activeTab === "contacts") {
      fetchContacts(currentPage);
    }
  }, [currentPage, refreshTrigger]); // Now listens to `activeTab`

  // Function to update contacts list after adding a new contact
  // Function to trigger a fetch when a new contact is added
  const onContactAdded = () => {
    setRefreshTrigger((prev) => prev + 1); // Increment trigger to refetch contacts
    refreshContacts() //refresh context after succesful contact submission
  };
  // Handle individual contact selection
  const toggleContactSelection = (id) => {
    setContacts((prevContacts) =>
      prevContacts.map((contact) =>
        contact.id === id
          ? { ...contact, selected: !contact.selected }
          : contact
      )
    );
  };

  // Handle select all toggle
  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setContacts((prevContacts) =>
      prevContacts.map((contact) => ({ ...contact, selected: newSelectAll }))
    );
  };

  const handleDeleteClick = (contact = null) => {
    if (contact) {
      // Single contact deletion
      setContactToDelete([contact]); 
    } else {
      // If no individual contact is passed, check for selected contacts
      const selectedContacts = contacts.filter(contact => contact.selected);
  
      if (selectedContacts.length === 0) {
        toast.warn("No contacts selected for deletion.");
        return;
      }
  
      setContactToDelete(selectedContacts);
    }
    setShowDeletePopup(true);
  };
  
  const confirmDeleteContact = async () => {
    if (!contactToDelete || contactToDelete.length === 0) {
      toast.warn("No contacts selected for deletion.");
      return;
    }
  
    try {
      await Promise.all(contactToDelete.map(async (contact) => {
        const response = await fetch(
          `https://zola.technology/api/company/customers/contact/${contact.id}/delete/`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          throw new Error(`Failed to delete contact: ${contact.name}`);
        }
      }));
  
      // Show a specific toast message for a single contact
      if (contactToDelete.length === 1) {
        toast.success(`Contact ${contactToDelete[0].name} deleted successfully`);
      } else {
        toast.success("Selected contacts deleted successfully");
      }
  
      setShowDeletePopup(false);
      setSelectAll(false);
      setRefreshTrigger((prev) => prev + 1); // Refresh the contact list
      refreshContacts() //refresh context on delete
    } catch (error) {
      console.error("Error deleting contacts:", error);
      toast.error("Failed to delete some contacts");
    }
  };
  
  


  // Filtered contacts based on search and filters
  const filteredContacts = contacts.filter((contact) => {
    const matchesSearch =
      contact.name.toLowerCase().includes(searchQuery) ||
      contact.email.toLowerCase().includes(searchQuery);
    const matchesFilters =
      (!appliedFilters.includesName || contact.name) &&
      (!appliedFilters.includesPhone || contact.phone_number) &&
      (!appliedFilters.includesEmail || contact.email) &&
      (!appliedFilters.contactedBefore || contact.selected) &&
      (!appliedFilters.notFullyInteracted || !contact.selected);

    return matchesSearch && matchesFilters;
  });

  // Count selected contacts
  const selectedCount = contacts.filter((contact) => contact.selected).length;
  // Open edit contact modal
  const handleEditContact = (contact) => {
    setCurrentContact(contact);
    setShowEditContact(true);
  };

  // Function to trigger a refresh after an edit
  const onContactUpdated = () => {
    setRefreshTrigger((prev) => prev + 1);
    refreshContacts() //on successful update refresh the context
  };

  // Save edited contact
  const handleSaveEdit = (updatedContact) => {
    setContacts((prevContacts) =>
      prevContacts.map((contact) =>
        contact.id === currentContact.id
          ? { ...contact, ...updatedContact }
          : contact
      )
    );
    setShowEditContact(false);
  };

  // 🔹 Function to handle exporting contacts
  const handleExport = async (format) => {
    if (contacts.length === 0) {
      toast.warn("No contacts available for export!");
      return;
    }

    if (format === "pdf") {
      const { default: jsPDF } = await import("jspdf");

      const doc = new jsPDF();
      doc.text("Contacts List", 14, 20);
      let y = 30;

      contacts.forEach((contact, index) => {
        doc.text(
          `${index + 1}. ${contact.name} - ${contact.email || "N/A"} - ${
            contact.phone_number || "N/A"
          }`,
          14,
          y
        );
        y += 10;
      });

      doc.save("contacts.pdf");
      toast.success("Contacts exported as PDF!");
    }

    if (format === "image") {
      const table = document.getElementById("contactsTable");
      if (table) {
        html2canvas(table).then((canvas) => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          link.download = "contacts.png";
          link.click();
        });

        toast.success("Contacts exported as an Image!");
      }
    }

    if (format === "csv") {
      const csvData = [
        ["Name", "Email", "Phone Number"], // Headers
        ...contacts.map((contact) => [
          contact.name,
          contact.email || "N/A",
          contact.phone_number || "N/A",
        ]),
      ];

      const csvContent =
        "data:text/csv;charset=utf-8," +
        csvData.map((e) => e.join(",")).join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.href = encodedUri;
      link.download = "contacts.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("Contacts exported as CSV!");
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <Overview
        title="Contacts"
        hideTimeRange={true}
        onExportClick={handleExport}
        allowCsvExport={true}
      />
      <div className="p-6 bg-white dark:bg-gray-800 min-h-screen flex flex-col items-center">
        {/* Tabs */}
        {/* <div className="flex space-x-4 bg-gray-100 rounded-full p-2">
          {["contacts", "requests", "scheduled"].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-6 py-2 rounded-full font-medium capitalize ${
                activeTab === tab ? "bg-purple-600 text-white" : "text-gray-700"
              }`}
            >
              {tab}
            </button>
          ))}
        </div> */}

        {/* Search & Actions */}
        <SearchActions
          setShowAddContact={setShowAddContact}
          setSearchQuery={setSearchQuery}
          setAppliedFilters={setAppliedFilters}
          appliedFilters={appliedFilters}
          onContactAdded={onContactAdded}
        />

        {/* Selection Bar (Only visible when contacts are selected) */}
        {selectedCount > 0 && (
          <div className="flex flex-col md:flex-row items-center justify-between bg-blue-600 dark:bg-gray-700 text-white dark:text-gray-200 p-3 rounded-lg my-4 w-full space-y-3 md:space-y-0">
            {/* Selected Count */}
            <div className="flex items-center space-x-2">
              <span>
                {selectedCount} of {filteredContacts.length} contacts selected
              </span>
            </div>

            {/* Action Buttons */}
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-3 w-full md:w-auto">
              {/* Mark as Contacted */}
              {/* <button className="flex items-center justify-center space-x-1 bg-green-500 hover:bg-green-600 text-white px-3 py-2 rounded-md transition w-full md:w-auto">
                <MdCheckCircle className="text-lg" />
                <span>Mark as contacted</span>
              </button> */}

              {/* Delete Selected */}
              <button onClick={() => handleDeleteClick()} className="flex items-center justify-center space-x-1 bg-red-500 hover:bg-red-600 text-white px-3 py-2 rounded-md transition w-full md:w-auto">
                <MdDelete className="text-lg"  />
                <span>Delete selected</span>
              </button>

              {/* Export Button */}
              {/* <button className="flex items-center justify-center space-x-1 bg-blue-500 hover:bg-blue-700 text-white px-3 py-2 rounded-md transition w-full md:w-auto">
                <BiExport className="text-lg" />
                <span>Export</span>
              </button> */}
            </div>
          </div>
        )}

        {activeTab === "contacts" && (
          <div className="w-full mt-6">
            {filteredContacts.length === 0 ? (
              <p className="text-gray-500 text-center mt-10">
                No contacts.Click + to add contacts or upload csv.
              </p>
            ) : (
              <table className="min-w-full table-auto border-separate border-spacing-y-3 ">
                <thead>
                  <tr className="text-left text-[#6F707E] text-[16px] font-medium bg-gray-100 dark:bg-gray-900">
                    <th className="px-4 py-3 font-normal">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={toggleSelectAll}
                        className="w-5 h-5 cursor-pointer"
                      />
                    </th>
                    <th className="px-4 py-3 font-normal rounded-tl-lg">S/N</th>
                    <th className="px-4 py-3 font-normal">Name</th>
                    <th className="px-4 py-3 font-normal">Email</th>
                    <th className="px-4 py-3 font-normal">Phone Number</th>
                    <th className="px-4 py-3 font-normal rounded-tr-lg">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition">
                      <td colSpan="5" className="text-center p-4">
                        Loading...
                      </td>
                    </tr>
                  ) : contacts.length === 0 ? (
                    <tr>
                      <td
                        colSpan="5"
                        className="text-center p-4 bg-gray-50 dark:bg-gray-800"
                      >
                        No contacts available
                      </td>
                    </tr>
                  ) : (
                    filteredContacts.map((contact, index) => (
                      <tr
                        key={contact.id}
                        className={`${
                          index % 2 === 0
                            ? "bg-gray-50 dark:bg-gray-900"
                            : "bg-white dark:bg-gray-800"
                        } text-gray-900 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition shadow-sm`}
                      >
                        <td className="px-4 py-3 border-b border-gray-200">
                          <input
                            type="checkbox"
                            checked={contact.selected || false}
                            onChange={() => toggleContactSelection(contact.id)}
                            className="w-5 h-5 cursor-pointer"
                          />
                        </td>
                        <td
                          data-cell="S/N"
                          className="md:px-2 py-3 border-b border-gray-200"
                        >
                          {index + 1}
                        </td>

                        {/* Name Column with Initials Avatar */}
                        <td
                          data-cell="Name"
                          className="px-4 py-3 border-b border-gray-200"
                        >
                          <div className="flex items-center space-x-4">
                            {/* <div
                className={`p-2 rounded-full bg-blue-600 flex items-center justify-center 
                ${contact.name.includes(" ") ? "w-10" : "w-9"}`}
              >
                <p className="text-white font-semibold text-[12px]">
                  {contact.name
                    .split(" ")
                    .slice(0, 2)
                    .map((word) => word[0].toUpperCase())
                    .join("")}
                </p>
              </div> */}
                            <span className="text-[16px] font-semibold">
                              {contact.name}
                            </span>
                          </div>
                        </td>

                        <td
                          data-cell="Email"
                          className="px-4 py-3 border-b border-gray-200"
                        >
                          {contact.email || "N/A"}
                        </td>
                        <td
                          data-cell="Phone Number"
                          className="px-4 py-3 border-b border-gray-200"
                        >
                          {contact.phone_number || "N/A"}
                        </td>

                        {/* Actions Buttons */}
                        <td
                          data-cell="Actions"
                          className="md:px-8 py-3 border-b border-gray-200"
                        >
                          <div className="space-x-2 flex flex-row">
                            <button
                              aria-label="Edit"
                              className="bg-[#0C4EF8] font-semibold px-4 py-2 text-[#FEFEFF] text-[14px] rounded-[4px] hover:bg-blue-600 transition xs:w-5/12 md:w-6/12 flex justify-center items-center"
                              onClick={() => handleEditContact(contact)}
                            >
                              Update
                            </button>
                            <button
                              aria-label="Delete"
                              onClick={() => handleDeleteClick(contact)}
                              className="py-2 px-4 bg-[#FDEAEB] font-semibold text-[#E6283B] rounded-[4px] hover:bg-red-600 hover:text-white transition xs:w-5/12 md:w-6/12"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            )}
            {totalPages > 0 && (
              <div className="flex justify-between mt-6 space-x-2">
                <button
                  className={`px-4 py-2 border rounded ${
                    currentPage === 1
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-blue-500 text-white"
                  }`}
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  Prev
                </button>
                <span className="px-4 py-2">{`Page ${currentPage} of ${totalPages}`}</span>
                <button
                  className={`px-4 py-2 border rounded ${
                    currentPage === totalPages
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-blue-500 text-white"
                  }`}
                  disabled={currentPage === totalPages}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        )}

        {/* Contact List
      <div className="w-full mt-6">
        {filteredContacts.length === 0 ? (
          <p className="text-gray-500 text-center mt-10">
            No contacts match your search.
          </p>
        ) : (
          filteredContacts.map((contact) => (
            <div
              key={contact.id}
              className="flex items-center justify-between bg-gray-100 rounded-lg p-4 mb-2"
            >
              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  checked={contact.selected}
                  onChange={() => toggleContactSelection(contact.id)}
                  className="w-5 h-5"
                />

                <span className="font-semibold">{contact.name}</span>
              </div>
              <div className="flex items-center space-x-3 text-gray-600">
                <span>{contact.phone_number}</span>
                <AiOutlineMail />
                <span>{contact.email}</span>
                <button onClick={() => handleEditContact(contact)}>
                  <AiOutlineEdit className="text-blue-500 text-lg" />
                </button>
                <button onClick={() => handleDeleteClick(contact)}>
                  <MdDelete className="text-red-500 text-lg" />
                </button>
              </div>
            </div>
          ))
        )}
        {totalPages > 0 && (
          <div className="flex mt-6 space-x-2">
            <button
              className={`px-4 py-2 border rounded ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 text-white"
              }`}
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Prev
            </button>
            <span className="px-4 py-2">{`Page ${currentPage} of ${totalPages}`}</span>
            <button
              className={`px-4 py-2 border rounded ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 text-white"
              }`}
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          </div>
        )}
      </div> */}

        {/* Requested Feedbacks Component (Rendered when "requests" tab is selected) */}
        {/* {activeTab === "requests" && <RequestedFeedbacks />} */}

        {/* Modals */}
        <AddContactModal
          isOpen={showAddContact}
          onClose={() => setShowAddContact(false)}
          onContactAdded={onContactAdded}
        />
        {/* Pass onContactUpdated to refresh contacts after edit */}
        <EditContactModal
          isOpen={showEditContact}
          onClose={() => setShowEditContact(false)}
          contact={currentContact}
          onSave={onContactUpdated}
        />

        <DeleteConfirmationPopup
          isOpen={showDeletePopup}
          onClose={() => setShowDeletePopup(false)}
          onConfirm={confirmDeleteContact}
          item={contactToDelete ? contactToDelete.name : "Selected contacts"}
        />
      </div>
    </>
  );
};

export default ContactsPage;
