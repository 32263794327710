import { LineChart, Line, ResponsiveContainer, Tooltip } from "recharts";
import {
  TrendingUp,
  Eye,
  MousePointerClick,
  BarChart,
} from "lucide-react";

export default function CampaignStats({ statsData }) {
  const stats = [
    {
      label: "Delivered",
      value: statsData.delivered,
      trend: 40,
      positive: true,
      icon: <TrendingUp className="w-6 h-6 text-gray-500" />,
      data: [
        { value: 1800 },
        { value: 1950 },
        { value: 1700 },
        { value: 2100 },
        { value: 2420 },
      ],
    },
    {
      label: "Opened",
      value: statsData.opened,
      trend: -10,
      positive: false,
      icon: <Eye className="w-6 h-6 text-gray-500" />,
      data: [
        { value: 1400 },
        { value: 800 },
        { value: 1050 },
        { value: 2000 },
        { value: 1210 },
      ],
    },
    {
      label: "Clicked",
      value: statsData.clicked,
      trend: 20,
      positive: true,
      icon: <MousePointerClick className="w-6 h-6 text-gray-500" />,
      data: [
        { value: 200 },
        { value: 280 },
        { value: 240 },
        { value: 290 },
        { value: 316 },
      ],
    },
    {
      label: "Bounced",
      value: statsData.bounced,
      trend: 40,
      positive: true,
      icon: <BarChart className="w-6 h-6 text-gray-500" />,
      data: [
        { value: 2100 },
        { value: 2250 },
        { value: 2150 },
        { value: 2350 },
        { value: 2420 },
      ],
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      {stats.map(({ label, value, trend, positive, icon, data }) => (
        <div
          key={label}
          className="flex flex-col justify-between p-6 gap-8 bg-white dark:bg-gray-900 rounded-xl shadow-sm border border-gray-200 dark:border-gray-800 transition-shadow"
        >
          {/* Label */}
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            {label}
          </h3>
          <div className="flex items-center justify-between">
            <div className="grid gap-2">
              {/* Value */}
              <p className="text-3xl font-bold text-gray-900 dark:text-white mt-1">
                {value?.toLocaleString()}
              </p>

              {/* Trend Indicator - Now Matches Screenshot */}
              {/* <div className="flex items-center gap-1">
                <div
                  className={`flex items-center ${
                    positive ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {positive ? (
                    <ArrowUp className="w-5 h-5" />
                  ) : (
                    <ArrowDown className="w-5 h-5" />
                  )}
                  <span className="text-lg font-semibold">
                    0%
                  </span>
                </div>
                <span className="text-gray-500 flex flex-col dark:text-gray-400 text-sm font-medium">
                  vs last <span>month</span>
                </span>
              </div> */}
            </div>

            {/* Mini Line Graph with Meandering Effect */}
            <div>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={data}>
                  <Tooltip contentStyle={{ display: "none" }} />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke={positive ? "#22C55E" : "#EF4444"}
                    strokeWidth={2.5}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
