import {useEffect, useState} from 'react'
import Features from "../Features/Features";
import centralize from './centralise.svg'

function CentralizeFeedback() {
  const [styles, setStyles] = useState({});

  const getStyles = () => {
    if (window.innerWidth < 768) {
      return {
        flexDirection: "column",
        background: "#222930",
        color: "white",
        padding: "2rem",
      };
    } else {
      return {
        flexDirection: "row-reverse",
        background: "#222930",
        color: "white",
      };
    }
  };

  // Update styles when window is resized
  useEffect(() => {
    const handleResize = () => {
      setStyles(getStyles());
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Features
    style={styles}
    title="Features"
    subtitle="Centralize Feedback Management"
    description="All feedback is automatically captured and centralized within Zola's user-friendly dashboard.
    Easily manage and analyze feedback data to identify trends, areas for improvement, and opportunities for growth."
    image={centralize}
    buttonText1="Try Out"
    buttonText2="Learn More"
    button1Style={{ background: "#000", color: "#fff" }} />
  )
}

export default CentralizeFeedback