import { useEffect, useState } from "react";
import { FaFacebook, FaTripadvisor } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useTimeRange } from "../../context/TimeRangeContext";
import { useIntegratedAccounts } from "../../context/IntegratedAccounts";

const ReviewDashboard = ({ data, taps, performance, emails, sms }) => {
  const { timeRange } = useTimeRange();
  const { integratedAccounts } = useIntegratedAccounts();
  const [tripAdvisorReviews, setTripAdvisorReviews] = useState(null);

 
  // Fetch TripAdvisor reviews if TripAdvisor is connected
  useEffect(() => {
    const tripAdvisorAccount = integratedAccounts.find(
      (acc) => acc.service_type === "tripadvisor" && acc.is_connected
    );

    if (!tripAdvisorAccount) return;

    const fetchTripAdvisorData = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const response = await fetch(
          "https://zola.technology/api/company/trip-advisor/list-reviews/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch TripAdvisor data");

        const tripData = await response.json();
        console.log(tripData, "trip data")
        setTripAdvisorReviews(tripData);
      } catch (error) {
        console.error("Error fetching TripAdvisor data:", error);
      }
    };

    fetchTripAdvisorData();
  }, [integratedAccounts]);
console.log(integratedAccounts, "trip advisor")
  // Define connected review sites
  const reviewSites = [
    {
      name: "Google",
      icon: <FcGoogle className="text-[#DB4437] text-xl" />,
      reviews: data?.reviews || 0,
      siteRating: data?.rating || 0,
      isConnected: true,
    },
    {
      name: "Facebook",
      icon: <FaFacebook className="text-[#1877F2] text-xl" />,
      reviews: data.facebook_reviews || 0,
      siteRating: data.facebook_rating || 0,
      isConnected: integratedAccounts.some(
        (acc) => acc.service_type === "facebook" && acc.is_connected
      ),
    },
    {
      name: "TripAdvisor",
      icon: <FaTripadvisor className="text-[#34E0A1] text-xl" />,
      reviews: tripAdvisorReviews?.review_count || 0,
      siteRating: tripAdvisorReviews?.rating || 0,
      isConnected: integratedAccounts.some(
        (acc) => acc.service_type === "tripadvisor" && acc.is_connected
      ),
    },
    // {
    //   name: "Yelp",
    //   icon: <FaYelp className="text-[#D32323] text-xl" />,
    //   reviews: data.yelp_reviews || 0,
    //   siteRating: data.yelp_rating || 0,
    //   isConnected: integratedAccounts.some(
    //     (acc) => acc.service_type === "yelp" && acc.is_connected
    //   ),
    // },
    // {
    //   name: "Apartments.com",
    //   icon: <FaBuilding className="text-[#009A8F] text-xl" />, // Apartments.com icon
    //   reviews: data.apartments_reviews || 0, // Replace with actual API field if different
    //   siteRating: data.apartments_rating || 0, // Replace with actual API field if different
    //   isConnected: integratedAccounts.some(
    //     (acc) => acc.service_type === "apartments_reviews" && acc.is_connected
    //   ),
    // },
  ].filter((site) => site.isConnected);
  

  return (
    <div className="mx-auto">
      {/* Review Sites Section */}
      <div className="rounded-xl pb-4">
        {reviewSites.length > 0 ? (
          <div
            className={`grid gap-4 ${
              reviewSites.length === 1
                ? "grid-cols-1"
                : "grid-cols-1 md:grid-cols-2"
            }`}
          >
            {reviewSites.map((site, index) =>
              site.name === "Yelp" ? (
                <div
                  key={index}
                  className="text-center text-gray-700 dark:text-gray-200 font-medium py-6 bg-white dark:bg-gray-800 rounded-lg shadow-md dark:shadow-lg border border-gray-200 dark:border-gray-700"
                >
                  Yelp is connected. Check reviews in your Yelp dashboard.
                </div>
              ) : (
                <div
                  key={index}
                  className="flex flex-col items-center bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md dark:shadow-lg border border-gray-200 dark:border-gray-700 w-full"
                >
                  <div className="flex items-center space-x-2">
                    {site.icon}
                    <p className="text-gray-700 dark:text-gray-200 font-medium">
                      {site.name}
                    </p>
                  </div>
                  <div className="flex justify-between gap-8">
                    <div className="mt-2 text-center">
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        Reviews
                      </p>
                      <p className="text-xl font-bold text-gray-900 dark:text-white">
                        {site.reviews}
                      </p>
                    </div>
                    <div className="mt-2 text-center">
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        Rating
                      </p>
                      <p className="text-xl font-bold text-yellow-500">
                        {site.siteRating}
                      </p>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ) : (
          <div className="text-center text-gray-500 dark:text-gray-400 py-6 bg-white dark:bg-gray-800 rounded-lg shadow-md dark:shadow-lg border border-gray-200 dark:border-gray-700">
            No review platforms are connected.
          </div>
        )}
      </div>

      {/* Second Row - Additional Details */}
      <div className="rounded-2xl">
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 text-center">
          {/* Email Requests Sent */}
          <div className="p-4 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-sm">
            <p className="text-sm font-semibold text-gray-600 dark:text-gray-400">
              EMAIL REQUESTS SENT
            </p>
            <p className="text-2xl font-bold text-gray-900 dark:text-white">
              {new Intl.NumberFormat().format(emails.used_emails)}
            </p>
          </div>

          {/* SMS Requests Sent */}
          <div className="p-4 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-sm">
            <p className="text-sm font-semibold text-gray-600 dark:text-gray-400">
              SMS REQUESTS SENT
            </p>
            <p className="text-2xl font-bold text-gray-900 dark:text-white">
              {new Intl.NumberFormat().format(sms.used_sms)}
            </p>
          </div>

          {/* SMS Request Usage */}
          <div className="p-4 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-sm">
            <p className="text-sm font-semibold text-gray-600 dark:text-gray-400">
              SMS REQUEST USAGE
            </p>
            <p className="text-2xl font-bold text-gray-900 dark:text-white">
              {sms.used_sms} / {sms.max_sms}
            </p>
          </div>

          {/* Email Request Usage */}
          <div className="flex flex-col items-center justify-center p-5 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-sm min-h-[120px]">
            <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
              EMAIL REQUEST USAGE
            </p>
            <p className="text-2xl font-bold text-gray-900 dark:text-white mt-1">
              {emails.used_emails} / {emails.max_emails}
            </p>
          </div>

          {/* Engagement Rate */}
          <div className="flex flex-col items-center justify-center p-5 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-sm min-h-[120px]">
            <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
              ENGAGEMENT RATE
            </p>
            <div className="flex items-center mt-1">
              <span
                className={`py-1 rounded-full text-2xl font-bold ${
                  taps.change.type === "Increase"
                    ? "text-green-500"
                    : taps.change.type === "Decrease"
                    ? "text-red-500"
                    : "text-gray-600 dark:text-gray-400"
                }`}
              >
                {taps.change.percentage_change}%
              </span>
              <span
                className={`text-2xl ${
                  taps.change.type === "Increase"
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {taps.change.type === "Increase" ? "↑" : "↓"}
              </span>
            </div>
          </div>

          {/* Experience Score */}
          <div className="flex flex-col items-center justify-center p-5 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-sm min-h-[120px]">
            <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
              EXPERIENCE
            </p>
            <div className="flex items-center mt-1">
              <p
                className={`text-2xl font-bold ${
                  performance.change.type === "Increase"
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {performance.score}%
              </p>
              <span
                className={`text-2xl ${
                  performance.change.type === "Increase"
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {performance.change.type === "Increase" ? "↑" : "↓"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewDashboard;