import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaSeedling, FaStar, FaTags } from "react-icons/fa";
import { MdGroups } from "react-icons/md";

function ClearlyTrack() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1, // Trigger animation when 10% of the component is visible
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  return (
    <motion.div
      className="p-6 mx-auto my-[5rem] flex flex-col md:flex-row items-center justify-between gap-12 font-figtree"
      ref={ref}
      variants={containerVariants}
      initial="hidden"
      animate={controls}
    >
      {/* Metrics Section */}
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full md:w-1/2 bg-[#f8f9fe] rounded-lg md:p-12"
        variants={itemVariants}
      >
        <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-start">
          <div className="text-green-500 text-3xl font-bold"><FaSeedling /></div>
          <div className="text-3xl font-bold">1,578%</div>
          <div className="text-gray-600 text-[17px] mt-2">Return on Investment</div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-start">
          <div className="text-yellow-500 text-3xl font-bold"><FaStar /></div>
          <div className="text-3xl font-bold">13</div>
          <div className="text-gray-600 text-[17px] mt-2">Google Reviews</div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-start">
          <div className="text-purple-500 text-3xl font-bold"><MdGroups /></div>
          <div className="text-3xl font-bold">21</div>
          <div className="text-gray-600 text-[17px] mt-2">Subscribers</div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-start">
          <div className="text-orange-500 text-3xl font-bold"><FaTags /></div>
          <div className="text-3xl font-bold">286</div>
          <div className="text-gray-600 text-[17px] mt-2">Redemptions</div>
        </div>
      </motion.div>

      {/* Text Section */}
      <motion.div
        className="md:w-1/2 space-y-4 mt-6 md:mt-0 md:ml-10 text-center md:text-start"
        variants={itemVariants}
      >
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-[38px] font-bold">Clearly Track Your ROI</h2>
        <ul className="space-y-2">
          <li className="text-gray-600 text-2xl">• Get Weekly ROI reports so you don&apos;t have to guess what&apos;s working</li>
          <li className="text-gray-600 text-2xl">• Track redemptions across all channels</li>
          <li className="text-gray-600 text-2xl">• Get $15 back for every $1 that you spend</li>
        </ul>
        {/* <button className="mt-4 px-6 py-4 bg-[#28008f] text-white rounded-lg">Show Me</button> */}
      </motion.div>
    </motion.div>
  );
}

export default ClearlyTrack;