import { useState, useEffect } from "react";
import { useTimeRange } from "../../../context/TimeRangeContext";

const DeliveryStats = ({ colSpan}) => {
  const { timeRange } = useTimeRange(); // Get selected time range
  const [stats, setStats] = useState([
    { label: "Surveys clicked", value: 0, percentage: 0, color: "bg-yellow-500" },
    { label: "Completed surveys", value: 0, percentage: 0, color: "bg-blue-400" },
    { label: "Abandoned surveys", value: 0, percentage: 0, color: "bg-gray-400" },
    { label: "Abandoned surveys", value: 0, percentage: 0, color: "bg-gray-400" },
  ]);

  useEffect(() => {
    const fetchSurveyStats = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const response = await fetch(
          `https://zola.technology/api/company/surveys/events/counts/?filter=${timeRange}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch data");

        const data = await response.json();

        const totalSurveys = data.clicked.count + data.completed.count + data.abandoned.count;
        const getPercentage = (count) => (totalSurveys ? ((count / totalSurveys) * 100).toFixed(1) : 0);

        setStats([
          { label: "Surveys clicked", value: data.clicked.count, percentage: getPercentage(data.clicked.count), color: "bg-yellow-500" },
          { label: "Completed surveys", value: data.completed.count, percentage: getPercentage(data.completed.count), color: "bg-blue-400" },
          { label: "Abandoned surveys", value: data.abandoned.count, percentage: getPercentage(data.abandoned.count), color: "bg-gray-400" },
        ]);
      } catch (error) {
        console.error("Error fetching survey stats:", error);
      }
    };

    fetchSurveyStats();
  }, [timeRange]);

  return (
<div className={`bg-white dark:bg-gray-900 shadow-md rounded-lg p-4 w-full ${colSpan}`}>
  {/* Header */}
  <div className="flex justify-between items-center text-gray-700 dark:text-gray-200 mb-3">
    <h3 className="text-lg font-semibold">Delivery Stats</h3>
  </div>

  {/* Stats List */}
  {stats.map((item, index) => (
    <div key={index} className="mb-4">
      {/* Label & Value */}
      <div className="flex justify-between items-center mb-1">
        <p className="text-sm text-gray-700 dark:text-gray-400">{item.label}</p>
        <p className="text-sm font-semibold text-gray-900 dark:text-gray-100">
          {item.value} <span className="text-gray-500 dark:text-gray-400">({item.percentage}%)</span>
        </p>
      </div>

      {/* Progress Bar */}
      <div className="relative w-full h-8 bg-gray-200 dark:bg-gray-700 rounded-full">
        <div
          className={`absolute top-0 left-0 h-full rounded-full transition-all duration-300 ${item.color}`}
          style={{ width: `${item.percentage}%` }}
        ></div>
      </div>
    </div>
  ))}
</div>

  );
};

export default DeliveryStats;
