import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddCouponModal = ({ onClose, onAdd }) => {
  const [name, setName] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [percentage, setPercentage] = useState("");
  const [cashValue, setCashValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setDuration] = useState("");
  const [usagetype, setUsagetype] = useState("");
  const [usagelimit, setUsagelimit] = useState("");
  const [redemptionArea, setRedemptionArea] = useState("");

  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const difference = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
      if (difference >= 0) {
        setDuration(`${difference} day${difference !== 1 ? "s" : ""}`);
      } else {
        setDuration("Invalid range");
      }
    } else {
      setDuration("");
    }
  }, [startDate, endDate]);

  const handleSubmit = async () => {
    if (
      !name ||
      !discountType ||
      !startDate ||
      !endDate ||
      !duration ||
      !usagetype ||
      !redemptionArea ||
      (usagetype === "general" && !usagelimit) ||
      (discountType === "Percentage" && !percentage) ||
      (discountType === "fixed" && !cashValue)
    ) {
      toast.error("Please fill in all fields correctly.");
      return;
    }
    if (duration === "Invalid range") {
      toast.error("End date cannot be before start date.");
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Authentication token is missing.");
      return;
    }

    const couponData = {
      name,
      usage_type: usagetype,
      coupon_code_prefix: name.toUpperCase().substring(0, 3),
      discount_type: discountType.toLowerCase(),
      discount_value: discountType === "Percentage" ? percentage : cashValue,
      currency: "USD",
      valid_from: new Date(startDate).toISOString(),
      valid_until: new Date(endDate).toISOString(),
      applicable_area: redemptionArea,
      ...(usagetype === "general" && { usage_limit: parseInt(usagelimit, 10) }),
    };

    try {
      const response = await fetch(
        "https://zola.technology/api/company/coupons/add/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(couponData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        toast.success("Coupon added successfully!");
        onAdd(data);
        onClose();
      } else {
        toast.error(`Error: ${data.message || "Failed to add coupon"}`);
      }
    } catch (error) {
      toast.error("An error occurred while adding the coupon.");
      console.error("Error:", error);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white dark:bg-gray-900 p-6 rounded-2xl shadow-xl w-full max-w-lg space-y-4"
      >
        <h3 className="text-2xl font-bold text-gray-800 dark:text-white mb-2">
          Add New Coupon
        </h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
              Coupon Name
            </label>
            <input
              type="text"
              placeholder="Enter coupon name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
              Usage Type
            </label>
            <select
              value={usagetype}
              onChange={(e) => setUsagetype(e.target.value)}
              className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
            >
              <option value="" disabled>
                Select Usage Type
              </option>
              <option value="general">General</option>
              <option value="unique">Unique</option>
            </select>
          </div>
          {usagetype === "general" && (
            <div>
              <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
                Usage Limit
              </label>
              <input
                type="number"
                placeholder="Enter usage limit"
                value={usagelimit}
                onChange={(e) => setUsagelimit(e.target.value)}
                className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
              />
            </div>
          )}

          <div>
            <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
              Discount Type
            </label>
            <select
              value={discountType}
              onChange={(e) => setDiscountType(e.target.value)}
              className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
            >
              <option value="" disabled>
                Select Discount Type
              </option>
              <option value="Percentage">Percentage</option>
              <option value="fixed">Cash</option>
            </select>
          </div>

          {discountType === "Percentage" && (
            <div>
              <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
                Discount Percentage
              </label>
              <input
                type="number"
                placeholder="Enter percentage (e.g., 20)"
                value={percentage}
                onChange={(e) => setPercentage(e.target.value)}
                className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
              />
            </div>
          )}

          {discountType === "fixed" && (
            <div>
              <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
                Cash Value
              </label>
              <input
                type="number"
                placeholder="Enter cash amount (e.g., 50)"
                value={cashValue}
                onChange={(e) => setCashValue(e.target.value)}
                className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
              />
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div>
              <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
                Start Date
              </label>
              <input
                type="date"
                value={startDate}
                min={today}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
                End Date
              </label>
              <input
                type="date"
                value={endDate}
                min={startDate || today}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
              Duration
            </label>
            <input
              type="text"
              value={duration}
              readOnly
              placeholder="Duration auto-calculated"
              className="w-full p-3 border rounded-md bg-gray-100 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200 cursor-not-allowed"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
            Coupon Redemption Area
          </label>
          <select
            value={redemptionArea}
            onChange={(e) => setRedemptionArea(e.target.value)}
            className="w-full p-3 border rounded-md bg-gray-50 dark:bg-gray-800 dark:border-gray-700 text-gray-800 dark:text-gray-200"
          >
            <option value="" disabled>
              Select Redemption Area
            </option>
            <option value="feedback">Feedback</option>
            <option value="survey">Survey</option>
          </select>
        </div>

        <div className="flex justify-end space-x-2 pt-4">
          <button
            onClick={onClose}
            className="px-5 py-2.5 bg-gray-300 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-5 py-2.5 bg-blue-600 text-white rounded-md"
          >
            Add Coupon
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCouponModal;
