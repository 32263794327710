import { motion } from "framer-motion";

const TermsOfService = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="p-8 md:p-24"
    >
      <motion.h1
        className="text-4xl font-bold mb-8 text-center"
        initial={{ x: -200 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 50 }}
      >
        Zola Terms of Service
      </motion.h1>

      <motion.section
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p className="text-lg leading-relaxed">
          Welcome to Zola! These Terms of Service ("Terms") govern your access
          to and use of our website and services. By accessing or using our
          services, you agree to be bound by these Terms. If you do not agree
          with these Terms, please do not use our services.
        </p>
      </motion.section>

      <motion.section
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.3 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-semibold mb-4">2. Definitions</h2>
        <p className="text-lg leading-relaxed">
          <strong>"Zola," "we," "our," "us":</strong> Refers to Zola, a review
          software company that provides tools and services for managing online
          reputation, customer feedback, and employee performance data.
          <br />
          <strong>"You," "your," "user":</strong> Refers to any individual or
          entity that uses our services.
          <br />
          <strong>"Services":</strong> Refers to all products, services,
          content, features, technologies, or functions offered by Zola,
          including our website and mobile applications.
        </p>
      </motion.section>

      <motion.section
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.4 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-semibold mb-4">3. Use of Services</h2>
        <div className="text-lg leading-relaxed">
          <h3 className="text-xl font-medium mb-2">3.1 Eligibility</h3>
          <p className="mb-4">
            To use our services, you must be at least 18 years old and capable
            of entering into a legally binding agreement.
          </p>
          <h3 className="text-xl font-medium mb-2">3.2 Account Registration</h3>
          <p className="mb-4">
            To access certain features of our services, you may need to create
            an account. You are responsible for maintaining the confidentiality
            of your account information, including your password, and for all
            activities that occur under your account.
          </p>
          <h3 className="text-xl font-medium mb-2">
            3.3 Prohibited Activities
          </h3>
          <p>You agree not to engage in any of the following activities:</p>
          <ul className="list-disc ml-6">
            <li>Violating any applicable laws or regulations.</li>
            <li>Using our services for any unlawful or fraudulent purpose.</li>
            <li>
              Interfering with or disrupting the integrity or performance of our
              services.
            </li>
            <li>
              Attempting to gain unauthorized access to our systems or networks.
            </li>
          </ul>
        </div>
      </motion.section>

      <motion.section
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-semibold mb-4">4. Content</h2>
        <div className="text-lg leading-relaxed">
          <h3 className="text-xl font-medium mb-2">4.1 User Content</h3>
          <p className="mb-4">
            You retain ownership of any content you submit, post, or display
            through our services ("User Content"). By submitting User Content,
            you grant Zola a worldwide, non-exclusive, royalty-free license to
            use, reproduce, modify, and distribute your User Content for the
            purpose of operating and improving our services.
          </p>
          <h3 className="text-xl font-medium mb-2">4.2 Zola Content</h3>
          <p>
            All content provided by Zola, including text, graphics, logos, and
            software, is the property of Zola and is protected by copyright,
            trademark, and other intellectual property laws. You may not use any
            Zola content without our prior written permission.
          </p>
        </div>
      </motion.section>

      <motion.section
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-semibold mb-4">
          5. Payments and Subscriptions
        </h2>
        <div className="text-lg leading-relaxed">
          <h3 className="text-xl font-medium mb-2">5.1 Fees</h3>
          <p className="mb-4">
            Certain features of our services may require payment of fees. All
            fees are stated in USD and are non-refundable unless otherwise
            stated.
          </p>
          <h3 className="text-xl font-medium mb-2">5.2 Subscriptions</h3>
          <p>
            If you subscribe to a paid service, your subscription will
            automatically renew at the end of each billing cycle unless you
            cancel it. You can cancel your subscription at any time by accessing
            your account settings.
          </p>
          <h3 className="text-xl font-medium mb-2">5.3 Payment Information</h3>
          <p>
            You agree to provide accurate and complete payment information and
            authorize Zola to charge your payment method for any fees incurred.
          </p>
        </div>
      </motion.section>

      <motion.section
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-semibold mb-4">
          6. Cancellation and Termination
        </h2>
        <div className="text-lg leading-relaxed">
          <h3 className="text-xl font-medium mb-2">6.1 Cancellation by You</h3>
          <p className="mb-4">
            You may cancel your account or subscription at any time. Upon
            cancellation, you will lose access to any paid features of our
            services.
          </p>
          <h3 className="text-xl font-medium mb-2">6.2 Termination by Zola</h3>
          <p>
            We reserve the right to suspend or terminate your account or access
            to our services at any time, without notice, for any reason,
            including if you violate these Terms.
          </p>
        </div>
      </motion.section>

      <motion.section
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-semibold mb-4">
          7. Disclaimer of Warranties
        </h2>
        <p className="mb-4">
          {" "}
          Our services are provided "as is" and "as available" without any
          warranties of any kind, either express or implied. We do not guarantee
          that our services will be error-free, uninterrupted, or secure.
        </p>
      </motion.section>

      <motion.section
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-semibold mb-4">
          8. Limitation of Liability
        </h2>
        <p className="mb-4">
          To the fullest extent permitted by law, Zola shall not be liable for
          any indirect, incidental, special, consequential, or punitive damages,
          or any loss of profits or revenues, whether incurred directly or
          indirectly, or any loss of data, use, goodwill, or other intangible
          losses, resulting from (i) your use or inability to use our services;
          (ii) any unauthorized access to or use of our servers and/or any
          personal information stored therein; (iii) any interruption or
          cessation of transmission to or from our services; (iv) any bugs,
          viruses, trojan horses, or the like that may be transmitted to or
          through our services by any third party; (v) any errors or omissions
          in any content or for any loss or damage incurred as a result of the
          use of any content posted, emailed, transmitted, or otherwise made
          available through the services.
        </p>
      </motion.section>

      <motion.section
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-semibold mb-4">9. Indemnification</h2>
        <p className="mb-4">
          You agree to indemnify and hold harmless Zola, its affiliates,
          officers, directors, employees, and agents from any and all claims,
          damages, liabilities, costs, or expenses (including attorney's fees)
          arising from your use of our services, your violation of these Terms,
          or your infringement of any third-party rights.
        </p>
      </motion.section>

      <motion.section
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-semibold mb-4">10. Changes to Terms</h2>
        <p className="mb-4">
          We may update these Terms from time to time. If we make material
          changes, we will notify you by email or through a notice on our
          website. Your continued use of our services after any changes to these
          Terms constitutes your acceptance of the new Terms.
        </p>
      </motion.section>

      <motion.section
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-semibold mb-4">11. Governing Law</h2>
        <p className="mb-4">
          These Terms shall be governed by and construed in accordance with the
          laws of the state in which Zola is headquartered, without regard to
          its conflict of law principles.
        </p>
      </motion.section>

      <motion.section
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.6 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-semibold mb-4">12. Contact Us</h2>
        <p className="text-lg leading-relaxed">
          If you have any questions or concerns about these Terms, please
          contact us at{" "}
          <a
            href="mailto:support@zolatap.com"
            className="text-blue-500 underline"
          >
            support@zolatap.com
          </a>
          .
        </p>
      </motion.section>
    </motion.div>
  );
};

export default TermsOfService;
