import { NFCProvider } from "./context/NFCContext";
import { TimeRangeProvider } from "./context/TimeRangeContext";
import { SurveyProvider } from "./context/SurveyContext";
import { CompanyProfileProvider } from "./context/CompanyProfile";
import { CustomerContactsProvider } from "./context/CustomerContactsContext";
import { CampaignProvider } from "./context/CampaignContext";
import { IntegratedAccountsProvider } from "./context/IntegratedAccounts";
import { CouponProvider } from "./context/CouponContext";

function DashboardProviders({ children }) {
  return (
    <CompanyProfileProvider>
      <IntegratedAccountsProvider>
        <TimeRangeProvider>
          <CouponProvider>
            <CustomerContactsProvider>
              <CampaignProvider>
                <SurveyProvider key={localStorage.getItem("token")}>
                  <NFCProvider>
                    {children}
                  </NFCProvider>
                </SurveyProvider>
              </CampaignProvider>
            </CustomerContactsProvider>
          </CouponProvider>
        </TimeRangeProvider>
      </IntegratedAccountsProvider>
    </CompanyProfileProvider>
  );
}

export default DashboardProviders;
