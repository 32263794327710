import { useState } from "react";
import { Edit, Search, Trash } from "lucide-react";
import DeleteConfirmationPopup from "../DeletePopUp";
import { toast } from "react-toastify";
import UpdateCampaignForm from "./UpdateCampaignForm";
import { useNavigate } from "react-router-dom";  
export default function CampaignTable({
  campaigns,
  className,
  currentPage,
  totalPages,
  onPageChange,
  refetchCampaigns,
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);
  const [campaignToUpdate, setCampaignToUpdate] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate hook
  const toggleRowSelection = (name) => {
    setSelectedRows((prev) =>
      prev.includes(name) ? prev.filter((row) => row !== name) : [...prev, name]
    );
  };

  const handleUpdateClick = (campaign) => {
    setCampaignToUpdate(campaign);
    setIsUpdatePopupOpen(true);
  };

  const handleDeleteClick = (campaign) => {
    setCampaignToDelete(campaign);
    setIsDeletePopupOpen(true);
  };

  const confirmDelete = async () => {
    if (!campaignToDelete) return;

    try {
      const token = localStorage.getItem("token");
      await fetch(
        `https://zola.technology/api/company/campaigns/${campaignToDelete.id}/delete/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      refetchCampaigns(); // Refresh the campaign list
    } catch (error) {
      toast.error("Error deleting campaign:", error);
    }
    toast.success("Campaign deleted successfully");
    setIsDeletePopupOpen(false);
    setCampaignToDelete(null);
  };
  const statusColors = {
    Completed: "bg-green-100 text-green-700",
    Pending: "bg-gray-200 text-gray-600",
  };

  // **Filter campaigns based on search query**
  const filteredCampaigns = campaigns.filter(
    ({ name, date, status }) =>
      name.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      date.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      status.toLowerCase().includes(searchQuery.toLowerCase().trim())
  );

  return (
    <div className={` rounded-xl ${className}`}>
      {/* Filters & Search */}
      <div className="flex flex-wrap items-center justify-between mb-4 gap-3">
        {/* <Filters
          onFilterChange={(selectedFilters) =>
            console.log("Filters selected:", selectedFilters)
          }
        /> */}
        <p></p>
        <div className="relative">
          <Search className="absolute left-3 top-2.5 text-gray-400 w-4 h-4" />
          <input
            type="text"
            placeholder="Search campaigns..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg text-sm w-64 focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-600"
          />
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto rounded-lg border border-gray-200 dark:border-gray-700">
        <table className="text-sm bg-white dark:bg-gray-900">
          <thead className="bg-gray-50 dark:bg-gray-800 text-gray-500 dark:text-gray-400 font-light">
            <tr>
              {/* Select All Checkbox */}
              <th className="lg:flex gap-2 items-center font-normal">
                {/* <input type="checkbox" className="w-4 h-4 cursor-pointer" />{" "} */}
                Name
              </th>
              <th className="px-4 py-3 text-start font-normal">Date</th>
              <th className="px-4 py-3 text-start font-normal">Contacts</th>
              {/* <th className="px-4 py-3 text-start font-normal">Clicked</th> */}
              <th className="px-4 py-3 text-start font-normal">Status</th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {filteredCampaigns.length > 0 ? (
              filteredCampaigns.map((campaign) => (
                <tr
                  key={campaign.id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-800 transition"
                >
                  <td data-cell="Name" className="py-5 lg:flex items-center gap-1 dark:text-gray-300">
                    {/* <button
                      className="hidden lg:flex"
                      onClick={() => toggleRowSelection(campaign.name)}
                    >
                      {selectedRows.includes(campaign.name) ? (
                        <CheckSquare className="text-blue-600 w-4 h-4" />
                      ) : (
                        <Square className="text-gray-400 w-4 h-4" />
                      )}
                    </button> */}
                    {campaign.name}
                  </td>
                  <td data-cell="Date" className="py-5 text-gray-700 dark:text-gray-300">
                    {new Date(campaign.scheduled_at).toLocaleDateString()}
                  </td>

                  <td data-cell="Contacts" className="py-5 text-gray-700 dark:text-gray-300">
                    {campaign.delivered}
                  </td>
                  {/* <td data-cell="Clicked" className="py-5 text-gray-700 dark:text-gray-300">
                    {campaign.clicked}
                  </td> */}
                  <td data-cell="Status" className="py-5 text-gray-700 dark:text-gray-300">
                    <div className="flex items-center gap-6">
                      <span
                        className={`inline-flex items-center min-w-[100px] justify-center px-3 py-1 rounded-full text-xs font-medium ${
                          statusColors[campaign.status]
                        }`}
                      >
                        {campaign.status}
                      </span>
                      <p
                        // href="#"
                        onClick={() => navigate(`/dashboard/campaign-details/${campaign.id}`)} // Navigate to details page
                        className="text-blue-600 text-sm font-medium hover:underline cursor-pointer"
                      >
                        View report
                      </p>
                      <button
                        className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                        onClick={() => handleDeleteClick(campaign)}
                      >
                        <Trash className="w-5 h-5" />
                      </button>
                      <button
                        className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                        onClick={() => handleUpdateClick(campaign)}
                      >
                        <Edit className="w-5 h-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="py-6 text-center text-gray-500">
                  No campaign results found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center mt-4 space-x-3">
        <button
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
          className="px-4 py-2 border rounded-md text-gray-700 bg-white disabled:opacity-50"
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
          className="px-4 py-2 border rounded-md text-gray-700 bg-white disabled:opacity-50"
        >
          Next
        </button>
      </div>

      {isUpdatePopupOpen && (
        <UpdateCampaignForm
          campaign={campaignToUpdate}
          onClose={() => setIsUpdatePopupOpen(false)}
          refetchCampaigns={refetchCampaigns}
        />
      )}
      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setIsDeletePopupOpen(false)}
        onConfirm={confirmDelete}
        item={campaignToDelete?.name}
      />
    </div>
  );
}
